import * as Yup from 'yup';

const validationSchema = () => Yup.object().shape({
    type: Yup
        .string()
        .required('Required field'),
    start_date: Yup
        .date()
        .required('Required field'),
    transform_from: Yup
        .string()
        .required('Required field'),
    load_treatment: Yup
        .string()
        .required('Required field'),
    period: Yup
        .number()
        .min(1, 'Must be greater than or equal to 1')
        .test(
            'Period Activity Period required',
            'Required field',
            function (val) {
                if (
                    this.parent.type !== "crop"
                    && !val
                ) {
                    return false
                } else {
                    return true
                }
            }
        ),
        
    crop: Yup.object().shape({
        name: Yup
            .string()
            .test(
                'Crop name required',
                'Required field',
                function (val, obj) {
                    if (
                        obj.from[1].value.type === "crop"
                        && !this.parent.name
                    ) {
                        return false
                    } else {
                        return true
                    }
                }
            ),
        exp_yield: Yup
            .number()
            .notOneOf([0], 'This field cannot accept a zero value')
            .positive('Must be greater than 0')
            .test(
                'Crop Yield required',
                'Required field',
                function (val, obj) {
                    if (
                        obj.from[1].value.type === "crop"
                        && !this.parent.exp_yield
                    ) {
                        return false
                    } else {
                        return true
                    }
                }
            ),
        root_depth: Yup
            .number()
            .notOneOf([0], 'This field cannot accept a zero value')
            .positive('Must be greater than 0'),
        n_uptake: Yup
            .number()
            .min(0, 'Must be greater than or equal to 0'),
        NAG: Yup
            .number()
            .min(0, 'Must be greater than or equal to 0')
            .max(1, 'must be less than or equal to 1'),
        NBG: Yup
            .number()
            .min(0, 'Must be greater than or equal to 0')
            .max(1, 'must be less than or equal to 1'),
        RAG: Yup
            .number()
            .min(0, 'Must be greater than or equal to 0')
            .max(1, 'must be less than or equal to 1'),
        RS: Yup
            .number()
            .min(0, 'Must be greater than or equal to 0')
            .max(1, 'must be less than or equal to 1'),
        DRY: Yup
            .number()
            .min(0, 'Must be greater than or equal to 0')
            .max(1, 'must be less than or equal to 1'),
        renew_factor: Yup
            .number()
            .min(0, 'Must be greater than or equal to 0')
            .max(1, 'must be less than or equal to 1'),
        remove_factor: Yup
            .number()
            .min(0, 'Must be greater than or equal to 0')
            .max(1, 'must be less than or equal to 1'),
        dry_mass_carbon: Yup
            .number()
            .min(0, 'Must be greater than or equal to 0')
            .max(1, 'must be less than or equal to 1'),
        planting_period: Yup
            .number()
            .min(1, 'Must be greater than or equal to 1')
            .test(
                'Crop Period required',
                'Required field',
                function (val, obj) {
                    if (
                        obj.from[1].value.type === "crop"
                        && !this.parent.planting_period
                    ) {
                        return false
                    } else {
                        return true
                    }
                }
            ),
        dry_mass_heavy_metal_content: Yup.object().shape({
            Cd: Yup
                .number()
                .min(0, 'Must be greater than or equal to 0')
                .max(1, 'must be less than or equal to 1'),
            Cu: Yup
                .number()
                .min(0, 'Must be greater than or equal to 0')
                .max(1, 'must be less than or equal to 1'),
            Zn: Yup
                .number()
                .min(0, 'Must be greater than or equal to 0')
                .max(1, 'must be less than or equal to 1'),
            Pb: Yup
                .number()
                .min(0, 'Must be greater than or equal to 0')
                .max(1, 'must be less than or equal to 1'),
            Ni: Yup
                .number()
                .min(0, 'Must be greater than or equal to 0')
                .max(1, 'must be less than or equal to 1'),
            Cr: Yup
                .number()
                .min(0, 'Must be greater than or equal to 0')
                .max(1, 'must be less than or equal to 1'),
            Hg: Yup
                .number()
                .min(0, 'Must be greater than or equal to 0')
                .max(1, 'must be less than or equal to 1'),
            As: Yup
                .number()
                .min(0, 'Must be greater than or equal to 0')
                .max(1, 'must be less than or equal to 1'),
        }),
        seed: Yup.object().shape({
            type: Yup
                .string()
                .test(
                    'Crop Seed Type required',
                    'Required field',
                    function (val, obj) {
                        if (
                            obj.from[2].value.type === 'crop'
                            && !this.parent.type
                        ) {
                            return false
                        } else {
                            return true
                        }
                    }
                ),
            amount: Yup
                .number()
                .test(
                    'Crop Seed Amount required',
                    'Required field',
                    function (val, obj) {
                        if (
                            obj.from[2].value.type === 'crop'
                            && !this.parent.amount
                        ) {
                            return false
                        } else {
                            return true
                        }
                    }
                ),
            unit: Yup
                .string()
                .test(
                    'Crop Seed Unit required',
                    'Required field',
                    function (val, obj) {
                        if (
                            obj.from[2].value.type === 'crop'
                            && this.parent.type === 'seedling'
                            && !this.parent.unit
                        ) {
                            return false
                        } else {
                            return true
                        }
                    }
                ),
        })
    })
})

export default validationSchema

