import { Box, Divider, Typography } from '@mui/material'
import React from 'react'


function About() {

    return (
        <Box sx={{ width: { xs: 340, sm: 500, md: 800 } }}>
            <Typography variant="h6" sx={{my:2}}>
                <strong>Life Cycle Assessment (LCA)</strong>
            </Typography>
            <Divider />
            <Typography variant="body2" sx={{ my: 2 }}>
                LCA is a systemic and methodology applied as a technique to quantify potential environmental impacts of products and services. Consider all inputs and outputs over the lifecycle of the studied system. It is structured four main phases: objective and scope definition; life cycle inventory (LCI) analysis; life cycle assessment (LCIA) and interpretation.
            </Typography>
            
            <Typography variant="body2" sx={{ my: 2 }}>
                In the elaboration of the LCI, the compilation and quantification of the inputs and outputs of the studied system takes place. This phase involves the survey of input data, such as natural resources, agricultural inputs and fuel, and the calculation of emissions and solid waste. Calculation procedures for adjustments to the functional unit and allocation of environmental loads between product and co-products are also part of this step. These inventories can be constructed based on primary data, such as those measured in the field, or secondary data, obtained from technical and scientific literature.
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
                <strong>Authors</strong>
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
                <strong>Brazilian Agricultural Research Corporation (Embrapa)</strong><br/>
                Marília I. S. Folegatti, José Paulo Pereira das Dores Savioli, Fernando Henrique Cardoso, Anna Letícia M. T. Pighinelli, Nilza Patrícia Ramos, Renan M. L. Novaes, Danilo F. T. Garofalo, Vinícius G. Maciel, Cristiano A. Andrade, Adriana M. M. Pires, Natália Crespo Mendes, Marcelo A. B. Morandi, Cláudia Cristina S. Moore, Cláudia Vaz Crecci.
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
                <strong>Ideia Ninja Soluções Web LTDA</strong><br/> 
                Maurílio Oliveira de Souza
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
                Version 1.0.0
            </Typography>
            <Divider />
            <Typography variant="h6" sx={{ my: 2 }}>
                <strong>Changes</strong>
            </Typography>

            <Typography variant="body2" sx={{ mb: 2 }}>
                <strong>Version 1.0.1 (09/04/2024)</strong>
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
                The SimaPro file download request will be sent to the backend with the token in its header to perform authentication and grant access.
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
                The “Process Result” button has been deleted and all processing will be carried out by pressing the “Download File” button.
            </Typography>
            <Divider sx={{mb:2}}/>

            <Typography variant="body2" sx={{ mb: 2 }}>
                <strong>Version 1.0.2 (13/06/2024)</strong>
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
                The AWT token for authorization to access backend data has been changed from 30 minutes to 6 hours. 
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
                Correction of numeric decimal fields (e.g. 0.00233)
            </Typography>
            <Divider sx={{mb:2}}/>


            <Box sx={{mb:20}}></Box>

        </Box>
    )
}

export default About
