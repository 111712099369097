import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useAuth } from '../../hooks/useAuth';
import LogoProjetoBranco from '../img/ICVCalc_Embrapa_Icone.png'

export const NavBarHorizontalHome = (props) => {

    const { logout } = useAuth();
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar component="nav" sx={{ backgroundColor:'#EFF7EF'}}>
                <Toolbar>
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
                        <Box
                            display={'flex'}
                            flexDirection={'row'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            gap={2}
                            color='#038628'
                            sx={{ pl: { lg:0, xl:12 } }}
                        >
                            <Box
                                component={"img"}
                                alt="Capa ICV Calc"
                                src={LogoProjetoBranco}
                                loading="lazy"
                                sx={{ width: 60 }}
                            />
                            <Box >
                                <Typography variant="h6" component="div">ICVCalc - Embrapa</Typography>
                            </Box>
                        </Box>
                        
                        <Box display={'flex'}>
                            
                            <Box>
                                <Button
                                    sx={{
                                        //color: '#fff',
                                        textTransform: 'none',
                                        fontSize: '17px',
                                        color:'#038628'
                                    }}
                                    startIcon={<ExitToAppIcon />}
                                    onClick={logout}
                                >
                                    Exit
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box component="main" sx={{ p: 3 }}>
                <Toolbar />
            </Box>
        </Box>
    );
}


export default NavBarHorizontalHome
