import { combineReducers } from "redux";
import enumeratedSliceReducer from "./enumeratedSlice";
import resultsReducer from "./resultsSlice";
import userAllReducer from "./userAllSlice";
import userAuthReducer from "./userSlice";

const rootReducer = combineReducers({
  enumerated: enumeratedSliceReducer,
  results: resultsReducer,
  userAll: userAllReducer,
  userAuth: userAuthReducer,
});

export default rootReducer;
