import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import axios from "axios";
import { addProperty, checkGisIsValid } from "../../redux/userAllSlice";
import CloseIcon from '@mui/icons-material/CancelRounded';
import './property.css'

const validationSchema = Yup.object({
  state_code: Yup.string().required("Required field"),
  city_code: Yup.string().required("Required field"),
  name: Yup.string().required("Required field"),
});

export const PropertyFormAdd1 = (props) => {
  const {
    openPropertiesForm,
    setOpenPropertiesForm,
    addProperty,
  } = props;

  const [ufs, setUfs] = useState([]);
  const [cities, seCities] = useState([]);
  const [selectedUf, setSelectedUf] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  let user = ''

  if (localStorage.getItem("user") !== '') {
    user = JSON.parse(localStorage.getItem("user"));
  }

  const initialValues = {
    state_code: "",
    city_code: "",
    name: "",
    GIS: [],
  };

  const handleClose = () => {
    setOpenPropertiesForm(false);
    setSelectedUf("");
    setSelectedCity("");
    //setErrorGIS("")
  };

  useEffect(() => {
    axios.get("https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome")
      .then((response) => { setUfs(response.data); });
  }, []);

  useEffect(() => {
    axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedUf}/municipios`)
      .then((response) => { seCities(response.data);});
  }, [selectedUf]);

  const handleChangeUf = (newValue, setFieldValue) => {
    setFieldValue("state_code", newValue.id);
    setFieldValue("city_code", "");
    setSelectedCity("");
    setSelectedUf(newValue.id);
  };

  const handleChangeCities = (newValue, setFieldValue) => {
    setFieldValue("city_code", newValue.id);
    setSelectedCity(newValue);
  };

  const handleSubmit = async (values, setFieldValue, setFieldError, resetForm, errors) => {
    const params = {
      user_id: user.user_icv_id,
      payload: {
        city_code: values.city_code,
        name: values.name,
        GIS: values.GIS,
      },
    };

    const result = await addProperty(params);
    if (Boolean(result.payload.error)) {
      setFieldError("name", result.payload.error);
    } else {
      resetForm();
      handleClose();
    }
  };

  return (
    <div>
      <Dialog open={openPropertiesForm} onClose={handleClose} scroll="body">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm, setFieldValue, setFieldError, errors }) => {
            handleSubmit(values, setFieldValue, setFieldError, resetForm, errors);
          }}
        >
          {({ values, touched, errors, handleChange, setFieldValue, setFieldError, resetForm }) => {
            return (
              <>
                <Form noValidate autoComplete="off">
                  
                  <DialogTitle display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <strong>Add Property</strong>
                    <IconButton
                      aria-label="close"
                      onClick={handleClose}
                      sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'green',
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>

                  <Typography variant="body2" sx={{ ml: 3, mt: 1, color: "red", fontWeight: "bold" }}>
                    * Required fields
                  </Typography>

                  <DialogContent sx={{ minWidth: { xs: 400, sm: 500, md: 500, lg: 600 } }}>
                    <Grid container spacing={2}>
                      
                    <Grid item xxs={12} sm={6}>
                        <Autocomplete
                          autoFocus
                          size="small"
                          fullWidth
                          id="state_code"
                          name="state_code"
                          options={ufs}
                          getOptionLabel={(option) => option.nome}
                          onChange={(value, newValue) =>
                            handleChangeUf(newValue, setFieldValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              label="State of property"
                              error={touched.state_code && Boolean(errors.state_code)}
                              helperText={touched.state_code && errors.state_code}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xxs={12} sm={6}>
                        <Autocomplete
                          size="small"
                          fullWidth
                          id="city_code"
                          name="city_code"
                          options={cities || []}
                          getOptionLabel={(option) => option.nome || ""}
                          onChange={(value, newValue) =>
                            handleChangeCities(newValue, setFieldValue)
                          }
                          value={selectedCity}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              label="City of property"
                              error={touched.city_code && Boolean(errors.city_code)}
                              helperText={touched.city_code && errors.city_code}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xxs={12}>
                        <TextField
                          required
                          fullWidth
                          size="small"
                          name="name"
                          label="Name of property"
                          value={values.name}
                          onChange={handleChange}
                          error={Boolean(errors.name)}
                          helperText={errors.name}
                        />
                      </Grid>
                      
                    </Grid>
                    
                  </DialogContent>
                  <DialogActions>
                    <Box
                      display={"flex"}
                      gap={2}
                      justifyContent="flex-end"
                      mt={2}
                    >
                      <Button
                        size="small"
                        onClick={handleClose}
                        variant="outlined"
                        startIcon={<CancelRoundedIcon />}
                      >
                        Cancel
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        startIcon={<SaveRoundedIcon />}
                        autoFocus
                        type="submit"
                      >
                        Add
                      </Button>
                    </Box>
                  </DialogActions>
                </Form>
              </>
            );
          }}
        </Formik>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    addProperty: (params) => dispatch(addProperty(params)),
    checkGisIsValid: (params) => dispatch(checkGisIsValid(params))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PropertyFormAdd1);
