import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../services/api";

const initialState = {
  loading: true,
  enumerationEventOperationType: [],
  enumerationEventType: [],
  enumerationNameOperation: [],
  enumerationEventApplicationType: [],
  enumerationEventApplicationUnit: [],
  enumerationEventOperationMachinery: [],
  enumerationEventOperationMachineryImplements: [],
  enumerationEventOperationEcoinvet: [],
  enumerationPlotType: [],
  enumerationPlotTransformFrom: [],
  enumerationPlotLoadTreatment: [],
  enumerationPlotCropName: [],
  enumerationSeedType: [],
  enumerationSeedUnitSeedling: [],
  enumerationPlotTerrainSoilType: [],
  enumerationImplementsType: [],
  enumerationImplementsStype: [],
  enumerationImplementsSStype: [],
  enumerationMachineryType: [],
  enumerationMachineryFuelType: [],
  enumerationMachineryStype: [],
  enumerationMachinerySStype: [],
  enumerationProductMainType: [],
  enumerationProductCorretiveName: [],
  enumerationProductFertiliserType: [],
  enumerationProductFertiliserOtherNutrientsNames: [],
  enumerationProductFertiliserSolidManureNames: [],
  enumerationProductFertiliserLiquidManureNames: [],
  enumerationProductPesticideFromTableNames: [],
  enumerationProductPesticideActivePrinciplesNames: [],
  enumerationMachineryPowerType: [],
  enumerationEventFromEcoinventMaiOperationProducts: [],
  enumerationEventGenericMainOperationProducts: [],
  enumerationPesticideType: [],
  enumerationFertiliserName: [],
  enumerationFertiliserNpk: [],
  enumerationPesticideUnits: [],
  enumerationEventMainOperationProducts: [],
  enumerationFertiliserSourcesN: [],
  enumerationFertiliserSourcesP: [],
  enumerationFertiliserSourcesK: [],
  enumerationEventGenericHasTransport: "",
  enumerationEventGenericTransportType: [],
  enumerationEventGenericTransportMachinery: [],
  enumerationEventGenericTransportImplements: [],
  enumerationPesticideActivePrincipleUnits: [],
  checkPesticideFromTableHasDensity: false,
  enumerationPesticideFoliarInterception: [],
  enumerationWaterNames: [],
  enumerationEnergyNames: [],
  error: {},
};

export const fetchEnumerationEventOperationType = createAsyncThunk(
  "enumerationEventOperationType",
  (event_type) => {
    return (
      api
        //.get('/enumeration-event-operation-type')
        .get(`/enumeration-event-operation-type/${event_type}`)
        .then((res) => res.data)
    );
  }
);

export const fetchEnumerationEventTypeCrop = createAsyncThunk("enumerationEventTypeCrop", (params) => {
    const crop = params.crop || '';
    const p_type = params.p_type;
    if(crop !== ''){
      return (
        api
          .get(`/enumeration-event-type/plot-or-plot-terrain/${p_type}/crop/${crop}`)
          .then((res) => res.data)
      );
    } else {
      return (
        api
          .get(`/enumeration-event-type/plot-or-plot-terrain/${p_type}/crop/`)
          .then((res) => res.data)
      );
    }
  }
);

export const fetchEnumerationNameOperation = createAsyncThunk("enumerationNameOperation", (params) => {
    const crop = params.crop; 
    const p_type = params.p_type; 
    const event_type = params.event_type; 
    
    if(!crop){
      return api
        .get(`/enumeration-event-generic-main-operation-name/plot-terrain-or-plot/${p_type}/event/${event_type}/crop/`)
        .then((res) => res.data);
    } else {
      return api
        .get(`/enumeration-event-generic-main-operation-name/plot-terrain-or-plot/${p_type}/event/${event_type}/crop/${crop}`)
        .then((res) => res.data);
    }
  }
);

export const fetchEnumerationEventApplicationType = createAsyncThunk("fetchEnumerationEventApplicationType", (params) => {
    const op_name = params.op_name;
    return api
      .get(`/enumeration-event-application-type/${op_name}`)
      .then((res) => res.data);
  }
);

export const fetchEnumerationEventFromEcoinventMaiOperationProducts =
  createAsyncThunk(
    "fetchEnumerationEventFromEcoinventMaiOperationProducts",
    (params) => {
      const user_id = params.user_id;
      const op_name = params.op_name;
      const body = params.payload;
      return api
        .post(
          `/enumeration-event-from-ecoinvent-main-operation-products/${user_id}/operation/${op_name}`,
          body
        )
        .then((res) => res.data);
    }
  );

export const fetchEnumerationEventApplicationUnit = createAsyncThunk("enumerationEventApplicationUnit", (prod_id) => {
    return api
      .get(`/enumeration-event-application-unit/${prod_id}`)
      .then((res) => res.data)
      .catch((err)=> console.log(err.response))
  }
);

export const fetchEnumeracionEventOperationMachinery = createAsyncThunk(
  "enumerationEventOperationMachinery",
  (params) => {
    const user_id = params.user_id;
    const prop_id = params.prop_id;
    const event_type = params.event_type;
    const op_name = params.op_name;

    return api
      .get(
        `/enumeration-event-generic-main-operation-machinery/${user_id}/property/${prop_id}/event-type/${event_type}/operation/${op_name}`
      )
      .then((res) => res.data)
      .catch((error) => error.response.data);
  }
);

export const fetchEnumerationEventGenericTransportMachinery = createAsyncThunk("fetchEnumerationEventGenericTransportMachinery", (params) => {
    const user_id = params.user_id;
    const prop_id = params.prop_id;
    const op_name = params.op_name;
    const t_name = params.t_name;

    return api
      .get(`/enumeration-event-generic-transport-machinery/${user_id}/property/${prop_id}/operation/${op_name}/transport/${t_name}`)
      .then((res) => res.data)
      .catch((error) => {
        if(error?.response?.status === 404){
          return error?.response?.data;
        } else if(error?.response?.status === 409){
          return error?.response?.data;
        } else if (error?.response?.status === 422) {
          return error?.response?.data;
        } else if (error?.response?.status === 500) {
          return {detail: 'Unable to access data, contact your system administrator.'};
        } else {
          return {detail: error.message} 
        }
      })
  }
);

export const fetchEnumerationEventOperationMachineryImplements = createAsyncThunk("enumerationEventOperationMachineryImplements", (params) => {
  const user_id = params.user_id; //'71af7439-548a-4a0e-baaf-e21e540f0964' //params.user_id
  const prop_id = params.prop_id;
  const mach_spectype = params.mach_spectype;
  const op_name = params.op_name; //'Surface Fertilization' //params.op_name

  return api
    .get(`/enumeration-event-generic-main-operation-implements/${user_id}/property/${prop_id}/machinery-type/${mach_spectype}/operation/${op_name}`)
    .then((res) => res.data)
    .catch((error) => error.response.data);
});



//>>>>>>>>>>>>>>>>>>>>>>>>>

export const fetchEnumerationEventGenericTransportImplements = createAsyncThunk("fetchEnumerationEventGenericTransportImplements", (params) => {
    const user_id = params.user_id;
    const prop_id = params.prop_id;
    const op_name = params.op_name;
    const t_name = params.t_name;
    const mach_spectype = params.mach_spectype;

    return api
      .get(`/enumeration-event-generic-transport-implements/${user_id}/property/${prop_id}/operation/${op_name}/transport/${t_name}/machinery-type/${mach_spectype}`)
      .then((res) => res.data)
      .catch((error) => error.response.data);
  }
);

export const fetchEnumerationEventOperationEcoinvet = createAsyncThunk( "enumerationEventOperationEcoinvet", (params) => {
    const crop = params.crop;
    const event_type = params.event_type;
    const p_type = params.p_type;

    if(!crop){
      return api
        .get(`/enumeration-event-operation-ecoinvent/plot-terrain-or-plot/${p_type}/event/${event_type}/crop/`)
        .then(res => res.data)
    } else {
      return api
        .get(`/enumeration-event-operation-ecoinvent/plot-terrain-or-plot/${p_type}/event/${event_type}/crop/${crop}`)
        .then((res) => res.data);
    }
  }
);

export const fetchEnumerationPlotType = createAsyncThunk(
  "enumerationPlotType",
  () => {
    return api.get(`/enumeration-plot-type/`).then((res) => res.data);
  }
);

export const fetchEnumerationSeedType = createAsyncThunk(
  "enumerationSeedType",
  (crop) => {
    return api
      .get(`/enumeration-plot-crop-seed-type/${crop}`)
      .then((res) => res.data);
  }
);

export const fetchEnumerationSeedUnitSeedling = createAsyncThunk( "enumerationSeedUnitSeedling", () => {
    return api
      .get(`/enumeration-plot-crop-seed-unit-seedling/`)
      .then((res) => res.data);
  }
);

export const fetchEnumerationPlotTransformFrom = createAsyncThunk(
  "enumerationPlotTransformFrom",
  () => {
    return api.get(`/enumeration-plot-transform-from/`).then((res) => res.data);
  }
);

export const fetchEnumerationPlotLoadTreatment = createAsyncThunk(
  "enumerationPlotLoadTreatment",
  () => {
    return api.get(`/enumeration-plot-load-treatment/`).then((res) => res.data);
  }
);

export const fetchEnumerationPlotCropName = createAsyncThunk(
  "enumerationPlotCropName",
  () => {
    return api.get(`/enumeration-plot-crop-name/`).then((res) => res.data);
  }
);

export const fetchEnumerationPlotTerrainSoilType = createAsyncThunk(
  "enumerationPlotTerrainSoilType",
  () => {
    return api
      .get(`/enumeration-plot-terrain-soil-type/`)
      .then((res) => res.data);
  }
);

export const fetchEnumerationImplementsType = createAsyncThunk(
  "enumerationImplementsType",
  () => {
    return api.get(`/enumeration-implement-type/`).then((res) => res.data);
  }
);

export const fetchEnumerationImplementsStype = createAsyncThunk(
  "enumerationImplementsStype",
  () => {
    return api.get(`/enumeration-implement-stype/`).then((res) => res.data);
  }
);

export const fetchEnumerationImplementsSStype = createAsyncThunk(
  "fetchEnumerationImplementsSStype",
  (stype) => {
    return api
      .get(`/enumeration-implement-ssubtype/${stype}`)
      .then((res) => res.data);
  }
);

export const fetchEnumerationMachineryType = createAsyncThunk(
  "fetchEnumerationMachineryType",
  () => {
    return api.get(`/enumeration-machinery-type/`).then((res) => res.data);
  }
);

export const fetchEnumerationMachineryFuelType = createAsyncThunk(
  "fetchEnumerationMachineryFuelType",
  () => {
    return api.get(`/enumeration-machinery-fuel-type/`).then((res) => res.data);
  }
);

export const fetchEnumerationMachineryStype = createAsyncThunk(
  "fetchEnumerationMachineryStype",
  (type_) => {
    return api
      .get(`/enumeration-machinery-stype/${type_}`)
      .then((res) => res.data);
  }
);

export const fetchEnumerationMachinerySStype = createAsyncThunk(
  "fetchEnumerationMachinerySStype",
  (stype) => {
    return api
      .get(`/enumeration-machinery-ssubtype/${stype}`)
      .then((res) => res.data);
  }
);

export const fetchEnumerationProductMainType = createAsyncThunk(
  "fetchEnumerationProductMainType",
  () => {
    return api.get(`/enumeration-product-main-type/`).then((res) => res.data);
  }
);

export const fetchEnumerationProductCorretiveName = createAsyncThunk( "fetchEnumerationProductCorretiveName", async () => {
    return await api.get(`/enumeration-corrective-names/`).then((res) => res.data);
  }
);

export const fetchEnumerationProductFertiliserType = createAsyncThunk(
  "fetchEnumerationProductFertiliserType",
  () => {
    return api.get(`/enumeration-fertiliser-type/`).then((res) => res.data);
  }
);

export const fetchEnumerationProductFertiliserOtherNutrientsNames =
  createAsyncThunk(
    "fetchEnumerationProductFertiliserOtherNutrientsNames",
    () => {
      return api
        .get(`/enumeration-other-nutrient-names/`)
        .then((res) => res.data);
    }
  );

export const fetchEnumerationProductFertiliserSolidManureNames =
  createAsyncThunk("fetchEnumerationProductFertiliserSolidManureNames", () => {
    return api
      .get(`/enumeration-fertiliser-solid-manure/`)
      .then((res) => res.data);
  });

export const fetchEnumerationProductFertiliserLiquidManureNames =
  createAsyncThunk("fetchEnumerationProductFertiliserLiquidManureNames", () => {
    return api
      .get(`/enumeration-fertiliser-liquid-manure/`)
      .then((res) => res.data);
  });
export const fetchEnumerationProductPesticideFromTableNames = createAsyncThunk(
  "fetchEnumerationProductPesticideFromTableNames",
  () => {
    return api.get(`/enumeration-pesticide-name/`).then((res) => res.data);
  }
);
export const fetchEnumerationProductPesticideActivePrinciplesNames = createAsyncThunk( "fetchEnumerationProductPesticideActivePrinciplesNames", () => {
      return api
        .get(`/enumeration-pesticide-active-principles/`)
        .then((res) => res.data);
    }
  );

export const fetchEnumerationMachineryPowerType = createAsyncThunk(
  "fetchEnumerationMachineryPowerType",
  () => {
    return api
      .get(`/enumeration-machinery-power-type/`)
      .then((res) => res.data);
  }
);

export const fetchEnumerationEventGenericMainOperationProducts =
  createAsyncThunk(
    "fetchEnumerationEventGenericMainOperationProducts",
    (params) => {
      const user_id = params.user_id;
      const op_name = params.op_name;
      const body = params.payload;
      return api
        .post(
          `/enumeration-event-generic-main-operation-products/${user_id}/operation/${op_name}`,
          body
        )
        .then((res) => ({ data: res.data, params }));
    }
  );

export const fetchEnumerationPesticideType = createAsyncThunk(
  "fetchEnumerationPesticideType",
  () => {
    return api.get(`/enumeration-pesticide-type/`).then((res) => res.data);
  }
);

export const fetchEnumerationFertiliserName = createAsyncThunk(
  "fetchEnumerationFertiliserName",
  () => {
    return api.get(`/enumeration-fertiliser-name/`).then((res) => res.data);
  }
);

export const fetchEnumerationFertiliserNpk = createAsyncThunk("fetchEnumerationFertiliserNpk",(name) => {
    return api
      .get(`/enumeration-fertiliser-npk/${name}`)
      .then((res) => res.data);
  }
);

export const fetchEnumerationPesticideUnits = createAsyncThunk(
  "fetchEnumerationPesticideUnits",
  () => {
    return api.get(`/enumeration-pesticide-units/`).then((res) => res.data);
  }
);

export const fetchEnumerationEventMainOperationProducts = createAsyncThunk( "fetchEnumerationEventMainOperationProducts", (params) => {
    const user_id = params.user_id;
    const prop_id = params.prop_id;
    const type_ = params.type_;
    return (
      api
        .get(`/enumeration-event-main-operation-products/${user_id}/property/${prop_id}/type/${type_}`)
        .then((res) => res.data)
    );
  }
);

export const fetchEnumerationFertiliserSourcesN = createAsyncThunk(
  "fetchEnumerationFertiliserSourcesN",
  () => {
    return api.get(`/enumeration-fertiliser-sources/n`).then((res) => res.data);
  }
);

export const fetchEnumerationFertiliserSourcesP = createAsyncThunk(
  "fetchEnumerationFertiliserSourcesP",
  () => {
    return api.get(`/enumeration-fertiliser-sources/p`).then((res) => res.data);
  }
);

export const fetchEnumerationFertiliserSourcesK = createAsyncThunk(
  "fetchEnumerationFertiliserSourcesK",
  () => {
    return api.get(`/enumeration-fertiliser-sources/k`).then((res) => res.data);
  }
);

export const fetchEnumerationEventGenericHasTransport = createAsyncThunk(
  "fetchEnumerationEventGenericHasTransport",
  (op_name) => {
    return (
      api
        .get(`/check-event-generic-has-transport/${op_name}`)
        .then((res) => res.data)
        .catch((error) => {
          if (error.response.status === 422) {
            return false;
          } else if (error.response.status === 422) {
            return false;
          }
        })
    );
  }
);


export const fetchEnumerationEventGenericTransportType = createAsyncThunk(
  "fetchEnumerationEventGenericTransportType",
  (op_name) => {
    return api
      .get(`/enumeration-event-generic-transport-type/${op_name}`)
      .then((res) => res.data);
  }
);

export const fetchCheckPesticideFromTableHasDensity = createAsyncThunk(
  "fetchCheckPesticideFromTableHasDensity",
  (pest_name) => {
    return api
      .get(`/check-pesticide-from-table-has-density/${pest_name}`)
      .then((res) => res.data);
  }
);

export const fetchEnumerationPesticideActivePrincipleUnits = createAsyncThunk(
  "fetchEnumerationPesticideActivePrincipleUnits",
  () => {
    return api
      .get(`/enumeration-pesticide-active-principle-units/`)
      .then((res) => res.data);
  }
);

export const checkEventGenericHasMachinery = createAsyncThunk(
  "checkEventGenericHasMachinery",
  (op_name) => {
    return api
      .get(`/check-event-generic-has-machinery/${op_name}`)
      .then((res) => res.data);
  }
);

export const checkEventTransportHasMachinery = createAsyncThunk(
  "checkEventTransportHasMachinery",
  (params) => {
    const op_name = params.op_name;
    const t_name = params.t_name;
    return api
      .get(`/check-event-transport-has-machinery/${op_name}/transport/${t_name}`)
      .then((res) => res.data);
  }
);

export const fetchEnumerationPesticideFoliarInterception = createAsyncThunk(
  "fetchEnumerationPesticideFoliarInterception",
  (params) => {
    const crop = params.crop;
    return api
      .get(`/enumeration-pesticide-foliar-interception/${crop}`)
      .then((res) => res.data);
  }
);

export const fetchEnumerationPesticideApplicationMethods = createAsyncThunk(
  "fetchEnumerationPesticideApplicationMethods",
  (params) => {
    const crop = params.crop;
    return api
      .get(`/enumeration-pesticide-application-methods/${crop}`)
      .then((res) => res.data);
  }
);

export const fetchEnumerationDatabaseName = createAsyncThunk("fetchEnumerationDatabaseName", async () => {
    return await api
    .get(`/enumeration-database-name/`)
    .then((res) => res.data);
  }
);

export const fetchEnumerationWaterNames = createAsyncThunk('fetchEnumerationWaterNames', async () => {
  return await api
    .get(`/enumeration-water-names/`)
    .then((res)=> res.data)
})

export const fetchEnumerationEnergyNames = createAsyncThunk('fetchEnumerationEnergyNames', async () => {
  return await api
    .get(`/enumeration-energy-names/`)
    .then((res) => res.data)
})

export const slice = createSlice({
  name: "enumerations",
  initialState,
  reducers: {
    resetEnumerationEventOperationMachineryImplements: (state, action) => {
      state.enumerationEventOperationMachineryImplements = [];
    },
    resetEnumerationEventGenericTransportImplements: (state, action) => {
      state.enumerationEventGenericTransportImplements = [];
    },
    resetCheckPesticideFromTableHasDensity: (state, action) => {
      state.checkPesticideFromTableHasDensity = [];
    },
    resetEnumerationFertiliserNpk: (state, action) => {
      state.enumerationFertiliserNpk = [];
    }
  },
  extraReducers: (builder) => {
    // Enumeration event operations type
    builder.addCase(fetchEnumerationEventOperationType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchEnumerationEventOperationType.fulfilled,
      (state, action) => {
        state.loading = false;
        //action.payload && action.payload.sort((a, b) => a.trim() < b.trim() ? -1 : 1)
        state.enumerationEventOperationType = action.payload;
        state.error = {};
      }
    );
    builder.addCase(
      fetchEnumerationEventOperationType.rejected,
      (state, action) => {
        state.loading = false;
        state.enumerationEventOperationType = [];
        state.error = action.error.message;
      }
    );

    // Enumeration event type
    builder.addCase(fetchEnumerationEventTypeCrop.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchEnumerationEventTypeCrop.fulfilled,
      (state, action) => {
        state.loading = false;
        action.payload &&
          action.payload.sort((a, b) => (a.trim() < b.trim() ? -1 : 1));
        state.enumerationEventType = action.payload;
        state.error = {};
      }
    );
    builder.addCase(fetchEnumerationEventTypeCrop.rejected, (state, action) => {
      state.loading = false;
      state.enumerationEventType = [];
      state.error = action.error.message;
    });

    // Enumeration event type
    builder.addCase(fetchEnumerationNameOperation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchEnumerationNameOperation.fulfilled,
      (state, action) => {
        state.loading = false;
        action.payload &&
          action.payload.sort((a, b) => (a.trim() < b.trim() ? -1 : 1));
        state.enumerationNameOperation = action.payload;
        state.error = {};
      }
    );
    builder.addCase(fetchEnumerationNameOperation.rejected, (state, action) => {
      state.loading = false;
      state.enumerationNameOperation = [];
      state.error = action.error.message;
    });
    // Enumeration application type
    builder.addCase(fetchEnumerationEventApplicationType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchEnumerationEventApplicationType.fulfilled,
      (state, action) => {
        state.loading = false;
        action.payload &&
          action.payload.sort((a, b) => (a.trim() < b.trim() ? -1 : 1));
        state.enumerationEventApplicationType = action.payload;
        state.error = {};
      }
    );
    builder.addCase(
      fetchEnumerationEventApplicationType.rejected,
      (state, action) => {
        state.loading = false;
        state.enumerationEventApplicationType = [];
        state.error = action.error.message;
      }
    );

    // Enumeration event application unit
    builder.addCase(fetchEnumerationEventApplicationUnit.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchEnumerationEventApplicationUnit.fulfilled,
      (state, action) => {
        state.loading = false;
        action.payload &&
          action.payload.sort((a, b) => (a.trim() < b.trim() ? -1 : 1));
        state.enumerationEventApplicationUnit = action.payload;
        state.error = {};
      }
    );
    builder.addCase(
      fetchEnumerationEventApplicationUnit.rejected,
      (state, action) => {
        state.loading = false;
        state.enumerationEventApplicationUnit = [];
        state.error = action.error.message;
      }
    );

    // Enumeration event generic main operation machinery
    builder.addCase( fetchEnumeracionEventOperationMachinery.pending, (state) => {
        state.loading = true;
      }
    );
    builder.addCase( fetchEnumeracionEventOperationMachinery.fulfilled, (state, action) => {
      state.loading = false;
      state.enumerationEventOperationMachinery = action.payload;
      if (!!action.payload.detail) {
        state.enumerationEventOperationMachinery = {
          error: action.payload.detail,
        };
      } else {
        state.enumerationEventOperationMachinery = {
          data: action.payload,
        };
      }
      state.error = {};
    });
    
    builder.addCase(fetchEnumeracionEventOperationMachinery.rejected, (state, action) => {
        state.loading = false;
        state.enumerationEventOperationMachinery = action.payload;
        state.error = action.error.message;
      }
    );

    // Enumeration event generic transport machinery
    builder.addCase(fetchEnumerationEventGenericTransportMachinery.pending, (state) => {
        state.loading = true;
      }
    );
    builder.addCase(fetchEnumerationEventGenericTransportMachinery.fulfilled, (state, action) => {
        state.loading = false;
        if (!!action.payload.error) {state.enumerationEventGenericTransportMachinery = {
          error: action.payload.error,
        };
        } else if (!!action.payload.detail) {state.enumerationEventGenericTransportMachinery = {
            error: action.payload.detail,
          };
        } else {
          state.enumerationEventGenericTransportMachinery = ({data: [action.payload]})
        }
        state.error = {};
      }
    );
    builder.addCase(fetchEnumerationEventGenericTransportMachinery.rejected, (state, action) => {
        state.loading = false;
        state.enumerationEventGenericTransportMachinery = action.payload;
        state.error = action.error.message;
      }
    );

    // Enumeration event generic main operation machinery implements
    builder.addCase(fetchEnumerationEventOperationMachineryImplements.pending, (state) => { state.loading = true; });
    builder.addCase(fetchEnumerationEventOperationMachineryImplements.fulfilled, (state, action) => {
        state.loading = false;
        state.enumerationEventOperationMachineryImplements = action.payload;
        state.error = {};
      }
    );
    builder.addCase(fetchEnumerationEventOperationMachineryImplements.rejected,(state, action) => {
        state.loading = false;
        state.enumerationEventOperationMachineryImplements = [];
        state.error = action.error.message;
      }
    );

    // Enumeration event generic transport implements
    builder.addCase(fetchEnumerationEventGenericTransportImplements.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchEnumerationEventGenericTransportImplements.fulfilled, (state, action) => {
      state.loading = false;
      state.enumerationEventGenericTransportImplements = action.payload;
      state.error = {};
    });
    builder.addCase(
      fetchEnumerationEventGenericTransportImplements.rejected,
      (state, action) => {
        state.loading = false;
        state.enumerationEventGenericTransportImplements = [];
        state.error = action.error.message;
      }
    );

    // Enumeration event operation ecoinvent
    builder.addCase(fetchEnumerationEventOperationEcoinvet.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchEnumerationEventOperationEcoinvet.fulfilled,
      (state, action) => {
        state.loading = false;
        action.payload &&
          action.payload.sort((a, b) => (a.trim() < b.trim() ? -1 : 1));
        state.enumerationEventOperationEcoinvet = action.payload;
        state.error = {};
      }
    );
    builder.addCase(
      fetchEnumerationEventOperationEcoinvet.rejected,
      (state, action) => {
        state.loading = false;
        state.enumerationEventOperationEcoinvet = [];
        state.error = action.error.message;
      }
    );

    // Enumeration plot type
    builder.addCase(fetchEnumerationPlotType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchEnumerationPlotType.fulfilled, (state, action) => {
      state.loading = false;
      action.payload &&
        action.payload.sort((a, b) => (a.trim() < b.trim() ? -1 : 1));
      state.enumerationPlotType = action.payload;
      state.error = {};
    });
    builder.addCase(fetchEnumerationPlotType.rejected, (state, action) => {
      state.loading = false;
      state.enumerationPlotType = [];
      state.error = action.error.message;
    });

    // Enumeration seed type
    builder.addCase(fetchEnumerationSeedType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchEnumerationSeedType.fulfilled, (state, action) => {
      state.loading = false;
      state.enumerationSeedType = action.payload;
      state.error = {};
    });
    builder.addCase(fetchEnumerationSeedType.rejected, (state, action) => {
      state.loading = false;
      state.enumerationSeedType = [];
      state.error = action.error.message;
    });

    // Enumeration seed Unit Seedling
    builder.addCase(fetchEnumerationSeedUnitSeedling.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchEnumerationSeedUnitSeedling.fulfilled,
      (state, action) => {
        state.loading = false;
        action.payload &&
          action.payload.sort((a, b) => (a.trim() < b.trim() ? -1 : 1));
        state.enumerationSeedUnitSeedling = action.payload;
        state.error = {};
      }
    );
    builder.addCase(
      fetchEnumerationSeedUnitSeedling.rejected,
      (state, action) => {
        state.loading = false;
        state.enumerationSeedUnitSeedling = [];
        state.error = action.error.message;
      }
    );

    // Enumeration plot transform from
    builder.addCase(fetchEnumerationPlotTransformFrom.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchEnumerationPlotTransformFrom.fulfilled,
      (state, action) => {
        state.loading = false;
        action.payload &&
          action.payload.sort((a, b) => (a.trim() < b.trim() ? -1 : 1));
        state.enumerationPlotTransformFrom = action.payload;
        state.error = {};
      }
    );
    builder.addCase(
      fetchEnumerationPlotTransformFrom.rejected,
      (state, action) => {
        state.loading = false;
        state.enumerationPlotTransformFrom = [];
        state.error = action.error.message;
      }
    );

    // Enumeration plot load treatment
    builder.addCase(fetchEnumerationPlotLoadTreatment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchEnumerationPlotLoadTreatment.fulfilled,
      (state, action) => {
        state.loading = false;
        action.payload &&
          action.payload.sort((a, b) => (a.trim() < b.trim() ? -1 : 1));
        state.enumerationPlotLoadTreatment = action.payload;
        state.error = {};
      }
    );
    builder.addCase(
      fetchEnumerationPlotLoadTreatment.rejected,
      (state, action) => {
        state.loading = false;
        state.enumerationPlotLoadTreatment = [];
        state.error = action.error.message;
      }
    );

    // Enumeration plot crop name
    builder.addCase(fetchEnumerationPlotCropName.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchEnumerationPlotCropName.fulfilled, (state, action) => {
      state.loading = false;
      action.payload &&
        action.payload.sort((a, b) => (a.trim() < b.trim() ? -1 : 1));
      state.enumerationPlotCropName = action.payload;
      state.error = {};
    });
    builder.addCase(fetchEnumerationPlotCropName.rejected, (state, action) => {
      state.loading = false;
      state.enumerationPlotCropName = [];
      state.error = action.error.message;
    });
    // Enumeration plot terrain soil type
    builder.addCase(fetchEnumerationPlotTerrainSoilType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchEnumerationPlotTerrainSoilType.fulfilled,
      (state, action) => {
        state.loading = false;
        action.payload &&
          action.payload.sort((a, b) => (a.trim() < b.trim() ? -1 : 1));
        state.enumerationPlotTerrainSoilType = action.payload;
        state.error = {};
      }
    );
    builder.addCase(
      fetchEnumerationPlotTerrainSoilType.rejected,
      (state, action) => {
        state.loading = false;
        state.enumerationPlotTerrainSoilType = [];
        state.error = action.error.message;
      }
    );
    // Enumeration implements type
    builder.addCase(fetchEnumerationImplementsType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchEnumerationImplementsType.fulfilled,
      (state, action) => {
        state.loading = false;
        action.payload &&
          action.payload.sort((a, b) => (a.trim() < b.trim() ? -1 : 1));
        state.enumerationImplementsType = action.payload;
        state.error = {};
      }
    );
    builder.addCase(
      fetchEnumerationImplementsType.rejected,
      (state, action) => {
        state.loading = false;
        state.enumerationImplementsType = [];
        state.error = action.error.message;
      }
    );
    // Enumeration implements specific type
    builder.addCase(fetchEnumerationImplementsStype.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchEnumerationImplementsStype.fulfilled,
      (state, action) => {
        state.loading = false;
        action.payload &&
          action.payload.sort((a, b) => (a.trim() < b.trim() ? -1 : 1));
        state.enumerationImplementsStype = action.payload;
        state.error = {};
      }
    );
    builder.addCase(
      fetchEnumerationImplementsStype.rejected,
      (state, action) => {
        state.loading = false;
        state.enumerationImplementsStype = [];
        state.error = action.error.message;
      }
    );
    // Enumeration implements specific sub type
    builder.addCase(fetchEnumerationImplementsSStype.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchEnumerationImplementsSStype.fulfilled,
      (state, action) => {
        state.loading = false;
        action.payload &&
          action.payload.sort((a, b) => (a.trim() < b.trim() ? -1 : 1));
        state.enumerationImplementsSStype = action.payload;
        state.error = {};
      }
    );
    builder.addCase(
      fetchEnumerationImplementsSStype.rejected,
      (state, action) => {
        state.loading = false;
        state.enumerationImplementsSStype = [];
        state.error = action.error.message;
      }
    );

    // Enumeration machinery type
    builder.addCase(fetchEnumerationMachineryType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchEnumerationMachineryType.fulfilled,
      (state, action) => {
        state.loading = false;
        action.payload &&
          action.payload.sort((a, b) => (a.trim() < b.trim() ? -1 : 1));
        state.enumerationMachineryType = action.payload;
        state.error = {};
      }
    );
    builder.addCase(fetchEnumerationMachineryType.rejected, (state, action) => {
      state.loading = false;
      state.enumerationMachineryType = [];
      state.error = action.error.message;
    });

    // Enumeration machinery fuel type
    builder.addCase(fetchEnumerationMachineryFuelType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchEnumerationMachineryFuelType.fulfilled,
      (state, action) => {
        state.loading = false;
        action.payload &&
          action.payload.sort((a, b) => (a.trim() < b.trim() ? -1 : 1));
        state.enumerationMachineryFuelType = action.payload;
        state.error = {};
      }
    );
    builder.addCase(
      fetchEnumerationMachineryFuelType.rejected,
      (state, action) => {
        state.loading = false;
        state.enumerationMachineryFuelType = [];
        state.error = action.error.message;
      }
    );

    // Enumeration machinery Specific type
    builder.addCase(fetchEnumerationMachineryStype.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchEnumerationMachineryStype.fulfilled,
      (state, action) => {
        state.loading = false;
        action.payload && action.payload.sort((a, b) => (a < b ? -1 : 1));
        state.enumerationMachineryStype = action.payload;
        state.error = {};
      }
    );
    builder.addCase(
      fetchEnumerationMachineryStype.rejected,
      (state, action) => {
        state.loading = false;
        state.enumerationMachineryStype = [];
        state.error = action.error.message;
      }
    );
    // Enumeration machinery Specific Sub type
    builder.addCase(fetchEnumerationMachinerySStype.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchEnumerationMachinerySStype.fulfilled,
      (state, action) => {
        state.loading = false;
        action.payload && action.payload.sort((a, b) => (a < b ? -1 : 1));
        state.enumerationMachinerySStype = action.payload;
        state.error = {};
      }
    );
    builder.addCase(
      fetchEnumerationMachinerySStype.rejected,
      (state, action) => {
        state.loading = false;
        state.enumerationMachinerySStype = [];
        state.error = action.error.message;
      }
    );
    // Enumeration product Main Type
    builder.addCase(fetchEnumerationProductMainType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchEnumerationProductMainType.fulfilled,
      (state, action) => {
        state.loading = false;
        action.payload && action.payload.sort((a, b) => (a < b ? -1 : 1));
        state.enumerationProductMainType = action.payload;
        state.error = {};
      }
    );
    builder.addCase(
      fetchEnumerationProductMainType.rejected,
      (state, action) => {
        state.loading = false;
        state.enumerationProductMainType = [];
        state.error = action.error.message;
      }
    );

    // Enumeration product corretive names
    builder.addCase(fetchEnumerationProductCorretiveName.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchEnumerationProductCorretiveName.fulfilled,
      (state, action) => {
        state.loading = false;
        action.payload && action.payload.sort((a, b) => (a < b ? -1 : 1));
        state.enumerationProductCorretiveName = action.payload;
        state.error = {};
      }
    );
    builder.addCase(
      fetchEnumerationProductCorretiveName.rejected,
      (state, action) => {
        state.loading = false;
        state.enumerationProductCorretiveName = [];
        state.error = action.error.message;
      }
    );

    // Enumeration product fertiliser types
    builder.addCase(fetchEnumerationProductFertiliserType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchEnumerationProductFertiliserType.fulfilled,
      (state, action) => {
        state.loading = false;
        //action.payload && action.payload.sort((a, b) => a < b ? -1 : 1)
        state.enumerationProductFertiliserType = action.payload;
        state.error = {};
      }
    );
    builder.addCase(
      fetchEnumerationProductFertiliserType.rejected,
      (state, action) => {
        state.loading = false;
        state.enumerationProductFertiliserType = [];
        state.error = action.error.message;
      }
    );

    // Enumeration product fertiliser other nutrients names
    builder.addCase(
      fetchEnumerationProductFertiliserOtherNutrientsNames.pending,
      (state) => {
        state.loading = true;
      }
    );
    builder.addCase(
      fetchEnumerationProductFertiliserOtherNutrientsNames.fulfilled,
      (state, action) => {
        state.loading = false;
        action.payload && action.payload.sort((a, b) => (a < b ? -1 : 1));
        state.enumerationProductFertiliserOtherNutrientsNames = action.payload;
        state.error = {};
      }
    );
    builder.addCase(
      fetchEnumerationProductFertiliserOtherNutrientsNames.rejected,
      (state, action) => {
        state.loading = false;
        state.enumerationProductFertiliserOtherNutrientsNames = [];
        state.error = action.error.message;
      }
    );

    // Enumeration product fertiliser solid manure names
    builder.addCase(
      fetchEnumerationProductFertiliserSolidManureNames.pending,
      (state) => {
        state.loading = true;
      }
    );
    builder.addCase(
      fetchEnumerationProductFertiliserSolidManureNames.fulfilled,
      (state, action) => {
        state.loading = false;
        action.payload && action.payload.sort((a, b) => (a < b ? -1 : 1));
        state.enumerationProductFertiliserSolidManureNames = action.payload;
        state.error = {};
      }
    );
    builder.addCase(
      fetchEnumerationProductFertiliserSolidManureNames.rejected,
      (state, action) => {
        state.loading = false;
        state.enumerationProductFertiliserSolidManureNames = [];
        state.error = action.error.message;
      }
    );

    // Enumeration product fertiliser liquid manure names
    builder.addCase(
      fetchEnumerationProductFertiliserLiquidManureNames.pending,
      (state) => {
        state.loading = true;
      }
    );
    builder.addCase(
      fetchEnumerationProductFertiliserLiquidManureNames.fulfilled,
      (state, action) => {
        state.loading = false;
        action.payload && action.payload.sort((a, b) => (a < b ? -1 : 1));
        state.enumerationProductFertiliserLiquidManureNames = action.payload;
        state.error = {};
      }
    );
    builder.addCase(
      fetchEnumerationProductFertiliserLiquidManureNames.rejected,
      (state, action) => {
        state.loading = false;
        state.enumerationProductFertiliserLiquidManureNames = [];
        state.error = action.error.message;
      }
    );

    // Enumeration product pesticide from table names
    builder.addCase(
      fetchEnumerationProductPesticideFromTableNames.pending,
      (state) => {
        state.loading = true;
      }
    );
    builder.addCase(
      fetchEnumerationProductPesticideFromTableNames.fulfilled,
      (state, action) => {
        state.loading = false;
        action.payload && action.payload.sort((a, b) => (a < b ? -1 : 1));
        state.enumerationProductPesticideFromTableNames = action.payload;
        state.error = {};
      }
    );
    builder.addCase(
      fetchEnumerationProductPesticideFromTableNames.rejected,
      (state, action) => {
        state.loading = false;
        state.numerationProductPesticideFromTableNames = [];
        state.error = action.error.message;
      }
    );

    // Enumeration product pesticide active principles names
    builder.addCase(
      fetchEnumerationProductPesticideActivePrinciplesNames.pending,
      (state) => {
        state.loading = true;
      }
    );
    builder.addCase(
      fetchEnumerationProductPesticideActivePrinciplesNames.fulfilled,
      (state, action) => {
        state.loading = false;
        action.payload &&
          action.payload.sort((a, b) => (a.trim() < b.trim() ? -1 : 1));
        state.enumerationProductPesticideActivePrinciplesNames = action.payload;
        state.error = {};
      }
    );
    builder.addCase(
      fetchEnumerationProductPesticideActivePrinciplesNames.rejected,
      (state, action) => {
        state.loading = false;
        state.enumerationProductPesticideActivePrinciplesNames = [];
        state.error = action.error.message;
      }
    );

    // Enumeration machinery power type
    builder.addCase(fetchEnumerationMachineryPowerType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchEnumerationMachineryPowerType.fulfilled,
      (state, action) => {
        state.loading = false;
        action.payload &&
          action.payload.sort((a, b) => (a.trim() < b.trim() ? -1 : 1));
        state.enumerationMachineryPowerType = action.payload;
        state.error = {};
      }
    );
    builder.addCase(
      fetchEnumerationMachineryPowerType.rejected,
      (state, action) => {
        state.loading = false;
        state.enumerationMachineryPowerType = [];
        state.error = action.error.message;
      }
    );

    // Enumeration event from ecoinvent main operation products
    builder.addCase(
      fetchEnumerationEventFromEcoinventMaiOperationProducts.pending,
      (state) => {
        state.loading = true;
      }
    );
    builder.addCase(
      fetchEnumerationEventFromEcoinventMaiOperationProducts.fulfilled,
      (state, action) => {
        state.loading = false;
        //action.payload && action.payload.sort((a, b) => a.trim() < b.trim() ? -1 : 1)
        state.enumerationEventFromEcoinventMaiOperationProducts =
          action.payload;
        state.error = {};
      }
    );
    builder.addCase(
      fetchEnumerationEventFromEcoinventMaiOperationProducts.rejected,
      (state, action) => {
        state.loading = false;
        state.enumerationEventFromEcoinventMaiOperationProducts = [];
        state.error = action.error.message;
      }
    );

    // Enumeration event from ecoinvent main operation products
    builder.addCase(
      fetchEnumerationEventGenericMainOperationProducts.pending,
      (state) => {
        state.loading = true;
      }
    );
    builder.addCase(
      fetchEnumerationEventGenericMainOperationProducts.fulfilled,
      (state, action) => {
        state.loading = false;
        state.enumerationEventGenericMainOperationProducts =
          action.payload.data;
        state.error = {};
      }
    );
    builder.addCase(
      fetchEnumerationEventGenericMainOperationProducts.rejected,
      (state, action) => {
        state.loading = false;
        state.enumerationEventGenericMainOperationProducts = [];
        state.error = action.error.message;
      }
    );

    // Enumeration products pesticide type
    builder.addCase(fetchEnumerationPesticideType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchEnumerationPesticideType.fulfilled,
      (state, action) => {
        state.loading = false;
        state.enumerationPesticideType = action.payload;
        state.error = {};
      }
    );
    builder.addCase(fetchEnumerationPesticideType.rejected, (state, action) => {
      state.loading = false;
      state.enumerationPesticideType = [];
      state.error = action.error.message;
    });

    // Enumeration fertiser name
    builder.addCase(fetchEnumerationFertiliserName.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchEnumerationFertiliserName.fulfilled,
      (state, action) => {
        state.loading = false;
        state.enumerationFertiliserName = action.payload;
        state.error = {};
      }
    );
    builder.addCase(
      fetchEnumerationFertiliserName.rejected,
      (state, action) => {
        state.loading = false;
        state.enumerationFertiliserName = [];
        state.error = action.error.message;
      }
    );

    // Enumeration fertiser npk
    builder.addCase(fetchEnumerationFertiliserNpk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchEnumerationFertiliserNpk.fulfilled,
      (state, action) => {
        state.loading = false;
        state.enumerationFertiliserNpk = action.payload;
        state.error = {};
      }
    );
    builder.addCase(fetchEnumerationFertiliserNpk.rejected, (state, action) => {
      state.loading = false;
      state.enumerationFertiliserNpk = [];
      state.error = action.error.message;
    });

    // Enumeration pesticide units
    builder.addCase(fetchEnumerationPesticideUnits.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchEnumerationPesticideUnits.fulfilled,
      (state, action) => {
        state.loading = false;
        state.enumerationPesticideUnits = action.payload;
        state.error = {};
      }
    );
    builder.addCase(
      fetchEnumerationPesticideUnits.rejected,
      (state, action) => {
        state.loading = false;
        state.enumerationPesticideUnits = [];
        state.error = action.error.message;
      }
    );

    // Enumeration event main operation products
    builder.addCase(
      fetchEnumerationEventMainOperationProducts.pending,
      (state) => {
        state.loading = true;
      }
    );
    builder.addCase(
      fetchEnumerationEventMainOperationProducts.fulfilled,
      (state, action) => {
        state.loading = false;
        state.enumerationEventMainOperationProducts = action.payload;
        state.error = {};
      }
    );
    builder.addCase(
      fetchEnumerationEventMainOperationProducts.rejected,
      (state, action) => {
        state.loading = false;
        state.enumerationEventMainOperationProducts = [];
        state.error = action.error.message;
      }
    );

    // Enumeration fertiliser sources N
    builder.addCase(fetchEnumerationFertiliserSourcesN.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchEnumerationFertiliserSourcesN.fulfilled,
      (state, action) => {
        state.loading = false;
        state.enumerationFertiliserSourcesN = action.payload;
        state.error = {};
      }
    );
    builder.addCase(
      fetchEnumerationFertiliserSourcesN.rejected,
      (state, action) => {
        state.loading = false;
        state.enumerationFertiliserSourcesN = [];
        state.error = action.error.message;
      }
    );

    // Enumeration fertiliser sources P
    builder.addCase(fetchEnumerationFertiliserSourcesP.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchEnumerationFertiliserSourcesP.fulfilled,
      (state, action) => {
        state.loading = false;
        state.enumerationFertiliserSourcesP = action.payload;
        state.error = {};
      }
    );
    builder.addCase(
      fetchEnumerationFertiliserSourcesP.rejected,
      (state, action) => {
        state.loading = false;
        state.enumerationFertiliserSourcesP = [];
        state.error = action.error.message;
      }
    );

    // Enumeration fertiliser sources K
    builder.addCase(fetchEnumerationFertiliserSourcesK.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchEnumerationFertiliserSourcesK.fulfilled,
      (state, action) => {
        state.loading = false;
        state.enumerationFertiliserSourcesK = action.payload;
        state.error = {};
      }
    );
    builder.addCase(
      fetchEnumerationFertiliserSourcesK.rejected,
      (state, action) => {
        state.loading = false;
        state.enumerationFertiliserSourcesK = [];
        state.error = action.error.message;
      }
    );

    // Enumeration event generic has transport
    builder.addCase(
      fetchEnumerationEventGenericHasTransport.pending,
      (state) => {
        state.loading = true;
      }
    );
    builder.addCase(
      fetchEnumerationEventGenericHasTransport.fulfilled,
      (state, action) => {
        state.loading = false;
        state.enumerationEventGenericHasTransport = action.payload;
        state.error = {};
      }
    );
    builder.addCase(
      fetchEnumerationEventGenericHasTransport.rejected,
      (state, action) => {
        state.loading = false;
        state.enumerationEventGenericHasTransport = "";
        state.error = action.error.message;
      }
    );

    // Enumeration event generic transport type
    builder.addCase(
      fetchEnumerationEventGenericTransportType.pending,
      (state) => {
        state.loading = true;
      }
    );
    builder.addCase(
      fetchEnumerationEventGenericTransportType.fulfilled,
      (state, action) => {
        state.loading = false;
        state.enumerationEventGenericTransportType = action.payload;
        state.error = {};
      }
    );
    builder.addCase(
      fetchEnumerationEventGenericTransportType.rejected,
      (state, action) => {
        state.loading = false;
        state.enumerationEventGenericTransportType = [];
        state.error = action.error.message;
      }
    );

    // Enumeration pesticide active principle units
    builder.addCase(
      fetchEnumerationPesticideActivePrincipleUnits.pending,
      (state) => {
        state.loading = true;
      }
    );
    builder.addCase(
      fetchEnumerationPesticideActivePrincipleUnits.fulfilled,
      (state, action) => {
        state.loading = false;
        state.enumerationPesticideActivePrincipleUnits = action.payload;
        state.error = {};
      }
    );
    builder.addCase(
      fetchEnumerationPesticideActivePrincipleUnits.rejected,
      (state, action) => {
        state.loading = false;
        state.enumerationPesticideActivePrincipleUnits = [];
        state.error = action.error.message;
      }
    );
    // Enumeration pesticide active principle units
    builder.addCase(fetchCheckPesticideFromTableHasDensity.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchCheckPesticideFromTableHasDensity.fulfilled,
      (state, action) => {
        state.loading = false;
        state.checkPesticideFromTableHasDensity = action.payload;
        state.error = {};
      }
    );
    builder.addCase(
      fetchCheckPesticideFromTableHasDensity.rejected,
      (state, action) => {
        state.loading = false;
        state.checkPesticideFromTableHasDensity = false;
        state.error = action.error.message;
      }
    );

    // Enumeration Pesticide Foliar Interception
    builder.addCase(
      fetchEnumerationPesticideFoliarInterception.pending,
      (state) => {
        state.loading = true;
      }
    );
    builder.addCase(
      fetchEnumerationPesticideFoliarInterception.fulfilled,
      (state, action) => {
        state.loading = false;
        state.enumerationPesticideFoliarInterception = action.payload;
        state.error = {};
      }
    );
    builder.addCase(
      fetchEnumerationPesticideFoliarInterception.rejected,
      (state, action) => {
        state.loading = false;
        state.enumerationPesticideFoliarInterception = [];
        state.error = action.error.message;
      }
    );

    // Enumeration Water Names
    builder.addCase(fetchEnumerationWaterNames.pending, (state) => {
        state.loading = true;
      }
    );
    builder.addCase(fetchEnumerationWaterNames.fulfilled,(state, action) => {
        state.loading = false;
        state.enumerationWaterNames = action.payload;
        state.error = {};
      }
    );
    builder.addCase(fetchEnumerationWaterNames.rejected,(state, action) => {
        state.loading = false;
        state.enumerationWaterNames = [];
        state.error = action.error.message;
      }
    );
    
    // Enumeration Energy names
    builder.addCase(fetchEnumerationEnergyNames.pending, (state) => {
        state.loading = true;
      }
    );
    builder.addCase(fetchEnumerationEnergyNames.fulfilled,(state, action) => {
        state.loading = false;
        state.enumerationEnergyNames = action.payload;
        state.error = {};
      }
    );
    builder.addCase(fetchEnumerationEnergyNames.rejected,(state, action) => {
        state.loading = false;
        state.enumerationEnergyNames = [];
        state.error = action.error.message;
      }
    );
  },
});

export const {
  resetEnumerationEventOperationMachineryImplements,
  resetEnumerationEventGenericTransportImplements,
  resetCheckPesticideFromTableHasDensity,
  resetEnumerationFertiliserNpk,
} = slice.actions;
export default slice.reducer;
