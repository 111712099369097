import * as Yup from 'yup';

const validationSchema = () => Yup.object().shape({
    area: Yup
        .number()
        .positive('Must be greater than 0')
        .notOneOf([0], 'This field cannot accept a zero value')
        .required('Required field '),
    name: Yup
        .string()
        .required('Required field'),
    soil: Yup.object().shape({
        type: Yup
            .string()
            .required('Required field'),
        phosphorus: Yup
            .number()
            .min(0, 'Must be greater than or equal to 0')
            .max(1, 'must be less than or equal to 1'),
        ph: Yup
            .number()
            .min(0, 'Must be greater than or equal to 0')
            .max(14, 'must be less than or equal to 14'),
        clay: Yup
            .number()
            .notOneOf([0], 'This field cannot accept a zero value')
            .positive('Must be greater than 0')
            .max(100, 'must be less than or equal to 100'),
        nitrogen: Yup
            .number()
            .min(0, 'Must be greater than or equal to 0')
            .max(1, 'must be less than or equal to 1'),
        bulk_density: Yup
            .number('A number is required')
            .notOneOf([0], 'This field cannot accept a zero value')
            .positive('Must be greater than 0'),
        soil_loss: Yup
            .number('A number is required')
            .min(0, 'Must be greater than or equal to 0'),
        enrichment_factor: Yup
            .number('A number is required')
            .min(0, 'Must be greater than or equal to 0')
            .max(1, 'must be less than or equal to 1'),
        erosion_fraction: Yup
            .number('A number is required')
            .min(0, 'Must be greater than or equal to 0')
            .max(1, 'must be less than or equal to 1'),
    })
})

export default validationSchema

