import * as Yup from 'yup';

const validationSchema = () => Yup.object().shape({
    type: Yup
        .string()
        .required('Required field'),
    date: Yup
        .date()
        .required('Required field'),
    transports: Yup.array()
        .of(
            Yup.object().shape({
                machinery: Yup
                    .object()
                    .test(
                        'Transports machinery required',
                        'Required field',
                        function(value){
                            if(this.parent.hasMachineryTransports && Boolean(this.parent.machinery._id)){
                                return true
                            } else {
                                return false
                            }
                        }
                    )
            })
        ),

    applications: Yup.array()
        .of(
            Yup.object().shape({
                type: Yup
                    .string()
                    .test(
                        'Application Type required',
                        'Required field',
                        function () {
                            if (
                                (
                                    this.from[1].value.type === 'application'
                                    || this.from[1].value.type === 'irrigation, generic'
                                    || this.from[1].value.type === 'irrigation, periodic'
                                )
                                && !this.parent.type
                            ) {
                                return false
                            } else {
                                return true
                            }
                        }
                    ),
                product: Yup
                    .string()
                    .test(
                        'Application Product required',
                        'Required field',
                        function () {
                            if (this.parent.type && !this.parent.product) {
                                return false
                            } else {
                                return true
                            }
                        }
                    ),
                amount: Yup
                    .number()
                    .test(
                        'Application Amount required',
                        'Required field',
                        function () {
                            if (this.parent.type && !this.parent.amount) {
                                return false
                            } else {
                                return true
                            }
                        }
                    ),
                amount_unit: Yup
                    .string()
                    .test(
                        'Application Amount Unit required',
                        'Required field',
                        function () {
                            if (this.parent.type && !this.parent.amount_unit) {
                                return false
                            } else {
                                return true
                            }
                        }
                    ),
                foliar_interception: Yup
                    .string()
                    .test(
                        'Foliar interception type required',
                        'Required field',
                        function () {
                            if (
                                this.options.from[1].value.type === 'application'
                                && this.options.from[0].value.type === 'pesticide'
                                && !this.parent.foliar_interception
                            ) {
                                return false
                            } else {
                                return true
                            }
                        }
                    ),
                application_method: Yup
                    .string()
                    .test(
                        'Application method required',
                        'Required field',
                        function () {
                            
                            if (
                                this.options.from[1].value.type === 'application'
                                && this.options.from[0].value.type === 'pesticide'
                                && !this.parent.application_method
                            ) {
                                return false
                            } else {
                                return true
                            }
                        }
                    ),
            })
        ),
    operations: Yup.object().shape({
        type: Yup
            .string()
            .required('Required field'),
        name: Yup
            .string()
            .test(
                'Name operation required',
                'Required field',
                function () {
                    if (
                        this.from[0].value.type === 'generic'
                        && !this.parent.name
                    ) {
                        return false
                    } else {
                        return true
                    }
                }
            ),
        ecoinvent: Yup
            .string()
            .test(
                'Name operation required',
                'Required field',
                function () {
                    if (
                        this.from[0].value.type === 'from ecoinvent'
                        && !this.parent.ecoinvent
                    ) {
                        return false
                    } else {
                        return true
                    }
                }
            ),
        amount: Yup
            .number()
            .min(0, 'Must be greater than or equal to 0')
            .test(
                'Name operation required',
                'Required field',
                function () {
                    if (
                        this.from[0].value.type === 'from ecoinvent'
                        && !this.parent.amount
                    ) {
                        return false
                    } else {
                        return true
                    }
                }
            ),
        machinery: Yup.object().shape({
            machinery_id: Yup
                .string()
                .test(
                    'Machinery generic required',
                    'Required field',
                    function(){
                        if(
                            this.from[2].value.operations.hasMachinery
                            && !this.parent.machinery_id
                        ){
                            return false
                        } else {
                            return true
                        }
                    }
                ),
        })
    }),
    area_burnt: Yup
        .number()
        .min(0, 'Must be greater than or equal to 0')
        .test(
            'Name operation required',
            'Required field',
            function () {
                if (
                    this.from[0].value.type === 'burning'
                    && !this.parent.area_burnt
                ) {
                    return false
                } else {
                    return true
                }
            }
        ),
    frequency: Yup
        .number()
        .min(1, 'Must be greater than or equal to 1')
        .test(
            'Frequency required',
            'Required field',
            function () {
                if (
                    this.parent.type === 'irrigation, periodic'
                    && !this.parent.frequency
                ) {
                    return false
                } else {
                    return true
                }
            }
        ),
    comb_fuel: Yup
        .number()
        .min(0, 'Must be greater than or equal to 0')
        .test(
            'Name operation required',
            'Required field',
            function () {
                if (
                    this.from[0].value.type === 'burning'
                    && !this.parent.comb_fuel
                ) {
                    return false
                } else {
                    return true
                }
            }
        ),
    water_cons: Yup
        .number()
        .min(0, 'Must be greater than or equal to 0'),
    benefit_time: Yup
        .number()
        .min(0, 'Must be greater than or equal to 0'),
})

export default validationSchema

