import { Box, Typography } from '@mui/material'
import React from 'react'
import ShowDataContainer from '../../layout/ShowDataContainer'

function ShowSoil(props) {

  const {item} = props

  return (
    <ShowDataContainer>
                                  
      <Typography sx={{width: "33%", flexShrink: 0, fontWeight: "bold",}}>
        Soil
      </Typography>

      <Typography variant="subtitle2" sx={{ lineHeight: 1.3, fontSize: "0.8rem" }}>
        {item.soil.type ? (<Box><strong>Type: </strong>{item.soil.type}</Box>) : null}
        {item.soil.phosphorus ? (<Box><strong>P Concentration: </strong>{item.soil.phosphorus}{"kg P/kg soil"}</Box>) : null}
        {item.soil.ph ? (<Box><strong>pH: </strong>{item.soil.ph}</Box>) : null}
        {item.soil.clay ? (<Box><strong>Clay Content: </strong>{item.soil.clay}{"%"}</Box>) : null}
        {item.soil.nitrogen ? (<Box><strong>N Concentration: </strong>{item.soil.nitrogen}{" kg N/kg soil"}</Box>) : null}
        {item.soil.bulk_density ? (<Box><strong>Bulk Density: </strong>{item.soil.bulk_density}{" kg/m³"}</Box>) : null}
        {item.soil.soil_loss ? (<Box><strong>Soil Loss: </strong>{item.soil.soil_loss}{" kg soil/year"}</Box>) : null}
        {item.soil.enrichment_factor ? (<Box><strong>P Enrichment Factor: </strong>{item.soil.enrichment_factor}</Box>) : null}
        {item.soil.erosion_fraction ? (<Box><strong>P Erosion Fraction: </strong>{item.soil.erosion_fraction}</Box>) : null}
      </Typography>
    </ShowDataContainer>
  )
}

export default ShowSoil
