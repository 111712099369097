import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../services/api";
import axios from "axios";

const initialState = {
  loading: true,
  registro: {},
  selectedProperty: {},
  property: {
    loading: true,
    registro: {},
    error: {},
  },
  eventForEdit: {
    loading: true,
    registro: {},
    error: {},
  },
  error: {},
};

export const fetchUserAll = createAsyncThunk("fetchUserAll", (user_id) => {
  return api.get(`/all-user-data/${user_id}`).then((res) => res.data);
});

export const addProperty = createAsyncThunk("addProperty", async (params) => {
  const user_id = params.user_id;
  const body = params.payload;
  return await api
    .post(`/new-user-property/${user_id}`, body)
    .then((res) => ({
      data: res.data,
      params,
    }))
    .catch((error) => ({
      error: error.response.data.detail,
    }));
});

export const addPlotTerrains = createAsyncThunk("addPlotTerrains", (params) => {
  const user_id = params.user_id;
  const prop_id = params.prop_id;
  const body = params.payload;
  return api
    .post(`/new-plot-terrain/${user_id}/property/${prop_id}`, body)
    .then((res) => ({
      data: res.data,
      params,
    }));
});

export const editPlotTerrains = createAsyncThunk(
  "editPlotTerrains",
  (params) => {
    const user_id = params.user_id;
    const prop_id = params.prop_id;
    const plot_ter_id = params.plot_ter_id;
    const body = params.payload;

    return api
      .put(
        `/edit-plot-terrain/${user_id}/property/${prop_id}/plot-terrain/${plot_ter_id}`,
        body
      )
      .then((res) => ({
        data: res.data,
        params,
      }))
      .catch((error) =>
        console.log(JSON.stringify(error.response.data.detail, null, 2))
      );
  }
);

export const editPlotEvent = createAsyncThunk("editPlotEvent", (params) => {
  const user_id = params.user_id;
  const prop_id = params.prop_id;
  const event_id = params.event_id;
  const crop = params.crop;
  const body = params.payload;
  return (
    api
      .put(`/edit-event/${user_id}/property/${prop_id}/event/${event_id}/crop/${crop}`, body)
      .then((res) => ({
        data: res.data,
        params,
      }))
      .catch((error) =>
        console.log(JSON.stringify(error.response.data.detail, null, 2))
      )
  );
});

export const editLandEvent = createAsyncThunk("editLandEvent", async (params) => {
    const user_id = params.user_id;
    const prop_id = params.prop_id;
    const event_id = params.event_id;
    const crop = params.crop;
    const body = params.payload;

    const result = await api
      .put(`/edit-event/${user_id}/property/${prop_id}/event/${event_id}/crop/${crop}`, body)
      .then((res) => ({data: res.data,params,}))
      .catch((err) => {
        if (err.response.status === 204) {
          return {
            error: false,
            message: `Land Event changed successfully.`,
            params,
          };
        } else if (err.response.status === 404) {
          return {
            error: true,
            message: `Error: ${err.response.data.detail}`,
            params,
          };
        } else {
          return {
            error: true,
            message: `Error: ${err.response.status} ${err.response.statusText}`,
            params,
          };
        }
      });
    return result;
  }
);

export const deleteProperty = createAsyncThunk("deleteProperty", (params) => {
  const user_id = params.user_id;
  const prop_id = params.prop_id;
  return api
    .delete(`/del-user-property/${user_id}/properties/${prop_id}`)
    .then((res) => ({
      data: res.data,
      prop_id,
    }));
});

export const deletePlotTerrains = createAsyncThunk(
  "deletePlotTerrains",
  async (params) => {
    const user_id = params.user_id;
    const prop_id = params.prop_id;
    const plot_ter_id = params.plot_ter_id;
    return api
      .delete(
        `/del-plot-terrain/${user_id}/property/${prop_id}/plot-terrain/${plot_ter_id}`
      )
      .then((res) => ({
        data: res.data,
        params,
      }))
      .catch((error) =>
        console.log(JSON.stringify(error.response.data.detail, null, 2))
      );
  }
);

export const addPlot = createAsyncThunk("addPlot", (params) => {
  const plot_ter_id = params.plot_ter_id;
  const body = params.payload;
  return api
    .post(`/new-plot/${plot_ter_id}`, body)
    .then((res) => ({
      data: res.data,
      params,
    }))
    .catch((error) => console.log("error", error.response));
});

export const editPlotActivity = createAsyncThunk(
  "editPlotActivity",
  (params) => {
    const plot_id = params.plot_id;
    const body = params.payload;
    return api
      .put(`/edit-plot/${plot_id}`, body)
      .then((res) => ({
        data: res.data,
        params,
      }))
      .catch((error) => console.log("error", error.response));
  }
);

export const addPlotEvent = createAsyncThunk("addPlotEvent", async (params) => {
  const user_id = params.user_id;
  const prop_id = params.prop_id;
  const plot_ter_id = params.plot_ter_id;
  const plot_id = params.plot_id;
  const body = params.payload;

  const resultAdd = await api
    .post(`/users/${user_id}/property/${prop_id}/plot-terrain/${plot_ter_id}/plot/${plot_id}/new-event`, body)
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 409) {
        return {
          error: true,
          message: `The data could not be added. 
              Review the reported data, and if the problem persists, contact your system administrator. (error status 409).`,
        };
      } else {
        return {
          error: true,
          message: err.message
        }
      }
    });
  
  if (!resultAdd.error) {
    return await api
      .get(`/event/${resultAdd}`)
      .then((res) => ({
        data: res.data,
        params,
        event_id: resultAdd,
      }))
      .catch((err) => console.log("erro no recuperação: ", err));
  } else {
    return resultAdd;
  }
});

export const addLandEvent = createAsyncThunk("addLandEvent", (params) => {
  const user_id = params.user_id;
  const prop_id = params.prop_id;
  const plot_ter_id = params.plot_ter_id;
  const body = params.payload;
  
  return api
    .post(`/users/${user_id}/property/${prop_id}/plot-terrain/${plot_ter_id}/new-event`, body)
    .then((res) => ({ data: res.data, params }));
});

export const addMachinery = createAsyncThunk("addMachinery", (params) => {
  const user_id = params.user_id;
  const body = params.payload;
  const prop_id = params.prop_id;
  return api
    .post(`/user/${user_id}/property/${prop_id}/new-machinery`, body)
    .then((res) => ({
      data: res.data,
      params,
    }))
    .catch((error) =>
      console.log(JSON.stringify(error.response.data.detail, null, 2))
    );
});

export const editMachinery = createAsyncThunk("editMachinery", (params) => {
  const mach_id = params.mach_id;
  const body = params.payload;
  return api
    .put(`/edit-machinery/${mach_id}`, body)
    .then((res) => ({
      data: res.data,
      params,
    }))
    .catch((error) =>
      console.log(JSON.stringify(error.response.data.detail, null, 2))
    );
});

export const getUfs = createAsyncThunk("getUfs", () => {
  return axios
    .get(
      "https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome"
    )
    .then((res) => {
      res.data.map((e) => ({
        id: e.id,
        nome: e.nome,
        sigla: e.sigla,
      }));
    });
});

export const getCities = createAsyncThunk("getCities", async (uf_code) => {
  let cities = [];
  await axios
    .get(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf_code}/municipios`
    )
    .then((response) => {
      cities = response.data.map((m) => ({
        id: m.id,
        nome: m.nome,
      }));
    })
    .catch((error) =>
      console.log(JSON.stringify(error.response.data.detail, null, 2))
    );
  return cities;
});

export const editSelectedProperty = createAsyncThunk(
  "editSelectedProperty",
  async (params) => {
    let uf_code = "";
    let propertyEdit = {
      ...params,
      city: "",
      state: "",
      selectUfs: [],
      selectUfCities: [],
    };
    await axios
      .get(
        "https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome"
      )
      .then((res) => {
        propertyEdit.selectUfs = res.data.map((e) => ({
          id: e.id,
          nome: e.nome,
          sigla: e.sigla,
        }));
      });
    await axios
      .get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/municipios/${params.city_code}`
      )
      .then((res) => {
        uf_code = res.data.microrregiao.mesorregiao.UF.id;
        propertyEdit.city = res.data.nome;
        propertyEdit.state = uf_code;
      });
    await axios
      .get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf_code}/municipios`
      )
      .then((response) => {
        propertyEdit.selectUfCities = response.data.map((m) => ({
          id: m.id,
          nome: m.nome,
        }));
      });
    return propertyEdit;
  }
);

export const editProperty = createAsyncThunk("editProperty", (params) => {
  const user_id = params.user_id;
  const prop_id = params.prop_id;
  const body = params.payload;
  return api
    .put(`/edit-user-property/${user_id}/property/${prop_id}`, body)
    .then((res) => ({
      data: res.data,
      params,
    }))
    .catch((error) =>
      console.log(JSON.stringify(error.response.data.detail, null, 2))
    );
});

export const deleteMachinery = createAsyncThunk("deleteMachinery", (params) => {
  const user_id = params.user_id;
  const mach_id = params.mach_id;
  const prop_id = params.prop_id;
  return api
    .delete(`/del-machinery/${user_id}/property/${prop_id}/machinery/${mach_id}`)
    .then((res) => ({ data: res.data, params }))
    //.catch((err)=> err.response.data)
    // .catch((err) => {
    //   if(err.response.status === 409){
    //     return (
    //       {
    //         error: err.response.data.message,
    //         data: [],
    //         params
    //       }
    //     )
    //   }
    // })
      
});

export const addProduct = createAsyncThunk("addProduct", (params) => {
  const user_id = params.user_id;
  const prop_id = params.prop_id;
  const body = params.payload;
  return api
    .post(`/new-product/${user_id}/property/${prop_id}/product`, body)
    .then((res) => ({
      data: res.data,
      params,
    }));
});

export const editProduct = createAsyncThunk("editProduct", (params) => {
  const prod_id = params.prod_id;
  const body = params.payload;
  return api
    .put(`/edit-product/${prod_id}`, body)
    .then((res) => ({
      data: res.data,
      params,
    }));
});

export const deleteProduct = createAsyncThunk("deleteProduct", (params) => {
  const user_id = params.user_id;
  const prop_id = params.prop_id;
  const prod_id = params.prod_id;
  return api
    .delete(`/del-product/${user_id}/property/${prop_id}/product/${prod_id}`)
    .then((res) => ({
      data: res.data,
      params,
    }))
    .catch((err) => {
      if(err.response.status === 409){
        return (
          {
            error: err.response.data.message,
            data: [],
            params
          }
        )
      }
    });
});

export const addImplement = createAsyncThunk("addImplement", (params) => {
  const user_id = params.user_id;
  const prop_id = params.prop_id;
  const body = params.payload;
  return api
    .post(`/user/${user_id}/property/${prop_id}/new-implement`, body)
    .then((res) => ({
      data: res.data,
      params,
    }))
    .catch((error) =>
      console.log(JSON.stringify(error.response.data.detail, null, 2))
    );
});

export const editImplements = createAsyncThunk("editImplements", (params) => {
  const impl_id = params.impl_id;
  const body = params.payload;
  return api.put(`/edit-implement/${impl_id}`, body).then((res) => ({
    data: res.data,
    params,
  }));
});

export const deleteImplement = createAsyncThunk("deleteImplement", (params) => {
  const user_id = params.user_id;
  const prop_id = params.prop_id;
  const impl_id = params.impl_id;
  return (
    api
      .delete(`/del-implement/${user_id}/property/${prop_id}/implement/${impl_id}`)
      .then((res) => ({
        data: res.data,
        params,
      }))
      .catch((err) => {
        if(err.response.status === 409){
          return (
            {
              error: err.response.data.message,
              data: [],
              params
            }
          )
        }
      })
  );
});



export const deletePlot = createAsyncThunk("deletePlot", (params) => {
  const prop_id = params.prop_id;
  const plot_ter_id = params.plot_ter_id;
  const plot_id = params.plot_id;
  return api.delete(`/del-plot/${plot_ter_id}/plot/${plot_id}`).then((res) => ({
    data: res.data,
    prop_id,
    plot_ter_id,
    plot_id,
  }));
});

export const deletePlotEvent = createAsyncThunk("deletePlotEvent", (params) => {
  const plot_id = params.plot_id;
  const user_id = params.user_id;
  const prop_id = params.prop_id;
  const event_id = params.event_id;
  return api
    .delete(
      `/del-plot-event/${plot_id}/user/${user_id}/property/${prop_id}/event/${event_id}`
    )
    .then((res) => ({ data: res.data, params }));
});

export const deleteLandEvent = createAsyncThunk("deleteLandEvent", (params) => {
  const plot_ter_id = params.plot_ter_id;
  const user_id = params.user_id;
  const prop_id = params.prop_id;
  const event_id = params.event_id;

  return api
    .delete(
      `/del-plot-terrain-event/${plot_ter_id}/user/${user_id}/property/${prop_id}/event/${event_id}`
    )
    .then((res) => ({ data: res.data, params }));
});

export const fetchEditEventData = createAsyncThunk(
  "fetchEditEventData",
  (params) => {
    const user_id = params.user_id;
    const prop_id = params.prop_id;
    const event_id = params.event_id;
    const plot_id = params.plot_id || "";
    
    if (plot_id === ""){
      return api
        .get(`/edit-event-data/${user_id}/property/${prop_id}/event/${event_id}/plot/`)
        .then((res) => res.data);
    } else {
      return api
        .get(`/edit-event-data/${user_id}/property/${prop_id}/event/${event_id}/plot/${plot_id}`)
        .then((res) => res.data);
    }
  }
);

export const fetchEventForEdition = createAsyncThunk(
  "fetchEventForEdition",
  async (params) => {
    const event_id = params.event_id;
    const crop = params.crop;
    const p_type = params.p_type;
    const event_type = params.event_type;
    const op_type = params.op_type;
    const type_ = params.type_;
    const e_type = params.e_type;
    const user_id = params.user_id;
    const op_name = params.op_name;
    const mach_type = params.mach_type;
    const prop_id = params.prop_id;

    let event = await api.get(`/event/${event_id}`).then((res) => res.data);

    event.typeList = await api
      .get(`/enumeration-event-type/${crop}/plot-or-plot-terrain/${p_type}`)
      .then((res) => res.data)
      .catch((err) => console.log("typeList ", err));
    event.main_operation.typeList = await api
      .get(`/enumeration-event-operation-type/${event_type}`)
      .then((res) => res.data)
      .catch((err) => console.log("main_operation.typeList ", err));
    if (op_type === "from ecoinvent") {
      event.main_operation.ecoinventList = await api
        .get(
          `/enumeration-event-operation-ecoinvent/${crop}/plot-terrain-or-plot/${type_}/event/${e_type}`
        )
        .then((res) => res.data)
        .catch((err) => console.log("ecoinventList ", err));
    } else {
      event.main_operation.nameList = await api
        .get(
          `/enumeration-event-generic-main-operation-name/${crop}/plot-terrain-or-plot/${type_}/event/${e_type}`
        )
        .then((res) => res.data)
        .catch((err) => console.log("main_operation.nameList ", err));
      event.main_operation.machineriesList = await api
        .get(
          `/enumeration-event-generic-main-operation-machinery/${user_id}/event-type/${e_type}/operation/${op_name}`
        )
        .then((res) => res.data)
        .catch((err) => console.log("machineriesList ", err));
      if (user_id && mach_type && op_name) {
        event.main_operation.machinery[0].implementsList = await api
          .get(
            `/enumeration-event-generic-main-operation-implements/${user_id}/machinery-type/${mach_type}/operation/${op_name}`
          )
          .then((res) => res.data)
          .catch((err) => console.log("implementsList ", err));
      }
    }

    event.applications &&
      event.applications.map((el, index) => {
        getApplicationTypes(index, el, event, op_name);
        getApplicationProducts(index, event, user_id, prop_id);
        getApplicationUnit(index, event);
        return true;
      });

    event.transports &&
      event.transports.map((el, index) => {
        getTransportsNames(index, event, op_name);
        getTransportsMachineries(index, event, user_id, op_name);
        getTransportsMachineryImplements(index, event);
        return true;
      });

    async function getApplicationTypes(index, el, event, op_name) {
      event.applications[index].typeList = await api
        .get(`/enumeration-event-application-type/${op_name}`)
        .then((res) => res.data);
    }

    async function getApplicationProducts(index, event, user_id, prop_id) {
      const prod_type = event.applications[index].type;
      event.applications[index].productList = await api
        .get(
          `/enumeration-event-main-operation-products/${user_id}/type/${type_}``/enumeration-event-main-operation-products/${user_id}/property/${prop_id}/type/${prod_type}`
        )
        .then((res) => res.data);
    }

    async function getApplicationUnit(index, event) {
      const prod_id = event.applications[index].product;
      event.applications[index].unitList = await api
        .get(`/enumeration-event-application-unit/${prod_id}`)
        .then((res) => res.data);
    }

    async function getTransportsNames(index, event, op_name) {
      event.transports[index].nameList = await api
        .get(`/enumeration-event-generic-transport-type/${op_name}`)
        .then((res) => res.data);
    }

    async function getTransportsMachineries(index, event, user_id, op_name) {
      const t_name = event.transports[index].name;
      event.transports[index].machinery[0].machineriesList = await api
        .get(
          `/enumeration-event-generic-transport-machinery/${user_id}/operation/${op_name}/transport/${t_name}`
        )
        .then((res) => res.data);
    }

    async function getTransportsMachineryImplements(index, event) {}

    return {
      event,
    };
  }
);

export const checkActivityStartDateIsNotConflicted = createAsyncThunk(
  "checkActivityStartDateIsNotConflicted",
  (params) => {
    const start_date = params.start_date;
    const plot_ter_id = params.plot_ter_id;
    const plot_id = params.plot_id;

    if (!plot_id) {
      return api
        .get(
          `/check-activity-start-date-is-not-conflicted/${start_date}/plot-terrain/${plot_ter_id}`
        )
        .then((res) => res.data)
        .catch((err) => {
          if (err.response.status === 409) {
            return {
              error: true,
              message: err.response.data.detail,
            };
          }
        });
    } else {
      return (
        api
          //.get(`/check-activity-start-date-is-not-conflicted/${start_date}/plot-terrain/${plot_ter_id}`)
          .get(
            `/check-activity-start-date-is-not-conflicted/${start_date}/plot-terrain/${plot_ter_id}/plot/${plot_id}`
          )
          .then((res) => res.data)
          .catch((err) => {
            if (err.response.status === 409) {
              return {
                error: true,
                message: err.response.data.detail,
              };
            }
          })
      );
    }
  }
);

export const checkGisIsValid = createAsyncThunk("", (params) => {
  const body = params.payload.GIS;
  return api
    .post(`/check-gis-is-valid/`, body)
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 409) {
        return {
          error: true,
          message: err.response.data,
        };
      }
    });
});

export const checkActivityPeriodIsNotConflicted = createAsyncThunk(
  "checkActivityPeriodIsNotConflicted",
  (params) => {
    const start_date = params.start_date;
    const period = params.period;
    const plot_ter_id = params.plot_ter_id;
    const plot_id = params.plot_id;

    if (!plot_id) {
      return api
        .get(
          `/check-activity-period-is-not-conflicted/${start_date}/period/${period}/plot-terrain/${plot_ter_id}`
        )
        .then((res) => res.data)
        .catch((err) => {
          if (err.response.status === 409) {
            return {
              error: true,
              message: err.response.data,
            };
          }
        });
    } else {
      return (
        api
          .get(`/check-activity-period-is-not-conflicted/${start_date}/period/${period}/plot-terrain/${plot_ter_id}/plot/${plot_id}`)
          .then((res) => res.data)
          .catch((err) => {
            if (err.response.status === 409) {
              return {
                error: true,
                message: err.response.data,
              };
            }
            if (err.response.status === 404) {
              return {
                error: true,
                message: err.response.data,
              };
            }
          })
      );
    }
  }
);

export const checkEventDateIsNotConflicted = createAsyncThunk(
  "checkEventDateIsNotConflicted",
  (params) => {
    const event_date = params.event_date;
    const plot_id = params.plot_id;

    return api
      .get(`/check-event-date-is-not-conflicted/${event_date}/plot/${plot_id}`)
      .then((res) => res.data)
      .catch((err) => {
        if (err.response.status === 409) {
          return {
            error: true,
            message: err.response.data,
          };
        }
      });
  }
);

export const slice = createSlice({
  name: "userAll",
  initialState,

  reducers: {
    setSelectedProperty: (state, action) => {
      state.selectedProperty = action.payload;
    },
  },

  extraReducers: (builder) => {
    // Todos as propriedades de um usuário
    builder.addCase(fetchUserAll.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUserAll.fulfilled, (state, action) => {
      state.loading = false;
      state.registro = action.payload;
      state.error = {};
    });
    builder.addCase(fetchUserAll.rejected, (state, action) => {
      state.loading = false;
      state.registro = {};
      state.error = action.error.message;
    });

    //Adicionar Propriedade
    builder.addCase(addProperty.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addProperty.fulfilled, (state, action) => {
      state.loading = false;
      let reg = {};
      if (action.payload.data) {
        reg = {
          _id: action.payload.data,
          ...action.payload.params.payload,
        };
        state.registro.properties && state.registro.properties.push(reg);
      }
      if (action.payload.error) {
        state.error = action.payload.error;
      }
    });
    builder.addCase(addProperty.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    //Incluir novo evento em plot
    builder.addCase(addPlotEvent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addPlotEvent.fulfilled, (state, action) => {
      state.loading = false;
      if(action.payload.params){
        const prop_id = action.payload.params.prop_id;
        const plot_ter_id = action.payload.params.plot_ter_id;
        const plot_id = action.payload.params.plot_id;

        if (!action.payload.error) {
          const indexProp = state.registro.properties.findIndex((p) => p._id === prop_id);
          const indexLand = state.registro.properties[indexProp].plot_terrains.findIndex((t) => t._id === plot_ter_id);
          const indexPlot = state.registro.properties[indexProp].plot_terrains[indexLand].plots.findIndex((l) => l._id === plot_id);

          if (state.registro.properties[indexProp].plot_terrains[indexLand].plots[indexPlot].hasOwnProperty("events")) {
            state.registro.properties[indexProp].plot_terrains[indexLand].plots[indexPlot].events.push(action.payload.data);
          } else {
            state.registro.properties[indexProp].plot_terrains[indexLand].plots[indexPlot].events = [action.payload.data];
          }
        } else {
          state.error = action.payload;
        }
      }
    });
    builder.addCase(addPlotEvent.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // excluir propriedade
    builder.addCase(deleteProperty.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteProperty.fulfilled, (state, action) => {
      state.loading = false;
      state.registro.properties = state.registro.properties.filter(
        (el) => el._id !== action.payload.prop_id
      );
      state.error = {};
    });
    builder.addCase(deleteProperty.rejected, (state, action) => {
      state.loading = false;
      //state.registro = {}
      state.error = action.error.message;
    });
    // excluir plot terrain plots
    builder.addCase(deletePlot.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deletePlot.fulfilled, (state, action) => {
      state.loading = false;
      const indexProp = state.registro.properties.findIndex(
        (p) => p._id === action.payload.prop_id
      );
      const indexLand = state.registro.properties[
        indexProp
      ].plot_terrains.findIndex((t) => t._id === action.payload.plot_ter_id);
      const indexPlot = state.registro.properties[indexProp].plot_terrains[
        indexLand
      ].plots.findIndex((l) => l._id === action.payload.plot_id);
      state.registro.properties[indexProp].plot_terrains[
        indexLand
      ].plots.splice(indexPlot, 1);
      state.error = {};
    });
    builder.addCase(deletePlot.rejected, (state, action) => {
      state.loading = false;
      //state.registro = {}
      state.error = action.error.message;
    });

    // Adicionar Plot Terrains
    builder.addCase(addPlotTerrains.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addPlotTerrains.fulfilled, (state, action) => {
      const prop_id = action.payload.params.prop_id;
      const reg = {
        ...action.payload.params.payload,
        _id: action.payload.data,
      };
      const indexProp = state.registro.properties.findIndex(
        (p) => p._id === prop_id
      );
      state.loading = false;
      state.registro.properties[indexProp].plot_terrains.push(reg);
      state.error = {};
    });
    builder.addCase(addPlotTerrains.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // Adicionar Plot
    builder.addCase(addPlot.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addPlot.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.params) {
        const prop_id = action.payload.params.prop_id;
        const plot_ter_id = action.payload.params.plot_ter_id;
        const reg = {
          ...action.payload.params.payload,
          _id: action.payload.data,
        };
        const indexProp = state.registro.properties.findIndex(
          (p) => p._id === prop_id
        );
        const indexPlotTerrain = state.registro.properties[
          indexProp
        ].plot_terrains.findIndex((p) => p._id === plot_ter_id);

        state.registro.properties[indexProp].plot_terrains[
          indexPlotTerrain
        ].plots.push(reg);
      }
      state.error = {};
    });
    builder.addCase(addPlot.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // Excluir plot teraains
    builder.addCase(deletePlotTerrains.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deletePlotTerrains.fulfilled, (state, action) => {
      state.loading = false;
      const prop_id = action.payload.params.prop_id;
      const plot_terr_id = action.payload.params.plot_terr_id;
      const indexProp = state.registro.properties.findIndex(
        (p) => p._id === prop_id
      );
      const indexLand = state.registro.properties[
        indexProp
      ].plot_terrains.findIndex((t) => t._id === plot_terr_id);
      state.registro.properties[indexProp].plot_terrains.splice(indexLand, 1);
      state.error = {};
    });
    builder.addCase(deletePlotTerrains.rejected, (state, action) => {
      state.loading = false;
      //state.registro = {}
      state.error = action.error.message;
    });

    //Adicionar implemento
    builder.addCase(addImplement.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addImplement.fulfilled, (state, action) => {
      const prop_id = action.payload.params.prop_id;
      state.loading = false;
      const indexProp = state.registro.properties.findIndex(
        (p) => p._id === prop_id
      );
      state.registro.properties[indexProp].prop_implements.push({
        _id: action.payload.data,
        ...action.payload.params.payload,
      });
      state.error = {};
    });
    builder.addCase(addImplement.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // excluir implement
    builder.addCase(deleteImplement.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteImplement.fulfilled, (state, action) => {
      state.loading = false;
      if(!action.payload.error){
        const prop_id = action.payload.params.prop_id;
        const impl_id = action.payload.params.impl_id;
        const indexProp = state.registro.properties && state.registro.properties.findIndex((p) => p._id === prop_id);
        const indexImpl = state.registro.properties && state.registro.properties[indexProp].prop_implements.findIndex((p) => p._id === impl_id);
        state.registro.properties && state.registro.properties[indexProp].prop_implements.splice(indexImpl, 1);
        state.error = {};
      }
    });
    builder.addCase(deleteImplement.rejected, (state, action) => {
      state.loading = false;
      state.registro = {}
      state.error = action.error.message;
    });

    // excluir product
    builder.addCase(deleteProduct.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteProduct.fulfilled, (state, action) => {
      state.loading = false;
      if(!action.payload.error){
        const prop_id = action.payload.params.prop_id;
        const indexProp = state.registro.properties.findIndex((reg) => reg._id === prop_id);
        const prod_id = action.payload.params.prod_id;
        const indexProd = state.registro.properties[indexProp].prop_products.findIndex((p) => p._id === prod_id);
        state.registro.properties[indexProp].prop_products.splice(indexProd, 1);
        state.error = {};
      }
    });
    builder.addCase(deleteProduct.rejected, (state, action) => {
      state.loading = false;
      state.registro = {}
      state.error = action.error.message;
    });

    // excluir machinery
    builder.addCase(deleteMachinery.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteMachinery.fulfilled, (state, action) => {
      state.loading = false;
      if(!action.payload.error){
        const prop_id = action.payload.params.prop_id;
        const indexProp = state.registro.properties && state.registro.properties.findIndex((p) => p._id === prop_id);
        const mach_id = action.payload.params.mach_id;
        const indexMach = state.registro.properties[indexProp].prop_machinery.findIndex((m) => m._id === mach_id);
        state.registro.properties[indexProp].prop_machinery.splice(indexMach, 1);
        state.error = {};
      }
    });
    builder.addCase(deleteMachinery.rejected, (state, action) => {
      state.loading = false;
      state.registro = {}
      state.error = action.error.message;
    });

    // Adicionar machinery
    builder.addCase(addMachinery.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addMachinery.fulfilled, (state, action) => {
      state.loading = false;
      const prop_id = action.payload.params.prop_id;
      const index_prop = state.registro.properties.findIndex((reg) => reg._id === prop_id);
      state.registro.properties[index_prop].prop_machinery.push({
        _id: action.payload.data,
        ...action.payload.params.payload,
      });
      state.error = {};
    });
    builder.addCase(addMachinery.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // alterar machinery
    builder.addCase(editMachinery.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editMachinery.fulfilled, (state, action) => {
      state.loading = false;
      const mach_id = action.payload.params.mach_id;
      const prop_id = action.payload.params.prop_id;
      const dados = {
        _id: action.payload.params.mach_id,
        ...action.payload.params.payload,
      };
      const indexProp = state.registro.properties.findIndex(
        (p) => p._id === prop_id
      );
      const indexMach = state.registro.properties[
        indexProp
      ].prop_machinery.findIndex((reg) => reg._id === mach_id);
      state.registro.properties[indexProp].prop_machinery[indexMach] = {
        ...state.registro.properties[indexProp].prop_machinery[indexMach],
        ...dados,
      };
      state.error = {};
    });
    builder.addCase(editMachinery.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // Adicionar product
    builder.addCase(addProduct.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addProduct.fulfilled, (state, action) => {
      state.loading = false;
      const prop_id = action.payload.params.prop_id;
      const indexProp = state.registro.properties.findIndex((p) => p._id === prop_id);
      const reg = {
        _id: action.payload.data,
        ...action.payload.params.payload,
      };
      state.registro.properties[indexProp].prop_products.push(reg);
      state.error = {};
    });
    builder.addCase(addProduct.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // excluir plot event
    builder.addCase(deletePlotEvent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deletePlotEvent.fulfilled, (state, action) => {
      state.loading = false;
      const prop_id = action.payload.params.prop_id;
      const plot_ter_id = action.payload.params.plot_ter_id;
      const plot_id = action.payload.params.plot_id;
      const event_id = action.payload.params.event_id;

      const indexProp = state.registro.properties.findIndex(
        (p) => p._id === prop_id
      );
      const indexLand = state.registro.properties[
        indexProp
      ].plot_terrains.findIndex((t) => t._id === plot_ter_id);
      const indexPlot = state.registro.properties[indexProp].plot_terrains[
        indexLand
      ].plots.findIndex((l) => l._id === plot_id);
      const indexPlotEvent = state.registro.properties[indexProp].plot_terrains[
        indexLand
      ].plots[indexPlot].events.findIndex((l) => l._id === event_id);

      state.registro.properties[indexProp].plot_terrains[indexLand].plots[
        indexPlot
      ].events.splice(indexPlotEvent, 1);
      state.error = {};
    });
    builder.addCase(deletePlotEvent.rejected, (state, action) => {
      state.loading = false;
      //state.registro = {}
      state.error = action.error.message;
    });

    // excluir land event
    builder.addCase(deleteLandEvent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteLandEvent.fulfilled, (state, action) => {
      state.loading = false;
      //const user_id = action.payload.params.user_id
      const prop_id = action.payload.params.prop_id;
      const plot_ter_id = action.payload.params.plot_ter_id;
      //const plot_id = action.payload.params.plot_id;
      const event_id = action.payload.params.event_id;

      const indexProp = state.registro.properties.findIndex(
        (p) => p._id === prop_id
      );
      const indexLand = state.registro.properties[
        indexProp
      ].plot_terrains.findIndex((t) => t._id === plot_ter_id);
      //const indexPlot = state.registro.properties[indexProp].plot_terrains[indexLand].plots.findIndex((l) => l._id === plot_id);
      const indexPlotEvent = state.registro.properties[indexProp].plot_terrains[
        indexLand
      ].events.findIndex((l) => l._id === event_id);
      state.registro.properties[indexProp].plot_terrains[
        indexLand
      ].events.splice(indexPlotEvent, 1);
      state.error = {};
    });
    builder.addCase(deleteLandEvent.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // alterar implement
    builder.addCase(editImplements.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editImplements.fulfilled, (state, action) => {
      const prop_id = action.payload.params.prop_id;
      const indexProp = state.registro.properties.findIndex(
        (p) => p._id === prop_id
      );
      state.loading = false;
      const impl_id = action.payload.params.impl_id;
      const dados = {
        _id: action.payload.params.impl_id,
        ...action.payload.params.payload,
      };
      const indexImpl = state.registro.properties[
        indexProp
      ].prop_implements.findIndex((reg) => reg._id === impl_id);
      state.registro.properties[indexProp].prop_implements[indexImpl] = {
        ...state.registro.properties[indexProp].prop_implements[indexImpl],
        ...dados,
      };
      state.error = {};
    });
    builder.addCase(editImplements.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // alterar product
    builder.addCase(editProduct.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editProduct.fulfilled, (state, action) => {
      state.loading = false;
      const prod_id = action.payload.params.prod_id;
      const prop_id = action.payload.params.prop_id;

      const indexProp = state.registro.properties.findIndex(
        (reg) => reg._id === prop_id
      );

      const dados = {
        _id: prod_id,
        ...action.payload.params.payload,
      };

      const indexProd = state.registro.properties[
        indexProp
      ].prop_products.findIndex((reg) => reg._id === prod_id);
      state.registro.properties[indexProp].prop_products[indexProd] = {
        ...state.registro.properties[indexProp].prop_products[indexProd],
        ...dados,
      };
      state.error = {};
    });
    builder.addCase(editProduct.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    //alterar LAND
    builder.addCase(editPlotTerrains.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editPlotTerrains.fulfilled, (state, action) => {
      state.loading = false;
      const prop_id = action.payload.params.prop_id;
      const plot_ter_id = action.payload.params.plot_ter_id;

      const dados = {
        _id: plot_ter_id,
        ...action.payload.params.payload,
      };

      const indexProp = state.registro.properties.findIndex(
        (reg) => reg._id === prop_id
      );
      const indexPlotTerrains = state.registro.properties[
        indexProp
      ].plot_terrains.findIndex((reg) => reg._id === plot_ter_id);

      state.registro.properties[indexProp].plot_terrains[indexPlotTerrains] = {
        ...state.registro.properties[indexProp].plot_terrains[
          indexPlotTerrains
        ],
        ...dados,
      };
      state.error = {};
    });
    builder.addCase(editPlotTerrains.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    //alterar plot Event
    builder.addCase(editPlotEvent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editPlotEvent.fulfilled, (state, action) => {
      state.loading = false;
      const prop_id = action.payload.params.prop_id;
      const plot_ter_id = action.payload.params.plot_ter_id;
      const plot_id = action.payload.params.plot_id;
      const event_id = action.payload.params.event_id;

      const dados = {
        _id: event_id,
        ...action.payload.params.payload,
      };

      const indexProp = state.registro.properties.findIndex(
        (reg) => reg._id === prop_id
      );
      const indexPlotTerrains = state.registro.properties[
        indexProp
      ].plot_terrains.findIndex((reg) => reg._id === plot_ter_id);
      const indexPlot = state.registro.properties[indexProp].plot_terrains[
        indexPlotTerrains
      ].plots.findIndex((reg) => reg._id === plot_id);
      const indexEvent = state.registro.properties[indexProp].plot_terrains[
        indexPlotTerrains
      ].plots[indexPlot].events.findIndex((reg) => reg._id === event_id);

      state.registro.properties[indexProp].plot_terrains[
        indexPlotTerrains
      ].plots[indexPlot].events[indexEvent] = {
        ...state.registro.properties[indexProp].plot_terrains[indexPlotTerrains]
          .plots[indexPlot].events[indexEvent],
        ...dados,
      };
      state.error = {};
    });
    builder.addCase(editPlotEvent.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    //alterar land Event
    builder.addCase(editLandEvent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editLandEvent.fulfilled, (state, action) => {
      state.loading = false;
      const prop_id = action.payload.params.prop_id;
      const plot_ter_id = action.payload.params.plot_ter_id;
      const event_id = action.payload.params.event_id;

      const dados = {
        _id: event_id,
        ...action.payload.params.payload,
      };

      const indexProp = state.registro.properties.findIndex(
        (reg) => reg._id === prop_id
      );
      const indexPlotTerrains = state.registro.properties[
        indexProp
      ].plot_terrains.findIndex((reg) => reg._id === plot_ter_id);
      const indexEvent = state.registro.properties[indexProp].plot_terrains[
        indexPlotTerrains
      ].events.findIndex((reg) => reg._id === event_id);

      state.registro.properties[indexProp].plot_terrains[
        indexPlotTerrains
      ].events[indexEvent] = {
        ...state.registro.properties[indexProp].plot_terrains[indexPlotTerrains]
          .events[indexEvent],
        ...dados,
      };
      state.error = {};
    });
    builder.addCase(editLandEvent.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // Edit Plot Activity

    builder.addCase(editPlotActivity.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editPlotActivity.fulfilled, (state, action) => {
      state.loading = false;

      const prop_id = action.payload.params.prop_id;
      const plot_ter_id = action.payload.params.plot_ter_id;
      const plot_id = action.payload.params.plot_id;

      const dados = {
        _id: plot_id,
        ...action.payload.params.payload,
      };

      const indexProp = state.registro.properties.findIndex(
        (reg) => reg._id === prop_id
      );
      const indexPlotTerrains = state.registro.properties[
        indexProp
      ].plot_terrains.findIndex((reg) => reg._id === plot_ter_id);
      const indexPlotActivity = state.registro.properties[
        indexProp
      ].plot_terrains[indexPlotTerrains].plots.findIndex(
        (reg) => reg._id === plot_id
      );

      state.registro.properties[indexProp].plot_terrains[
        indexPlotTerrains
      ].plots[indexPlotActivity] = {
        ...state.registro.properties[indexProp].plot_terrains[indexPlotTerrains]
          .plots[indexPlotActivity],
        ...dados,
      };
      state.error = {};
    });
    builder.addCase(editPlotActivity.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // alterar property
    builder.addCase(editProperty.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editProperty.fulfilled, (state, action) => {
      state.loading = false;
      const prop_id = action.payload.params.prop_id;
      const dados = {
        _id: action.payload.params.prop_id,
        ...action.payload.params.payload,
      };
      const index = state.registro.properties.findIndex(
        (reg) => reg._id === prop_id
      );
      state.registro.properties[index] = {
        ...state.registro.properties[index],
        ...dados,
      };
      state.error = {};
    });
    builder.addCase(editProperty.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // Selected property edit
    builder.addCase(editSelectedProperty.pending, (state) => {
      state.property.loading = true;
    });
    builder.addCase(editSelectedProperty.fulfilled, (state, action) => {
      state.property.loading = false;
      state.property.registro = action.payload;
      state.property.error = {};
    });
    builder.addCase(editSelectedProperty.rejected, (state, action) => {
      state.property.loading = false;
      state.property.registro = {};
      state.property.error = action.error.message;
    });
  },
});
export const { setSelectedProperty } = slice.actions;
export default slice.reducer;
