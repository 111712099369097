import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/CancelRounded";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { connect } from "react-redux";
import { registerUser, deleteUser, registerUserIcvcalc } from "../../redux/userSlice";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
//import RegisterDialog from "./registerDialog";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import Loading from '../layout/Loading';
import SnackbarCustom from '../layout/SnackbarCustom'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const Register = (props) => {
  const { registerUser, deleteUser, registerUserIcvcalc } = props;

  const [open, setOpen] = useState(false);
  const [ufs, setUfs] = useState([]);
  const [selectedUf, setSelectedUf] = useState("");
  const [selectedMunicipio, setSelectedMunicipio] = useState("");
  const [municipios, setMunicipios] = useState([]);
  const [loading, setLoading] = useState(false)
  const [openError, setOpenError] = useState(false)
  const [msgError, setMsgError] = useState('')
  const [msgSuccess, setMsgSuccess] = useState('')
  const [openSuccess, setOpenSuccess] = useState(false)

  const initialValues = {
    email: "",
    password: "",
    name: "",
    city_code: "",
    confirm_password: "",
  };

  const validationSchema = () =>
    Yup.object().shape({
      email: Yup.string()
        .email("Provide a valid email")
        .required("Required field"),
      state: Yup.string().required("Required field"),
      city: Yup.string().required("Required field"),
      name: Yup.string().required("Required field"),
      password: Yup.string()
        .required("Required field")
        .min(8, "Password must be at least 8 characters long"),
      confirm_password: Yup.string()
        .required("Required field")
        .test("password-match", "Password must match", function (value) {
          return this.parent.password === value;
        }),
    });

  useEffect(() => {
    axios
      .get(
        "https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome"
      )
      .then((response) => {
        if (typeof response !== "undefined") {
          setUfs(response.data);
        }
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedUf}/municipios`
      )
      .then((response) => {
        if (typeof response !== "undefined") {
          setMunicipios(response.data);
        }
      });
  }, [selectedUf]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeUf = (value, setFieldValue) => {
    setSelectedMunicipio("");
    setSelectedUf(value.id);
    setFieldValue("state", value.id || "");
  };

  const handleChangeMunicipio = (value, setFieldValue) => {
    setSelectedMunicipio(value);
    setFieldValue("city_code", value?.id || "");
    setFieldValue("city", value?.id || "");
  };

  const handleSubmit = async (values, { setFieldError }) => {
    setLoading(true)
    const register = await registerUser(values);

    if(register.payload.error && register.payload.result.isSendMail){
      setMsgError(register.payload.error)
      setOpenError(true)
      setLoading(false)
      return
    }

    if(register.payload.result.id){
      // O usuário foi registrado
      // verificar se foi enviado e-mail
      if(register.payload.result.isSendMail){
        // Seguir o processamento normal
        // Enviar UUID para o backend
        const params = {
          user_id: register.payload.result.user_icv_id,
          payload: {
            properties: [
              {
                name: values.name,
                city_code: values.city_code,
              },
            ],
          },
        };
        await registerUserIcvcalc(params);
        setMsgSuccess(`New user added successfully. Verification email has been sent to ${register.payload.result.email}`)
        setOpenSuccess(true)

      } else {
        // registrar a mensagem de erro
        setMsgError(register.payload.error)
        // exibir feedback
        setOpenError(true)
        // Remover o usuário do banco de dados de autenticação
        await deleteUser(register.payload.result.id)
      }
    } else {
      setMsgError('Unable to register new user. Please try again later or contact your system administrator.')
      setOpenError(true)
    }
    setLoading(false)
    handleClose()
  };

  return (
    <div>
      {loading ? (<Loading/>) : null}
      <Button
        fullWidth
        variant="outlined"
        size="small"
        onClick={handleClickOpen}
        startIcon={<AppRegistrationIcon />}
      >
        Register
      </Button>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        scroll="body"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setFieldError }) =>
            handleSubmit(values, { setFieldError })
          }
        >
          {({values, touched, errors, handleChange, handleBlur, isValid, setFieldError, setFieldValue,}) => {
            return (
              <Form noValidate autoComplete="off">
                <Box>
                  <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                    className="title"
                  >
                    Register
                  </BootstrapDialogTitle>

                  <Typography variant="body2" sx={{ ml: 3, mt: 1, color: "red", fontWeight: "bold" }}>
                    * Required Fields
                  </Typography>
                  
                  <DialogContent>
                    {
                      loading ? (<Loading />) : null
                    }
                    <Box display={"flex"} flexDirection={"column"} gap={2} minWidth={400} sx={{ p: 2 }}>
                      <TextField
                        fullWidth
                        size="small"
                        required
                        id="email"
                        type="email"
                        label="E-mail address"
                        value={values.email}
                        onChange={handleChange}
                        error={Boolean(errors.email)}
                        helperText={errors.email}
                      />

                      <Autocomplete
                        size="small"
                        id="state"
                        name="state"
                        options={ufs.length > 0 ? ufs : []}
                        getOptionLabel={(option) => option.nome || ""}
                        onChange={(e, value) => handleChangeUf(value, setFieldValue)}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            label="State of property"
                            value={values.state}
                            error={Boolean(errors.state)}
                            helperText={errors.state}
                          />
                        )}
                      />

                      <Autocomplete
                        size="small"
                        id="city_code"
                        name="city_code"
                        options={municipios.length > 0 ? municipios : []}
                        getOptionLabel={(option) => option.nome || ""}
                        onChange={(e, value) => handleChangeMunicipio(value, setFieldValue)}
                        value={selectedMunicipio || null}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            label="City of property"
                            error={Boolean(errors.city)}
                            helperText={errors.city}
                          />
                        )}
                      />

                      <TextField
                        fullWidth
                        size="small"
                        required
                        id="name"
                        name="name"
                        label="Name of property"
                        value={values.name || ""}
                        onChange={handleChange}
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                      />

                      <TextField
                        fullWidth
                        size="small"
                        required
                        id="password"
                        name="password"
                        type="password"
                        label="Password"
                        value={values.password}
                        onChange={handleChange}
                        error={Boolean(errors.password)}
                        helperText={errors.password}
                      />

                      <TextField
                        fullWidth
                        size="small"
                        required
                        id="confirm_password"
                        name="confirm_password"
                        type="password"
                        label="Confirm Password"
                        value={values.confirm_password}
                        onChange={handleChange}
                        error={touched.confirm_password && Boolean(errors.confirm_password)}
                        helperText={touched.confirm_password && errors.confirm_password}
                      />
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      size="small"
                      onClick={handleClose}
                      variant="outlined"
                      startIcon={<CancelRoundedIcon />}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      autoFocus
                      startIcon={<SaveIcon />}
                      type="submit"
                      //disabled={!isValid}
                    >
                      Save
                    </Button>
                  </DialogActions>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </BootstrapDialog>
      {
        openError
        ? (
          <SnackbarCustom
            open={openError}
            setOpen={setOpenError}
            msg={msgError}
            severity='error'
          />
        )
        : null
      }
      {
        openSuccess
        ? (
          <SnackbarCustom
            open={openSuccess}
            setOpen={setOpenSuccess}
            msg={msgSuccess}
            severity='success'
          />
        )
        : null
      }
      
    </div>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  registerUser: (formRegister) => dispatch(registerUser(formRegister)),
  deleteUser: (id) => dispatch(deleteUser(id)),
  registerUserIcvcalc: (user) => dispatch(registerUserIcvcalc(user)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Register);
