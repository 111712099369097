import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAuth from "../services/apiAuth";
import api from "../services/api";

const initialState = {
  loading: true,
  user: {},
  error: {},
};

export const authUser = createAsyncThunk("authUser", async (formLogin) => {
  const res = await apiAuth.post("/api/auth", formLogin);
  return res.data;
});

export const registerUser = createAsyncThunk( "registerUser", async (formRegister) => {
    const res = await apiAuth.post("/api/register", formRegister);
    return res.data;
  }
);

export const deleteUser = createAsyncThunk("deleteUser", async (id) => {
  const res = await apiAuth.delete(`/api/delete/${id}`)
  return res.data;
})

export const registerUserIcvcalc = createAsyncThunk("registerUserIcvcalc", async (params) => {
    const user_id = params.user_id;
    const body = params.payload;
    await api
      .post(`/new-user-with-single-property/${user_id}`, body)
      .then((res) => res.data)
      .catch((err) =>
        console.log("errRegister: ", JSON.stringify(err, null, 2))
      );
  }
);

export const checkToken = createAsyncThunk("checkToken", async (token) => {
  const res = await apiAuth.post("/api/check-token", { token: token });
  return res;
});

export const saveNewPassword = createAsyncThunk( "saveNewPassword",  async (params) => {
    const res = await apiAuth.post("/api/save-new-password", { params });
    return res.data;
  }
);

export const isRegistered = createAsyncThunk("isRegistered", async (email) => {
  const res = await apiAuth.post("/api/is-registered", { email });
  return res.data;
});

export const changePassword = createAsyncThunk( "changePassword", async (form) => {
    const res = await apiAuth.post("/api/change-password", form);
    return res.data;
  }
);

export const slice = createSlice({
  name: "authUser",
  initialState,
  extraReducers: (builder) => {
    // Autenticar
    builder.addCase(authUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(authUser.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload.results;
      state.error = action.payload.error;
    });
    builder.addCase(authUser.rejected, (state, action) => {
      state.loading = false;
      state.user = {};
      state.error = action.error.message;
    });
    // Registrar
    builder.addCase(registerUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(registerUser.fulfilled, (state, actions) => {
      state.loading = false;
    });
  },
});

export default slice.reducer;
