import { Grid, Typography } from '@mui/material'
import React from 'react'

const HelpItem = (props) => {
  
  const { title, content } = props
  
  return (
      <Grid item sx={{ my: 2 }}>
          <Typography variant="Title" sx={{my:2}}>
              <strong>{title}</strong>
          </Typography>
          <Typography variant="body2" sx={{ ml: 6 }}>
              {content}
          </Typography>
      </Grid>
  )
}

export default HelpItem
