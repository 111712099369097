import { Box, Button, Divider, Grid, Typography } from '@mui/material'
import React from 'react'
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import { useNavigate } from 'react-router-dom';
import PopoverCustom from "../layout/PopoverCustom";


const TitlePage = (props) => {

  const navigate = useNavigate();
  const {handleAdd, title, item, isBack, info} = props

  return (
    <Grid item xs={12}>

      <Divider />
      
      <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} py={1}>
        
        <Typography variant="h6" sx={{ fontWeight: 700, display: 'flex', gap: 2 }}>
          {title} {info ? (<PopoverCustom info={info} />) : null} 
        </Typography>
        
        <Box display={'flex'} gap={2}>
          {
            isBack ? (
              <Button
                size="small"
                variant="outlined"
                startIcon={<ArrowBackIosRoundedIcon />}
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
            ) : null
          }
          
          <Button
            size="small"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => handleAdd(item)}
            color="secondary"
          >
            Add
          </Button>
        </Box>
      </Box>
      <Divider />
    </Grid>
  )
}

export default TitlePage
