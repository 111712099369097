import { Route, Routes } from 'react-router-dom';
import Layout from './components/layout';
import NoPage from './components/nopage';
import Login from './components/login';
import Machinery from './components/machinery'
import Implements from './components/implements'
import Products from './components/products';
import Results from './components/results';
import GenerateSimaPro from './components/generateSimaPro';
import Land from './components/properties/land/index';
import Plot from './components/properties/plot';
import Home from './components/home';
import About from './components/about';
import RegisterConfirm from './components/login/registerConfirm';
import Help from './components/help';

function App() {

  return (
    <Routes>
      
      <Route exact path="/" element={<Login />} />
      <Route path='/verify/:token' element={<RegisterConfirm />} />

      <Route path="/home" element={<Home />} />
      <Route path="/icvcalc" element={<Layout />}>
        <Route path="land" element={<Land />} />
        <Route path="plot/:id" element={<Plot />} />
        <Route path="machinery" element={<Machinery />} />
        <Route path="implements" element={<Implements />} />
        <Route path="products" element={<Products />} />
        <Route path="results" element={<Results />} />
        <Route path="generateSimaPro" element={<GenerateSimaPro />} />
        <Route path="about" element={<About />} />
        <Route path="help" element={<Help />} />
      </Route>
      <Route path="*" element={<NoPage />} />
    </Routes>
  );
}

export default App;
