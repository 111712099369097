import { Box, Button, Typography } from "@mui/material"
import Header from "../layout/Header"
//import Footer from "../layout/Footer"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useNavigate } from 'react-router-dom';

function NoPage() {
    const navigate = useNavigate();
    return (
        <Box>
            <Header />
            <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                minHeight={'83vh'}
            >
                <WarningAmberIcon
                    sx={{ 
                        fontSize: 40, 
                        color: '#ff9800',
                        mb:2
                    }}
                    
                />
                <Typography variant="h5" mb={3}>Page not found.</Typography>
                <Button 
                    variant="outlined"
                    startIcon={<ArrowBackIcon />}
                    onClick={() => navigate(-1)}
                >
                    Back
                </Button>
                
            </Box>
        </Box>
    )
}

export default NoPage