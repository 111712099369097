import React from 'react'
import ShowDataContainer from '../../layout/ShowDataContainer'
import { Box } from '@mui/material'
import { formatedDate } from '../../../hooks/functions'

function ShowPlotActivity(props) {

  const {itemPlot} = props

  return (
    <ShowDataContainer>
      <Box sx={{ fontWeight: 700 }}>
        PLOT ACTIVITY
      </Box>
      {itemPlot?.type ? (<Box><strong>Type: </strong>{itemPlot?.type}</Box>) : null}
      {itemPlot?.start_date ? (<Box><strong>Start date:</strong> {formatedDate(itemPlot?.start_date)}</Box>) : null}
      {itemPlot?.transform_from ? (<Box><strong>Land Use Before:</strong>{" "}{itemPlot?.transform_from}</Box>) : null}
      {itemPlot?.load_treatment ? (<Box><strong>Load Treatment:</strong>{" "}{itemPlot.load_treatment}</Box>) : null}
    </ShowDataContainer>
  )
}

export default ShowPlotActivity
