import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'

function ShowActivePrinciples(props) {

  const {item} = props

  return (
    <Box sx={{ border: "1px solid green", borderLeft: "5px solid green", borderRadius: 2, p: 2, mb: 2,}}>
                                  
        <Typography variant="subtitle2" sx={{ flexShrink: 0, fontWeight: 600 }}>
          Active Principles
        </Typography>
        
        <TableContainer component={Paper}>
          
          <Table size="small" aria-label="a dense table">
            
            <TableHead>

              <TableRow
                sx={{
                  fontWeight: 600,
                  "&:last-child td, &:last-child th": {
                    border: 0,
                    fontSize: "0.8rem",
                  },
                }}
              >
                <TableCell sx={{ fontWeight: 600 }}>#</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Name</TableCell>
                <TableCell sx={{ fontWeight: 600 }} align="right">Amount</TableCell>
                <TableCell sx={{ fontWeight: 600 }} align="right">Unit</TableCell>
              </TableRow>

            </TableHead>

            <TableBody>
              {
                item.active_principles &&  item.active_principles.map((row, index) => (
                  <TableRow key={index} sx={{"& td, & th": {border: 0, fontSize: "0.7rem",},}}>
                    <TableCell component="th" scope="row">{index + 1}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell align="right">{row.amount}</TableCell>
                    <TableCell align="right">{row.unit}</TableCell>
                  </TableRow>
                ))
              }
            </TableBody>

          </Table>

        </TableContainer>

      </Box>
  )
}

export default ShowActivePrinciples

