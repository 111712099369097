import { Box, Typography } from '@mui/material'
import React from 'react'

function ShowSource(props) {

  const {item} = props

  return (
    <Box sx={{border: "1px solid green", borderLeft: "5px solid green", borderRadius: 2, p: 2, mb: 2,}}>
                                  
      <Typography variant="subtitle2" sx={{ flexShrink: 0, fontWeight: 600 }}>
        Source
      </Typography>
      
      <Typography variant="subtitle2" sx={{ lineHeight: 1.3, fontSize: "0.8rem" }}>
        {
          item.source 
          ? (
            <>
              {
                Object.entries(item.source).map(([key, val]) => (
                  <Box key={key}>Source of {key}: {val}</Box>
                ))
              }
            </>
          ) 
          : null
        }
      </Typography>

    </Box>
  )
}

export default ShowSource
