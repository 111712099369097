import { IconButton } from '@mui/material';
import React from 'react'
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const ShowSummaryActions = (props) => {

  const {item, setSelected, setConfirmOpen, size} = props

  return (
    <div style={{display:'flex', zIndex: 2}}>
      <IconButton
        size="small"
        onClick={() => {
          setSelected(item);
          setConfirmOpen(true);
        }}
        sx={
          size ==='small'
          ? { p: { xs: 0, md: 0, }, zIndex: 99 }
          : { p: { xs: 0, md: 1, }, zIndex: 99 }
        }
      >
        <DeleteOutlineIcon sx={size ==='small'? { fontSize: 18, color: "red" }:{ fontSize: 25, color: "red" }}/>
      </IconButton>
    </div>
  )
}

export default ShowSummaryActions
