import { Box, Typography } from '@mui/material'
import React from 'react'

const ShowFuel = (props) => {

  const {item} = props

  return (
    <Box sx={{border: "1px solid green", borderLeft: "5px solid green", borderRadius: 2, p: 2, mb: 2,}}>
                                
      <Typography variant="subtitle2" sx={{width: "100%", flexShrink: 0, fontWeight: 600,}}>
        Fuel
      </Typography>
      
      <Typography variant="subtitle2" sx={{ lineHeight: 1.3, fontSize: "0.7rem" }}>
        {item.fuel.type ? (<Box><strong>Type: </strong>{item.fuel.type}</Box>) : null}
        {item.power_type ? (<Box><strong>Power Type: </strong>{item.power_type}</Box>) : null}
        {item.fuel_cons ? (<Box><strong>Fuel Consumption: </strong>{item.fuel_cons}{" l/h"}</Box>) : null}
        {item.fuel.biodiesel_perc ? (<Box><strong>Biodiesel Percentage: </strong>{item.fuel.biodiesel_perc}{"%"}</Box>) : null}
        {item.fuel.diesel_dens ? (<Box><strong>Diesel Density: </strong>{item.fuel.diesel_dens}{" kg/l"}</Box>) : null}
        {item.fuel.biodiesel_dens ? (<Box><strong>Biodiesel Density: </strong>{item.fuel.biodiesel_dens}{" kg/l"}</Box>) : null}
      </Typography>

    </Box>
  )
}

export default ShowFuel
