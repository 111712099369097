import { Box, Container, Divider, Typography } from '@mui/material'
import React from 'react'
import { helpItems } from '../data/helpData'
import HelpItem from './HelpItem'
import ExplicacaoImg from '../img/Explicacao_ICVCalc.png'

function Help() {
    return (
        <Container sx={{ width: { xs: 340, sm: 500, md: 800 }, my:2 }}>
            {
                helpItems.map((item, index) => (
                    <HelpItem key={index} title={item.title} content={item.content} />
                ))
            }
            
            <Divider />

            <Typography variant="h6" sx={{my:2}}>
                <strong>ICVCalc - Embrapa step-by-step</strong>
            </Typography>
            <Divider />
            
            <Typography variant="body2" sx={{ my: 2 }}>
                Are you new here? Follow the steps on this flowchart to fill the data for your Life 
                Cycle Assessment Inventory! Upon finishing, remember to click on the “<a href="/icvcalc/results" 
                style={{ cursor: 'pointer' }}><strong>Results</strong></a>”{' '} or 
                “<a href="/icvcalc/generateSimaPro" style={{ cursor: 'pointer' }}><strong>SimaPro</strong></a>”{' '} 
                tabs to generate the results!
            </Typography>

            <Box
                component={"img"}
                alt="Capa ICVCalc"
                src={ExplicacaoImg}
                loading="lazy"
                sx={{ maxWidth: "100%",}}
            />

            <Typography variant="body2" sx={{ mb: 2 }}>
                In <strong>ICVCalc</strong>, the foundational element is the <strong>Property</strong>, which 
                serves as the backbone of the system. Each <strong>Property</strong> contains crucial information 
                about its unique <strong>Plots/Lands</strong>, the corresponding <strong>Machinery</strong>, 
                <strong>Machinery's Implements</strong>, and <strong>Agricultural Inputs</strong> used in the 
                production process. 
            </Typography>

            <Typography variant="body2" sx={{ mb: 2 }}>
                New users begin by creating one or more <strong>Properties</strong> that they want to analyze and 
                generate LCA inventories from the information. With that done, the next step involves populating 
                information for each <strong>Plot</strong>, referred to as <strong>Land</strong> in ICVCalc. 
                Contrary to expectations, <strong>Lands</strong> do not focus on crop and production details but 
                rather hold essential soil information and exclusive <strong>Events</strong>, such as corrective 
                applications, performed on the soil and that can have effects in multiple cultivation periods after 
                their application.
            </Typography>
    
            <Typography variant="body2" sx={{ mb: 2 }}>
                To capture <strong>crop</strong> and <strong>production</strong> specifics, users can use 
                the <strong>Plot Activities</strong>, which unfold as a timeline for each <strong>Plot/Land</strong>. 
                This structured approach allows the construction of a comprehensive <strong>cultivation's historic</strong>, 
                detailing activities like harvesting, seedling, and chemical applications across different harvests for 
                inventory purposes.
            </Typography>

            <Typography variant="body2" sx={{ mb: 2 }}>
                The next step involves creating a list of <strong>Machinery</strong> used in <strong>Property</strong> activities, 
                along with their <strong>Implements</strong>. Importantly, this list doesn't guarantee inclusion in the inventory 
                unless the <strong>Machinery</strong> or <strong>Implements</strong> are used in an Event (see explanation on 
                Events below). 
            </Typography>
    
            <Typography variant="body2" sx={{ mb: 2 }}>
                After that, we can focus on <strong>Agricultural Inputs</strong>, that encompass water, fertilizers, pesticides, or 
                correctives applied in a given <strong>Property</strong>. Notably, this information becomes part of the inventory 
                only if used in an <strong>application Event</strong>. Copying these inputs across different properties is, 
                unfortunately, not currently supported in ICVCalc.
            </Typography>
    
            <Typography variant="body2" sx={{ mb: 2 }}>
                The <strong>Event</strong> serves as a pivotal structure that ensures the incorporation of <strong>Machinery</strong>, 
                <strong>Implements</strong>, and <strong>Agricultural Inputs</strong> into the resulting LCA inventory. Adding 
                an <strong>Event</strong> within the <strong>plot/land</strong> or <strong>plot activity</strong> results in the 
                calculation of emissions of operations like applying agricultural inputs, harvesting, seedling, terracing, residue 
                burning and many others, including operations specific for particular crops. The <strong>Event</strong> offers a 
                choice between a <strong>generic operation</strong> and <strong>operation from ecoinvent</strong> approach, with 
                the former preferred for using primary data to calculate emissions. For a comprehensive emission calculation and 
                LCA inventory, it is essential to add <strong>Events</strong>.
            </Typography>
    
            <Box sx={{mb:20}}></Box>
        </Container>
    )
}

export default Help
