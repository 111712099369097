import * as Yup from 'yup';

const validationSchema = () => Yup.object().shape({
    main_type: Yup
        .string()
        .required('Required field'),
    type: Yup
        .string()
        .when(['main_type'], {
            is: (main_type) => {
                if (main_type === 'pesticide' || main_type === 'fertiliser') {
                    return true
                } else {
                    return false
                }
            },
            then: (schema) =>
                schema.required('Required for the type of pesticide or fertiliser')
        }),
    name: Yup
        .string()
        .required('Required field'),
    CaCO3: Yup
        .number()
        .min(0, 'Must be greater than or equal to 0')
        .max(1, 'must be less than or equal to 1'),
    dens: Yup
        .number()
        .min(0, 'Must be greater than or equal to 0')
        .test(
            'Dens required',
            'Required field',
            function(){
                if(
                    this.parent.main_type === 'fertiliser' 
                    && this.parent.type === 'liquid manure'
                    && this.parent?.dens === undefined
                ){
                    return false
                } else {
                    return true
                }
            }
        ),
    N: Yup
        .number()
        .min(0, 'Must be greater than or equal to 0')
        .max(1, 'must be less than or equal to 1')
        .test(
            'N required',
            'Required field',
            function () {
                const sourceN = this.from[0].value.source.N
                const concentrationN = this.from[0].value.N
                let isTypeRequired = true
                let isNPK = false

                if(this.parent.type === "mineral, generic" 
                    || this.parent.type === "mineral" 
                    || this.parent.main_type === 'pesticide'
                    || this.parent.main_type === 'water'
                    || this.parent.main_type === 'corrective'
                ){
                    isTypeRequired = false
                }
                
                if(
                    this.parent.type !== "mineral, generic" 
                    && this.parent.type !== "mineral"
                ){
                    isNPK = !!this.from[0].value.N || !!this.from[0].value.P || !!this.from[0].value.K
                }

                if(isTypeRequired && !isNPK && !concentrationN){
                    return false
                } else if (!!sourceN && !concentrationN) {
                    return false
                }
                else {
                    return true
                }
            }
        ),
    P: Yup
        .number()
        .min(0, 'Must be greater than or equal to 0')
        .max(1, 'must be less than or equal to 1')
        .test(
            'P required',
            'Required field',
            function () {

                const sourceP = this.from[0].value.source.P
                const concentrationP = this.from[0].value.P
                let isTypeRequired = true
                let isNPK = false

                if(this.parent.type === "mineral, generic" 
                    || this.parent.type === "mineral" 
                    || this.parent.main_type === 'pesticide'
                    || this.parent.main_type === 'water'
                    || this.parent.main_type === 'corrective'
                ){
                    isTypeRequired = false
                } 

                if(this.parent.type !== "mineral, generic" && this.parent.type !== "mineral"){
                    isNPK = !!this.from[0].value.N || !!this.from[0].value.P || !!this.from[0].value.K
                }

                if(isTypeRequired && !isNPK && !concentrationP){
                    return false
                } else if (!!sourceP && !concentrationP) {
                    return false
                }
                else {
                    return true
                }
            }
        ),
    K: Yup
        .number()
        .min(0, 'Must be greater than or equal to 0')
        .max(1, 'must be less than or equal to 1')
        .test(
            'K required',
            'Required field',
            function () {

                const sourceK = this.from[0].value.source.K
                const concentrationK = this.from[0].value.K
                let isTypeRequired = true
                let isNPK = false

                if(this.parent.type === "mineral, generic" 
                    || this.parent.type === "mineral" 
                    || this.parent.main_type === 'pesticide'
                    || this.parent.main_type === 'water'
                    || this.parent.main_type === 'corrective'
                ){
                    isTypeRequired = false
                } 

                if(this.parent.type !== "mineral, generic" && this.parent.type !== "mineral"){
                    isNPK = !!this.from[0].value.N || !!this.from[0].value.P || !!this.from[0].value.K
                }

                if(isTypeRequired && !isNPK && !concentrationK){
                    return false
                } else if (!!sourceK && !concentrationK) {
                    return false
                }
                else {
                    return true
                }
            }
        ),
    heavy_metal_content: Yup.object().shape({
        Cd: Yup
            .number()
            .min(0, 'Must be greater than or equal to 0')
            .max(1, 'must be less than or equal to 1'),
        Cu: Yup
            .number()
            .min(0, 'Must be greater than or equal to 0')
            .max(1, 'must be less than or equal to 1'),
        Zn: Yup
            .number()
            .min(0, 'Must be greater than or equal to 0')
            .max(1, 'must be less than or equal to 1'),
        Pb: Yup
            .number()
            .min(0, 'Must be greater than or equal to 0')
            .max(1, 'must be less than or equal to 1'),
        Ni: Yup
            .number()
            .min(0, 'Must be greater than or equal to 0')
            .max(1, 'must be less than or equal to 1'),
        Cr: Yup
            .number()
            .min(0, 'Must be greater than or equal to 0')
            .max(1, 'must be less than or equal to 1'),
        Hg: Yup
            .number()
            .min(0, 'Must be greater than or equal to 0')
            .max(1, 'must be less than or equal to 1'),
        As: Yup
            .number()
            .min(0, 'Must be greater than or equal to 0')
            .max(1, 'must be less than or equal to 1'),
    }),
    source: Yup.object().shape({
        N: Yup
            .string()
            .test(
                'Source N required',
                'Required when informed N Concentration',
                function () {
                    const sourceN = this.from[1].value.source.N
                    const concentrationN = this.from[1].value.N

                    if(this.from[1].value.type === "mineral, generic"){
                        if (!sourceN && !!concentrationN && concentrationN !== "0") {
                            return false
                        } else {
                            return true
                        }
                    } else {
                        return true
                    }
                }
            ),
        P: Yup
            .string()
            .test(
                'Source P required',
                'Required when informed P Concentration',
                function () {
                    const main_type = this.from[1].value.main_type
                    const type = this.from[1].value.type
                    const Pcontent = parseFloat(this.from[1].value.P)
                    if (main_type === 'fertiliser'
                        && type === 'mineral, generic'
                        && Pcontent > 0
                        && !this.parent.P
                    ) {
                        return false
                    } else {
                        return true
                    }

                }
            ),
        K: Yup
            .string()
            .test(
                'Source K required',
                'Required when informed K Concentration',
                function () {
                    const main_type = this.from[1].value.main_type
                    const type = this.from[1].value.type
                    const Kcontent = parseFloat(this.from[1].value.K)
                    if (main_type === 'fertiliser'
                        && type === 'mineral, generic'
                        && Kcontent > 0
                        && !this.parent.K
                    ) {
                        return false
                    } else {
                        return true
                    }
                }
            ),
    }),
})

export default validationSchema
