import * as Yup from 'yup';

const validationSchema = () => Yup.object().shape({
    date: Yup
        .date()
        .required('Required field'),
    applications: Yup.array()
        .of(
            Yup.object().shape({
                type: Yup
                    .string()
                    .test(
                        'Application Type required',
                        'Required field',
                        function () {
                            if (
                                (
                                    this.from[1].value.type === 'application'
                                    || this.from[1].value.type === 'irrigation, generic'
                                    || this.from[1].value.type === 'irrigation, periodic'
                                )
                                && !this.parent.type
                            ) {
                                return false
                            } else {
                                return true
                            }
                        }
                    ),
                amount: Yup
                    .number()
                    .test(
                        'Application Amount required',
                        'Required field',
                        function () {
                            if (this.parent.type && !this.parent.amount) {
                                return false
                            } else {
                                return true
                            }
                        }
                    ),
                amount_unit: Yup
                    .string()
                    .test(
                        'Application Amount Unit required',
                        'Required field',
                        function () {
                            if (this.parent.type && !this.parent.amount_unit) {
                                return false
                            } else {
                                return true
                            }
                        }
                    ),
            })
        ),
    operations: Yup.object().shape({
        amount: Yup
            .number()
            .min(0, 'Must be greater than or equal to 0')
            .test(
                'Name operation required',
                'Required field',
                function () {
                    if (
                        this.from[0].value.type === 'from ecoinvent'
                        && !this.parent.amount
                    ) {
                        return false
                    } else {
                        return true
                    }
                }
            ),
        machinery: Yup.object().shape({
            machinery_id: Yup
                .string()
                .test(
                    'Machinery generic required',
                    'Required field',
                    function () {
                        if (
                            this.from[1].value.type === 'generic'
                            && !this.parent.machinery_id
                        ) {
                            return false
                        } else {
                            return true
                        }
                    }
                ),
        })
    }),
    area_burnt: Yup
        .number()
        .min(0, 'Must be greater than or equal to 0')
        .test(
            'Name operation required',
            'Required field',
            function () {
                if (
                    this.from[0].value.type === 'burning'
                    && !this.parent.area_burnt
                ) {
                    return false
                } else {
                    return true
                }
            }
        ),
    frequency: Yup
        .number()
        .min(1, 'Must be greater than or equal to 1')
        .test(
            'Frequency required',
            'Required field',
            function () {
                if (
                    this.parent.type === 'irrigation, periodic'
                    && !this.parent.frequency
                ) {
                    return false
                } else {
                    return true
                }
            }
        ),
    comb_fuel: Yup
        .number()
        .min(0, 'Must be greater than or equal to 0')
        .test(
            'Name operation required',
            'Required field',
            function () {
                if (
                    this.from[0].value.type === 'burning'
                    && !this.parent.comb_fuel
                ) {
                    return false
                } else {
                    return true
                }
            }
        ),
    water_cons: Yup
        .number()
        .min(0, 'Must be greater than or equal to 0'),
    benefit_time: Yup
        .number()
        .min(0, 'Must be greater than or equal to 0'),
})

export default validationSchema

