import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, IconButton } from '@mui/material';
import React, { useState } from 'react'
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { formatedDate, objetoVazio } from '../../../hooks/functions';
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import ConfirmDialog from '../../layout/ConfirmDialog';
import ShowEventRegister from './ShowEventRegister';
import ShowOperation from './ShowOperation';
import Transports from '../showData/Transports';
import Applications from '../showData/Applications';
import ButtonEdit from '../../layout/ButtonEdit';

const CustomAccordion = styled(Accordion)(({ theme }) => {
  return {
    ".MuiAccordionDetails-root": {
      fontSize: "0.9rem",
    },
    ".MuiAccordionSummary-root": {
      minHeight: "0px",
      lineHeight: 1,
    },
    ".MuiAccordionSummary-content": { p: 0, fontSize: 15 },
    ".MuiButtonBase-root": {
      "& .muiExpanded:": { minHeight: "0px" },
    },
  };
});

function ShowEventDetails(props) {
  
  const { 
    //events, 
    index, 
    item,
    setSelectedCropName,
    setModeEvent,
    setPlotEventSelected,
    setSelectedPlot,
    setOpenPlotEventFormEdit,
    setEventPlotActivitySelected,
    setConfirmOpenEvent,
    plotEventActivitySelected,
    confirmOpenEvent,
    handleDeletePlotEvent,
  } = props;

  const handleEditEvent = (ev, item) => {

    setSelectedCropName(item.crop.name || '');
    handleOpenPlotEventFormEdit();
    setModeEvent("edit");
    setPlotEventSelected(ev);
    setSelectedPlot(item);

  };

    const [expanded, setExpanded] = useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    const handleOpenPlotEventFormEdit = () => {
      setOpenPlotEventFormEdit(true);
    };

    return (
      <Box key={index}>
        {item.events &&
          item.events
            .slice()
            .sort((a,b) => new Date(b.date) < new Date(a.date) ? -1 : 1)
            .map((ev, index) => (
            <CustomAccordion
              key={ev._id}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              elevation={2}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Box
                  display="flex"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  gap={2}
                  width={"100%"}
                  sx={{ fontSize: "0.85rem" }}
                >
                  <Box display={"flex"} gap={2}>
                    <Box>{index + 1}</Box>
                    <Box align="left">{formatedDate(ev.date)}</Box>
                    <Box>{ev.type}</Box>
                    <Box>{ev.main_operation.type}</Box>
                  </Box>

                  <Box sx={{ p: 0 }}>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setEventPlotActivitySelected(ev);
                        setConfirmOpenEvent(true);
                      }}
                      sx={{ p: { xs: 0, md: 0 } }}
                    >
                      <DeleteOutlineRoundedIcon sx={{ fontSize: 16, color: "red" }}/>
                    </IconButton>
                  </Box>

                  {
                    ev._id === plotEventActivitySelected._id 
                    ? (
                      <ConfirmDialog
                        name="Delete event plot activity"
                        open={confirmOpenEvent}
                        setOpen={setConfirmOpenEvent}
                        onConfirm={(event) => handleDeletePlotEvent(item, ev, event)}
                        title={`${formatedDate(ev?.date)} ${ev?.type}`}
                        subtitle={`${ev?.main_operation?.type}`}
                      />
                    ) 
                    : null
                  }
                </Box>

              </AccordionSummary>

              <AccordionDetails>
                <Grid container spacing={1} flexDirection={"column"} sx={{ fontSize: "12px", mt:1 }}>
                  
                  <Grid item>
                    <ShowEventRegister item={ev}/>
                  </Grid>
                  {
                    !objetoVazio(ev.main_operation) ? (
                      <Grid item>
                        <ShowOperation item={ev.main_operation} />
                      </Grid>
                    )
                    : null 
                  }

                  {
                    ev.transports && ev.transports.length > 0 
                    ? (
                      <Grid item>
                        <Transports rows={ev.transports} />
                      </Grid>
                    )
                    : null
                  }

                  {
                    ev.applications && ev.applications.length > 0 
                    ? (
                      <Grid item>
                        <Applications rows={ev.applications} />
                      </Grid>
                    )
                    : null
                  }

                  <Grid item>

                    <Box>
                      <ButtonEdit item={ev} handleEdit={handleEditEvent} params={item} />
                    </Box>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </CustomAccordion>
          ))}
      </Box>
    );
}

export default ShowEventDetails
