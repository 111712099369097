import { Backdrop, Box, Button, CircularProgress, Divider, Grid, ListItemText, MenuItem, Paper, Table, TableBody, TableContainer, TableHead, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import HeaderProperty from '../layout/HeaderProperty'
import { fetchUserAll } from '../../redux/userAllSlice'
import { formatedDate } from '../../hooks/functions'
import { Form, Formik } from 'formik'
import * as Yup from 'yup';
import MiscellaneousServicesRoundedIcon from '@mui/icons-material/MiscellaneousServicesRounded';
import { fetchResults, resetResults } from '../../redux/resultsSlice'
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const validationSchema = Yup.object().shape({
    plot_ter_id: Yup
        .string()
        .required('Field Required'),
    plot_id: Yup
        .string()
        .required('Field Required')
})

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.success.dark,
        color: theme.palette.common.white,
        padding: '6px 16px',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,

    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export const Results = (props) => {

    const [landSelected, setLandSelected] = useState('')
    const [landSelectedData, setLandSelectedData] = useState('')
    const [plotSelected, setPlotSelected] = useState('')
    const [plots, setPlots] = useState('')
    const [error, setError] = useState(false)
    const [keyResult, setKeyResult] = useState('')

    const property = JSON.parse(localStorage.getItem('property'))
    const user = JSON.parse(localStorage.getItem('user'))

    const {
        fetchUserAll,
        userAll,
        fetchResults,
        results,
        resetResults
    } = props

    const initialValues = {
        plot_ter_id: '',
        plot_id: ''
    }


    useEffect(() => {
        fetchUserAll(user.user_icv_id)
    }, [fetchUserAll, user.user_icv_id])

    const propertySelected = userAll.registro.properties && userAll.registro.properties.filter(p => p._id === property._id)
    const lands = propertySelected && propertySelected[0].plot_terrains

    const handleChangeLand = (event, setFieldValue) => {
        setLandSelectedData('')
        setPlotSelected('')
        setLandSelected(event.target.value)
        setFieldValue('plot_ter_id', event.target.value)
        setError(false)
        resetResults()
    }

    const handleChangePlot = (event, setFieldValue) => {
        setPlotSelected(event.target.value)
        setFieldValue('plot_id', event.target.value)
        setError(false)
        resetResults()
    }

    useEffect(() => {
        setLandSelectedData(lands && lands.filter(l => l._id === landSelected))
    }, [landSelected, lands])

    useEffect(() => {
        setPlots(landSelectedData && landSelectedData[0]?.plots)
    }, [landSelected, landSelectedData, plots])

    const handleSubmit = async (values) => {
        const params = {
            user_id: user.user_icv_id,
            prop_id: property._id,
            plot_ter_id: values.plot_ter_id,
            plot_id: values.plot_id,
            method: 'BRCalc',
            mode: 'results'
        }
        const result = await fetchResults(params)
        setKeyResult(Object.keys(result.payload)[0])
        if(result.payload.error) setError(true)
    }

    const converteValues = (val) => {
        var num = parseFloat(val.replace(',', '.'))
        if (num === 0){
            num = 0.00
        } else if(num < 0.01){
            num = num.toExponential(2)
        } else {
            num = num.toFixed(2)
        }
        return num
    }

    return (
        <Box sx={{ width: { xs: 340, sm: 500, md: 800 } }}>
            
            <HeaderProperty property={property}/>
            
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} py={1}>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>Results</Typography>
            </Box>

            <Divider sx={{ marginBottom: '10px' }} />

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => handleSubmit(values)}
            >
                {({ values, touched, errors, setFieldValue, isValid }) => {
                    return (
                        <Form noValidate autoComplete="off" >
                            <Grid container gap={2} mt={2}>
                                <TextField
                                    fullWidth
                                    size='small'
                                    select
                                    name='plot_ter_id'
                                    label="select the land"
                                    value={landSelected}
                                    onChange={(event) => handleChangeLand(event, setFieldValue)}
                                    error={touched.plot_ter_id && Boolean(errors.plot_ter_id)}
                                    helperText={touched.plot_ter_id && errors.plot_ter_id}
                                >
                                    {
                                        !lands ? (
                                            <MenuItem key={'no_options'} value={'no_options'}>{'no_options'}</MenuItem>
                                        ) : (
                                            lands.map((option) => (
                                                <MenuItem key={option._id} value={option._id}>{option.name}</MenuItem>
                                            ))
                                        )
                                    }
                                </TextField>

                                <TextField
                                    fullWidth
                                    size='small'
                                    name='plot_id'
                                    select
                                    label="select the plot"
                                    value={plotSelected}
                                    onChange={(event) => handleChangePlot(event, setFieldValue)}
                                    error={touched.plot_id && Boolean(errors.plot_id)}
                                    helperText={touched.plot_id && errors.plot_id}
                                >
                                    {
                                        !plots ? (
                                            <MenuItem key={'no_options'} value={'no_options'}>{'No options'}</MenuItem>
                                        ) : (
                                            plots.filter(p => p.load_treatment !== "Donate Loads").map((option) => (
                                                <MenuItem key={option._id} value={option._id}>
                                                    <ListItemText
                                                        primary={
                                                            `${option.type} 
                                                            ${option?.crop?.name ? option.crop.name : ''} 
                                                            ${formatedDate(option.start_date)}`
                                                        }
                                                        secondary={
                                                            `${option.load_treatment} (${option.transform_from})`
                                                        }
                                                    >
                                                    </ListItemText>

                                                </MenuItem>
                                            ))
                                        )
                                    }
                                </TextField>

                                <Button
                                    disabled={!isValid}
                                    variant='contained'
                                    startIcon={<MiscellaneousServicesRoundedIcon />}
                                    autoFocus
                                    type='submit'
                                    color='secondary'
                                >
                                    Process Result
                                </Button>
                            </Grid>
                        </Form>
                    )
                }}
            </Formik>

            {
                results && results.loading ? (
                    <Backdrop open={true} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                        <CircularProgress color="secondary" />
                    </Backdrop>
                ) : (
                    <Box sx={{ mt: 2 }}>
                        {
                            error && results.registro.error ? (
                                <Box>
                                    <Typography variant='body2' color='error'>{results.registro.message}</Typography>
                                </Box>
                            ) : (
                                <>
                                    {
                                        results && results.registro[keyResult]
                                        ? (
                                            Object.entries(results.registro[keyResult]).map(([key, val]) => {
                                                return (
                                                    <TableContainer component={Paper} key={key} sx={{ mb: 4}} elevation={10}>
                                                        <Table sx={{ minWidth: 600 }} size="small" >
                                                            <TableHead>
                                                                <TableRow>
                                                                    <StyledTableCell colSpan={4} align="center">
                                                                        <Typography
                                                                            variant="overline"
                                                                            display="block"
                                                                            //gutterBottom
                                                                            sx={{ p: 0, fontWeight: 700 }}
                                                                        >
                                                                            {key}
                                                                        </Typography>
                                                                    </StyledTableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <StyledTableCell>Item</StyledTableCell>
                                                                    <StyledTableCell>Value</StyledTableCell>
                                                                    <StyledTableCell>Unit</StyledTableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>

                                                                {
                                                                    Object.entries(val).map(([key1, val1]) => (
                                                                        <StyledTableRow key={key1} >
                                                                            <StyledTableCell>{key1}</StyledTableCell>
                                                                            <StyledTableCell>{converteValues(val1[0])}</StyledTableCell>
                                                                            <StyledTableCell>{val1[1]}</StyledTableCell>
                                                                        </StyledTableRow>
                                                                    ))
                                                                }
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                )
                                            })
                                        ) : null
                                    }
                                </>
                            )
                        }
                    </Box>
                )
            }
            <Box sx={{ pb: 10 }}></Box>
        </Box >
    )
}

const mapStateToProps = (state) => {
    return {
        userAll: {
            loading: state.userAll.loading,
            registro: state.userAll.registro,
            error: state.userAll.error
        },
        results: {
            loading: state.results.loading,
            registro: state.results.registro,
            error: state.results.error
        }
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchUserAll: (user_id) => dispatch(fetchUserAll(user_id)),
        fetchResults: (params) => dispatch(fetchResults(params)),
        resetResults: () => dispatch(resetResults())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Results)