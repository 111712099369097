import { Box } from "@mui/material";
import React from "react";
//import LogoProjetoImg from '../img/logo-icv-calc.png'
//import LogoProjetoImg from '../img/ICVCalc_Embrapa_150px_125px.png'
import LogoProjetoImg from "../img/ICVCalc_Embrapa_240px_200px.png";

function LogoProjeto() {
  return (
    <Box
      minWidth={200}
      minHeight={100}
      mb={5}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        component={"img"}
        alt="Logo do projeto ICVCalc"
        src={LogoProjetoImg}
        loading="lazy"
        sx={{ width: { xxs: 90, xs: 100, md: 120 } }}
      />
    </Box>
  );
}

export default LogoProjeto;
