import { Box, Button, Card, Container, Grid, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Cover from './Cover'
import LogoProjeto from './LogoProjeto'
import Footer from './Footer'
import { useNavigate, useParams } from 'react-router-dom'
import { checkToken } from '../../redux/userSlice'
import { useDispatch } from 'react-redux'

function RegisterConfirm(props) {
    
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [resultVerify, setResultVerify] = useState({})
    let { token } = useParams()

    useEffect(() => {
        const getToken = async () => {
            const result = await dispatch(checkToken(token))
            setResultVerify(result.payload.data)
        }
        getToken()
    }, [dispatch, token])

    return (
        <Grid container component='main' sx={{ height: '100vh' }}>
            <Cover />
            <Grid item xs={12} sm={12} md={5} lg={6} component={Paper} elevation={6} square>
                <Container >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignItems: 'center',
                            height: '100vh',
                        }}
                    >
                        <LogoProjeto />
                        <Card
                            elevation={5}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignContent: 'center',
                                alignItems: 'center',
                                minHeight: '50%',
                                mb: 5,
                                width: '90%',
                                backgroundColor: '#EFF7EF',
                                p: 2
                            }}
                        >
                            {
                                resultVerify.result && resultVerify.result.isChecked && resultVerify.result.isSave
                                    ? (
                                        <>
                                            <Typography variant='h5' mb={1}>Confirmed registration</Typography>
                                            <Typography variant='h6' mb={3}>Welcome!</Typography>
                                        </>
                                    )
                                    : (
                                        <>
                                            <Typography variant='h5' mb={1}>unconfirmed registration</Typography>
                                            <Typography variant='body2' mb={3}>Make contact with the person responsible for the system</Typography>
                                        </>
                                    )
                            }

                            <Button
                                variant='contained'
                                onClick={() => navigate("/")}
                            >
                                Sign in
                            </Button>
                        </Card>

                        <Footer />
                    </Box>
                </Container>
            </Grid>
        </Grid>
    )
}

export default RegisterConfirm
