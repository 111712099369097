import { Box, Typography } from '@mui/material'
import React from 'react'

function ShowHeavyMetalContent(props) {

  const {item} = props

  return (
    <Box sx={{border: "1px solid green", borderLeft: "5px solid green", borderRadius: 2, p: 2, mb: 2,}}>
                                  
      <Typography variant="subtitle2" sx={{ flexShrink: 0, fontWeight: 600 }}>
        Heavy Metal Content
      </Typography>
      
      <Typography variant="subtitle2" sx={{ lineHeight: 1.3, fontSize: "0.8rem" }}>

        {
          item.heavy_metal_content 
          ? (
            <>
              {
                Object.entries(item.heavy_metal_content).map(([key, val]) => (
                  <Box key={key}>{key} content: {val}{` kg ${key}/kg`}</Box>
                ))
              }
            </>
          ) 
          : null
        }

      </Typography>

    </Box>
  )
}

export default ShowHeavyMetalContent
