import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import NavBarHorizontalHome from "../layout/NavBarHorizontalHome";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { deleteProperty, fetchUserAll } from "../../redux/userAllSlice";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import PropertyFormAdd from "./PropertyFormAdd";
import PropertyFormEdit from "./PropertyFormEdit";
import ConfirmDialog from "../layout/ConfirmDialog";
import { objetoVazio } from "../../hooks/functions";
import ShowRegister from './ShowRegister';
import ShowGIS from './ShowGIS';
import ShowSummary from '../layout/ShowSummary';
import Loading from '../layout/Loading';
import TitlePage from '../layout/TitlePage';
import ShowSummaryActions from '../layout/ShowSummaryActions';
import EditButton from '../layout/ButtonEdit';

export const Home = (props) => {
  const navigate = useNavigate();

  const { fetchUserAll, userAll, deleteProperty } = props;

  const user =
    localStorage.getItem("user") &&
    JSON.parse(localStorage.getItem("user") || {});

  const [propertySelected, setPropertySelected] = useState({});
  const [openPropertiesForm, setOpenPropertiesForm] = useState(false);
  const [openPropertiesFormEdit, setOpenPropertiesFormEdit] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  useEffect(() => {
    if (user?.user_icv_id) {
      fetchUserAll(user?.user_icv_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let properties = userAll.registro.properties;

  const handleChange = (panel) => (event, isExpanded, item) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleAdd = () => {
    setPropertySelected({});
    setOpenPropertiesForm(true);
  };

  const registerProperty = (property) => {
    setPropertySelected(property);
    const propertyRegister = {
      _id: property._id,
      name: property.name,
    };
    window.localStorage.setItem("property", JSON.stringify(propertyRegister));
    return navigate("/icvcalc/land");
  };

  const handleDeleteProperty = async (prop_id) => {
    const params = {
      user_id: user.user_icv_id,
      prop_id,
    };
    await deleteProperty(params);
  };

  const handleEdit = async (item) => {
    setOpenPropertiesFormEdit(true);
    setPropertySelected(item);
  };

  return (
    <>
      <NavBarHorizontalHome />
      <Container sx={{ minWidth: "100%", display: "flex", justifyContent: "center",}}>
        <Grid
          container
          display="flex"
          justifyContent={"space-between"}
          sx={{ width: { xs: 340, sm: 500, md: 800 },}}
        >
          <TitlePage 
            title="Choose property" 
            handleAdd={handleAdd} 
            info={
              <Typography component={'span'}>
                <strong>Properties</strong> refer to the <strong>croplands</strong> in our context. 
                They serve as the fundamental elements in our inventory information. 
                Each <strong>property</strong> is associated with a distinct list of specific 
                <strong> Agricultural Inputs</strong> and <strong>Machinery</strong>, including 
                various machinery <strong>Implements</strong>. 
                Additionally, every <strong>Property</strong> is subdivided into multiple areas 
                designated for cultivation, commonly referred to as <strong>plots</strong>. 
                In the context of ICVCalc, these areas are alternatively known as <strong>Land</strong>.<br/><br/>
                More information in the <a href="/icvcalc/help" style={{ cursor: 'pointer' }}>Help</a>{' '} tab
              </Typography>
            } 
          />

          <Grid item xs={12} sx={{ mt: 1 }}>
            {
              userAll.loading 
              ? (<Loading />) 
              : !objetoVazio(userAll.error) 
                ? (
                  <Typography variant="body" sx={{ color: "red" }}>
                    Unable to access data. Contact your system administrator.
                  </Typography>
                ) 
                : (
                  <>
                    {
                      properties && properties.map((item, index) => (
                        <Accordion
                          key={index}
                          expanded={expanded === `panel${index}`}
                          onChange={handleChange(`panel${index}`)}
                          elevation={2}
                          width={"800px"}
                        >
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <ShowSummary title={item.name}  subtitle={item.city_code}/>

                            <Button
                              size="small"
                              variant="contained"
                              onClick={() => registerProperty(item)}
                              color="tertiary"
                            >
                              LAND
                            </Button>

                            <ShowSummaryActions 
                              item={item} 
                              setSelected={setPropertySelected} 
                              setConfirmOpen={setConfirmOpen}
                            />

                            {
                              item._id === propertySelected._id 
                              ? (
                                <ConfirmDialog
                                  name="Delete property"
                                  open={confirmOpen}
                                  setOpen={setConfirmOpen}
                                  onConfirm={() => handleDeleteProperty(item._id)}
                                  title={item?.name}
                                  subtitle={item.city_code}
                                />
                              ) 
                              : null
                            }

                          </AccordionSummary>

                          <AccordionDetails>
                            
                            <Grid container spacing={2} display={"flex"}>

                              <Grid item xs={12} sm={4} md={5}>
                                <ShowRegister item={item} />
                              </Grid>
                              <Grid item xs={12} sm={8} md={7}>
                                <ShowGIS GIS={item.GIS}/>
                              </Grid>

                            </Grid>

                            <EditButton item={item} handleEdit={handleEdit} />

                          </AccordionDetails>
                        </Accordion>
                      ))
                    }
                  </>
                )
            }
          </Grid>
        </Grid>
      </Container>
      <Box sx={{ pb: "100px" }}></Box>

      {
        openPropertiesForm ? (
          <PropertyFormAdd
            openPropertiesForm={openPropertiesForm}
            setOpenPropertiesForm={setOpenPropertiesForm}
          />
        ) : null
      }

      {
        openPropertiesFormEdit ? (
          <PropertyFormEdit
            openPropertiesForm={openPropertiesFormEdit}
            setOpenPropertiesForm={setOpenPropertiesFormEdit}
            propertySelected={propertySelected}
          />
        ) : null
      }

    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userAll: {
      loading: state.userAll.loading,
      registro: state.userAll.registro,
      selectedProperty: state.userAll.selectedProperty,
      error: state.userAll.error,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserAll: (user_id) => dispatch(fetchUserAll(user_id)),
    deleteProperty: (params) => dispatch(deleteProperty(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
