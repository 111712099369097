import { Box, Button, Divider, Grid, IconButton, Typography } from '@mui/material';
import HorizontalTimeline from "react-horizontal-timeline";
import { formatedDate } from '../../../hooks/functions';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import './styleTimeLineHorizontal.css'
import ConfirmDialog from '../../layout/ConfirmDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import ButtonEdit from '../../layout/ButtonEdit';
import ShowPlotActivity from './ShowPlotActivity';
import ShowCrop from './ShowCrop';
import ShowSeed from './ShowSeed';
import ShowMetalContent from './ShowMetalContent';

const PlotActivityTimelineHorizontal = (props) => {
    const {
        plots,
        setSelectedPlot,
        handleOpenPlotEventForm,
        EventDetails,
        handleEdit,
        state,
        setState,
        plotActivitySelected,
        setPlotActivitySelected,
        confirmOpen,
        setConfirmOpen,
        handleDeletePlot,
        setSelectedCropName,
        setModeEvent,
        setPlotEventSelected,
        setOpenPlotEventFormEdit,
        setEventPlotActivitySelected,
        setConfirmOpenEvent,
        plotEventActivitySelected,
        confirmOpenEvent,
        handleDeletePlotEvent,
    } = props
    // eslint-disable-next-line no-unused-vars
    const curStatus = plots[state.curIdx]?._id || '';
    // eslint-disable-next-line no-unused-vars
    const prevStatus = state.prevIdx >= 0 ? plots[state.prevIdx]?._id : "";

    const ViewData = (props) => {
        const { item } = props
        return (
            <>
                <Divider />
                {
                    item
                    ? (
                        <>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                alignItems: 'center',
                                background: '#edf9ed',
                                py: 1,
                            }}>
                                <Typography variant='h5'>{item.transform_from}</Typography>
                                <Typography variant='body'>{item.type}{' '}{item.load_treatment}{' '}({formatedDate(item.start_date)})</Typography>
                            </Box>

                            <Divider sx={{ mb: 2 }} />
                            <Grid container spacing={2}>
                                <Grid item sm={12} md={4} lg={5} sx={{ fontSize: '12px' }}>

                                    <ShowPlotActivity itemPlot={item}/>

                                    {
                                        item.crop 
                                        ? (
                                            <ShowCrop itemPlot={item}/>
                                        ) : null
                                    }

                                    {
                                        item.crop && item.crop.seed 
                                        ? (
                                            <ShowSeed itemPlot={item} />
                                        ) : null
                                    }

                                    {
                                        item.crop
                                        && item.crop.dry_mass_heavy_metal_content 
                                        ? (
                                            <ShowMetalContent itemPlot={item} />
                                        ) : null
                                    }
                                    <Box display={'flex'} gap={3} alignContent={'center'} alignItems={'center'}>
                                        
                                        <ButtonEdit item={item} handleEdit={handleEdit}/>
                                        
                                        <Box sx={{ p: 0 }}>
                                            <IconButton
                                                size='small'
                                                sx={{ size: '12px' }}
                                                onClick={() => {
                                                    setPlotActivitySelected(item)
                                                    setConfirmOpen(true)
                                                }}
                                            >
                                                <DeleteIcon sx={{ color: 'red', fontSize: '25px' }} />
                                            </IconButton>
                                        </Box>
                                        {
                                            item._id === plotActivitySelected._id
                                                ? (
                                                    <ConfirmDialog
                                                        name='Delete plot activity'
                                                        open={confirmOpen}
                                                        setOpen={setConfirmOpen}
                                                        onConfirm={() => handleDeletePlot(item._id)}
                                                        title={`${item?.type} ${item?.transform_from}`}
                                                        subtitle={`${item?.load_treatment} ${formatedDate(item?.start_date)}`}
                                                    />
                                                    
                                                )
                                                : null
                                        }
                                    </Box>
                                </Grid>
                                {/****  Events ****/}
                                {
                                    ( item.events && item.events.length > 0 ) 
                                    || item.type === 'crop'
                                    ? (
                                        <Grid item sm={12} md={8} lg={7} sx={{ fontSize: '12px' }}>
                                            
                                            <Box sx={{ border: '1px solid green', borderLeft: '5px solid green', borderRadius: 2, p: 2 }}>
                                                <Box display='flex' flexDirection={'row'} justifyContent={'space-between'} mb={2}>
                                                    <Box sx={{ fontWeight: 'bold' }}>EVENTS</Box>
                                                    <Button
                                                        size='small'
                                                        variant='contained'
                                                        onClick={() => {
                                                            setSelectedPlot(item)
                                                            handleOpenPlotEventForm(item?.crop?.name)
                                                        }}
                                                        startIcon={<AddCircleOutlineRoundedIcon />}
                                                    >
                                                        Add Event
                                                    </Button>
                                                </Box>

                                                <EventDetails
                                                    key={item._id}
                                                    events={item.events}
                                                    item={item}
                                                    itemPlot={item}
                                                    setSelectedCropName={setSelectedCropName}
                                                    setModeEvent={setModeEvent}
                                                    setPlotEventSelected={setPlotEventSelected}
                                                    setSelectedPlot={setSelectedPlot}
                                                    setOpenPlotEventFormEdit={setOpenPlotEventFormEdit}
                                                    setEventPlotActivitySelected={setEventPlotActivitySelected}
                                                    setConfirmOpenEvent={setConfirmOpenEvent}
                                                    plotEventActivitySelected={plotEventActivitySelected}
                                                    confirmOpenEvent={confirmOpenEvent}
                                                    handleDeletePlotEvent={handleDeletePlotEvent}
                                                />
                                            </Box>
                                        </Grid>
                                    )
                                    : null
                                }
                            </Grid>
                            <Divider />
                        </>
                    ) : null
                }
            </>
        )
    }

    return (
        <div>
            {
                plots.length > 0
                    ? (
                        <>
                            <div
                                className={'horizontal-timeline'}
                                style={{
                                    width: "100%",
                                    height: "80px",
                                    margin: "0 auto",
                                    fontSize: "14px",
                                    
                                }}
                            >
                                <HorizontalTimeline
                                    styles={{
                                        background: "#ffffff",
                                        foreground: "#318712",
                                        outline: "#dfdfdf"
                                    }}
                                    index={state.curIdx}
                                    indexClick={(index) => {
                                        const curIdx = state.curIdx;
                                        setState({ curIdx: index, prevIdx: curIdx });
                                    }}
                                    values={plots.map((x) => {
                                        return x.start_date
                                    })}
                                    minEventPadding={20}
                                    maxEventPadding={100}
                                    linePadding={70}
                                    labelWidth={85}
                                    getLabel={(date, index) => formatedDate(date)}
                                />
                            </div>

                            <div className="text-center">
                                <ViewData state={state} item={plots[state.curIdx]}/>
                            </div>
                        </>
                    )
                    : (<Box>No plot registered.</Box>)
            }

        </div>
    )
}

export default PlotActivityTimelineHorizontal
