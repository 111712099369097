import { Box, Collapse, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, tableCellClasses } from '@mui/material'
import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.common.white,
        fontSize: 12,
        lineHeight: 1.2,
        padding: '6px',
        opacity: 0.9
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
        lineHeight: 1.2,
        padding: '6px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        //border: 0,
    },
    '&:MuiTableCell-root': {
        lineHeight: 1.0,
    }
}));

function Machinery(props) {
    const { rows } = props

    const RowsMachinery = (props) => {
        const { row, index } = props
        const [openDetailsMachinery, setOpenDetailsMachinery] = useState(false)
        return (
            <>
                <StyledTableRow>
                    <StyledTableCell>{index + 1}</StyledTableCell>
                    <StyledTableCell>{row.machine_name}</StyledTableCell>
                    <StyledTableCell>{row.type}</StyledTableCell>
                    <StyledTableCell>{row.model}</StyledTableCell>
                    <StyledTableCell>{row.specific_type}</StyledTableCell>
                    <StyledTableCell>{row.specific_subtype}</StyledTableCell>
                    <StyledTableCell>
                        <IconButton
                            size="small"
                            onClick={() => setOpenDetailsMachinery(!openDetailsMachinery)}
                        >
                            {openDetailsMachinery ? <KeyboardArrowUpIcon sx={{ fontSize: 15 }} /> : <KeyboardArrowDownIcon sx={{ fontSize: 15 }} />}
                        </IconButton>
                    </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow style={{ paddingBottom: 0, paddingTop: 0 }} >
                    <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                        <Collapse in={openDetailsMachinery} timeout={'auto'} sx={{ p: 2 }} unmountOnExit>
                            <Grid container>
                                <Grid item md={6}>
                                    <Grid item sx={{ fontWeight: 'bold' }}>Register</Grid>
                                    <Grid xs={12} item>
                                        <Box sx={{ fontSize: 12, lineHeight: 1.2 }}>
                                            {row.lifespan && (<Box>Lifespan: {row.lifespan}</Box>)}
                                            {row.tset_lifespan && (<Box>Tireset Lifespan: {row.tset_lifespan}</Box>)}
                                            {row.tset_rubbed_off && (<Box>Rubber Removed: {row.tset_rubbed_off}</Box>)}
                                            {row.tset_weight && (<Box>Tireset Weight: {row.tset_weight}</Box>)}
                                            {row.weight && (<Box>Weight: {row.weight}</Box>)}
                                            {row.fuel_cons && (<Box>Fuel Consumption: {row.fuel_cons}</Box>)}
                                            {row.energy_cons && (<Box>Energy Consumption: {row.energy_cons}</Box>)}
                                            {row.power_type && (<Box>Power Type: {row.power_type}</Box>)}
                                            {row.operating_yield && (<Box>Operating Yield: {row.operating_yield}</Box>)}
                                            {row.power && (<Box>Power: {row.power}</Box>)}
                                        </Box>
                                    </Grid>
                                </Grid>
                                {
                                    row.fuel && (
                                        <Grid item md={6}>
                                            <Grid item sx={{ fontWeight: 'bold' }}>Fuel</Grid>
                                            <Grid item>
                                                <Box sx={{ fontSize: 12, lineHeight: 1.2 }}>
                                                    {row.fuel.type && (<Box>Type: {row.fuel.type}</Box>)}
                                                    {row.fuel.diesel_dens && (<Box>Diesel Density: {row.fuel.diesel_dens}</Box>)}
                                                    {row.fuel.biodiesel_perc && (<Box>Biodiesel Percentage: {row.fuel.biodiesel_perc}</Box>)}
                                                    {row.fuel.biodiesel_dens && (<Box>Biodiesel Density: {row.fuel.biodiesel_dens}</Box>)}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )
                                }

                            </Grid>
                        </Collapse>
                    </StyledTableCell>
                </StyledTableRow>
            </>
        )
    }

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: '100%' }} >
                <TableHead>
                    <StyledTableRow>
                        <StyledTableCell align='center' colSpan={7}>Machinery</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <StyledTableCell>#</StyledTableCell>
                        <StyledTableCell>Name</StyledTableCell>
                        <StyledTableCell>Type</StyledTableCell>
                        <StyledTableCell>Model</StyledTableCell>
                        <StyledTableCell>Specific Type</StyledTableCell>
                        <StyledTableCell>Specific Subtype</StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {
                        rows && rows.map((row, index) => (
                            <RowsMachinery row={row} key={index} index={index} />
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default Machinery