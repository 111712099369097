import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import PlotForm from "./PlotForm";
import PlotEventForm from "./PlotEventForm";
import HeaderProperty from "../../layout/HeaderProperty";
import { connect } from "react-redux";
import {
  deletePlot,
  deletePlotEvent,
  fetchUserAll,
} from "../../../redux/userAllSlice";
import PlotEventFormEdit from "./PlotEventFormEdit";
import PlotActivityTimelineHorizontal from "./plotActivityTimelineHorizontal";
import Loading from '../../layout/Loading'
import PlotActivityTimelineVertical from './plotActivityTimelineVertical';
import EventDetails from './ShowEventDetails'
import PopoverCustom from '../../layout/PopoverCustom'

export const Plot = (props) => {
  const navigate = useNavigate();
  const { userAll, fetchUserAll, deletePlot, deletePlotEvent } = props;

  let { id } = useParams();
  const [itensDetails, setItensDetails] = useState({id: null, event: false, data: false,});
  const [mode, setMode] = useState("add");
  const [modeEvent, setModeEvent] = useState("add");
  const [plotActivitySelected, setPlotActivitySelected] = useState({});
  const [plotEventActivitySelected, setEventPlotActivitySelected] = useState({});
  const [plotEventSelected, setPlotEventSelected] = useState({});
  const [openPlotForm, setOpenPlotForm] = useState(false);
  const [openPlotEventForm, setOpenPlotEventForm] = useState(false);
  const [openPlotEventFormEdit, setOpenPlotEventFormEdit] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({plot_id: null, crop_id: null, event: {},});
  const [selectedCropName, setSelectedCropName] = useState("");
  const [selectedPlot, setSelectedPlot] = useState("");
  const [updatedUsers, setUpdatedUsers] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmOpenEvent, setConfirmOpenEvent] = useState(false);
  const [selectedDirection, setSelectedDirection] = useState("v");
  const [state, setState] = useState({ curIdx: 0, prevIdx: 0 });
  const [plot_terrain, setPlot_terrain] = useState([]);
  const [plots, setPlots] = useState([]);
  const [index_property, setIndex_Property] = useState("");
  const [index_plot_terrain, setIndex_plot_terrain] = useState("");

  const user = JSON.parse(localStorage.getItem("user"));
  const property = JSON.parse(localStorage.getItem("property"));

  const handleChangeDirection = (event) => {
    setSelectedDirection(event.target.value);
  };

  useEffect(() => {
    if (!updatedUsers) {
      fetchUserAll(user.user_icv_id);
    }
    setUpdatedUsers(true);
  }, [fetchUserAll, setUpdatedUsers, updatedUsers, user.user_icv_id]);

  useEffect(() => {
    if (userAll.registro.properties) {
      const ind_property = userAll.registro.properties.findIndex((p) => p._id === property._id);
      setIndex_Property(ind_property);
      const ind_plot_terr = userAll.registro.properties[ind_property].plot_terrains.findIndex((el) => el._id === id);
      setIndex_plot_terrain(ind_plot_terr);
      setPlot_terrain(userAll.registro.properties[ind_property].plot_terrains[ind_plot_terr]);
      setPlots(userAll.registro.properties[ind_property].plot_terrains[ind_plot_terr].plots);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAll.registro.properties]);

  const handleViewData = (type, index) => {
    setSelectedEvent({});
    if (type === "crop") {
      setItensDetails({ id: index, event: false, data: true });
    } else {
      setItensDetails({ id: index, event: true, data: false });
    }
  };

  const handleOpenPlotForm = () => {
    setOpenPlotForm(true);
  };

  const handleOpenPlotEventForm = (nameCrop) => {
    setSelectedCropName(nameCrop);
    setOpenPlotEventForm(true);
  };

  const handleDeletePlot = async (plot_id) => {
    setState({ curIdx: 0, prevIdx: 0 });
    const params = {
      prop_id: property._id,
      plot_ter_id: id,
      plot_id,
    };
    await deletePlot(params);
  };

  const handleDeletePlotEvent = async (plot, ev, event) => {
    const params = {
      user_id: user.user_icv_id,
      prop_id: property._id,
      plot_ter_id: id,
      plot_id: plot._id,
      event_id: ev._id,
    };
    await deletePlotEvent(params);
  };

  const handleEdit = (item) => {
    setMode("edit");
    setPlotActivitySelected(item);
    handleOpenPlotForm();
  };

  return (
    <Box sx={{ width: { xs: 340, sm: 500, md: 800, lg: 900 } }}>
      <HeaderProperty property={property} land={plot_terrain} />
      <Grid container>

        <Grid item xs={12} sm={12} md={4} display={"flex"} alignItems={"center"} gap={2}>
          <Typography variant="h6" sx={{ fontWeight: 700 }}>
            Plot Activity Timeline
          </Typography>
          <PopoverCustom info={
              <span style={{fontSize:'16px'}}>            
              The <strong>soil</strong> itself is not inherently productive; its productivity emerges 
              through cultivation. This process is captured and organized in ICVCalc through 
              <strong> plot activities</strong>. Essentially, this section serves as a chronological 
              record or <strong>timeline of cultivations</strong> that occurred in a given 
              <strong> Land</strong>. It provides insights into the various 
              <strong> cultivations</strong> carried out on that specific <strong>plot</strong>. 
              Importantly, this is the repository for information related to <strong>crops</strong>.
              <br/><br/>

              <strong>Plot activities</strong> have <strong>Events</strong> that represent the 
              <strong> actions performed during the cultivation</strong>. These <strong>Events </strong> 
              encompass a range of activities, including <strong>generic</strong> ones such as 
              harvesting or planting, <strong>application</strong> activities like fertilization and 
              application of pesticides, and more specific actions like irrigation or burning. 
              Notably, these <strong>Events</strong> can be chosen from the 
              <strong> ecoinvent</strong> database processes (option <strong>from ecoinvent</strong>), 
              or can be created using machinery and agricultural inputs registered by the user in 
              the <strong>generic</strong> option.<br/><br/>

              The bulk of crucial information is consolidated here, where <strong>Events </strong> 
              act as the linchpin connecting <strong>Machinery</strong> and <strong>Agricultural 
              Inputs</strong> registered to the inventory calculations. To add an 
              <strong> Event</strong> for your <strong>Plot Activity</strong>, simply click on 
              <strong> Plot Activity Events</strong> and proceed with the relevant details.<br/><br/>

              More information in the <a href="/icvcalc/help" style={{ cursor: 'pointer' }}>Help</a>
              {' '} tab
            </span>
          }
          />
          
        </Grid>

        <Grid item xs={12} sm={12} md={4} display={"flex"} justifyContent={"center"} alignItems={"center"}>  
          <FormControl>
            <RadioGroup
              row
              value={selectedDirection}
              onChange={handleChangeDirection}
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: 16,
                },
                "& .MuiFormControlLabel-label": {
                  fontSize: 13,
                },
              }}
            >
              <FormControlLabel
                value="v"
                control={<Radio />}
                label="Vertical"
                color="secondary"
              />
              <FormControlLabel
                value="h"
                control={<Radio />}
                label="Horizontal"
                color="secondary"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} md={4} display={"flex"} justifyContent={{md:"end", xs:"center"}} alignItems={"center"} gap={2} py={1}>
          <Button
            size="small"
            variant="outlined"
            startIcon={<ArrowBackIosRoundedIcon />}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>

          <Button
            size="small"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleOpenPlotForm}
            color="secondary"
          >
            Add Plot
          </Button>
        </Grid>
      </Grid>

      <Divider sx={{ marginBottom: "10px" }} />

      {
        userAll.loading ? (<Loading />) : (
          <>
            {
              selectedDirection === "h" 
              ? (
                <PlotActivityTimelineHorizontal
                  plots={plots}
                  setSelectedPlot={setSelectedPlot}
                  selectedPlot={selectedPlot}
                  handleOpenPlotEventForm={handleOpenPlotEventForm}
                  EventDetails={EventDetails}
                  handleEdit={handleEdit}
                  state={state}
                  setState={setState}
                  setEventPlotActivitySelected={setEventPlotActivitySelected}
                  plotEventActivitySelected={plotEventActivitySelected}
                  setConfirmOpenEvent={setConfirmOpenEvent}
                  confirmOpenEvent={confirmOpenEvent}
                  handleDeletePlotEvent={handleDeletePlotEvent}
                  land={plot_terrain}
                  plotActivitySelected={plotActivitySelected}
                  setPlotActivitySelected={setPlotActivitySelected}
                  confirmOpen={confirmOpen}
                  setConfirmOpen={setConfirmOpen}
                  handleDeletePlot={handleDeletePlot}
                  handleViewData={handleViewData}
                  setSelectedCropName={setSelectedCropName}
                  setModeEvent={setModeEvent}
                  setPlotEventSelected={setPlotEventSelected}
                  setOpenPlotEventFormEdit={setOpenPlotEventFormEdit}
                />
              )
              : null
            }

            {
              selectedDirection === "v" 
              && userAll.registro.properties[index_property]?.plot_terrains[index_plot_terrain].plots.length > 0 
              ? (
                <PlotActivityTimelineVertical 
                  userAll={userAll}
                  index_property={index_property}
                  index_plot_terrain={index_plot_terrain}
                  handleEdit={handleEdit}
                  setPlotActivitySelected={setPlotActivitySelected}
                  setConfirmOpen={setConfirmOpen}
                  plotActivitySelected={plotActivitySelected}
                  confirmOpen={confirmOpen}
                  itensDetails={itensDetails}
                  setItensDetails={setItensDetails}
                  setSelectedPlot={setSelectedPlot}
                  selectedPlot={selectedPlot}
                  handleOpenPlotEventForm={handleOpenPlotEventForm}
                  selectedEvent={selectedEvent}
                  EventDetails={EventDetails}
                  handleViewData={handleViewData}
                  setModeEvent={setModeEvent}
                  setPlotEventSelected={setPlotEventSelected}
                  setOpenPlotEventFormEdit={setOpenPlotEventFormEdit}
                  setEventPlotActivitySelected={setEventPlotActivitySelected}
                  setConfirmOpenEvent={setConfirmOpenEvent}
                  plotEventActivitySelected={plotEventActivitySelected}
                  confirmOpenEvent={confirmOpenEvent}
                  handleDeletePlot={handleDeletePlot}
                  handleDeletePlotEvent={handleDeletePlotEvent}
                  setSelectedCropName={setSelectedCropName}
                />
              ) 
              : null
            }
          </>
        )
      }

      {
        openPlotForm ? (
          <PlotForm
            openPlotForm={openPlotForm}
            setOpenPlotForm={setOpenPlotForm}
            plotTerrains={plot_terrain}
            mode={mode}
            setMode={setMode}
            plot_terrain={plot_terrain}
            plotActivitySelected={mode === "edit" ? plotActivitySelected : {}}
          />
        ) : null
      }

      {
        openPlotEventForm ? (
          <PlotEventForm
            openPlotEventForm={openPlotEventForm}
            setOpenPlotEventForm={setOpenPlotEventForm}
            plot_terrain={plot_terrain}
            plot={selectedPlot}
            cropName={selectedCropName}
            type_={"plot"}
            modeEvent={modeEvent}
            setModeEvent={setModeEvent}
          />
        ) : null
      }
      
      {
        openPlotEventFormEdit ? (
          <PlotEventFormEdit
            openPlotEventFormEdit={openPlotEventFormEdit}
            setOpenPlotEventFormEdit={setOpenPlotEventFormEdit}
            plot_terrain={plot_terrain}
            plot={selectedPlot || {}}
            cropName={selectedCropName}
            type_={"plot"}
            modeEvent={modeEvent}
            setModeEvent={setModeEvent}
            plotEventSelected={plotEventSelected}
          />
        ) : null
      }

      <Box sx={{ pb: "100px" }}></Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    propertySelected: {
      loading: state.userAll.loading,
      registro: state.userAll.selectedProperty,
      error: state.userAll.error,
    },
    userAll: {
      loading: state.userAll.loading,
      registro: state.userAll.registro,
      error: state.userAll.error,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserAll: (user_id) => dispatch(fetchUserAll(user_id)),
    deletePlot: (params) => dispatch(deletePlot(params)),
    deletePlotEvent: (params) => dispatch(deletePlotEvent(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Plot);
