import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/CancelRounded";
import PropTypes from "prop-types";
import SendIcon from "@mui/icons-material/Send";
import { authUser, changePassword } from "../../redux/userSlice";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import "./styles.css";
import SnackbarCustom from '../layout/SnackbarCustom';
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const ChangePassword = (props) => {
  const { dispatchUserAuth, changePassword } = props;

  const [open, setOpen] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [openError, setOpenError] = useState(false)
  const [msgError, setMsgError] = useState('')
  const [msgSuccess, setMsgSuccess] = useState('')
  const [openSuccess, setOpenSuccess] = useState(false)

  const initialValues = {
    email: "",
    password: "",
    newPassword: "",
    newPasswordConfirm: "",
  };

  const validationSchema = () =>
    Yup.object().shape({
      email: Yup.string()
        .email("Provide a valid email")
        .required("Required field"),
      password: Yup.string()
        .required("Required field"),
        //.min(8, "Password must be at least 8 characters long"),
      newPassword: Yup.string()
        .required("Required field")
        .min(8, "Password must be at least 8 characters long"),
      newPasswordConfirm: Yup.string()
        .required("Required field")
        .test("password-match", "Password must match", function (value) {
          return this.parent.newPassword === value;
        }),
    });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setIsChanged(false);
    setOpen(false);
  };

  const handleSubmit = async (values, { setFieldError }) => {
    const autenticated = await dispatchUserAuth(values);
    const err = autenticated.payload.error;
    if (err) {
      if (err.cod === "001") setFieldError("email", err.msg);
      if (err.cod === "002") setFieldError("password", err.msg);
      if (err.cod === "003") setFieldError("email", err.msg);
    } else {
      // salvar nova senha
      const result = await changePassword(values);
      //setIsChanged(true);
      //--------------------------------------------------------
      // OC 004/2024 22/03/2024
      // Implementação de feedback no caso de troca senha.
      //--------------------------------------------------------
      if(result.payload.error){
        setMsgError(result.payload.error)
        setOpenError(true)
      } else {
        setMsgSuccess('New password changed successfully!')
        setOpenSuccess(true)
        handleClose()
      }
    }
    
  };

  return (
    <div>
      <Button
        fullWidth
        variant="text"
        size="small"
        sx={{ minWidth: "150px", textTransform: "none" }}
        onClick={handleClickOpen}
        tabIndex={-1}
      >
        Change Password
      </Button>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        scroll="body"
      >
        

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setFieldError }) => handleSubmit(values, { setFieldError })}
        >
          {({ values, touched, errors, handleChange }) => {
            return (
              <Form noValidate autoComplete="off">
                <Box>
                  <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                    className="title"
                  >
                    Change Password
                  </BootstrapDialogTitle>
                  {
                    !isChanged ? (
                      <Typography variant="body2" sx={{ ml: 3, mt: 1, color: "red", fontWeight: "bold" }}>
                        * Required Fields
                      </Typography>
                    ) : null
                  }
                  
                </Box>

                <DialogContent>
                  <Box display={"flex"} flexDirection={"column"} minWidth={250} sx={{ px: 2 }}>
                    {!isChanged ? (
                      <>
                        <Grid item>
                          <TextField
                            size="small"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="E-mail"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={handleChange}
                            value={values.email}
                            error={touched.email && Boolean(errors.email)}
                            helperText={touched.email && errors.email}
                          />
                        </Grid>
                        <TextField
                          size="small"
                          margin="normal"
                          required
                          fullWidth
                          type="password"
                          id="password"
                          label="Old Password"
                          name="password"
                          autoComplete="current-password"
                          onChange={handleChange}
                          value={values.password}
                          error={touched.password && Boolean(errors.password)}
                          helperText={touched.password && errors.password}
                        />
                        <TextField
                          size="small"
                          margin="normal"
                          required
                          fullWidth
                          type="password"
                          id="newPassword"
                          label="New Password"
                          name="newPassword"
                          autoComplete="current-password"
                          onChange={handleChange}
                          value={values.newPassword}
                          error={
                            touched.newPassword && Boolean(errors.newPassword)
                          }
                          helperText={touched.newPassword && errors.newPassword}
                        />
                        <TextField
                          size="small"
                          margin="normal"
                          required
                          fullWidth
                          type="password"
                          id="newPasswordConfirm"
                          label="Confirm New Password"
                          name="newPasswordConfirm"
                          autoComplete="current-password"
                          onChange={handleChange}
                          value={values.newPasswordConfirm}
                          error={
                            touched.newPasswordConfirm &&
                            Boolean(errors.newPasswordConfirm)
                          }
                          helperText={
                            touched.newPasswordConfirm &&
                            errors.newPasswordConfirm
                          }
                        />
                      </>
                    ) : (
                      <Typography>
                        New password changed successfully!
                      </Typography>
                    )}
                  </Box>
                </DialogContent>

                <DialogActions>
                  {!isChanged ? (
                    <>
                      <Button
                        size="small"
                        onClick={handleClose}
                        variant="outlined"
                        startIcon={<CancelRoundedIcon />}
                      >
                        Cancel
                      </Button>
                      
                      <Button
                        type="submit"
                        variant="contained"
                        size="small"
                        startIcon={<SendIcon />}
                      >
                        Send
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="outlined"
                      size="small"
                      startIcon={<CloseIcon />}
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  )}
                </DialogActions>
              
              </Form>
            );
          }}
        </Formik>
      </BootstrapDialog>
      {
        openError
        ? (
          <SnackbarCustom
            open={openError}
            setOpen={setOpenError}
            msg={msgError}
            severity='error'
          />
        )
        : null
      }

      {
        openSuccess
        ? (
          <SnackbarCustom
            open={openSuccess}
            setOpen={setOpenSuccess}
            msg={msgSuccess}
            severity='success'
          />
        )
        : null
      }
    </div>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  dispatchUserAuth: (formLogin) => dispatch(authUser(formLogin)),
  changePassword: (form) => dispatch(changePassword(form)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
