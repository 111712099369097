import React from 'react'
import ShowDataContainer from '../../layout/ShowDataContainer'
import { Box, Typography } from '@mui/material'
import { formatedDate } from '../../../hooks/functions'

function ShowCrop(props) {

  const {itemPlot} = props

  return (
    <ShowDataContainer>
      <Typography variant="subtitle2" sx={{width: "33%", flexShrink: 0, fontWeight: 600,}}>
        CROP 1
      </Typography>
      
      {itemPlot.crop?.name ? (<Box><strong>Name: </strong>{itemPlot.crop?.name}</Box>) : null}
      {itemPlot.crop?.start_date ? (<Box><strong>Start Date:</strong>{" "}{formatedDate(itemPlot.crop?.start_date)}</Box>) : null}
      {itemPlot.crop?.planting_period ? (<Box><strong>Crop Period:</strong>{" "}{itemPlot.crop?.planting_period}{' days'}</Box>) : null}
      {itemPlot.crop.DRY ? (<Box><strong>DRY:</strong>{" "}{itemPlot.crop.DRY}{' kg DM/kg product'}</Box>) : null}
      {itemPlot.crop.NAG ? (<Box><strong>Above-Ground Residue N Content:</strong>{" "}{itemPlot.crop.NAG}{' kg N/kg DM'}</Box>) : null}
      {itemPlot.crop.NBG ? (<Box><strong>Below-Ground Residue N Content:</strong>{" "}{itemPlot.crop.NBG}{' kg N/kg DM'}</Box>) : null}
      {itemPlot.crop.RAG ? (<Box><strong>RAG:</strong>{" "}{itemPlot.crop.RAG}{' kg DM/kg product'}</Box>) : null}
      {itemPlot.crop.RS ? (<Box><strong>RS:</strong>{" "}{itemPlot.crop.RS}{' kg/kg'}</Box>) : null}
      {itemPlot.crop.dry_mass_carbon ? (<Box><strong>C in Dry Mass:</strong>{" "}{itemPlot.crop.dry_mass_carbon}{' kg C/kg DM'}</Box>) : null}
      {itemPlot.crop.exp_yield ? (<Box><strong>Yield: </strong>{itemPlot.crop.exp_yield}{' t/ha'}</Box>) : null}
      {itemPlot.crop.n_uptake ? (<Box><strong>N Uptake: </strong>{itemPlot.crop.n_uptake}{' kg N/ha'}</Box>) : null}
      {itemPlot.crop.remove_factor ? (<Box><strong>Above-Ground Removed Fraction:</strong>{" "}{itemPlot.crop.remove_factor}</Box>) : null}
      {itemPlot.crop.renew_factor ? (<Box><strong>Renewed Fraction:</strong>{" "}{itemPlot.crop.renew_factor}</Box>) : null}
      {itemPlot.crop.root_depth ? (<Box><strong>Root Depth:</strong>{" "}{itemPlot.crop.root_depth}{' m'}</Box>) : null}
    </ShowDataContainer>
  )
}

export default ShowCrop
