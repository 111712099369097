import { Box, Typography } from '@mui/material'
import React from 'react'

const ShowOptionalFields = (props) => {

  const {item} = props

  return (
    <Box sx={{border: "1px solid green", borderLeft: "5px solid green", borderRadius: 2, p: 2, mb: 2,}}>
                                
      <Typography variant="subtitle2" sx={{width: "100%", flexShrink: 0, fontWeight: 600,}}>
        Optional Fields
      </Typography>
      
      <Typography variant="subtitle2" sx={{ lineHeight: 1.3, fontSize: "0.7rem" }}>
        {item.weight ? (<Box><strong>Weight: </strong>{item.weight}{" kg"}</Box>) : null}
        {item.lifespan ? (<Box><strong>Lifespan: </strong>{item.lifespan}{" h"}</Box>) : null}
        {item.tSet_lifespan ? (<Box><strong>Tireset Lifespan: </strong>{item.tSet_lifespan}{" h"}</Box>) : null}
        {item.tset_weight ? (<Box><strong>Tireset Weight: </strong>{item.tset_weight}{" kg tireset/kg machine"}</Box>) : null}
        {item.tset_rubbed_off ? (<Box><strong>Rubber Removed: </strong>{item.tset_rubbed_off}{" kg rubber/kg tireset"}</Box>) : null}
        {item.type === "detailed, tractor" && item.power ? (<Box><strong>Power: </strong>{item.power}</Box>) : null}
      </Typography>

    </Box>
  )
}

export default ShowOptionalFields
