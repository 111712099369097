import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { FieldArray, Form, Formik, getIn } from "formik";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import validationSchema from "./productsValidationSchema";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import {
  fetchCheckPesticideFromTableHasDensity,
  fetchEnumerationFertiliserName,
  fetchEnumerationFertiliserNpk,
  fetchEnumerationFertiliserSourcesK,
  fetchEnumerationFertiliserSourcesN,
  fetchEnumerationFertiliserSourcesP,
  fetchEnumerationPesticideActivePrincipleUnits,
  fetchEnumerationPesticideType,
  //fetchEnumerationPesticideUnits,
  fetchEnumerationProductCorretiveName,
  fetchEnumerationProductFertiliserLiquidManureNames,
  fetchEnumerationProductFertiliserSolidManureNames,
  fetchEnumerationProductFertiliserType,
  fetchEnumerationProductMainType,
  fetchEnumerationProductPesticideActivePrinciplesNames,
  fetchEnumerationProductPesticideFromTableNames,
  fetchEnumerationWaterNames,
  resetEnumerationFertiliserNpk,
} from "../../redux/enumeratedSlice";
import { addProduct } from "../../redux/userAllSlice";
import PopoverCustom from "../layout/PopoverCustom";
import { numericField } from "../../hooks/functions";
import CloseIcon from "@mui/icons-material/CancelRounded";

export const ProductsForm = (props) => {
  const {
    openProductForm,
    setOpenProductForm,
    fetchEnumerationProductMainType,
    productsMainType,
    fetchEnumerationProductCorretiveName,
    fetchEnumerationProductFertiliserType,
    fertiliserType,
    fetchEnumerationProductFertiliserSolidManureNames,
    fetchEnumerationProductFertiliserLiquidManureNames,
    fetchEnumerationProductPesticideFromTableNames,
    pesticideActivePrinciplesNames,
    addProduct,
    fetchEnumerationPesticideType,
    pesticideType,
    fetchEnumerationFertiliserName,
    fetchEnumerationFertiliserNpk,
    fertiliserNpk,
    //fetchEnumerationPesticideUnits,
    fetchEnumerationFertiliserSourcesN,
    fetchEnumerationFertiliserSourcesP,
    fetchEnumerationFertiliserSourcesK,
    fertiliserSourcesN,
    fertiliserSourcesP,
    fertiliserSourcesK,
    fetchCheckPesticideFromTableHasDensity,
    fetchEnumerationPesticideActivePrincipleUnits,
    pesticideActivePrincipleUnits,
    fetchEnumerationWaterNames,
    waterNames,
    origin,
    setProductSelected,
    resetEnumerationFertiliserNpk,
    fetchEnumerationProductPesticideActivePrinciplesNames
  } = props;

  const [typeSelected, setTypeSelected] = useState("");
  const [mainTypeSelected, setMainTypeSelected] = useState("");
  const [optionsNames, setOptionsNames] = useState([]);
  const [typeInputName, setTypeInputName] = useState("input");
  const [nameInput, setNameInput] = useState("");
  const [nameEnum, setNameEnum] = useState("");
  const [showNotRequired, setShowNotRequired] = useState(false);
  const [density, setDensity] = useState("");
  const [hasDensity, setHasDensity] = useState(false);
  const [hasDensityRequired, setHasDensityRequired] = useState(true);
  const [npkRequired, setNpkRequired] = useState(false)
  const [formUpdate, setFormUpdate] = useState(false)

  const user = JSON.parse(localStorage.getItem("user"));
  const property = JSON.parse(localStorage.getItem("property"))

  useEffect(() => {
    fetchEnumerationProductMainType();
  }, [fetchEnumerationProductMainType]);

  useEffect(() => {
    if (mainTypeSelected === "fertiliser")
      fetchEnumerationProductFertiliserType();
  }, [fetchEnumerationProductFertiliserType, mainTypeSelected]);

  useEffect(() => {
    if (mainTypeSelected === "pesticide") fetchEnumerationPesticideType();
  }, [fetchEnumerationPesticideType, mainTypeSelected]);

  useEffect(() => {
    fetchEnumerationFertiliserSourcesN();
    fetchEnumerationFertiliserSourcesP();
    fetchEnumerationFertiliserSourcesK();
  }, [
    fetchEnumerationFertiliserSourcesK,
    fetchEnumerationFertiliserSourcesN,
    fetchEnumerationFertiliserSourcesP,
  ]);

  useEffect(() => {
    fetchEnumerationPesticideActivePrincipleUnits();
  }, [fetchEnumerationPesticideActivePrincipleUnits]);

  useEffect(() => {
    if(typeSelected === "pesticide"){
      nameEnum && fetchCheckPesticideFromTableHasDensity(nameEnum);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCheckPesticideFromTableHasDensity, nameEnum]);
  
  useEffect(() => {
    fetchEnumerationWaterNames();
  }, [fetchEnumerationWaterNames]);

  useEffect(() => {
    setHasDensity(false);

    const handleHasDensity = async () => {
      if (mainTypeSelected === "fertiliser") {
        if (
          typeSelected === "sugarcane vinasse" ||
          typeSelected === "liquid manure" ||
          typeSelected === "mineral" ||
          typeSelected === "mineral, generic"
        ) {
          setHasDensity(true);
        }
      }
      if (mainTypeSelected === "pesticide") {
        if (typeSelected === "from table") {
          if (!!nameEnum) {
            const result = await fetchCheckPesticideFromTableHasDensity(nameEnum);
            if (result.payload === true) {
              setHasDensity(true);
            } else {
              setHasDensity(false);
            }
          }
        }
      }
    };

    handleHasDensity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainTypeSelected, nameEnum, typeSelected]);

  useEffect(() => {
    setHasDensityRequired(true);
    if (mainTypeSelected === "fertiliser") {
      if (typeSelected === "mineral" || typeSelected === "mineral, generic") {
        setHasDensityRequired(false);
      }
    }
  }, [hasDensity, mainTypeSelected, typeSelected]);

  useEffect( () => {
    const getNamesEnum = async() => {
      setTypeInputName("input");
     
      if (typeSelected === "solid manure") {

        setTypeInputName("enum");
        const result = await fetchEnumerationProductFertiliserSolidManureNames()
        setOptionsNames(result.payload);

      } else if (typeSelected === "liquid manure") {

        setTypeInputName("enum");
        const result = await fetchEnumerationProductFertiliserLiquidManureNames()
        setOptionsNames(result.payload);

      } else if (typeSelected === "mineral") {

        setTypeInputName("enum");
        const result = await fetchEnumerationFertiliserName()
        setOptionsNames(result.payload);

      } else if (mainTypeSelected === "corrective") {

        setTypeInputName("enum");
        const result = await fetchEnumerationProductCorretiveName()
        setOptionsNames(result.payload);

      } else if (mainTypeSelected === "pesticide") {
        if (typeSelected === "from table") {
          setTypeInputName("enum");
          const result = await fetchEnumerationProductPesticideFromTableNames()
          setOptionsNames(result.payload);
        }
      }
      if (typeSelected === "sugarcane ashe") setNameInput("sugarcane ashe");
      if (typeSelected === "sugarcane filter cake") setNameInput(" sugarcane filter cake");
      if (typeSelected === "sugarcane vinasse") setNameInput("sugarcane vinasse");
      if (mainTypeSelected === "water") setNameInput(typeSelected);
    }
    getNamesEnum()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[mainTypeSelected, typeSelected, typeInputName]);

  const initialValues = {
    main_type: "",
    name: "",
    dens: "",
    type: "",
    N: "",
    P: "",
    K: "",
    CaCO3: "",
    heavy_metal_content: {
      Cd: "",
      Cu: "",
      Zn: "",
      Pb: "",
      Ni: "",
      Cr: "",
      Hg: "",
      As: "",
    },
    source: {
      N: "",
      P: "",
      K: "",
    },
    active_principles: [
      {
        name: "",
        amount: "",
        unit: "",
      },
    ],
  };

  const clearForm = (values) => {
    values.name = "";
    values.dens = "";
    values.type = "";
    values.N = "";
    values.P = "";
    values.K = "";
    values.CaCO3="";
    values.heavy_metal_content = {
      Cd: "",
      Cu: "",
      Zn: "",
      Pb: "",
      Ni: "",
      Cr: "",
      Hg: "",
      As: "",
    };
    values.source = { N: "", P: "", K: "" };
    values.active_principles = [{ name: "", amount: "", unit: "" }];
    setNameInput("");
    setNameEnum("");
  };

  const handleCloseProductForm = () => {
    setDensity("");
    setTypeSelected("");
    setMainTypeSelected("");
    setOpenProductForm(false);
    setTypeInputName("input");
    setShowNotRequired(false);
  };

  const handleChangeMainType = (values, event, setFieldValue) => {

    setShowNotRequired(false);
    const value = event.target.value;
    setMainTypeSelected(event.target.value);

    setTypeSelected("");
    setOptionsNames([]);
    setFieldValue("name", "");
    setFieldValue("type", "");
    setFieldValue("main_type", event.target.value);
    clearForm(values);
    setNameInput("");
    setNameEnum("");
    clearForm(values);
    resetEnumerationFertiliserNpk()
    if (value === "corrective") setShowNotRequired(false);
    if (value === "water") setShowNotRequired(false);

    setTimeout(() => {
      numericField()
    }, 1000);
  };

  const handleChangeType = async (values, event, setFieldValue) => {

    numericField()

    setTypeSelected(event.target.value);
    await setFieldValue("type", event.target.value);
    setNameInput("");
    setNameEnum("");
    clearForm(values);
    resetEnumerationFertiliserNpk()
    setFieldValue('N', '')
    setFieldValue('P', '')
    setFieldValue('K', '')

    if (event.target.value === "liquid manure"){
      setNpkRequired(true)
    } else if (event.target.value === "solid manure"){
      setNpkRequired(true)
    } else if (event.target.value === "sugarcane ashe"){
      //await setFieldValue("name", "sugarcane ashe");
      setFieldValue("name", event.target.value);
      setNameInput(event.target.value);
      setNpkRequired(true)
      await fetchEnumerationFertiliserNpk("sugarcane ashe")
      setFormUpdate(!formUpdate)
    } else if (event.target.value === "sugarcane filter cake") {
      setFieldValue("name", event.target.value)
      setNameInput(event.target.value);      
      setNpkRequired(true)
      await fetchEnumerationFertiliserNpk("sugarcane filter cake")
      setFormUpdate(!formUpdate)
    } else if (event.target.value === "sugarcane vinasse"){ 
      //await setFieldValue("name", "sugarcane vinasse");
      setFieldValue("name", event.target.value);
      setNameInput(event.target.value);   
      setNpkRequired(true)
      await fetchEnumerationFertiliserNpk("sugarcane vinasse")
      setFormUpdate(!formUpdate)
    }

    if (values.main_type === 'water') {
      setNameInput(event.target.value)
      setFieldValue("name", event.target.value)
    }

    if (values.main_type === 'pesticide' || values.type === 'generic' ){
      await fetchEnumerationProductPesticideActivePrinciplesNames()
    }
    
    setFormUpdate(!formUpdate)

    setTimeout(() => {
      numericField()
    }, 1000);
    
  };

  const handleChangeName = (event, setFieldValue, values) => {

    if (typeInputName === "input") {
      setNameInput(event.target.value);
    } else {
      setNameEnum(event.target.value);
      if (mainTypeSelected === "fertiliser") {
        fetchEnumerationFertiliserNpk(event.target.value);
      }
    }
    setFieldValue("name", event.target.value);
    numericField()

  };

  const handleChangeActivePrinciplesName = (e, value, setFieldValue) => {
    //fetchEnumerationPesticideUnits();
  };

  const handleChangeActivePrinciplesUnit = async (values, value, setFieldValue,index) => {
    await setFieldValue(`active_principles[${index}].unit`, value);
    const apAjustado = [...values.active_principles];
    if (values.active_principles[index].unit === "g/L" && value !== "g/L") {
      apAjustado.splice(index, 1);
    }
    hasDensityPesticideGeneric(values, value, apAjustado, setFieldValue);
  };

  const handleChangeDensity = (e, setFieldValue) => {
    setFieldValue("dens", e.target.value);
    setDensity(e.target.value);
  };

  function hasDensityPesticideGeneric(values, vlrAtual, apAjustado, setFieldValue) {
    setHasDensity(false);
    if (vlrAtual === "g/L") {
      setHasDensity(true);
    } else {
      const units = apAjustado.filter((item) => item.unit === "g/L");
      if (units.length > 0) {
        setHasDensity(true);
      } else {
        setHasDensity(false);
        setDensity("");
        setFieldValue(`dens`, "");
      }
    }
  }

  const handleSubmit = async (values, setFieldError) => {
    if (density === "" && hasDensity && hasDensityRequired) {
      setFieldError("dens", "Required");
      return;
    }

    const activePrinciplesValidated = await values.active_principles.filter((el) => el.name !== "");
    
    let npkValidated = {};
    if (values.N !== "" || values.P !== "" || values.K !== "") {
      npkValidated = {
        N: values.N === "" ? null : values.N,
        P: values.P === "" ? null : values.P,
        K: values.K === "" ? null : values.K,
      };
    }
    Object.keys(npkValidated).forEach( (key) => npkValidated[key] == null && delete npkValidated[key]);

    let heavyMetalContentValidated = {};
    if (values.heavy_metal_content) {
      heavyMetalContentValidated = {
        Cd:
          values.heavy_metal_content.Cd === ""
            ? null
            : values.heavy_metal_content.Cd,
        Cu:
          values.heavy_metal_content.Cu === ""
            ? null
            : values.heavy_metal_content.Cu,
        Zn:
          values.heavy_metal_content.Zn === ""
            ? null
            : values.heavy_metal_content.Zn,
        Pb:
          values.heavy_metal_content.Pb === ""
            ? null
            : values.heavy_metal_content.Pb,
        Ni:
          values.heavy_metal_content.Ni === ""
            ? null
            : values.heavy_metal_content.Ni,
        Cr:
          values.heavy_metal_content.Cr === ""
            ? null
            : values.heavy_metal_content.Cr,
        Hg:
          values.heavy_metal_content.Hg === ""
            ? null
            : values.heavy_metal_content.Hg,
        As:
          values.heavy_metal_content.As === ""
            ? null
            : values.heavy_metal_content.As,
      };
      Object.keys(heavyMetalContentValidated).forEach(
        (key) =>
          heavyMetalContentValidated[key] == null &&
          delete heavyMetalContentValidated[key]
      );
    }

    let sourceValidated = values.source;
    Object.keys(sourceValidated).forEach(
      (key) => sourceValidated[key] === "" && delete sourceValidated[key]
    );

    let params = {};
    if (values.main_type === "pesticide" && values.type === "from table" ) {
      params = {
        user_id: user.user_icv_id,
        prop_id: property._id,
        payload: {
          main_type: values.main_type,
          type: values.type,
          dens: values.dens === "" ? null : values.dens,
          name: values.name,
        },
      };
    } else if (values.main_type === "pesticide" && values.type === "generic") {
      params = {
        user_id: user.user_icv_id,
        prop_id: property._id,
        payload: {
          main_type: values.main_type,
          type: values.type,
          dens: values.dens === "" ? null : values.dens,
          name: values.name,
          active_principles: activePrinciplesValidated,
        },
      };
    } else if (values.main_type === "corrective") {
      params = {
        user_id: user.user_icv_id,
        prop_id: property._id,
        payload: {
          main_type: values.main_type,
          dens: values.dens === "" ? null : values.dens,
          name: values.name,
          CaCO3: values.CaCO3 || null,
          heavy_metal_content: heavyMetalContentValidated,
        },
      };
    } else if (values.main_type === "fertiliser" && values.type === "mineral, generic") {
      params = {
        user_id: user.user_icv_id,
        prop_id: property._id,
        payload: {
          main_type: values.main_type,
          name: values.name,
          dens: values.dens === "" ? null : values.dens,
          type: values.type,
          ...npkValidated,
          heavy_metal_content: heavyMetalContentValidated,
          source: sourceValidated,
        },
      };
    } else if (values.main_type === "fertiliser"  && (values.type === "sugarcane filter cake" || values.type === "mineral")) {
      params = {
        user_id: user.user_icv_id,
        prop_id: property._id,
        payload: {
          main_type: values.main_type,
          name: values.name,
          dens: values.dens === "" ? null : values.dens,
          type: values.type,
          ...npkValidated,
          heavy_metal_content: heavyMetalContentValidated,
        },
      };
    } else if (values.main_type === "fertiliser" &&  values.type === "sugarcane vinasse") {
      params = {
        user_id: user.user_icv_id,
        prop_id: property._id,
        payload: {
          main_type: values.main_type,
          name: values.name,
          dens: values.dens === "" ? null : values.dens,
          type: values.type,
          ...npkValidated,
          heavy_metal_content: heavyMetalContentValidated,
        },
      };
    } else if (values.main_type === "fertiliser" && (values.type === "solid manure" || values.type === "liquid manure")) {
      params = {
        user_id: user.user_icv_id,
        prop_id: property._id,
        payload: {
          main_type: values.main_type,
          name: values.name,
          dens: values.dens === "" ? null : values.dens,
          type: values.type,
          ...npkValidated,
          heavy_metal_content: heavyMetalContentValidated,
        },
      };
    } else if (values.main_type === "fertiliser" && values.type === "sugarcane ashe") {
      params = {
        user_id: user.user_icv_id,
        prop_id: property._id,
        payload: {
          main_type: values.main_type,
          name: values.name,
          dens: values.dens === "" ? null : values.dens,
          type: values.type,
          ...npkValidated,
          heavy_metal_content: heavyMetalContentValidated,
        },
      };
    } else if (values.main_type === "water" ) {
      params = {
        user_id: user.user_icv_id,
        prop_id: property._id,
        payload: {
          main_type: values.main_type,
          name: values.name,
          type: values.type,
        },
      };
    }
    const result = await addProduct(params)

    if(origin === "addEvent"){
      setProductSelected({_id: result.data, ...result.payload.params.payload})
    }
    handleCloseProductForm();
    setDensity("");
  };

  const handleChangeNConcentration = (event, setFieldValue) => {
    const concentration = event.target.value;
    setFieldValue('N', concentration)
    if(concentration === "" ){
      setFieldValue('source.N', "")
    }
  }

  const handleChangePConcentration = (event, setFieldValue) => {
    const concentration = event.target.value;
    setFieldValue('P', concentration)
    if(concentration === "" || parseFloat(concentration) === 0){
      setFieldValue('source.P', "")
    }
  }

  const handleChangeKConcentration = (event, setFieldValue) => {
    const concentration = event.target.value;
    setFieldValue('K', concentration)
    if(concentration === "" || parseFloat(concentration) === 0){
      setFieldValue('source.K', "")
    }
  }

  return (
    <div>
      <Dialog
        open={openProductForm}
        onClose={handleCloseProductForm}
        maxWidth="md"
        scroll="body"
      >
        <DialogTitle
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <strong>Add Agricultural Inputs</strong>
          <IconButton
            aria-label="close"
            onClick={handleCloseProductForm}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "green",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <Typography variant="body2" sx={{ ml: 3, mt: 1, color: "red", fontWeight: "bold" }}>
          * Required Fields
        </Typography>
        
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={(values, { setFieldError, resetForm }) => {
            handleSubmit(values, setFieldError);
            resetForm();
          }}
        >
          {({ values, touched, errors, handleChange, handleBlur, isvalid, setFieldValue, resetForm, }) => {
            return (
              <Form noValidate autoComplete="off">
                <DialogContent sx={{ minWidth: { sm: "400px", md: "600px", lg: "600px" } }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <TextField
                        required
                        fullWidth
                        size="small"
                        name="main_type"
                        select
                        label="Type"
                        value={mainTypeSelected}
                        onChange={(event) => { handleChangeMainType(values, event, setFieldValue);}}
                        error={touched.main_type && Boolean(errors.main_type)}
                        helperText={touched.main_type && errors.main_type}
                      >
                        {
                          productsMainType.registros.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))
                        }
                      </TextField>
                    </Grid>

                    {
                      values.main_type 
                      ? (
                        <>
                          {
                            mainTypeSelected === "pesticide" 
                            ? (
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                  <TextField
                                    required
                                    fullWidth
                                    size="small"
                                    name="type"
                                    id="type"
                                    select
                                    label="Specific Type"
                                    value={values.type}
                                    onChange={(event) => handleChangeType(values, event, setFieldValue)}
                                    error={touched.type && Boolean(errors.type)}
                                    helperText={touched.type && errors.type}
                                  >
                                    {
                                      pesticideType.registros.map((option) => (
                                        <MenuItem key={option} value={option}>
                                          {option}
                                        </MenuItem>
                                      ))
                                    }
                                  </TextField>
                                </Grid>
                              ) 
                            : null
                          }

                          {
                            mainTypeSelected === "fertiliser" 
                            ? (
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                  <TextField  
                                    required
                                    fullWidth
                                    size="small"
                                    name="type"
                                    select
                                    label="Specific Type"
                                    value={typeSelected}
                                    onChange={(event) => { handleChangeType(values, event, setFieldValue);}}
                                    error={touched.type && Boolean(errors.type)}
                                    helperText={touched.type && errors.type}
                                  >
                                    {
                                      fertiliserType.registros.map((option) => (
                                        <MenuItem key={option} value={option}>
                                          {option}
                                        </MenuItem>
                                      ))
                                    }
                                  </TextField>
                                </Grid>
                              ) 
                            : null
                          }
                          
                          {
                            mainTypeSelected === "water" 
                            ? (
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                  <TextField
                                    required
                                    fullWidth
                                    size="small"
                                    name="type"
                                    select
                                    label="Specific Type"
                                    value={typeSelected}
                                    onChange={(event) => handleChangeType(values, event, setFieldValue)}
                                    error={touched.type && Boolean(errors.type)}
                                    helperText={touched.type && errors.type}
                                  >
                                    {
                                      waterNames.registros.map((option) => (
                                        <MenuItem key={option} value={option}>
                                          {option}
                                        </MenuItem>
                                      ))
                                    }
                                  </TextField>
                                </Grid>
                              ) 
                            : null
                          }

                          {
                            !showNotRequired && typeInputName === "input" 
                            ? (
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <TextField
                                  required
                                  fullWidth
                                  size="small"
                                  name="name"
                                  label="Name"
                                  value={nameInput}
                                  onChange={(event) => {
                                    handleChangeName(event, setFieldValue, values);
                                    setFieldValue("name", event.target.value);
                                  }}
                                  error={touched.name && Boolean(errors.name)}
                                  helperText={touched.name && errors.name}
                                  disabled={
                                    values.type === "sugarcane ashe" ||
                                    values.type === "sugarcane filter cake" ||
                                    values.type === "sugarcane vinasse" ||
                                    values.main_type === 'water'
                                  }
                                />
                              </Grid>
                            ) 
                            : null
                          }

                          {
                            !showNotRequired && typeInputName === "enum" 
                            ? (
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <TextField
                                  required
                                  fullWidth
                                  size="small"
                                  select
                                  label="Name"
                                  value={nameEnum}
                                  onChange={(event) => handleChangeName(event, setFieldValue, values)}
                                  error={touched.name && Boolean(errors.name)}
                                  helperText={touched.name && errors.name}
                                >
                                  {
                                    optionsNames
                                    .slice()
                                  
                                    .sort((a,b)=> a < b ? -1 : 1)
                                    .map((option) => (
                                    <MenuItem key={option} value={option}>
                                      {option}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Grid>
                            ) 
                            : null
                          }
                          
                          {
                            values.main_type === 'corrective'
                            && (
                              values.name === 'Limestone, Calcitic'
                              || values.name === 'Limestone, Dolomitic'
                              || values.name === 'Limestone, Magnesian'
                              || values.name === 'Limestone, Unspecified'
                            ) 
                            ? (
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel size="small" htmlFor="N" error={touched.CaCO3 && Boolean(errors.CaCO3)}>CaCO3 content</InputLabel>
                                  <OutlinedInput
                                    fullWidth
                                    size="small"
                                    name="CaCO3"
                                    id="CaCO3"
                                    label="CaCO3 content"
                                    value={values.CaCO3}
                                    onChange={handleChange}
                                    inputProps={{ step: 0.01, min: 0, max: 1}}
                                    //onKeyDown={numericOnly}
                                    type="number"
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <Box mr={1}>[-]</Box>
                                      </InputAdornment>
                                    }
                                    error={touched.CaCO3 && Boolean(errors.CaCO3)}
                                  />
                                  {touched.CaCO3 && errors.CaCO3 && (
                                    <FormHelperText error>
                                      {errors.CaCO3}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>
                            )
                            : null
                          }

                          {
                            hasDensity && (
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel
                                    size="small"
                                    htmlFor="dens"
                                    error={touched.dens && Boolean(errors.dens)}
                                    required={hasDensityRequired}
                                  >
                                    Density
                                  </InputLabel>
                                  <OutlinedInput
                                    size="small"
                                    name="dens"
                                    id="dens"
                                    label="Density"
                                    value={density}
                                    onChange={(e) => { handleChangeDensity(e, setFieldValue); }}
                                    //onKeyDown={numericOnly}
                                    error={touched.dens && Boolean(errors.dens)}
                                    inputProps={{ step: 0.01, min: 0 }}
                                    type="number"
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <Box>[kg/l]</Box>
                                      </InputAdornment>
                                    }
                                  />
                                  {touched.dens && errors.dens && (
                                    <FormHelperText error>
                                      {errors.dens}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>
                            )
                          }

                          {
                            values.type === "mineral, generic" || fertiliserNpk.registros.findIndex((val) => val === "N") !== -1 
                            ? (
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel 
                                    size="small" 
                                    htmlFor="N" 
                                    error={touched.N && Boolean(errors.N)}
                                    required={npkRequired && !Boolean(values.P) && !Boolean(values.K)}
                                  >
                                    N concentration
                                  </InputLabel>
                                  <OutlinedInput
                                    fullWidth
                                    size="small"
                                    name="N"
                                    id="N"
                                    label="N concentration"
                                    value={values.N}
                                    onChange={(event) => {handleChangeNConcentration(event, setFieldValue);}}
                                    error={touched.N && Boolean(errors.N)}
                                    inputProps={{ step: 0.01, min: 0, max: 1 }}
                                    type="number"
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <Box mr={1}>[kg N / kg]</Box>
                                        <PopoverCustom
                                          info={"Nitrogen concentration"}
                                        />
                                      </InputAdornment>
                                    }
                                  />
                                  {touched.N && errors.N && (<FormHelperText error>{errors.N}</FormHelperText>)}
                                </FormControl>
                              </Grid>
                            ) 
                            : null
                          }

                          {
                            values.type === "mineral, generic" || fertiliserNpk.registros.findIndex((val) => val === "P") !== -1 
                            ? (
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel 
                                    size="small" 
                                    htmlFor="P" 
                                    error={touched.P && Boolean(errors.P)}
                                    required={npkRequired && !Boolean(values.N) && !Boolean(values.K)}
                                  >
                                    P concentration
                                  </InputLabel>

                                  <OutlinedInput
                                    fullWidth
                                    size="small"
                                    name="P"
                                    id="P"
                                    label="P concentration"
                                    value={values.P}
                                    onChange={(event) => handleChangePConcentration(event, setFieldValue)}
                                    error={touched.P && Boolean(errors.P)}
                                    inputProps={{ step: 0.01, min: 0, max: 1 }}
                                    //onKeyDown={numericOnly}
                                    type="number"
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <Box mr={1}>[kg P / kg]</Box>
                                        <PopoverCustom
                                          info={"Phosphorus concentration"}
                                        />
                                      </InputAdornment>
                                    }
                                  />
                                  {touched.P && errors.P && (<FormHelperText error>{errors.P}</FormHelperText>)}
                                </FormControl>
                              </Grid>
                            ) 
                            : null
                          }

                          {
                            values.type === "mineral, generic" || fertiliserNpk.registros.findIndex((val) => val === "K") !== -1 
                            ? (
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel 
                                    size="small" 
                                    htmlFor="K" 
                                    error={touched.K && Boolean(errors.K)}
                                    required={npkRequired && !Boolean(values.N) && !Boolean(values.P)}
                                  >
                                    K concentration
                                  </InputLabel>
                                  <OutlinedInput
                                    fullWidth
                                    size="small"
                                    name="K"
                                    id="K"
                                    label="K concentration"
                                    value={values.K}
                                    onChange={ (event) => handleChangeKConcentration(event, setFieldValue) }
                                    error={touched.K && Boolean(errors.K)}
                                    inputProps={{ step: 0.01, min: 0, max: 1 }}
                                    //onKeyDown={numericOnly}
                                    type="number"
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <Box mr={1}>[kg K / kg]</Box>
                                        <PopoverCustom
                                          info={"Potassium concentration"}
                                        />
                                      </InputAdornment>
                                    }
                                  />
                                  {touched.K && errors.K && (<FormHelperText error>{errors.K}</FormHelperText>)}
                                </FormControl>
                              </Grid>
                            ) 
                            : null
                          }

                          {
                            !showNotRequired 
                            && ( mainTypeSelected === "fertiliser" ||  mainTypeSelected === "corrective") 
                            ? (
                              <>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  Heavy Metal Content
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                  <FormControl fullWidth variant="outlined">
                                    <InputLabel size="small" htmlFor="heavy_metal_content.Cd">
                                      Cd content
                                    </InputLabel>
                                    <OutlinedInput
                                      fullWidth
                                      size="small"
                                      name="heavy_metal_content.Cd"
                                      id="heavy_metal_content.Cd"
                                      label="Cd content"
                                      value={values.heavy_metal_content.Cd}
                                      onChange={handleChange}
                                      error={touched.heavy_metal_content?.Cd && Boolean(errors.heavy_metal_content?.Cd)}
                                      inputProps={{ step: 0.01, min: 0, max: 1 }}
                                      //onKeyDown={numericOnly}
                                      type="number"
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <Box mr={1}>[kg Cd / kg]</Box>
                                          <PopoverCustom info={"Cadmium content"} />
                                        </InputAdornment>
                                      }
                                    />
                                    {
                                      touched.heavy_metal_content?.Cd && errors.heavy_metal_content?.Cd && (
                                        <FormHelperText error>
                                          {errors.heavy_metal_content?.Cd}
                                        </FormHelperText>
                                      )
                                    }
                                  </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                  <FormControl fullWidth variant="outlined">
                                    <InputLabel size="small" htmlFor="heavy_metal_content.Cu">
                                      Cu content
                                    </InputLabel>
                                    <OutlinedInput
                                      fullWidth
                                      size="small"
                                      name="heavy_metal_content.Cu"
                                      id="heavy_metal_content.Cu"
                                      label="Cu content"
                                      value={values.heavy_metal_content.Cu}
                                      onChange={handleChange}
                                      error={touched.heavy_metal_content?.Cu && Boolean(errors.heavy_metal_content?.Cu)}
                                      inputProps={{ step: 0.01, min: 0, max: 1 }}
                                      //onKeyDown={numericOnly}
                                      type="number"
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <Box mr={1}>[kg Cu / kg]</Box>
                                          <PopoverCustom info={"Copper content"} />
                                        </InputAdornment>
                                      }
                                    />
                                    {
                                      touched.heavy_metal_content?.Cu && errors.heavy_metal_content?.Cu && (
                                        <FormHelperText error>
                                          {errors.heavy_metal_content?.Cu}
                                        </FormHelperText>
                                      )
                                    }
                                  </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                  <FormControl fullWidth variant="outlined">
                                    <InputLabel size="small" htmlFor="heavy_metal_content.Zn">
                                      Zn content
                                    </InputLabel>
                                    <OutlinedInput
                                      fullWidth
                                      size="small"
                                      name="heavy_metal_content.Zn"
                                      id="heavy_metal_content.Zn"
                                      label="Zn content"
                                      value={values.heavy_metal_content.Zn}
                                      onChange={handleChange}
                                      error={touched.heavy_metal_content?.Zn && Boolean(errors.heavy_metal_content?.Zn)}
                                      inputProps={{ step: 0.01, min: 0, max: 1 }}
                                      //onKeyDown={numericOnly}
                                      type="number"
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <Box mr={1}>[kg Zn / kg]</Box>
                                          <PopoverCustom info={"Zinc content"} />
                                        </InputAdornment>
                                      }
                                    />
                                    {
                                      touched.heavy_metal_content?.Zn && errors.heavy_metal_content?.Zn && (
                                        <FormHelperText error>
                                          {errors.heavy_metal_content?.Zn}
                                        </FormHelperText>
                                      )
                                    }
                                  </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                  <FormControl fullWidth variant="outlined">
                                    <InputLabel size="small" htmlFor="heavy_metal_content.Pb">
                                      Pb content
                                    </InputLabel>
                                    <OutlinedInput
                                      fullWidth
                                      size="small"
                                      name="heavy_metal_content.Pb"
                                      id="heavy_metal_content.Pb"
                                      label="Pb content"
                                      value={values.heavy_metal_content.Pb}
                                      onChange={handleChange}
                                      error={touched.heavy_metal_content?.Pb && Boolean(errors.heavy_metal_content?.Pb)}
                                      inputProps={{ step: 0.01, min: 0, max: 1 }}
                                      //onKeyDown={numericOnly}
                                      type="number"
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <Box mr={1}>[kg Pb / kg]</Box>
                                          <PopoverCustom info={"Lead content"} />
                                        </InputAdornment>
                                      }
                                    />
                                    {
                                      touched.heavy_metal_content?.Pb && errors.heavy_metal_content?.Pb && (
                                        <FormHelperText error>
                                          {errors.heavy_metal_content?.Pb}
                                        </FormHelperText>
                                      )
                                    }
                                  </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                  <FormControl fullWidth variant="outlined">
                                    <InputLabel size="small" htmlFor="heavy_metal_content.Ni">
                                      Ni content
                                    </InputLabel>
                                    <OutlinedInput
                                      fullWidth
                                      size="small"
                                      name="heavy_metal_content.Ni"
                                      id="heavy_metal_content.Ni"
                                      label="Ni content"
                                      value={values.heavy_metal_content.Ni}
                                      onChange={handleChange}
                                      error={touched.heavy_metal_content?.Ni && Boolean(errors.heavy_metal_content?.Ni)}
                                      inputProps={{ step: 0.01, min: 0, max: 1 }}
                                      //onKeyDown={numericOnly}
                                      type="number"
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <Box mr={1}>[kg Ni / kg]</Box>
                                          <PopoverCustom info={"Nickel content"} />
                                        </InputAdornment>
                                      }
                                    />
                                    {
                                      touched.heavy_metal_content?.Ni && errors.heavy_metal_content?.Ni && (
                                        <FormHelperText error>
                                          {errors.heavy_metal_content?.Ni}
                                        </FormHelperText>
                                      )
                                    }
                                  </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                  <FormControl fullWidth variant="outlined">
                                    <InputLabel size="small" htmlFor="heavy_metal_content.Cr">
                                      Cr content
                                    </InputLabel>
                                    <OutlinedInput
                                      fullWidth
                                      size="small"
                                      name="heavy_metal_content.Cr"
                                      id="heavy_metal_content.Cr"
                                      label="Cr content"
                                      value={values.heavy_metal_content.Cr}
                                      onChange={handleChange}
                                      error={touched.heavy_metal_content?.Cr && Boolean(errors.heavy_metal_content?.Cr)}
                                      inputProps={{ step: 0.01, min: 0, max: 1 }}
                                      //onKeyDown={numericOnly}
                                      type="number"
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <Box mr={1}>[kg Cr / kg]</Box>
                                          <PopoverCustom
                                            info={"Chromium content"}
                                          />
                                        </InputAdornment>
                                      }
                                    />
                                    {
                                      touched.heavy_metal_content?.Cr && errors.heavy_metal_content?.Cr && (
                                        <FormHelperText error>
                                          {errors.heavy_metal_content?.Cr}
                                        </FormHelperText>
                                      )
                                    }
                                  </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                  <FormControl fullWidth variant="outlined">
                                    <InputLabel size="small" htmlFor="heavy_metal_content.Hg">
                                      Hg content
                                    </InputLabel>
                                    <OutlinedInput
                                      fullWidth
                                      size="small"
                                      name="heavy_metal_content.Hg"
                                      id="heavy_metal_content.Hg"
                                      label="Hg content"
                                      value={values.heavy_metal_content.Hg}
                                      onChange={handleChange}
                                      error={touched.heavy_metal_content?.Hg && Boolean(errors.heavy_metal_content?.Hg)}
                                      inputProps={{ step: 0.01, min: 0, max: 1 }}
                                      //onKeyDown={numericOnly}
                                      type="number"
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <Box mr={1}>[kg Hg / kg]</Box>
                                          <PopoverCustom info={"Mercury content"} />
                                        </InputAdornment>
                                      }
                                    />
                                    {
                                      touched.heavy_metal_content?.Hg && errors.heavy_metal_content?.Hg && (
                                        <FormHelperText error>
                                          {errors.heavy_metal_content?.Hg}
                                        </FormHelperText>
                                      )
                                    }
                                  </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                  <FormControl fullWidth variant="outlined">
                                    <InputLabel size="small" htmlFor="heavy_metal_content.As">
                                      As content
                                    </InputLabel>
                                    <OutlinedInput
                                      fullWidth
                                      size="small"
                                      name="heavy_metal_content.As"
                                      id="heavy_metal_content.As"
                                      label="As content"
                                      value={values.heavy_metal_content.As}
                                      onChange={handleChange}
                                      error={touched.heavy_metal_content?.As && Boolean(errors.heavy_metal_content?.As)}
                                      inputProps={{ step: 0.01, min: 0, max: 1 }}
                                      //onKeyDown={numericOnly}
                                      type="number"
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <Box mr={1}>[kg As / kg]</Box>
                                          <PopoverCustom info={"Arsenic content"} />
                                        </InputAdornment>
                                      }
                                    />
                                    {
                                      touched.heavy_metal_content?.As && errors.heavy_metal_content?.As && (
                                        <FormHelperText error>
                                          {errors.heavy_metal_content?.As}
                                        </FormHelperText>
                                      )
                                    }
                                  </FormControl>
                                </Grid>
                              </>
                            ) 
                            : null
                          }

                          {
                            mainTypeSelected === "fertiliser" && values.type === "mineral, generic" 
                            ? (
                              <>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  Source
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                  <TextField
                                    fullWidth
                                    size="small"
                                    name="source.N"
                                    select
                                    label="Source Nitrogen"
                                    value={values.source.N || ""}
                                    onChange={handleChange}
                                    error={touched.source?.N && Boolean(errors.source?.N)}
                                    helperText={touched.source?.N && errors.source?.N}
                                    InputProps={{
                                      endAdornment: (
                                        <Box width={"30%"}>
                                          <PopoverCustom info={"Source of nitrogen in fertiliser"}/>
                                        </Box>
                                      ),
                                    }}
                                  >
                                    <MenuItem key={'none'} value={""}>
                                      {'None'}
                                    </MenuItem>
                                    {
                                      fertiliserSourcesN.registros.map((option) => (
                                        <MenuItem key={option} value={option}>
                                          {option}
                                        </MenuItem>
                                      ))
                                    }
                                  </TextField>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                  <TextField
                                    fullWidth
                                    size="small"
                                    name="source.P"
                                    select
                                    label="Source Phosphorus"
                                    value={values.source.P || ""}
                                    onChange={handleChange}
                                    error={touched.source?.P && Boolean(errors.source?.P)}
                                    helperText={touched.source?.P && errors.source?.P}
                                    InputProps={{
                                      endAdornment: (
                                        <Box width={"30%"}>
                                          <PopoverCustom info={"Source of phosphorus in fertiliser"}/>
                                        </Box>
                                      ),
                                    }}
                                  >
                                    <MenuItem key={'none'} value={""}>
                                      {'None'}
                                    </MenuItem>
                                    {
                                      fertiliserSourcesP.registros.map((option) => (
                                        <MenuItem key={option} value={option}>
                                          {option}
                                        </MenuItem>
                                      ))
                                    }
                                  </TextField>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                  <TextField
                                    fullWidth
                                    size="small"
                                    name="source.K"
                                    select
                                    label="Source Potassium"
                                    value={values.source.K || ""}
                                    onChange={handleChange}
                                    error={touched.source?.K && Boolean(errors.source?.K)}
                                    helperText={touched.source?.K && errors.source?.K}
                                    InputProps={{
                                      endAdornment: (
                                        <Box width={"30%"}>
                                          <PopoverCustom info={"Source of potassium in fertiliser"}/>
                                        </Box>
                                      ),
                                    }}
                                  >
                                    <MenuItem key={'none'} value={""}>
                                      {'None'}
                                    </MenuItem>
                                    {
                                      fertiliserSourcesK.registros.map((option) => (
                                        <MenuItem key={option} value={option}>
                                          {option}
                                        </MenuItem>
                                      ))
                                    }
                                  </TextField>
                                </Grid>

                              </>
                            ) 
                            : null
                          }

                          {
                            mainTypeSelected === "pesticide" && typeSelected === "generic" 
                            ? (
                              <Grid item xs={12}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  Active Principles
                                </Grid>

                                <Box sx={{ px: 1, py: 3 }}>
                                  <FieldArray name="active_principles">
                                    {({ push, remove }) => (
                                      <Box>
                                        {values.active_principles &&
                                          values?.active_principles.map(
                                            (ap, index) => {
                                              const name = `active_principles[${index}].name`;
                                              const touchedName = getIn(touched, name);
                                              const errorName = getIn(errors, name);
                                              const amount = `active_principles[${index}].amount`;
                                              const touchedAmount = getIn(touched, amount);
                                              const errorAmount = getIn( errors, amount);
                                              const unit = `active_principles[${index}].unit`;
                                              const touchedUnit = getIn(touched, unit);
                                              const errorUnit = getIn(errors, unit);
                                              return (
                                                <Box key={index} display={"flex"} flexDirection={"row"} alignItems={"center"} gap={1} mb={1}>
                                                  <Box>{index + 1}</Box>
                                                  <Grid item xs={12} sm={6} md={4} lg={6}>
                                                    <Autocomplete
                                                      size="small"
                                                      id="activePrinciples"
                                                      name={`active_principles[${index}].name`}
                                                      options={pesticideActivePrinciplesNames.registros}
                                                      onChange={(e, value) => {
                                                        handleChangeActivePrinciplesName(e, value, setFieldValue);
                                                        setFieldValue(`active_principles[${index}].name`, value);
                                                      }}
                                                      isOptionEqualToValue={(option, value) => option.value === value.value}
                                                      value={values.active_principles[index].name || null}
                                                      renderInput={(params) => (
                                                        <TextField
                                                          required
                                                          {...params}
                                                          autoFocus
                                                          label="Name"
                                                          error={touchedName && Boolean(errorName)}
                                                          helperText={touchedName && errorName}
                                                        />
                                                      )}
                                                    />
                                                  </Grid>

                                                  <Grid item xs={12} sm={6} md={4} lg={4}>
                                                    <FormControl fullWidth variant="outlined">
                                                      <InputLabel required size="small" htmlFor="heavy_metal_content.Cd">
                                                        Amount
                                                      </InputLabel>
                                                      <OutlinedInput
                                                        required
                                                        fullWidth
                                                        size="small"
                                                        name={amount}
                                                        id={amount}
                                                        label="Amount"
                                                        value={ap.amount}
                                                        onChange={handleChange}
                                                        //onKeyDown={numericOnly}
                                                        error={touchedAmount && Boolean(errorAmount)}
                                                        inputProps={{step: 0.01, min: 0,}}
                                                        type="number"
                                                      />
                                                      {
                                                        touchedAmount && errorAmount && (
                                                          <FormHelperText error>
                                                            {errorAmount}
                                                          </FormHelperText>
                                                        )
                                                      }
                                                    </FormControl>
                                                  </Grid>

                                                  <Grid item xs={12} sm={6} md={4} lg={6}>
                                                    <Autocomplete
                                                      size="small"
                                                      id={`active_principles[${index}].unit`}
                                                      name={`active_principles[${index}].unit`}
                                                      options={pesticideActivePrincipleUnits.registros}
                                                      onChange={(e, value) => { handleChangeActivePrinciplesUnit(values, value, setFieldValue, index); }}
                                                      isOptionEqualToValue={(option, value) => option === value}
                                                      value={values.active_principles[index].unit || null}
                                                      renderInput={(params) => (
                                                        <TextField
                                                          required
                                                          {...params}
                                                          label="Unit"
                                                          error={touchedUnit && Boolean(errorUnit)}
                                                          helperText={touchedUnit && errorUnit}
                                                        />
                                                      )}
                                                    />
                                                  </Grid>

                                                  <IconButton onClick={() => remove(index)}>
                                                    <RemoveCircleOutlineRoundedIcon />
                                                  </IconButton>
                                                </Box>
                                              );
                                            }
                                          )}
                                        <Button
                                          type="button"
                                          variant="outlined"
                                          onClick={() => push({name: "", amount: "", unit: ""})}
                                          startIcon={<AddCircleOutlineRoundedIcon />}
                                        >
                                          Add Active Principle
                                        </Button>
                                      </Box>
                                    )}
                                  </FieldArray>
                                </Box>
                              </Grid>
                            ) 
                            : null
                          }
                        </>
                      ) 
                      : null
                    }
                  </Grid>
                </DialogContent>

                <DialogActions>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Button
                      size="small"
                      onClick={handleCloseProductForm}
                      variant="outlined"
                      startIcon={<CancelRoundedIcon />}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      startIcon={<SaveRoundedIcon />}
                      autoFocus
                      type="submit"
                    >
                      save
                    </Button>
                  </Box>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>

      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    productsMainType: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationProductMainType,
      error: state.enumerated.error,
    },
    pesticideType: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationPesticideType,
      error: state.enumerated.error,
    },
    fertiliserType: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationProductFertiliserType,
      error: state.enumerated.error,
    },
    pesticideActivePrinciplesNames: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationProductPesticideActivePrinciplesNames,
      error: state.enumerated.error,
    },
    fertiliserNpk: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationFertiliserNpk,
      error: state.enumerated.error,
    },
    fertiliserSourcesN: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationFertiliserSourcesN,
      error: state.enumerated.error,
    },
    fertiliserSourcesP: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationFertiliserSourcesP,
      error: state.enumerated.error,
    },
    fertiliserSourcesK: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationFertiliserSourcesK,
      error: state.enumerated.error,
    },
    pesticideActivePrincipleUnits: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationPesticideActivePrincipleUnits,
      error: state.enumerated.error,
    },
    waterNames: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationWaterNames,
      error: state.enumerated.error,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEnumerationProductMainType: () => dispatch(fetchEnumerationProductMainType()),
    fetchEnumerationProductCorretiveName: () => dispatch(fetchEnumerationProductCorretiveName()),
    fetchEnumerationProductFertiliserType: () => dispatch(fetchEnumerationProductFertiliserType()),
    fetchEnumerationProductFertiliserSolidManureNames: () => dispatch(fetchEnumerationProductFertiliserSolidManureNames()),
    fetchEnumerationProductFertiliserLiquidManureNames: () => dispatch(fetchEnumerationProductFertiliserLiquidManureNames()),
    fetchEnumerationProductPesticideFromTableNames: () => dispatch(fetchEnumerationProductPesticideFromTableNames()),
    fetchEnumerationProductPesticideActivePrinciplesNames: () => dispatch(fetchEnumerationProductPesticideActivePrinciplesNames()),
    addProduct: (params) => dispatch(addProduct(params)),
    fetchEnumerationPesticideType: () => dispatch(fetchEnumerationPesticideType()),
    fetchEnumerationFertiliserName: () => dispatch(fetchEnumerationFertiliserName()),
    fetchEnumerationFertiliserNpk: (name) => dispatch(fetchEnumerationFertiliserNpk(name)),
    //fetchEnumerationPesticideUnits: () => dispatch(fetchEnumerationPesticideUnits()),
    fetchEnumerationFertiliserSourcesN: () => dispatch(fetchEnumerationFertiliserSourcesN()),
    fetchEnumerationFertiliserSourcesP: () => dispatch(fetchEnumerationFertiliserSourcesP()),
    fetchEnumerationFertiliserSourcesK: () => dispatch(fetchEnumerationFertiliserSourcesK()),
    fetchCheckPesticideFromTableHasDensity: (pest_name) => dispatch(fetchCheckPesticideFromTableHasDensity(pest_name)),
    fetchEnumerationPesticideActivePrincipleUnits: () => dispatch(fetchEnumerationPesticideActivePrincipleUnits()),
    fetchEnumerationWaterNames: () => dispatch(fetchEnumerationWaterNames()),
    resetEnumerationFertiliserNpk: () => dispatch(resetEnumerationFertiliserNpk())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsForm);
