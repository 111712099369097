import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab';
import { Box, Button, Chip, Grid, IconButton, Typography } from '@mui/material';
import React from 'react'
import { formatedDate, objetoVazio } from '../../../hooks/functions';
import ButtonEdit from '../../layout/ButtonEdit';
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDialog from '../../layout/ConfirmDialog';
import ArrowDropUpRoundedIcon from "@mui/icons-material/ArrowDropUpRounded";
import ShowPlotActivity from './ShowPlotActivity';
import ShowSeed from './ShowSeed';
import ShowMetalContent from './ShowMetalContent';
import ShowCrop from './ShowCrop';
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import ShowEventRegister from './ShowEventRegister';
import ShowOperation from './ShowOperation';


function PlotActivityTimelineVertical(props) {

  const {
    userAll,
    index_property,
    index_plot_terrain,
    handleEdit,
    setPlotActivitySelected,
    setConfirmOpen,
    plotActivitySelected,
    confirmOpen,
    handleViewData,
    itensDetails,
    setItensDetails,
    setSelectedPlot,
    handleOpenPlotEventForm,
    EventDetails,
    selectedEvent,
    setSelectedCropName,
    setModeEvent,
    setPlotEventSelected,
    setOpenPlotEventFormEdit,
    setEventPlotActivitySelected,
    setConfirmOpenEvent,
    plotEventActivitySelected,
    confirmOpenEvent,
    handleDeletePlot,
    handleDeletePlotEvent
  } = props

  return (
    <Timeline>
      {
        userAll.registro.properties[index_property].plot_terrains[index_plot_terrain].plots 
        && userAll.registro.properties[index_property].plot_terrains[index_plot_terrain].plots
        .slice()
        .sort((a,b) => new Date(a.start_date) > new Date(b.start_date) ? -1: 1)
        .map((itemPlot, index) => {

          const alignButtons = (index + 1) % 2 ? "start" : "start";
          const directionButtons = (index + 1) % 2 ? "row" : "row";
          
          return (
            <Box key={index}>
              {
                itemPlot 
                ? (
                  <TimelineItem>
                    
                    <TimelineOppositeContent
                      sx={{ m: "auto 0", ml: -5, maxWidth: "180px" }}
                      align="right"
                      variant="body2"
                      color="text.secondary"
                    >
                      <Box>
                        <Typography variant="h5">{itemPlot?.type}</Typography>
                        <Typography variant="body2">{itemPlot?.load_treatment}</Typography>
                        <Typography variant="body2">{itemPlot?.transform_from}</Typography>
                        <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: "1.1rem" }}>
                          {formatedDate(itemPlot?.start_date)}
                        </Typography>

                        <Box display={"flex"} justifyContent={"end"} alignItems={"center"} gap={1} mr={-2}>
                          
                          <ButtonEdit item={itemPlot} handleEdit={handleEdit}/>

                          <IconButton
                            size="small"
                            sx={{ size: "12px" }}
                            onClick={() => {
                              setPlotActivitySelected(itemPlot);
                              setConfirmOpen(true);
                            }}
                          >
                            <DeleteIcon sx={{ color: "red", fontSize: "22px" }}/>
                          </IconButton>

                          {
                            itemPlot._id === plotActivitySelected._id 
                            ? (
                              <ConfirmDialog
                                name="Delete plot activity"
                                open={confirmOpen}
                                setOpen={setConfirmOpen}
                                onConfirm={() => handleDeletePlot(itemPlot._id)}
                                title={ `${itemPlot?.type} ${itemPlot?.transform_from}`}
                                subtitle={`${itemPlot?.load_treatment} ${formatedDate(itemPlot?.start_date)}`}
                              />
                            ) : null
                          }

                        </Box>
                      </Box>

                    </TimelineOppositeContent>

                    <TimelineSeparator>

                      <TimelineConnector />

                      <TimelineDot
                        sx={{
                          width: 40,
                          height: 40,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {index + 1}
                      </TimelineDot>

                      <TimelineConnector />

                    </TimelineSeparator>

                    <TimelineContent sx={{py: "20px", px: 2, width: { xs: "100%", sm: "100%" },}}>
                      <Box>
                        <Typography variant="h5">
                          {" "}{itemPlot?.type} {itemPlot?.crop?.name}
                        </Typography>
                      </Box>

                      <Typography variant="body2">
                        {formatedDate(itemPlot.crop?.start_date)}
                      </Typography>

                      <Typography variant="body2">
                        {
                          itemPlot?.crop?.planting_period
                          ? itemPlot?.crop?.planting_period
                          : itemPlot?.period
                        }
                        {" days"}
                      </Typography>

                      <Box
                        display={"flex"}
                        flexDirection={directionButtons}
                        justifyContent={alignButtons}
                        alignItems={"center"}
                        gap={1}
                        sx={{ my: 1 }}
                      >
                        {
                          itemPlot.crop 
                          ? (
                            <Chip
                              color="tertiary"
                              variant="contained"
                              label="Crop"
                              onClick={() => handleViewData("crop", index)}
                              sx={{ py: "12px", height: "20px" }}
                            />
                          ) 
                          : null
                        }

                        {
                          itemPlot.type === "crop" ? (
                            <Chip
                              color="primary"
                              label="Plot Activity Events"
                              variant="outlined"
                              onClick={() =>
                                handleViewData("events", index)
                              }
                              sx={{ py: "12px", height: "20px" }}
                            />
                          ) 
                          : null
                        }

                        {
                          itensDetails.id === index 
                          && (itensDetails.data || itensDetails.event) 
                          ? (
                            <IconButton
                              size="small"
                              onClick={() => {
                                setItensDetails({
                                  id: index,
                                  event: false,
                                  data: false,
                                });
                              }}
                            >
                              <ArrowDropUpRoundedIcon />
                            </IconButton>
                          ) 
                          : null
                        }
                      </Box>

                      {
                        itensDetails.id === index && itensDetails.data ? (
                          <Box sx={{ fontSize: "12px", border: "1px solid green", p: 2, borderRadius: 2, width: "100%",}}>
                            <Grid container spacing={2}>
                              
                              <Grid item xs={12} md={5}>

                                <ShowPlotActivity itemPlot={itemPlot}/>
                                {
                                  itemPlot.crop && itemPlot.crop.seed 
                                    ? (<ShowSeed itemPlot={itemPlot} />)
                                    : null
                                }
                                
                                {
                                  itemPlot && itemPlot.crop && 
                                  itemPlot.crop.dry_mass_heavy_metal_content 
                                  ? (
                                      <ShowMetalContent itemPlot={itemPlot} />
                                  ) : null
                                }
                              </Grid>

                              {
                                itemPlot.crop 
                                ? (
                                  <Grid item xs={12} md={7}>
                                    <ShowCrop itemPlot={itemPlot} />
                                  </Grid>
                                ) 
                                : null
                              }
                            </Grid>
                          </Box>
                        ) 
                        : null
                      }

                      {
                        itensDetails.id === index && itensDetails.event 
                        ? (
                          <Box sx={{border: "1px solid green", borderRadius: 2, p: 2,}}>
                            <Box
                              display="flex"
                              flexDirection={"row"}
                              justifyContent={"space-between"}
                              mb={2}
                            >
                              <Box sx={{ fontWeight: "bold" }}>Plot Activity Events List</Box>
                              
                              <Button
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  setSelectedPlot(itemPlot);
                                  handleOpenPlotEventForm(itemPlot?.crop?.name);
                                }}
                                startIcon={<AddCircleOutlineRoundedIcon />}
                              >
                                Add Event
                              </Button>

                            </Box>
                            <EventDetails
                              key={index}
                              events={userAll.registro.properties[index_property].plot_terrains[index_plot_terrain].plots[index].events}
                              item={itemPlot}
                              itemPlot={itemPlot}
                              setSelectedCropName={setSelectedCropName}
                              setModeEvent={setModeEvent}
                              setPlotEventSelected={setPlotEventSelected}
                              setSelectedPlot={setSelectedPlot}
                              setOpenPlotEventFormEdit={setOpenPlotEventFormEdit}
                              setEventPlotActivitySelected={setEventPlotActivitySelected}
                              setConfirmOpenEvent={setConfirmOpenEvent}
                              plotEventActivitySelected={plotEventActivitySelected}
                              confirmOpenEvent={confirmOpenEvent}
                              handleDeletePlotEvent={handleDeletePlotEvent}
                            />
                          </Box>
                        ) 
                        : null
                      }
                      {
                        itensDetails.id === index 
                        ? (
                          <>
                            {
                              selectedEvent.event 
                              ? (
                                <Box sx={{ fontSize: "14px" }}>
                                  <Box
                                    display={"flex"}
                                    flexDirection={directionButtons}
                                    justifyContent={"space-between"}
                                    flexWrap={"wrap"}
                                    gap={1}
                                    sx={{border: "1px solid green", p: 2, mt: 1, borderRadius: 2,}}
                                  >
                                    <Grid container>
                                      {
                                        !objetoVazio(selectedEvent.event) 
                                        ? (
                                          <Grid item xs={12} md={6}>
                                            <ShowEventRegister item={selectedEvent.event} />
                                          </Grid>
                                        )
                                        : null 
                                      }

                                      <Grid item xs={12} md={6}>
                                        {
                                          selectedEvent.event.operations && selectedEvent.event.operations.map((itemOperations, index) => (
                                            <Box key={index}>
                                              <ShowOperation item={itemOperations}/>
                                            </Box>
                                          ))
                                        }
                                      </Grid>
                                    </Grid>

                                    {
                                      selectedEvent.length > 0 
                                      ? (
                                        <>
                                          <Box sx={{ fontWeight: 700 }}>
                                            Applications
                                          </Box>
                                        </>
                                      ) 
                                      : null
                                    }
                                  </Box>
                                </Box>
                              )
                              : null 
                            }
                          </>
                        )
                        : null 
                      }
                    </TimelineContent>

                  </TimelineItem>
                ) : null
              }
            </Box>
          );
        })}
    </Timeline>
  )
}

export default PlotActivityTimelineVertical
