import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material'
import React from 'react'
import CheckIcon from '@mui/icons-material/Check'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const ConfirmDialog = (props) => {
    const { name, title, subtitle, children, open, setOpen, onConfirm } = props
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
        >
            <DialogTitle id="conform-dialog" sx={{ display: 'flex', justifyContent: 'space-between'}}>
                <strong>{name}</strong>
                <IconButton onClick={() => setOpen(false)} sx={{ mt: -1, mr: -2 }}>
                    <CancelOutlinedIcon sx={{ color: 'green' }} />
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{ p: 0 }}>
                <Grid container
                    display={'flex'}
                    justifyContent={'center'}
                    alignContent={'center'}
                    p={4}
                >
                    <Grid item>
                        {children}
                        <Typography variant="subtitle1">
                            {title}
                        </Typography>

                        <Typography variant="subtitle1">
                            {subtitle}
                        </Typography> 

                        <Typography variant="body2" color="red">
                            Are you sure you want to delete this register?
                        </Typography>
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    size='small'
                    variant="outlined"
                    onClick={() => {
                        setOpen(false)
                        onConfirm()
                    }}
                    color='secondary'
                    startIcon={<CheckIcon />}
                >
                    Confirm
                </Button>

                <Button
                    autoFocus
                    size='small'
                    variant='contained'
                    onClick={() => setOpen(false)}
                    color='primary'
                    startIcon={<CancelRoundedIcon />}
                >
                    Cancel
                </Button>
                
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmDialog
