import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  //FormControl,
  //FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  //InputLabel,
  MenuItem,
  //OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { connect } from "react-redux";
import { Form, Formik } from "formik";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import validationSchema from "./MachineryValidationSchema";
import "./machineryCss.css";
import {
  fetchEnumerationEnergyNames,
  fetchEnumerationMachineryFuelType,
  fetchEnumerationMachineryPowerType,
  fetchEnumerationMachinerySStype,
  fetchEnumerationMachineryStype,
  fetchEnumerationMachineryType,
} from "../../redux/enumeratedSlice";
import {
  addMachinery,
  editMachinery,
} from "../../redux/userAllSlice";
import PopoverCustom from "../layout/PopoverCustom";
//import { numericField, numericOnly } from "../../hooks/functions";
import CloseIcon from "@mui/icons-material/CancelRounded";
import Loading from '../layout/Loading';
import { numericField } from '../../hooks/functions';

export const MachineryForm = (props) => {
  const {
    openMachineryForm,
    setOpenMachineryForm,
    mode,
    user_id,
    fetchEnumerationMachineryType,
    enumerationMachineryType,
    fetchEnumerationMachineryFuelType,
    enumerationMachineryFuelType,
    fetchEnumerationMachineryStype,
    enumerationMachineryStype,
    fetchEnumerationMachinerySStype,
    enumerationMachinerySStype,
    fetchEnumerationMachineryPowerType,
    enumerationMachineryPowerType,
    addMachinery,
    machinerySelected,
    editMachinery,
    property,
    fetchEnumerationEnergyNames,
    enumerationEnergyNames,
    origin,
    setMachinerySelected,
    setMachineryTransportSelected,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [typeSelected, setTypeSelected] = useState("");
  const [stypeSelected, setStypeSelected] = useState("");
  //const [powerType, setPowerType] = useState("");
  const [loading, setLoading] = useState(true)

  async function init(){
    setLoading(true)
    setTimeout(async () => {
      await numericField()
      setLoading(false)
    }, 2000);
  }

  useLayoutEffect(()=> {
    init()
  }, [])

  useEffect(() => {
    fetchEnumerationMachineryType();
  }, [fetchEnumerationMachineryType]);

  useLayoutEffect(() => {
    fetchEnumerationMachineryFuelType();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchEnumerationMachineryPowerType();
  }, [fetchEnumerationMachineryPowerType]);
  
  useEffect(() => {
    fetchEnumerationEnergyNames();
  }, [fetchEnumerationEnergyNames]);

  useEffect(() => {
    if (mode === "edit" && machinerySelected.specific_type) {
      fetchEnumerationMachinerySStype(machinerySelected.specific_type);
    }
    if (stypeSelected) {
      fetchEnumerationMachinerySStype(stypeSelected);
    }
  }, [
    fetchEnumerationMachinerySStype,
    machinerySelected?.specific_type,
    mode,
    stypeSelected,
  ]);

  const initialValues = {
    id: machinerySelected._id || "",
    user_id: user_id,
    machine_name: machinerySelected.machine_name || "",
    type: machinerySelected.type || "",
    fuel: {
      type: machinerySelected.fuel?.type || "",
      biodiesel_perc: machinerySelected.fuel?.biodiesel_perc || "",
      diesel_dens: machinerySelected.fuel?.diesel_dens || "",
      biodiesel_dens: machinerySelected.fuel?.biodiesel_dens || "",
    },
    fuel_cons: machinerySelected.fuel_cons || "",
    energy_cons: machinerySelected.energy_cons || "",
    water_cons: machinerySelected.water_cons || "",
    model: machinerySelected.model || "",
    specific_type: machinerySelected.specific_type || null,
    specific_subtype: machinerySelected.specific_subtype || null,
    operating_yield: machinerySelected.operating_yield || "",
    lifespan: machinerySelected.lifespan || "",
    tset_weight: machinerySelected.tset_weight || "",
    tset_lifespan: machinerySelected.tset_lifespan || "",
    tset_rubbed_off: machinerySelected.tset_rubbed_off || "",
    weight: machinerySelected.weight || "",
    power: machinerySelected.power || "",
    power_type: machinerySelected.power_type || "",
    energy_type: machinerySelected.energy_type || "",
  };

  useEffect(() => {
    if (mode === "edit" && machinerySelected.specific_type) {
      fetchEnumerationMachinerySStype(machinerySelected.specific_type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setOpenMachineryForm(false);
  };

  const handleSubmit = async (values) => {
    const preparedValues = {
      id: values._id,
      user_id: user_id,
      machine_name: values.machine_name === "" ? null : values.machine_name,
      type: values.type === "" ? null : values.type,
      fuel: {
        type: values.fuel.type === "" ? null : values.fuel.type,
        biodiesel_perc: values.fuel.biodiesel_perc === "" ? null : values.fuel.biodiesel_perc,
        diesel_dens: values.fuel?.diesel_dens === "" ? null : values.fuel.diesel_dens,
        biodiesel_dens: values.fuel?.biodiesel_dens === "" ? null : values.fuel.biodiesel_dens,
      },
      fuel_cons: values.fuel_cons === "" ? null : values.fuel_cons,
      energy_cons: values.energy_cons === "" ? null : values.energy_cons,
      water_cons: values.water_cons === "" ? null : values.water_cons,
      model: values.model === "" ? null : values.model,
      specific_type: values.specific_type === "" ? null : values.specific_type,
      specific_subtype: values.specific_subtype === "" ? null : values.specific_subtype,
      operating_yield: values.operating_yield === "" ? null : values.operating_yield,
      lifespan: values.lifespan === "" ? null : values.lifespan,
      tset_weight: values.tset_weight === "" ? null : values.tset_weight,
      tset_lifespan: values.tset_lifespan === "" ? null : values.tset_lifespan,
      tset_rubbed_off: values.tset_rubbed_off === "" ? null : values.tset_rubbed_off,
      weight: values.weight === "" ? null : values.weight,
      power: values.power === "" ? null : values.power,
      power_type: values.power_type === "" ? null : values.power_type,
      energy_type: values.energy_type === "" ? null : values.energy_type,
    };
    let params = {};
    if (preparedValues.type === "irrigation, generic" && preparedValues.power_type === "fuel") {
      params = {
        user_id,
        prop_id: property._id,
        payload: {
          type: preparedValues.type,
          power_type: preparedValues.power_type,
          machine_name: preparedValues.machine_name,
          fuel_cons: preparedValues.fuel_cons,
          fuel: {
            type: preparedValues.fuel.type,
            biodiesel_perc: preparedValues.fuel.biodiesel_perc,
            diesel_dens: preparedValues.fuel.diesel_dens,
            biodiesel_dens: preparedValues.fuel.biodiesel_dens,
          },
          energy_cons: preparedValues.energy_cons,
        },
      };
    } else if (preparedValues.type === "irrigation, generic" && preparedValues.power_type === "energy") {
      params = {
        user_id,
        prop_id: property._id,
        payload: {
          type: preparedValues.type,
          machine_name: preparedValues.machine_name,
          power_type: preparedValues.power_type,
          energy_cons: preparedValues.energy_cons,
          energy_type: preparedValues.energy_type,
        },
      };
    } else if (preparedValues.type === "simple, generic") {
      params = {
        user_id,
        prop_id: property._id,
        payload: {
          type: preparedValues.type,
          machine_name: preparedValues.machine_name,
          fuel_cons: preparedValues.fuel_cons,
          fuel: {
            type: preparedValues.fuel.type,
            biodiesel_perc: preparedValues.fuel.biodiesel_perc,
            diesel_dens: preparedValues.fuel.diesel_dens,
            biodiesel_dens: preparedValues.fuel.biodiesel_dens,
          },
        },
      };
    } else if (preparedValues.type === "detailed, generic") {
      params = {
        user_id,
        prop_id: property._id,
        payload: {
          type: preparedValues.type,
          machine_name: preparedValues.machine_name,
          model: preparedValues.model,
          specific_type: preparedValues.specific_type,
          specific_subtype: preparedValues.specific_subtype,
          operating_yield: preparedValues.operating_yield,
          lifespan: preparedValues.lifespan,
          tset_lifespan: preparedValues.tset_lifespan,
          tset_weight: preparedValues.tset_weight,
          tset_rubbed_off: preparedValues.tset_rubbed_off,
          fuel_cons: preparedValues.fuel_cons,
          weight: preparedValues.weight,
          fuel: {
            type: preparedValues.fuel.type,
            biodiesel_perc: preparedValues.fuel.biodiesel_perc,
            diesel_dens: preparedValues.fuel.diesel_dens,
            biodiesel_dens: preparedValues.fuel.biodiesel_dens,
          },
        },
      };
    } else if (preparedValues.type === "detailed, tractor") {
      params = {
        user_id,
        prop_id: property._id,
        payload: {
          type: preparedValues.type,
          machine_name: preparedValues.machine_name,
          model: preparedValues.model,
          specific_type: preparedValues.specific_type,
          specific_subtype: preparedValues.specific_subtype,
          operating_yield: preparedValues.operating_yield,
          lifespan: preparedValues.lifespan,
          tset_lifespan: preparedValues.tset_lifespan,
          tset_weight: preparedValues.tset_weight,
          tset_rubbed_off: preparedValues.tset_rubbed_off,
          power: preparedValues.power,
          fuel_cons: preparedValues.fuel_cons,
          fuel: {
            type: preparedValues.fuel.type,
            biodiesel_perc: preparedValues.fuel.biodiesel_perc,
            diesel_dens: preparedValues.fuel.diesel_dens,
            biodiesel_dens: preparedValues.fuel.biodiesel_dens,
          },
        },
      };
    }

    if (mode === "add") {
      const result = await addMachinery(params);
      
      if(origin === "addEvent") {
        // Se o form foi acionado de Add Land Event ou Add Plot Activity event
        // atualizar a lista (select) de maquinário
        setMachinerySelected({_id: result.payload.data, ...result.payload.params.payload})
      } else if(origin === "addEventTransport"){
        setMachineryTransportSelected({_id: result.payload.data, ...result.payload.params.payload})
      }
      handleClose();
    }

    if (mode === "edit") {
      const mach_id = machinerySelected._id;
      const paramsEdit = {
        prop_id: property._id,
        mach_id,
        payload: params.payload,
      };
      await editMachinery(paramsEdit);
      handleClose();
    }
  };

  const handleChangeType = async (event, setFieldValue, values) => {
    // limpar inputs pre-selecionados an alteração de Main Type.
    init()
    //await numericField()
    // setTimeout( async () => {
    //   setLoading(true)
    //   console.log('Executou numericField() em handleChangeType...')
    //   //numericField()
    //   await numericField()
    //   setLoading(false)
    // }, 2000);
    
    clearMachinery(values, setFieldValue)
    clearFuel(values, setFieldValue)
    clearOptionalFields(values, setFieldValue)

    setFieldValue("specific_subtype", "");
    setFieldValue("specific_type", "");
    
    setFieldValue("type", event.target.value);
    setTypeSelected(event.target.value);
   
    if(event.target.value !== 'simple, generic')
      await fetchEnumerationMachineryStype(event.target.value);
  };

  const handleChangeSpecificType = (e, value, setFieldValue, values) => {
    setFieldValue("specific_subtype", "");
    setFieldValue("specific_type", value);
    setStypeSelected(value);
  };

  useEffect(() => {
    setTimeout(() => {
      const dialog = document.querySelector('.dialog')
      if(dialog){
        dialog.scrollIntoView({behavior: 'smooth', block: 'start'})
      }
      
      setLoading(false)
      }, 1000);
  }, [])

  // useEffect(() => {
  //   if(mode==="add"){
  //     numericField('add')
  //   } else {
  //     numericField('edit')
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [typeSelected, loading, powerType]);

  const handleChangePowerType = async (e, setFieldValue) => {
    await init()
    await setFieldValue("power_type", e.target.value)
    //setPowerType(e.target.value)
  }

  function clearMachinery(values, setFieldValue) {
    setFieldValue('machine_name', '')
    setFieldValue('model', '')
    setFieldValue('specific_type', '')
    setFieldValue('specific_subtype,', '')
    setFieldValue('operating_yield', '')
    setFieldValue('power', '')
  }

  function clearFuel(values, setFieldValue){
    setFieldValue('fuel_cons', '')
    setFieldValue('fuel.type', '')
    setFieldValue('fuel.biodiesel_perc', '')
    setFieldValue('fuel.diesel_dens', '')
    setFieldValue('fuel.biodiesel_dens', '')
  }

  function clearOptionalFields(values, setFieldValue){
    setFieldValue('weight', '')
    setFieldValue('lifespan', '')
    setFieldValue('tset_lifespan', '')
    setFieldValue('tset_weight', '')
    setFieldValue('tset_rubbed_off', '')
  }

  return (
    <div>
      <Dialog
        open={openMachineryForm}
        onClose={handleClose}
        maxWidth="md"
        scroll="body"
      >
        <DialogTitle
          display={"flex"}
          justifyContent={"space-between"}
        >
          <strong>{mode === "add" ? "Add Machinery" : "Edit Machinery"}</strong>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "green",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        
        <Typography variant="body2" sx={{ ml: 3, mt: 1, color: "red", fontWeight: "bold" }}>
          * Required Fields
        </Typography>

        { loading ? (<Loading />) : null}

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => { handleSubmit(values);}}
          validateOnBlur={false}
        >
          {({ values, touched, errors, handleChange, handleBlur, isvalid, setFieldValue, resetForm, isValidating, setErrors,}) => {
            return (
              <Form noValidate autoComplete="off">
                {/* {
                  loading ? (
                    <Loading />
                  ) : ( */}
                    <DialogContent sx={{ minWidth: { sm: "600px" } }}>
                      <Grid container spacing={2}>
                        
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <TextField
                            disabled={mode === "edit"}
                            fullWidth
                            size="small"
                            name="type"
                            select={mode === "add"}
                            label="Type"
                            value={values.type}
                            onChange={(event) => {handleChangeType(event, setFieldValue, values);}}
                            error={touched.type && Boolean(errors.type)}
                            helperText={touched.type && errors.type}
                            required
                          >
                            {mode === "add" &&
                              enumerationMachineryType.registros.map((option) => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                          </TextField>
                        </Grid>

                        {
                          values.type === "irrigation, generic" 
                          ? (
                            <Grid item xs={12} sm={6} md={4} lg={4}>
                              <TextField
                                required
                                fullWidth
                                size="small"
                                name="type"
                                select
                                label="Power Type"
                                value={values.power_type}
                                //onChange={(event) => { setFieldValue("power_type", event.target.value); }}
                                onChange={(e) => handleChangePowerType(e, setFieldValue)}
                                error={ touched.power_type && Boolean(errors.power_type) }
                                helperText={touched.power_type && errors.power_type}
                              >
                                {enumerationMachineryPowerType.registros.map(
                                  (option) => (
                                    <MenuItem key={option} value={option}>
                                      {option}
                                    </MenuItem>
                                  )
                                )}
                              </TextField>
                            </Grid>
                          ) : null
                        }

                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <TextField
                            fullWidth
                            required
                            size="small"
                            name="machine_name"
                            label="Name"
                            value={values.machine_name}
                            onChange={handleChange}
                            error={touched.machine_name && Boolean(errors.machine_name)}
                            helperText={touched.machine_name && errors.machine_name}
                          />
                        </Grid>

                        {
                          (values.type === "detailed, tractor" || values.type === "detailed, generic") 
                          && (
                            <>
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <TextField
                                  required
                                  fullWidth
                                  size="small"
                                  name="model"
                                  label="Model"
                                  value={values.model}
                                  onChange={handleChange}
                                  error={touched.model && Boolean(errors.model)}
                                  helperText={touched.model && errors.model}
                                />
                              </Grid>

                              {
                                mode === "add" 
                                ? (
                                  <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <Autocomplete
                                      disabled={mode === "edit"}
                                      size="small"
                                      id="specific_type"
                                      name="specific_type"
                                      isOptionEqualToValue={(option, value) => option === value}
                                      options={enumerationMachineryStype.registros ? enumerationMachineryStype.registros : ""}
                                      onChange={(e, value) => handleChangeSpecificType(e, value, setFieldValue, values)}
                                      value={values.specific_type || null}
                                      renderInput={(params) => (
                                        <TextField
                                          required
                                          {...params}
                                          label="Specific Type"
                                          error={ touched.specific_type && Boolean(errors.specific_type)}
                                          helperText={touched.specific_type && errors.specific_type}
                                        />
                                      )}
                                    />
                                  </Grid>
                                ) : (
                                  <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <TextField
                                      disabled
                                      required
                                      fullWidth
                                      size="small"
                                      name="specific_type"
                                      label="Specific Type"
                                      value={values.specific_type}
                                    />
                                  </Grid>
                                )
                              }

                              {
                                mode === "add" 
                                ? (
                                  <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <Autocomplete
                                      disabled={ !Boolean(values.specific_type) || mode === "edit"}
                                      size="small"
                                      id="specific_subtype"
                                      name="specific_subtype"
                                      isOptionEqualToValue={(option, value) =>  option === value}
                                      options={ enumerationMachinerySStype ? enumerationMachinerySStype.registros : ""}
                                      onChange={(e, value) => {setFieldValue("specific_subtype", value);}}
                                      value={values.specific_subtype || null}
                                      renderInput={(params) => (
                                        <TextField
                                          required
                                          {...params}
                                          label="Specific Subtype"
                                          error={touched.specific_subtype && Boolean(errors.specific_subtype)}
                                          helperText={touched.specific_subtype && errors.specific_subtype}
                                        />
                                      )}
                                    />
                                  </Grid>
                                ) : (
                                  <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <TextField
                                      disabled
                                      required
                                      fullWidth
                                      size="small"
                                      name="specific_subtype"
                                      label="Specific Subtype"
                                      value={values.specific_subtype}
                                    />
                                  </Grid>
                                )
                              }


                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <TextField 
                                  size='small'
                                  fullWidth
                                  type='number'
                                  name="operating_yield"
                                  label="Operating Yield"
                                  value={values.operating_yield }
                                  onChange={handleChange}
                                  inputProps={{ step: 0.01,  min: 0.0 }}
                                  error={touched.operating_yield && Boolean(errors.operating_yield)}
                                  helperText={touched.operating_yield && errors.operating_yield}
                                  InputProps={{
                                    endAdornment:<InputAdornment position="end">[ha/h]</InputAdornment>
                                  }}
                                />
                              </Grid>

                              {/* <Grid item xs={12} sm={6} md={4} lg={4}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel
                                    size="small"
                                    htmlFor="operating_yield"
                                    required
                                    error={touched.operating_yield && Boolean(errors.operating_yield)}
                                  >
                                    Operating Yield
                                  </InputLabel>

                                  <OutlinedInput
                                    fullWidth
                                    size="small"
                                    name="operating_yield"
                                    id="operating_yield"
                                    label="Operating Yield"
                                    value={values.operating_yield.toString() || "" }
                                    type="number"
                                    onChange={handleChange}
                                    error={touched.operating_yield && Boolean(errors.operating_yield)}
                                    inputProps={{ step: 0.01,  min: 0.0 }}
                                    endAdornment={<InputAdornment position="end">[ha/h]</InputAdornment>}
                                  />

                                  {
                                    touched.operating_yield && errors.operating_yield && (
                                      <FormHelperText error>
                                        {errors.operating_yield}
                                      </FormHelperText>
                                    )
                                  }
                                </FormControl>
                              </Grid> */}

                              {
                                values.type && values.type === "detailed, tractor" && (
                                  <>
                                  <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <TextField 
                                      required
                                      size='small'
                                      fullWidth
                                      type='number'
                                      name="power"
                                      label="Power"
                                      value={values.power}
                                      onChange={handleChange}
                                      inputProps={{ step: 0.01, min: 0 }}
                                      error={touched.power && Boolean(errors.power)}
                                      helperText={errors.power}
                                      InputProps={{
                                        endAdornment:
                                          <InputAdornment position="end">
                                            <Box mr={1} sx={{ whiteSpace: "normal", lineHeight: "1.2",}}>[hp]</Box>
                                            <PopoverCustom info={"Tractor power"} />
                                          </InputAdornment>
                                      }}
                                    />
                                  </Grid>

                                  {/* <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <FormControl fullWidth variant="outlined">
                                      <InputLabel
                                        size="small"
                                        htmlFor="power"
                                        required
                                        error={touched.power && Boolean(errors.power)}
                                      >
                                        Power
                                      </InputLabel>
                                      <OutlinedInput

                                        fullWidth
                                        size="small"
                                        name="power"
                                        id="power"
                                        label="Power"
                                        value={values.power.toString() || ""}
                                        type="number"
                                        onChange={handleChange}
                                        error={touched.power && Boolean(errors.power)}
                                        inputProps={{ step: 0.01, min: 0 }}
                                        endAdornment={
                                          <InputAdornment position="end">
                                            <Box mr={1} sx={{ whiteSpace: "normal", lineHeight: "1.2",}}>
                                              [hp]
                                            </Box>
                                            <PopoverCustom info={"Tractor power"} />
                                          </InputAdornment>
                                        }
                                      />
                                      {touched.power && errors.power && (
                                        <FormHelperText error>
                                          {errors.power}
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </Grid> */}
                                  </>
                                )
                              }
                            </>
                          )
                        }

                        {
                        values.type &&
                        !(values.type === "irrigation, generic" && values.power_type !== "fuel") 
                        ? (
                            <>
                              <Grid item xs={12}>
                                <Typography variant="subtitle1">Fuel</Typography>
                              </Grid>

                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <TextField
                                  disabled={!Boolean(values.type)} 
                                  size='small'
                                  fullWidth
                                  type='number'
                                  name="fuel_cons"
                                  label="Fuel Consumption"
                                  value={ values.fuel_cons }
                                  onChange={handleChange}
                                  inputProps={{ step: 0.01, min: 0.00 }}
                                  error={ touched.fuel_cons && Boolean(errors.fuel_cons) }
                                  helperText={touched.fuel_cons && errors.fuel_cons}
                                  InputProps = {{
                                    endAdornment:
                                      <InputAdornment position="end">
                                        {
                                          values.type === "irrigation, generic" ? "[l]"
                                          : values.type === "detailed, generic" || values.type === "detailed, tractor" ? "[l/h]"
                                          : values.type === "simple, generic" ? "[l/ha]"
                                          : null
                                        }
                                      </InputAdornment>
                                  }}
                                />
                              </Grid>

                              {/* <Grid item xs={12} sm={6} md={4} lg={4}>
                                <FormControl variant="outlined" sx={{ width: "100%" }}>
                                  <InputLabel
                                    error={ touched.fuel_cons && Boolean(errors.fuel_cons) }
                                    required
                                    size="small"
                                    htmlFor="fuel_cons"
                                  >
                                    Fuel Consumption
                                  </InputLabel>
                                  <OutlinedInput
                                    required
                                    fullWidth
                                    size="small"
                                    name="fuel_cons"
                                    id="fuel_cons"
                                    label="Fuel Consumption"
                                    value={ values.fuel_cons.toString() || "" }
                                    type="number"
                                    onChange={handleChange}
                                    error={ touched.fuel_cons && Boolean(errors.fuel_cons) }
                                    inputProps={{ step: 0.01 }}
                                    
                                    endAdornment={
                                      <InputAdornment position="end">
                                        {
                                          values.type === "irrigation, generic" ? "[l]"
                                          : values.type === "detailed, generic" || values.type === "detailed, tractor" ? "[l/h]"
                                          : values.type === "simple, generic" ? "[l/ha]"
                                          : null
                                        }
                                      </InputAdornment>
                                    }
                                    disabled={!Boolean(values.type)}
                                  />

                                  {
                                    touched.fuel_cons && errors.fuel_cons && (
                                      <FormHelperText error>
                                        {errors.fuel_cons}
                                      </FormHelperText>
                                    )
                                  }
                                </FormControl>
                              </Grid> */}

                              {
                                enumerationMachineryFuelType.registros.length > 0 ? (
                                  <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <TextField
                                      required
                                      fullWidth
                                      size="small"
                                      name="fuel.type"
                                      select
                                      label="Fuel Type"
                                      value={values.fuel.type}
                                      onChange={handleChange}
                                      error={touched.fuel?.type && Boolean(errors.fuel?.type)}
                                      helperText={touched.fuel?.type && errors.fuel?.type}
                                      disabled={!Boolean(values.type)}
                                    >
                                      {
                                        enumerationMachineryFuelType.registros 
                                        && enumerationMachineryFuelType.registros.map((option) => (
                                            <MenuItem key={option} value={option}>
                                              {option}
                                            </MenuItem>
                                          )
                                        ) 
                                      }
                                    </TextField>
                                  </Grid>
                                ) : null
                              }


                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <TextField 
                                  size='small'
                                  fullWidth
                                  type='number'
                                  name="fuel.biodiesel_perc"
                                  label="Biodiesel Percentage"
                                  value={values.fuel?.biodiesel_perc}
                                  onChange={handleChange}
                                  inputProps={{ step: 0.01, min: 0, max: 1 }}
                                  error={ Boolean(errors.fuel?.biodiesel_perc) }
                                  helperText={errors.fuel?.biodiesel_perc}
                                  InputProps={{
                                    endAdornment:
                                      <InputAdornment position="end">
                                        <Box mr={1}>[%]</Box>
                                        <PopoverCustom info={"Biodiesel percentage in diesel between 0 and 1"} />
                                      </InputAdornment>
                                  }}
                                />
                              </Grid>

                              {/* <Grid item xs={12} sm={6} md={4} lg={4}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel
                                    required
                                    size="small"
                                    htmlFor="biodiesel_perc"
                                    error={touched.fuel?.biodiesel_perc && Boolean(errors.fuel?.biodiesel_perc)}
                                  >
                                    Biodiesel Percentage
                                  </InputLabel>
                                  <OutlinedInput
                                    required
                                    fullWidth
                                    size="small"
                                    name="fuel.biodiesel_perc"
                                    id="biodiesel_perc"
                                    label="Biodiesel Percentage"
                                    value={values.fuel?.biodiesel_perc.toString() || ""}
                                    onChange={handleChange}
                                    onKeyDown={numericOnly}
                                    error={ touched.fuel?.biodiesel_perc && Boolean(errors.fuel?.biodiesel_perc) }
                                    inputProps={{ step: 0.01, min: 0, max: 1 }}
                                    type="number"
                                    disabled={!Boolean(values.type)}
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <Box mr={1}>[%]</Box>
                                        <PopoverCustom
                                          info={"Biodiesel percentage in diesel between 0 and 1"}
                                        />
                                      </InputAdornment>
                                    }
                                  />

                                  {
                                    touched.fuel?.biodiesel_perc && errors.fuel?.biodiesel_perc && (
                                      <FormHelperText error>
                                        {errors.fuel?.biodiesel_perc}
                                      </FormHelperText>
                                    )
                                  }
                                </FormControl>
                              </Grid> */}

                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <TextField 
                                  size='small'
                                  fullWidth
                                  type='number'
                                  name="fuel.diesel_dens"
                                  label="Diesel Density"
                                  value={ values.fuel?.diesel_dens }
                                  onChange={handleChange}
                                  inputProps={{ step: 0.01, min: 0 }}
                                  error={ touched.fuel?.diesel_dens && Boolean(errors.fuel?.diesel_dens) }
                                  helperText={errors.fuel?.diesel_dens}
                                  InputProps={{
                                    endAdornment:<InputAdornment position="end">[kg/l]</InputAdornment>
                                  }}
                                />
                              </Grid>

                              {/* <Grid item xs={12} sm={6} md={4} lg={4}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel size="small" htmlFor="diesel_dens">
                                    Diesel Density
                                  </InputLabel>
                                  <OutlinedInput
                                    size="small"
                                    name="fuel.diesel_dens"
                                    id="diesel_dens"
                                    label="Diesel Density"
                                    value={ values.fuel?.diesel_dens.toString() || "" }
                                    onChange={handleChange}
                                    onKeyDown={numericOnly}
                                    error={ touched.fuel?.diesel_dens && Boolean(errors.fuel?.diesel_dens) }
                                    inputProps={{ step: 0.01, min: 0 }}
                                    type="number"
                                    endAdornment={<InputAdornment position="end">[kg/l]</InputAdornment>}
                                    disabled={!Boolean(values.type)}
                                  />
                                  {
                                    touched.fuel?.diesel_dens && errors.fuel?.diesel_dens && (
                                      <FormHelperText error>
                                        {errors.fuel?.diesel_dens}
                                      </FormHelperText>
                                    )
                                  }
                                </FormControl>
                              </Grid> */}

                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <TextField
                                  disabled={!Boolean(values.type)} 
                                  size='small'
                                  fullWidth
                                  type='number'
                                  name="fuel.biodiesel_dens"
                                  label="Biodiesel Density"
                                  value={ values.fuel?.biodiesel_dens}
                                  onChange={handleChange}
                                  inputProps={{ step: 0.01 }}
                                  error={ touched.fuel?.biodiesel_dens && Boolean(errors.fuel?.biodiesel_dens) }
                                  helperText={errors.fuel?.biodiesel_dens}
                                  InputProps={{
                                    endAdornment:<InputAdornment position="end">[kg/l]</InputAdornment>
                                  }}
                                />
                              </Grid>

                              {/* <Grid item xs={12} sm={6} md={4} lg={4}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel size="small" htmlFor="biodiesel_dens">
                                    Biodiesel Density
                                  </InputLabel>
                                  <OutlinedInput
                                    fullWidth
                                    size="small"
                                    name="fuel.biodiesel_dens"
                                    id="biodiesel_dens"
                                    label="Biodiesel Density"
                                    value={ values.fuel?.biodiesel_dens.toString() || ""}
                                    onChange={handleChange}
                                    onKeyDown={numericOnly}
                                    error={ touched.fuel?.biodiesel_dens && Boolean(errors.fuel?.biodiesel_dens) }
                                    inputProps={{ step: 0.01 }}
                                    type="number"
                                    endAdornment={<InputAdornment position="end">[kg/l]</InputAdornment>}
                                    disabled={!Boolean(values.type)}
                                  />
                                  {
                                    touched.fuel?.biodiesel_dens && errors.fuel?.biodiesel_dens && (
                                      <FormHelperText error>
                                        {errors.fuel?.biodiesel_dens}
                                      </FormHelperText>
                                    )
                                  }
                                </FormControl>
                              </Grid> */}

                            </>
                          ) : null
                        }

                        {
                          values.type === "irrigation, generic" && (
                          <>
                            {
                              values.power_type && values.power_type !== "fuel" 
                              ? (
                                <>
                                  <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <TextField
                                      required
                                      fullWidth
                                      size="small"
                                      name="type"
                                      select
                                      label="Energy Type"
                                      value={values.energy_type}
                                      onChange={(event) => {setFieldValue("energy_type", event.target.value);}}
                                      error={touched.energy_type && Boolean(errors.energy_type)}
                                      helperText={touched.energy_type && errors.energy_type}
                                    >
                                      {
                                        enumerationEnergyNames.registros
                                        .slice()
                                        .sort((a,b) => a.toUpperCase() > b.toUpperCase() ? 1 : -1)
                                        .map((option) => (
                                          <MenuItem key={option} value={option}>
                                            {option}
                                          </MenuItem>
                                        ))
                                      }
                                    </TextField>
                                  </Grid>

                                  <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <TextField 
                                      size='small'
                                      fullWidth
                                      type="number"
                                      name="energy_cons"
                                      label="Energy Consumption"
                                      value={values.energy_cons}
                                      onChange={handleChange}
                                      inputProps={{ step: 0.01, min: 0 }}
                                      error={ touched.energy_cons && Boolean(errors.energy_cons)}
                                      helperText={errors.energy_cons}
                                      InputProps={{
                                        endAdornment:<InputAdornment position="end">[kWh]</InputAdornment>
                                      }}
                                    />
                                  </Grid>

                                  {/* <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <FormControl fullWidth variant="outlined">
                                      <InputLabel size="small" htmlFor="energy_cons" required error={touched.energy_cons && Boolean(errors.energy_cons)}>
                                        Energy Consumption
                                      </InputLabel>
                                      <OutlinedInput
                                        fullWidth
                                        size="small"
                                        name="energy_cons"
                                        id="energy_cons"
                                        label="Energy Consumption"
                                        value={values.energy_cons.toString() || ""}
                                        onChange={handleChange}
                                        error={ touched.energy_cons && Boolean(errors.energy_cons)}
                                        inputProps={{ step: 0.01, min: 0 }}
                                        type="number"
                                        endAdornment={<InputAdornment position="end">[kWh]</InputAdornment>}
                                      />
                                      {
                                        touched.energy_cons && errors.energy_cons && (
                                          <FormHelperText error>
                                            {errors.energy_cons}
                                          </FormHelperText>
                                        )
                                      }
                                    </FormControl>
                                  </Grid> */}
                                </>
                              ) : null
                            }

                            {
                              values.type !== "irrigation, generic" && (
                                <>
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                  <TextField 
                                    size='small'
                                    fullWidth
                                    type='number'
                                    name="water_cons"
                                    label="Water Consumption"
                                    value={values.water_cons}
                                    onChange={handleChange}
                                    inputProps={{ step: 0.01 }}
                                    error={ touched.water_cons && Boolean(errors.water_cons) }
                                    helperText={errors.water_cons}
                                    InputProps={{
                                      endAdornment:<InputAdornment position="end">[m<sup>3</sup>]</InputAdornment>
                                    }}
                                  />
                                </Grid>

                                {/* <Grid item xs={12} sm={6} md={4} lg={4}>
                                  <FormControl fullWidth variant="outlined">
                                    <InputLabel size="small" htmlFor="water_cons">
                                      Water Consumption
                                    </InputLabel>
                                    <OutlinedInput
                                      fullWidth
                                      size="small"
                                      name="water_cons"
                                      id="water_cons"
                                      label="Water Consumption"
                                      value={values.water_cons.toString() || ""}
                                      onChange={handleChange}
                                      onKeyDown={numericOnly}
                                      error={ touched.water_cons && Boolean(errors.water_cons) }
                                      inputProps={{ step: 0.01 }}
                                      type="number"
                                      endAdornment={<InputAdornment position="end">[m<sup>3</sup>]</InputAdornment>}
                                    />
                                    {
                                      touched.water_cons && errors.water_cons && (
                                        <FormHelperText error>
                                          {errors.water_cons}
                                        </FormHelperText>
                                      )
                                    }
                                  </FormControl>
                                </Grid> */}
                                </>
                              )
                            }
                          </>
                          )
                        }

                        {
                          (values.type === "detailed, tractor" || values.type === "detailed, generic") && (
                            <>
                              <Grid item xs={12}>
                                <Typography variant="subtitle1">
                                  Optional fields
                                </Typography>
                              </Grid>
                              {
                                values.type === "detailed, generic" && (
                                  <>

                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                      <TextField 
                                        size='small'
                                        fullWidth
                                        type='number'
                                        name="weight"
                                        label="Weight"
                                        value={values.weight}
                                        onChange={handleChange}
                                        inputProps={{ step: 0.01, min: 0 }}
                                        error={ touched.weight && Boolean(errors.weight) }
                                        helperText={errors.weight}
                                        InputProps={{
                                          endAdornment:<InputAdornment position="end"><Box>[kg]</Box></InputAdornment>
                                        }}
                                      />
                                    </Grid>

                                    {/* <Grid item xs={12} sm={6} md={4} lg={4}>
                                      <FormControl fullWidth variant="outlined">
                                        <InputLabel size="small" htmlFor="weight">
                                          Weight
                                        </InputLabel>
                                        <OutlinedInput
                                          fullWidth
                                          size="small"
                                          name="weight"
                                          id="weight"
                                          label="Weight"
                                          value={values.weight.toString() || ""}
                                          onChange={handleChange}
                                          onKeyDown={numericOnly}
                                          error={ touched.weight && Boolean(errors.weight) }
                                          inputProps={{ step: 0.01, min: 0 }}
                                          type="number"
                                          endAdornment={<InputAdornment position="end"><Box>[kg]</Box></InputAdornment>}
                                        />
                                        {
                                          touched.weight && errors.weight && (
                                            <FormHelperText error>
                                              {errors.weight}
                                            </FormHelperText>
                                          )
                                        }
                                      </FormControl>
                                    </Grid> */}
                                  </>
                                )
                              }

                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <TextField 
                                  size='small'
                                  fullWidth
                                  type='number'
                                  name="lifespan"
                                  label="Lifespan"
                                  value={values.lifespan}
                                  onChange={handleChange}
                                  inputProps={{ step: 0.01, min: 0 }}
                                  error={ touched.lifespan && Boolean(errors.lifespan) }
                                  helperText={errors.lifespan}
                                  InputProps={{
                                    endAdornment:
                                      <InputAdornment position="end">
                                        <Box mr={1} sx={{ whiteSpace: "normal", lineHeight: "1.0", }}>[h]</Box>
                                        <PopoverCustom info={"Lifespan of the machinery."} />
                                      </InputAdornment>
                                  }}
                                />
                              </Grid>

                              {/* <Grid item xs={12} sm={6} md={4} lg={4}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel 
                                    size="small" 
                                    htmlFor="lifespan"
                                    error={ touched.lifespan && Boolean(errors.lifespan) }
                                  >
                                    Lifespan
                                  </InputLabel>
                                  <OutlinedInput
                                    fullWidth
                                    size="small"
                                    name="lifespan"
                                    id="lifespan"
                                    label="Lifespan"
                                    value={values.lifespan.toString() || ""}
                                    onChange={handleChange}
                                    onKeyDown={numericOnly}
                                    error={ touched.lifespan && Boolean(errors.lifespan) }
                                    inputProps={{ step: 0.01, min: 0 }}
                                    type="number"
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <Box mr={1} sx={{ whiteSpace: "normal", lineHeight: "1.0", }}>
                                          [h]
                                        </Box>
                                        <PopoverCustom info={"Lifespan of the machinery."} />
                                      </InputAdornment>}
                                  />
                                  {
                                    touched.lifespan && errors.lifespan && (
                                      <FormHelperText error>
                                        {errors.lifespan}
                                      </FormHelperText>
                                    )
                                  }
                                </FormControl>
                              </Grid> */}

                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <TextField 
                                  size='small'
                                  fullWidth
                                  type='number'
                                  name="tset_lifespan"
                                  label="Tireset Lifespan"
                                  value={ values.tset_lifespan }
                                  onChange={handleChange}
                                  inputProps={{ step: 0.01, min: 0 }}
                                  error={ touched.tset_lifespan && Boolean(errors.tset_lifespan) }
                                  helperText={errors.tset_lifespan}
                                  InputProps={{
                                    endAdornment: 
                                      <InputAdornment position="end">
                                        <Box mr={1} sx={{ whiteSpace: "normal", lineHeight: "1.0", }}>[h]</Box>
                                        <PopoverCustom info={"Lifespan of the tireset."} />
                                      </InputAdornment> 
                                  }}
                                />
                              </Grid>


                              {/* <Grid item xs={12} sm={6} md={4} lg={4}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel size="small" htmlFor="tset_lifespan">
                                    Tireset Lifespan
                                  </InputLabel>
                                  <OutlinedInput
                                    fullWidth
                                    size="small"
                                    name="tset_lifespan"
                                    id="tset_lifespan"
                                    label="Tireset Lifespan"
                                    value={ values.tset_lifespan.toString() || "" }
                                    onChange={handleChange}
                                    onKeyDown={numericOnly}
                                    error={ touched.tset_lifespan && Boolean(errors.tset_lifespan) }
                                    inputProps={{ step: 0.01, min: 0 }}
                                    type="number"
                                    endAdornment={ 
                                      <InputAdornment position="end">
                                        <Box mr={1} sx={{ whiteSpace: "normal", lineHeight: "1.0", }}>
                                          [h]
                                        </Box>
                                        <PopoverCustom info={"Lifespan of the tireset."} />
                                      </InputAdornment> }
                                  />
                                  {
                                    touched.tset_lifespan && errors.tset_lifespan && (
                                      <FormHelperText error>
                                        {errors.tset_lifespan}
                                      </FormHelperText>
                                    )
                                  }
                                </FormControl>
                              </Grid> */}

                              <Grid item xs={12} sm={6} md={6} lg={6}>
                                <TextField 
                                  size='small'
                                  fullWidth
                                  type='number'
                                  name="tset_weight"
                                  label="Tireset Weight"
                                  value={ values.tset_weight}
                                  onChange={handleChange}
                                  inputProps={{ step: 0.01, min: 0, max: 1 }}
                                  error={ touched.tset_weight && Boolean(errors.tset_weight)}
                                  helperText={errors.tset_weight}
                                  InputProps={{
                                    endAdornment:
                                      <InputAdornment position="end">
                                        <Box mr={1} sx={{ whiteSpace: "normal", lineHeight: "1.0", }}>[kg tireset / kg machine]</Box>
                                        <PopoverCustom info={"Relative weight of the tireset compared to the machine"}/>
                                      </InputAdornment>
                                  }}
                                />
                              </Grid>

                              {/* <Grid item xs={12} sm={6} md={6} lg={6}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel size="small" htmlFor="tset_weight">
                                    Tireset Weight
                                  </InputLabel>
                                  <OutlinedInput
                                    fullWidth
                                    size="small"
                                    name="tset_weight"
                                    id="tset_weight"
                                    label="Tireset Weight"
                                    value={ values.tset_weight.toString() || ""}
                                    onChange={handleChange}
                                    onKeyDown={numericOnly}
                                    error={ touched.tset_weight && Boolean(errors.tset_weight)}
                                    inputProps={{ step: 0.01, min: 0, max: 1 }}
                                    type="number"
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <Box mr={1} sx={{ whiteSpace: "normal", lineHeight: "1.0", }}>
                                          [kg tireset / kg machine]
                                        </Box>
                                        <PopoverCustom
                                          info={"Relative weight of the tireset compared to the machine"}
                                        />
                                      </InputAdornment>
                                    }
                                  />
                                  {
                                    touched.tset_weight && errors.tset_weight && (
                                      <FormHelperText error>
                                        {errors.tset_weight}
                                      </FormHelperText>
                                    )
                                  }
                                </FormControl>
                              </Grid> */}

                              <Grid item xs={12} sm={6} md={6} lg={6}>
                                <TextField 
                                  size='small'
                                  fullWidth
                                  type='number'
                                  name="tset_rubbed_off"
                                  label="Tireset Rubbed Off"
                                  value={ values.tset_rubbed_off}
                                  onChange={handleChange}
                                  inputProps={{ step: 0.01, min: 0, max: 1 }}
                                  error={ touched.tset_rubbed_off && Boolean(errors.tset_rubbed_off) }
                                  helperText={errors.tset_rubbed_off}
                                  InputProps={{
                                    endAdornment:
                                      <InputAdornment position="end">
                                        <Box mr={1} sx={{ whiteSpace: "normal", lineHeight: "1.2",}}>[kg rubber / kg tireset]</Box>
                                        <PopoverCustom info={"Rubber removed per tireset"}/>
                                      </InputAdornment>
                                    
                                  }}
                                />
                              </Grid>

                              {/* <Grid item xs={12} sm={6} md={6} lg={6}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel size="small" htmlFor="tset_rubbed_off">
                                    Tireset Rubbed Off
                                  </InputLabel>
                                  <OutlinedInput
                                    fullWidth
                                    size="small"
                                    name="tset_rubbed_off"
                                    id="tset_rubbed_off"
                                    label="Tireset Rubbed Off"
                                    value={ values.tset_rubbed_off.toString() || ""}
                                    onChange={handleChange}
                                    onKeyDown={numericOnly}
                                    error={ touched.tset_rubbed_off && Boolean(errors.tset_rubbed_off) }
                                    inputProps={{ step: 0.01, min: 0, max: 1 }}
                                    type="number"
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <Box mr={1} sx={{ whiteSpace: "normal", lineHeight: "1.2",}}>
                                          [kg rubber / kg tireset]
                                        </Box>
                                        <PopoverCustom
                                          info={"Rubber removed per tireset"}
                                        />
                                      </InputAdornment>
                                    }
                                  />

                                  {
                                    touched.tset_rubbed_off && errors.tset_rubbed_off && (
                                      <FormHelperText error>
                                        {errors.tset_rubbed_off}
                                      </FormHelperText>
                                    )
                                  }
                                </FormControl>
                              </Grid> */}
                            </>
                          )
                        }
                      </Grid>
                    </DialogContent>
                  {/* )
                } */}

                <Divider />

                <DialogActions sx={{ display: "fixed" }}>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    
                    <Button
                      size="small"
                      onClick={handleClose}
                      variant="outlined"
                      startIcon={<CancelRoundedIcon />}
                    >
                      Cancel
                    </Button>

                    <Button
                      size="small"
                      variant="contained"
                      startIcon={<SaveRoundedIcon />}
                      autoFocus
                      type="submit"
                    >
                      {mode === "add" ? "add" : "save"}
                    </Button>
                  </Box>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>

      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userAll: {
      loading: state.userAll.loading,
      registros: state.userAll.registro,
      error: state.userAll.error,
    },
    enumerationMachineryType: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationMachineryType,
      error: state.enumerated.error,
    },
    enumerationMachineryFuelType: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationMachineryFuelType,
      error: state.enumerated.error,
    },
    enumerationMachineryStype: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationMachineryStype,
      error: state.enumerated.error,
    },
    enumerationMachinerySStype: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationMachinerySStype,
      error: state.enumerated.error,
    },
    enumerationMachineryPowerType: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationMachineryPowerType,
      error: state.enumerated.error,
    },
    enumerationEnergyNames: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationEnergyNames,
      error: state.enumerated.error,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEnumerationMachineryType: () => dispatch(fetchEnumerationMachineryType()),
    fetchEnumerationMachineryFuelType: () => dispatch(fetchEnumerationMachineryFuelType()),
    fetchEnumerationMachineryStype: (type_) => dispatch(fetchEnumerationMachineryStype(type_)),
    fetchEnumerationMachinerySStype: (stype) => dispatch(fetchEnumerationMachinerySStype(stype)),
    fetchEnumerationMachineryPowerType: (stype) => dispatch(fetchEnumerationMachineryPowerType(stype)),
    addMachinery: (params) => dispatch(addMachinery(params)),
    editMachinery: (params) => dispatch(editMachinery(params)),
    fetchEnumerationEnergyNames: () => dispatch(fetchEnumerationEnergyNames())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MachineryForm);
