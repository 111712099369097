import {
  Box,
  Button,
  Divider,
  Grid,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import HeaderProperty from "../layout/HeaderProperty";
import { fetchUserAll } from "../../redux/userAllSlice";
import { formatedDate } from "../../hooks/functions";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { resetResults } from "../../redux/resultsSlice";
import { fetchEnumerationDatabaseName } from "../../redux/enumeratedSlice";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import Loading from '../layout/Loading';
import axios from 'axios'
import SnackbarCustom from '../layout/SnackbarCustom'

const validationSchema = Yup.object().shape({
  plot_ter_id: Yup.string().required("Field Required"),
  plot_id: Yup.string().required("Field Required"),
  database_name: Yup.string().required("Field Required"),
});

export const GenerateSimaPro = (props) => {

  const {
    fetchUserAll,
    userAll,
    fetchEnumerationDatabaseName,
    resetResults,
  } = props;

  const [landSelected, setLandSelected] = useState("");
  const [landSelectedData, setLandSelectedData] = useState("");
  const [plotSelected, setPlotSelected] = useState("");
  const [databaseSelected, setDatabaseSelected] = useState("");
  const [plots, setPlots] = useState("");
  const [databaseList, setDatabaseList] = useState([]);
  const [loading, setLoading] = useState(false)
  const [openError, setOpenError] = useState(false)
  const [msgError, setMsgError] = useState('')
  const [msgSuccess, setMsgSuccess] = useState('')
  const [openSuccess, setOpenSuccess] = useState(false)

  const property = JSON.parse(localStorage.getItem("property"));
  const user = JSON.parse(localStorage.getItem("user"));

  const initialValues = {
    plot_ter_id: "",
    plot_id: "",
    database_name: "",
  };

  useEffect(()=>{
    resetResults()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    fetchUserAll(user.user_icv_id);
  }, [fetchUserAll, user.user_icv_id]);

  const propertySelected =
    userAll.registro.properties &&
    userAll.registro.properties.filter((p) => p._id === property._id);

    const lands = propertySelected && propertySelected[0].plot_terrains;

  const handleChangeLand = async (event, setFieldValue) => {
    setLandSelectedData("");
    setPlotSelected("");
    setLandSelected(event.target.value);
    setFieldValue("plot_ter_id", event.target.value);
    await resetResults()
  };

  const handleChangePlot = async (event, setFieldValue) => {
    setPlotSelected(event.target.value);
    setFieldValue("plot_id", event.target.value);
    await resetResults()
  };

  const handleChangeDatabase = async (event, setFieldValue) => {
    setDatabaseSelected(event.target.value);
    setFieldValue("database_name", event.target.value);
    await resetResults()
  };

  useEffect(() => {
    const getDatabaseList = async () => {
      const result = await fetchEnumerationDatabaseName();
      setDatabaseList(result.payload);
    };
    getDatabaseList();
  }, [fetchEnumerationDatabaseName]);

  useEffect(() => {
    setLandSelectedData(lands && lands.filter((l) => l._id === landSelected));
  }, [landSelected, lands]);

  useEffect(() => {
    setPlots(landSelectedData && landSelectedData[0]?.plots);
  }, [landSelected, landSelectedData, plots]);

  const handleSubmit = async (values) => {

    const params = {
      user_id: user.user_icv_id,
      prop_id: property._id,
      plot_ter_id: values.plot_ter_id,
      plot_id: values.plot_id,
      method: "BRCalc",
      mode: "SimaPRO",
      database_name: values.database_name || "",
    };

    const url = `https://icvcalc-backend.cnpma.embrapa.br/calculate/${params.user_id}/property/${params.prop_id}/plot-terrain/${params.plot_ter_id}/plot/${params.plot_id}?method=${params.method}&mode=${params.mode}&database_name=${params.database_name}`

    const landFilter = lands.filter((l) => l._id === values.plot_ter_id)
    const plotFilter = plots.filter((p)=> p._id === values.plot_id)

    const fileName = `ICVcalc_Simapro_${property.name}_${landFilter[0].name}_${plotFilter[0].start_date}`
    
    axiosDownloadFile(url, fileName)
  };

  const axiosDownloadFile =(url, fileName) => {
    setLoading(true)
   
    const user = JSON.parse(localStorage.getItem('user'))
   
    return axios({
      url,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Authorization': `Bearer ${user.token_api}`
      }
    })
    .then(response => {
      const href= window.URL.createObjectURL(response.data);
      const anchorElement = document.createElement('a');
      anchorElement.href = href
      anchorElement.download = fileName
      document.body.appendChild(anchorElement)
      anchorElement.click()
      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(href)
      setLoading(false)
      setOpenSuccess(true)
      setMsgSuccess(`Downloading the "${fileName}.csv" file was successful.`)
    })
    .catch(error => {
      setLoading(false)
      setOpenError(true)
      setMsgError('The SimaPro file could not be downloaded. Please try again later or contact your system administrator.')
    })
  }

  return (
    <Box sx={{ width: { xs: 340, sm: 500, md: 800 } }}>
      <HeaderProperty property={property} />
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        py={1}
      >
        <Typography variant="h6" sx={{ fontWeight: 700 }}>
          Generate SimaPro
        </Typography>
      </Box>

      <Divider sx={{ marginBottom: "10px" }} />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ values, touched, errors, setFieldValue, isValid }) => {
          return (
            <Form noValidate autoComplete="off">
              <Grid container gap={2} mt={2}>
                <TextField
                  fullWidth
                  size="small"
                  select
                  name="plot_ter_id"
                  label="select the land"
                  value={landSelected}
                  onChange={(event) => handleChangeLand(event, setFieldValue)}
                  error={touched.plot_ter_id && Boolean(errors.plot_ter_id)}
                  helperText={touched.plot_ter_id && errors.plot_ter_id}
                >
                  {
                    !lands ? (
                      <MenuItem key={"no_options"} value={"no_options"}>
                        {"no_options"}
                      </MenuItem>
                    ) : (
                      lands.map((option) => (
                        <MenuItem key={option._id} value={option._id}>
                          {option.name}
                        </MenuItem>
                      ))
                    )
                  }
                </TextField>

                <TextField
                  fullWidth
                  size="small"
                  name="plot_id"
                  select
                  label="select the plot"
                  value={plotSelected}
                  onChange={(event) => handleChangePlot(event, setFieldValue)}
                  error={touched.plot_id && Boolean(errors.plot_id)}
                  helperText={touched.plot_id && errors.plot_id}
                >
                  {
                    !plots ? (
                      <MenuItem key={"no_options"} value={"no_options"}>
                        {"No options"}
                      </MenuItem>
                    ) : (
                      plots.filter((p) => p.load_treatment !== "Donate Loads").map((option) => (
                        <MenuItem key={option._id} value={option._id}>
                          <ListItemText
                            primary={`${option.type} 
                            ${option?.crop?.name ? option.crop.name : ""} 
                            ${formatedDate(option.start_date)}`}
                            secondary={`${option.load_treatment} (${option.transform_from})`}
                          ></ListItemText>
                        </MenuItem>
                      ))
                    )
                  }
                </TextField>

                <TextField
                  fullWidth
                  size="small"
                  select
                  name="database_name"
                  label="select database name"
                  value={databaseSelected}
                  onChange={(event) => handleChangeDatabase(event, setFieldValue)}
                  error={touched.database_name && Boolean(errors.database_name)}
                  helperText={touched.database_name && errors.database_name}
                >
                  {
                    !lands 
                    ? (
                      <MenuItem key={"no_options"} value={"no_options"}>
                        {"no_options"}
                      </MenuItem>
                    ) 
                    : (
                      databaseList.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))
                    )
                  }
                </TextField>

                <Button
                  disabled={!isValid}
                  variant="contained"
                  startIcon={<DownloadForOfflineIcon />}
                  autoFocus
                  type="submit"
                  color="secondary"
                >
                  <i className="fas fa-download" />
                  Download File
                </Button>
              </Grid>
            </Form>
          );
        }}
      </Formik>

      {
        loading ? (<Loading />) : null
      }

      {
        openError
        ? (
          <SnackbarCustom
            open={openError}
            setOpen={setOpenError}
            msg={msgError}
            severity='error'
          />
        )
        : null
      }

      {
        openSuccess
        ? (
          <SnackbarCustom
            open={openSuccess}
            setOpen={setOpenSuccess}
            msg={msgSuccess}
            severity='success'
            vertical= 'bottom'
            horizontal='center'
          />
        )
        : null
      }
      <Box sx={{ pb: 10 }}></Box>

    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    userAll: {
      loading: state.userAll.loading,
      registro: state.userAll.registro,
      error: state.userAll.error,
    },
    resultsSimaPro: {
      loading: state.results.loading,
      registro: state.results.registro,
      error: state.results.error,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserAll: (user_id) => dispatch(fetchUserAll(user_id)),
    //fetchResults: (params) => dispatch(fetchResults(params)),
    resetResults: () => dispatch(resetResults()),
    fetchEnumerationDatabaseName: () => dispatch(fetchEnumerationDatabaseName()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GenerateSimaPro);
