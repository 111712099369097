import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { navItems } from '../data/layoutData'
import { NavLink, useNavigate } from 'react-router-dom';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useAuth } from '../../hooks/useAuth';
import LogoProjeto from '../img/ICVCalc_Embrapa_Icone.png'
import LogoProjetoBranco from '../img/ICVCalc_Embrapa_Icone.png'
import styled from '@emotion/styled';
import { connect } from 'react-redux';

const drawerWidth = 240;

const StyledLink = styled(NavLink)`
    text-decoration: none;
    color: white;
`;

export const DrawerAppBar = (props) => {

    const { window } = props;
    const navigate = useNavigate();
    const { logout } = useAuth();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <StyledLink to="properties">
                
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    py={2}
                    color="black"
                >
                    <Box
                        component={"img"}
                        alt="Capa ICV Calc"
                        src={LogoProjeto}
                        loading="lazy"
                        sx={{ width: 75, }}
                    >
                    </Box>
                    <Typography variant="h6" component="div" color={'black'}>ICVCalc - Embrapa</Typography>
                </Box>

            </StyledLink>

            <Divider />

            <List dense>
                {
                    navItems.map((item) => {
                        if(!item.externo){
                            return(
                                <ListItem disablePadding key={item.id}>
                                    <ListItemButton onClick={() => navigate(item.route)}>
                                        <ListItemText primary={item.title} />
                                    </ListItemButton>
                                </ListItem>
                            )
                        } else {
                            return (
                            <ListItem disablePadding key={item.id}>
                                <ListItemButton
                                    component={"a"}
                                    href={item.href}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <ListItemText primary={item.title} sx={{ textWrap: 'nowrap' }} />
                                </ListItemButton>
                            </ListItem>
                            )
                        }
                         
                    })
                }

                <ListItem disablePadding key={999}>
                    <ListItemButton
                        onClick={logout}
                    >
                        <ListItemText primary="Exit" />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;
    
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            <AppBar component="nav" sx={{ backgroundColor: '#EFF7EF' }}>
                <Toolbar>

                    <Box
                        display={'flex'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        width={'100%'}
                    >
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { sm: 'block', md: 'block', lg: 'none' }, color: 'black' }}
                        >
                            <MenuIcon sx={{ color: '#038628' }} />
                        </IconButton>

                        <Box
                            display={'flex'}
                            flexDirection={'row'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            gap={2}
                            color='#038628'
                            sx={{ width: { md: '50%', lg: '25%' } }}
                        >
                            <Box
                                component={"img"}
                                alt="Capa ICV Calc"
                                src={LogoProjetoBranco}
                                loading="lazy"
                                sx={{ width: 60 }}
                            />
                            <Box >
                                <Typography component="div" sx={{fontSize: { lg: '1.3rem' }, display: { xs: 'none', sm: 'block' }}}>
                                    ICVCalc - Embrapa
                                </Typography>

                                <Typography sx={{ display: { sm: 'none' } }}>ICVCalc</Typography>
                                
                            </Box>
                        </Box>


                        <List dense sx={{
                            display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex' },
                            justifyContent: 'end',
                            width: '73%',
                            color: '#038628'
                        }}>
                            {
                                navItems.map((item) => {
                                    if(!item.externo){
                                        return (
                                            <Box key={item.id}>
                                                <ListItem disablePadding >
                                                    <ListItemButton
                                                        sx={{ px: 0.8 }}
                                                        onClick={() => navigate(item.route)}
                                                    >
                                                        <ListItemText primary={item.title} sx={{ textWrap: 'nowrap' }} />
                                                    </ListItemButton>
                                                    <Divider
                                                        orientation="vertical"
                                                        flexItem
                                                        variant='middle'
                                                        width='2px'
                                                        color='#038628'
                                                    />
                                                </ListItem>
                                            </Box>
                                        )
                                    } else {
                                        return (
                                            <Box key={item.id}>
                                                <ListItem disablePadding >
                                                    <ListItemButton
                                                        sx={{ px: 0.8 }}
                                                        component={"a"}
                                                        href={item.href}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <ListItemText primary={item.title} sx={{ textWrap: 'nowrap' }} />
                                                    </ListItemButton>
                                                    <Divider
                                                        orientation="vertical"
                                                        flexItem
                                                        variant='middle'
                                                        width='2px'
                                                        color='#038628'
                                                    />
                                                </ListItem>
                                            </Box>
                                        )
                                    }
                                })
                            }
                        </List>

                        <Box display={'flex'}>
                            <Box>
                                <Button
                                    sx={{color: '#038628', textTransform: 'none', fontSize: '14px'}}
                                    startIcon={<ExitToAppIcon />}
                                    onClick={logout}
                                >
                                    Exit
                                </Button>
                            </Box>
                        </Box>

                    </Box>

                </Toolbar>
            </AppBar>

            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{ keepMounted: true, }}
                    sx={{
                        display: { xs: 'block', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>

            <Box component="main" sx={{ p: 3 }}>
                <Toolbar />
            </Box>

        </Box >
    );
}

DrawerAppBar.propTypes = {
    window: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {
    }
}

export default connect(mapStateToProps)(DrawerAppBar)
