import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Capa from "../../components/img/CultivarSoja.jpg";

function Cover() {
  return (
    <Grid
      item
      xxs={false}
      xs={false}
      sm={false}
      md={6}
      lg={6}
      sx={{
        display: { xxs: "none", xs: "none", sm: "none", md: "flex" },
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "black",
        width: 940,
      }}
    >
      <Box
        component={"img"}
        alt="Capa ICVCalc"
        src={Capa}
        loading="lazy"
        sx={{
          maxWidth: "100%",
          height: { sm: "100%" },
          opacity: 0.9,
          backgroundSize: "cover",
          objectFit: 'cover'
        }}
      />
      <Grid
        item
        xxs={false}
        xs={false}
        sm={false}
        md={6}
        lg={6}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignContent={"center"}
        alignItems={"center"}
        sx={{ position: "fixed", zIndex: 10, color: "white", p: 10 }}
      >
        <Typography
          align="center"
          variant="h3"
          sx={{
            fontWeight: "bold",
            mb: 2,
            fontSize: { lg: "45px", sm: "36px" },
          }}
        >
          ICVCalc - Embrapa
        </Typography>
        <Typography
          align="center"
          variant="h6"
          sx={{ fontSize: { lg: "20px", sm: "18px" } }}
        >
          Tool for building agricultural inventories for
        </Typography>
        <Typography
          align="center"
          variant="h6"
          sx={{ fontSize: { lg: "20px", sm: "18px" } }}
        >
          Life Cycle Assessment studies
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Cover;
