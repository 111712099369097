import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MachineryForm from "./MachineryForm";
import HeaderProperty from "../layout/HeaderProperty";
import { connect } from "react-redux";
import { deleteMachinery, fetchUserAll } from "../../redux/userAllSlice";
import ConfirmDialog from "../layout/ConfirmDialog";
import { objetoVazio } from "../../hooks/functions";
import Loading from '../layout/Loading';
import TitlePage from '../layout/TitlePage'
import ButtonEdit from '../layout/ButtonEdit'
import ShowRegister from './ShowRegister';
import ShowFuel from './ShowFuel';
import ShowOptionalFields from './ShowOptionalFields';
import ShowSummary from '../layout/ShowSummary';
import ShowSummaryActions from '../layout/ShowSummaryActions';
import SnackbarCustom from '../layout/SnackbarCustom';

export const Machinery = (props) => {
  const { fetchUserAll, userAll, deleteMachinery } = props;

  const [expanded, setExpanded] = useState(false);
  const [openMachineryForm, setOpenMachineryForm] = useState(false);
  const [mode, setMode] = useState("add");
  const [machinerySelected, setMachinerySelected] = useState({});
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [prop_machineries, setProp_machineries] = useState([]);
  const [openNoDelete, setOpenNoDelete] = useState(false)
  const [msgErroNoDelete] = useState("")

  const user = JSON.parse(localStorage.getItem("user"));
  const property = JSON.parse(localStorage.getItem("property"));

  useEffect(() => {
    fetchUserAll(user.user_icv_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!objetoVazio(userAll.registro)) {
      const index_pro = userAll.registro.properties?.findIndex(
        (reg) => reg._id === property._id
      );
      setProp_machineries(
        userAll.registro.properties[index_pro]?.prop_machinery
      );
    }
  }, [property._id, userAll]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleOpenMachineryForm = () => {
    setMode("add");
    setOpenMachineryForm(true);
  };

  const handleDeleteMachinery = async (item) => {
    const params = {
      user_id: user.user_icv_id,
      mach_id: item._id,
      prop_id: property._id,
    };
    await deleteMachinery(params);
   
};

  const handleEdit = (item) => {
    setMachinerySelected(item);
    setMode("edit");
    setOpenMachineryForm(true);
  };

  return (
    <Box sx={{ width: { xs: 340, sm: 500, md: 800 } }}>
      <HeaderProperty property={property} />

      {
        userAll.loading 
        ? (
          <Loading />
        ) 
        : (
          <>
            <TitlePage 
              handleAdd={handleOpenMachineryForm} 
              title={'Machinery List'} 
              info={
                <Typography component={'span'}>
                  The <strong>Machinery</strong> list serves as a comprehensive repository for all 
                  information pertaining to the <strong>machines</strong> employed on the <strong>Property</strong>, 
                  encompassing vehicles like tractors, trucks, harvesters, and other relevant 
                  equipment. However, it's important to note that the information stored in this 
                  list will contribute to the inventory calculations <strong>only</strong> when a 
                  specific <strong>machine</strong> is utilized in an Event with a <strong>generic 
                  Main Operation</strong>.<br/><br/>

                  To gain a deeper understanding of how <strong>Machinery</strong> factors into the 
                  broader context of <strong>Events</strong>, refer to 
                  the <a href="/icvcalc/help" style={{ cursor: 'pointer' }}><strong>Help
                  </strong></a>{' '} tab for more detailed information on <strong>Events </strong> 
                  and their implications on inventory calculations.
                </Typography>
              }
            />
            {
              prop_machineries && prop_machineries
              .slice()
              .sort((a,b) => (a.machine_name.toUpperCase() > b.machine_name.toUpperCase()) ? 1 : -1)
              .map((item, index) => {
                return (
                  <Accordion
                    key={index}
                    expanded={expanded === `panel${index}`}
                    onChange={handleChange(`panel${index}`)}
                    elevation={2}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >

                      <ShowSummary 
                        title={item.machine_name} 
                        subtitle={item.type} 
                      />

                      <ShowSummaryActions 
                        item={item} 
                        setSelected={setMachinerySelected} 
                        setConfirmOpen={setConfirmOpen} 
                      />

                      {
                        item._id === machinerySelected._id 
                        ? (
                          <ConfirmDialog
                            name="Delete Machinery"
                            open={confirmOpen}
                            setOpen={setConfirmOpen}
                            onConfirm={() => handleDeleteMachinery(item)}
                            title={`${item.machine_name} ${item?.type}`}
                            subtitle={`${item.specific_type} ${item?.specific_subtype}`}
                          />
                            
                        ) 
                        : null
                      }
                    </AccordionSummary>

                    <AccordionDetails>

                      <Grid container spacing={2} display={"flex"}>
                        {
                          item 
                          ? (
                            <Grid item xs={12} sm={4}>
                              <ShowRegister item={item} />
                            </Grid>
                          ) 
                          : null
                        }

                        {
                          (item.type === "irrigation, generic" && item.power_type === "fuel") 
                          || item.type === "simple, generic" 
                          || item.type === "detailed, tractor" 
                          || item.type === "detailed, generic" 
                          ? (
                            <Grid item xs={12} sm={4}>
                              <ShowFuel item={item} />
                            </Grid>
                          ) 
                          : null
                        }

                        {
                          item?.weight 
                          || item?.lifespan 
                          || item?.lifespan 
                          || item?.tset_weight 
                          || item?.tset_rubbed_off 
                          || (item.type === "detailed, tractor" && item.power) 
                          ? (
                            <Grid item xs={12} sm={4}>
                              <ShowOptionalFields item={item}/>
                            </Grid>
                          ) 
                          : null
                        }
                      </Grid>
                      
                      <ButtonEdit item={item} handleEdit={handleEdit} />
                      
                    </AccordionDetails>

                  </Accordion>
                );
              })
            }
            
            <Box sx={{ pb: "100px" }}></Box>

            {
              openMachineryForm ? (
                <MachineryForm
                  openMachineryForm={openMachineryForm}
                  setOpenMachineryForm={setOpenMachineryForm}
                  mode={mode}
                  machinerySelected={mode === "edit" ? machinerySelected : {}}
                  user_id={user.user_icv_id}
                  property={property}
                />
              ) : null
            }

            {
              openNoDelete
              ? (
                <SnackbarCustom
                  open={openNoDelete}
                  setOpen={setOpenNoDelete}
                  msg={msgErroNoDelete}
                />
              )
              : null
            }
          </>
        )
      }
    </Box>
  );
};

const mapStateToProps = (state, property) => {
  return {
    userAll: {
      loading: state.userAll.loading,
      registro: state.userAll.registro,
      error: state.userAll.error,
    },
    propertySelected: {
      loading: state.userAll.loading,
      registro: state.userAll.selectedProperty,
      error: state.userAll.error,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserAll: (user_id) => dispatch(fetchUserAll(user_id)),
    deleteMachinery: (params) => dispatch(deleteMachinery(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Machinery);
