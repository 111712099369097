import {Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Typography,} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import HeaderProperty from "../../layout/HeaderProperty";
import { useNavigate } from "react-router-dom";
import {
  deletePlotTerrains,
  fetchUserAll,
} from "../../../redux/userAllSlice";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LandForm from "./LandForm";
import ConfirmDialog from "../../layout/ConfirmDialog";
import TitlePage from '../../layout/TitlePage';
import Loading from '../../layout/Loading';
import ShowSummary from '../../layout/ShowSummary';
import ShowSummaryActions from '../../layout/ShowSummaryActions';
import ShowSoil from './ShowSoil';
import ShowLandEvents from './ShowLandEvents';
import ButtonEdit from '../../layout/ButtonEdit';
import ContainerPage from '../../layout/ContainerPage';
import LandEventForm from './LandEventForm';
import LandEventFormEdit from './LandEventFormEdit';

export const Land = (props) => {
  const { 
    fetchUserAll, 
    userAll, 
    deletePlotTerrains, 
  } = props;

  const navigate = useNavigate();

  const [mode, setMode] = useState("add");
  const [openLandForm, setOpenLandForm] = useState(false);
  const [openLandEventForm, setOpenLandEventForm] = useState(false)
  const [openLandEventFormEdit, setOpenLandEventFormEdit] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [landSelected, setLandSelected] = useState({});
  const [landEventSelected, setLandEventSelected] = useState({});
  const [confirmOpen, setConfirmOpen] = useState(false);
  
  const user = JSON.parse(localStorage.getItem("user"));
  const property = JSON.parse(localStorage.getItem("property"));

  useEffect(() => {
    fetchUserAll(user.user_icv_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const land = userAll.registro.properties && userAll.registro.properties.find((el) => el._id === property._id);

  const plot_terrains = land && land.plot_terrains;

  const handleOpenLandForm = () => {
    if (mode === "add") {
      setMode("add");
    } else {
      setMode("edit");
    }
    setOpenLandForm(true);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleDeleteLand = async (plot_ter_id) => {
    const paramsDelete = {
      user_id: user.user_icv_id,
      prop_id: property._id,
      plot_ter_id,
    };
    await deletePlotTerrains(paramsDelete);
    setOpenLandForm(false);
  };

  const handleEdit = (item) => {
    setMode("edit");
    setLandSelected(item);
    setOpenLandForm(true);
  };

  return (
    
    <ContainerPage>
      <HeaderProperty property={property} />
      <TitlePage 
        handleAdd={handleOpenLandForm} 
        title={'Land List'} 
        isBack={true}
        info={
          <Typography component={'span'}>
            The <strong>Lands</strong> represent specific areas of cultivation, focusing on the 
            <strong> soil</strong> aspects. It's important to note that a single patch of 
            <strong> soil</strong> may undergo various <strong>activities</strong> over time. 
            Therefore, the information stored here pertains exclusively to the 
            <strong> soil</strong> itself. Each <strong>Land</strong> contains a record of 
            cultivation occurrences called <strong>Plot Activity</strong>, detailing the specific 
            cultivation practices applied to the <strong>soil</strong> over time.<br/><br/>

            Regarding the <strong>soil</strong> information, it's worth mentioning that not all 
            fields are mandatory. Leaving optional fields empty prompts the ICVCalc system to assign 
            default values during inventory calculations. Additionally, the 
            <strong> Lands</strong> feature an essential component known as <strong>Event</strong>, 
            accessible by clicking on any <strong>Land card</strong> to access the 
            <strong> Land Events List</strong>. The <strong>Event</strong> consolidates data on each 
            activity performed in the plot, including details on applied <strong>Agricultural 
            Inputs</strong>, utilized <strong>Machinery/Implements</strong>, and other pertinent 
            information. This consolidated data is crucial for generating the Life Cycle 
            Assessment (LCA) inventory for the associated inputs.<br/><br/>

            It's noteworthy that <strong>Events</strong> within <strong>Lands</strong> are specific 
            to the <strong>soil</strong>, focusing on activities like lime application that can have 
            enduring benefits across multiple cultivations.<br/><br/>

            More information in the <a href="/icvcalc/help" style={{ cursor: 'pointer' }}>Help</a>{' '} tab
          </Typography>
        } 
      />

      <Grid container>
        {
          userAll.loading 
          ? (
            <Loading />
          ) 
          : (
            <div>
              {
                plot_terrains 
                && plot_terrains
                  .slice()
                  .sort((a,b) => a.name < b.name ? -1 : 1)
                  .map((item, index) => {
                  return (
                    <Accordion
                      key={index}
                      expanded={expanded === `panel${index}`}
                      onChange={handleChange(`panel${index}`)}
                      elevation={2}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ width: { xs: 340, sm: 500, md: 800 } }}>
                        <ShowSummary title={item.name} subtitle={`Area of ${item.area} ha`} />
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => navigate(`/icvcalc/plot/${item._id}`)}
                          color="tertiary"
                        >
                          PLOT ACTIVITY
                        </Button>
                        <ShowSummaryActions 
                          item={item} 
                          setSelected={setLandSelected} 
                          setConfirmOpen={setConfirmOpen} 
                        />
                        
                        {
                          item._id === landSelected._id 
                          ? (
                            <ConfirmDialog
                              name="Delete land"
                              open={confirmOpen}
                              setOpen={setConfirmOpen}
                              onConfirm={() => handleDeleteLand(item._id)}
                              title={item?.name}
                              subtitle={''}
                            />
                              
                          ) 
                          : null
                        }
                      </AccordionSummary>

                      <AccordionDetails>
                        <Grid container spacing={2}>
                        
                          <Grid item xs={12} md={4} sx={{ fontSize: "12px" }}>
                            { item?.soil ? (<ShowSoil item={item} />) : null }
                          </Grid>

                          <Grid item xs={12} md={8}>
                            <ShowLandEvents 
                              item={item} 
                              user={user} 
                              property={property}
                              setOpenLandEventForm={setOpenLandEventForm}
                              landSelected={landSelected}
                              setLandSelected={setLandSelected}
                              setLandEventSelected={setLandEventSelected}
                              setOpenLandEventFormEdit ={setOpenLandEventFormEdit }
                              landEventSelected={landEventSelected}
                            />

                          </Grid>
                        </Grid>
                        <Box>
                          <ButtonEdit item={item} handleEdit={handleEdit} />
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  );
                })
              }
            </div>
          )
        }
      </Grid>

      <Box sx={{ pb: "100px" }}></Box>
      
      {
        openLandForm 
        ? (
          <LandForm
            openLandForm={openLandForm}
            setOpenLandForm={setOpenLandForm}
            user_id={user.user_icv_id}
            property={property}
            mode={mode}
            setMode={setMode}
            landSelected={mode === "edit" ? landSelected : {}}
          />
        )
        : null
      }
     
      {
        openLandEventFormEdit 
        ? (
          <LandEventFormEdit
            openLandEventFormEdit={openLandEventFormEdit}
            setOpenLandEventFormEdit={setOpenLandEventFormEdit}
            landSelected={landSelected}
            landEventSelected={landEventSelected}
            setLandEventSelected={setLandEventSelected}
            plot_terrain={landSelected}
          />
        )
        : null
      }

      {
        openLandEventForm 
        ? (
          <LandEventForm
            openLandEventForm={openLandEventForm}
            setOpenLandEventForm={setOpenLandEventForm}
            plot_terrain={landSelected}
            landSelected={landSelected}
            setLandSelected={setLandSelected}
          />
        )
        : null
      }
    </ContainerPage>  
  );
};

const mapStateToProps = (state) => {
  return {
    propertySelected: {
      loading: state.userAll.loading,
      registro: state.userAll.selectedProperty,
      error: state.userAll.error,
    },
    userAll: {
      loading: state.userAll.loading,
      registro: state.userAll.registro,
      error: state.userAll.error,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserAll: (user_id) => dispatch(fetchUserAll(user_id)),
    deletePlotTerrains: (params) => dispatch(deletePlotTerrains(params)),
    //deleteLandEvent: (params) => dispatch(deleteLandEvent(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Land);
