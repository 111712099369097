import * as Yup from 'yup';

const validationSchema = () => Yup.object().shape({
    type: Yup
        .string()
        .required('Required field'),
    machine_name: Yup
        .string()
        .required('Required field'),
    power_type: Yup
        .string()
        .test(
            'Power Type required',
            'Required field',
            function () {
                if (
                    this.parent.type === "irrigation, generic"
                    && !this.parent.power_type
                ) {
                    return false
                }
                else {
                    return true
                }
            }
        ),
    fuel_cons: Yup
        .number()
        .min(0, 'Must be greater than or equal to 0')
        .test(
            'Fuel consuption requered',
            'Required field',
            function () {
                if (
                    ((this.parent.type === "irrigation, generic"
                        && this.parent.power_type === "fuel")
                        || this.parent.type === "simple, generic"
                        || this.parent.type === "detailed, tractor"
                        || this.parent.type === "detailed, generic")
                    && !this.parent.fuel_cons
                ) {
                    return false
                } else {
                    return true
                }
            }
        ),

    fuel: Yup.object().shape({
        type: Yup
            .string()
            .test(
                'Fuel Type required',
                'Required field',
                function () {
                    if (
                        (this.from[1].value.power_type !== "energy"
                            && !this.parent.type
                        )) {
                        return false
                    } else {
                        return true
                    }
                }
            ),
        biodiesel_perc: Yup
            .number()
            .min(0, 'Must be greater than or equal to 0')
            .max(1, 'must be less than or equal to 1')
            .test(
                'Biodiesel Percentage required',
                'Required field',
                function () {
                    if (
                        (
                            this.from[1].value.power_type !== "energy"
                            && !this.parent.biodiesel_perc
                        )) {
                        return false
                    } else {
                        return true
                    }
                }
            ),
        diesel_dens: Yup
            .number()
            .min(0, 'Must be greater than or equal to 0'),
        biodiesel_dens: Yup
            .number()
            .min(0, 'Must be greater than or equal to 0'),
    }),
    energy_cons: Yup
        .number()
        .min(0, 'Must be greater than or equal to 0')
        .test(
            'Energy Consumption required',
            'Required field',
            function () {
                if (
                    this.parent.type === "irrigation, generic"
                    && this.parent.power_type === 'energy'
                    && !this.parent.energy_cons
                ) {
                    return false
                }
                else {
                    return true
                }
            }
        ),
    water_cons: Yup
        .number()
        .min(0, 'Must be greater than or equal to 0'),
    model: Yup
        .string()
        .test(
            'Model required',
            'Required field',
            function () {
                if (
                    (this.parent.type === "detailed, generic"
                        || this.parent.type === "detailed, tractor")
                    && !this.parent.model
                ) {
                    return false
                }
                else {
                    return true
                }
            }
        ),
    specific_type: Yup
        .string()
        .nullable(true)
        .test(
            'Specific Type required',
            'Required field',
            function (value) {
                if (
                    (this.parent.type === "detailed, generic"
                        || this.parent.type === "detailed, tractor")
                    && !this.parent.specific_type
                ) {
                    return false
                }
                else {
                    return true
                }
            }
        )
        .typeError('A number is required'),
    specific_subtype: Yup
        .string()
        .nullable(true)
        .test(
            'Specific Subtype required',
            'Required field',
            function (value) {
                if (
                    (this.parent.type === "detailed, generic"
                        || this.parent.type === "detailed, tractor")
                    && !this.parent.specific_subtype
                ) {
                    return false
                }
                else {
                    return true
                }
            }
        ),
    operating_yield: Yup
        .number()
        .min(0, 'Must be greater than or equal to 0')
        .test(
            'Operating yield required',
            'Required field',
            function (value) {
                if (
                    (this.parent.type === "detailed, generic"
                        || this.parent.type === "detailed, tractor")
                    && !this.parent.operating_yield
                ) {
                    return false
                }
                else {
                    return true
                }
            }
        ),
    energy_type: Yup
        .string()
        .test(
            'Energy Type required',
            'Required field',
            function (value) {
                if (
                    this.parent.type === "irrigation, generic"
                    && this.parent.power_type === "energy"
                    && !this.parent.energy_type
                ) {
                    return false
                }
                else {
                    return true
                }
            }
        ),
    lifespan: Yup
        .number()
        .positive('Must be greater than 0')
        .notOneOf([0], 'This field cannot accept a zero value'),
    tset_lifespan: Yup
        .number()
        .positive('Must be greater than 0')
        .notOneOf([0], 'This field cannot accept a zero value'),
    tset_weight: Yup
        .number()
        .positive('Must be greater than 0')
        .notOneOf([0], 'This field cannot accept a zero value')
        .max(1, 'must be less than or equal to 1'),
    tset_rubbed_off: Yup
        .number()
        .positive('Must be greater than 0')
        .notOneOf([0], 'This field cannot accept a zero value')
        .max(1, 'must be less than or equal to 1'),
    weight: Yup
        .number()
        .positive('Must be greater than 0')
        .notOneOf([0], 'This field cannot accept a zero value'),
    power: Yup
        .number()
        .positive('Must be greater than 0')
        .notOneOf([0], 'This field cannot accept a zero value')
        .test(
            'power required',
            'Required field',
            function (value) {
                if (
                    (this.parent.type === "detailed, tractor")
                    && !this.parent.power
                ) {
                    return false
                }
                else {
                    return true
                }
            }
        ),

})

export default validationSchema

