import { Backdrop, CircularProgress } from '@mui/material'
import React from 'react'

const Loading = () => {
  return (
    
    <Backdrop
      open={true}
      sx={{ 
        color: "#fff", 
        zIndex: (theme) => theme.zIndex.drawer + 1 
      }}
    >
      <CircularProgress color="secondary" />
    </Backdrop>
  )
}

export default Loading
