import React from 'react'
import ShowDataContainer from '../../layout/ShowDataContainer'
import { Box, Typography } from '@mui/material'
import { formatNumberFloat } from '../../../hooks/functions'
import Machinery from '../showData/Machinery'

function ShowMainOperation(props) {

  const {landEvent} = props

  return (
    <ShowDataContainer>
      <Typography variant="title1" sx={{ fontWeight: "bold" }}>
        Main Operation
      </Typography>

      <Box key={Math.random(1, 2)}>
        {landEvent.main_operation.type ? (<Box>Type:{" "}{landEvent.main_operation.type}</Box>) : null}
        {landEvent.main_operation.name ? (<Box>Name:{" "}{landEvent.main_operation.name}</Box>) : null}
        {landEvent.main_operation.ecoinvent ? (<Box>Name (ecoinvent):{" "}{landEvent.main_operation.ecoinvent}</Box>) : null}
        {landEvent.main_operation.amount ? (<Box>Amount:{" "}{formatNumberFloat(landEvent.main_operation.amount)}</Box>) : null}
        
        {
          landEvent.main_operation.machinery 
          && landEvent.main_operation.machinery.length !==0 
          ? (
            <Box>
              <Machinery rows={landEvent.main_operation.machinery}/>
            </Box>
          )
          : null
        }

      </Box>
    </ShowDataContainer>
  )
}

export default ShowMainOperation
