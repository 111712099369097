import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../services/api";

const initialState = {
  loading: false,
  registro: {},
  error: {},
};

export const fetchResults = createAsyncThunk("fetchResults", (params) => {
  const user_id = params.user_id;
  const prop_id = params.prop_id;
  const plot_ter_id = params.plot_ter_id;
  const plot_id = params.plot_id;
  const method = params.method;
  const mode = params.mode;

  return api
    .get(`/calculate/${user_id}/property/${prop_id}/plot-terrain/${plot_ter_id}/plot/${plot_id}?method=${method}&mode=${mode}`)
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 500) {
        return {
          error: true,
          message: `It was not possible to perform the calculations.\n
                    Review the data entered and if the problem persists, contact your system administrator (error status 500).`,
        };
      }
    });
});

export const slice = createSlice({
  name: "results",
  initialState,
  reducers: {
    resetResults: (state, action) => {state.registro = {};}
  },
  extraReducers: (builder) => {
    // obter resultados
    builder.addCase(fetchResults.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchResults.fulfilled, (state, action) => {
      state.loading = false;
      state.registro = action.payload;
      state.error = {};
    });
    builder.addCase(fetchResults.rejected, (state, action) => {
      state.loading = false;
      state.registro = {};
      state.error = action.error.message;
    });
  },
});

export const { resetResults,} = slice.actions;
export default slice.reducer;
