import { Accordion, AccordionDetails, AccordionSummary, Box, Grid } from '@mui/material';
import React, { useState } from 'react'
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ShowSummary from '../../layout/ShowSummary';
import ConfirmDialog from '../../layout/ConfirmDialog';
import { connect } from 'react-redux';
import { deleteLandEvent } from '../../../redux/userAllSlice';
import Transports from '../showData/Transports';
import Applications from '../showData/Applications';
import TitlePage from '../../layout/TitlePage';
import ShowSummaryActions from '../../layout/ShowSummaryActions';
import ShowEvent from './ShowEvent';
import ShowMainOperation from './ShowMainOperation';
import ButtonEdit from '../../layout/ButtonEdit';
import { formatedDate } from '../../../hooks/functions';

const CustomAccordion = styled(Accordion)(({ theme }) => {
  return {
    ".MuiAccordionDetails-root": {
      fontSize: "0.9rem",
    },
    ".MuiAccordionSummary-root": {
      minHeight: "0px",
      lineHeight: 1,
    },
    ".MuiAccordionSummary-content": { p: 0, fontSize: 15 },
    ".MuiButtonBase-root": {
      "& .muiExpanded:": { minHeight: "0px" },
    },
  };
});

function ShowLandEvents(props) {
  const {
    item, 
    user, 
    property,
    deleteLandEvent,
    setOpenLandEventForm,
    setOpenLandEventFormEdit,
    setLandSelected,
    setLandEventSelected,
    landEventSelected,
  } = props

  
  const [expandedEvent, setExpandedEvent] = useState(false);
  const [confirmOpenLand, setConfirmOpenLand] = useState(false);

  const handleChangeEvent = (panel1) => (event, isExpanded) => {
    setExpandedEvent(isExpanded ? panel1 : false);
  };

  const handleEditEvent = async (landEvent, item) => {
    await setLandEventSelected(landEvent);
    await setLandSelected(item);
    await setOpenLandEventFormEdit(true);
  };

  const handleOpenEventLandForm = (item) => {
    setLandSelected(item);
    setOpenLandEventForm(true);
  };

  const handleDeletePlotEvent = async (item, landEvent) => {
    const params = {
      plot_ter_id: item._id,
      user_id: user.user_icv_id,
      prop_id: property._id,
      event_id: landEvent._id,
    };
    await deleteLandEvent(params);
  };

  return (
    <Box>
      <Box sx={{ fontSize: "12px" }} display={"flex"} gap={2} flexWrap={"wrap"}>

        <Box sx={{border: "1px solid green", borderLeft: "5px solid green", borderRadius: 2, p: 2, mb: 2, minWidth: '100%'}}>
          
          <Box display="flex" flexDirection={"row"} justifyContent={"space-between"} mb={2}>
            
            <TitlePage 
              handleAdd={() => handleOpenEventLandForm(item)}
              title={'Land Events List'}
              item={item}
            />
          </Box>

          {
            item.events && 
            item.events
              .slice()
              .sort((a,b) => new Date(b.date) < new Date(a.date) ? -1: 1 )
              .map((landEvent, ind) => {
              return (
                <CustomAccordion
                  key={landEvent._id}
                  expanded={expandedEvent === `panel1${ind}`}
                  onChange={handleChangeEvent(`panel1${ind}`)}
                  elevation={2}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <ShowSummary 
                        title={`${ind + 1} ${formatedDate(landEvent.date)} ${landEvent.type} ${landEvent.main_operation.type}`}
                        size={'small'} 
                      />
                      
                      <ShowSummaryActions 
                        item={landEvent} 
                        setSelected={setLandEventSelected} 
                        setConfirmOpen={setConfirmOpenLand}
                        size={'small'}
                      />
                      
                    {
                      landEventSelected
                      && landEvent._id === landEventSelected._id 
                      ? (
                        <ConfirmDialog
                          name="Delete event plot activity"
                          open={confirmOpenLand}
                          setOpen={setConfirmOpenLand}
                          onConfirm={() => handleDeletePlotEvent(item, landEvent)}
                          title={`${formatedDate(landEvent?.date)} ${landEvent?.type}`}
                          subtitle={landEvent?.main_operation?.type}
                        />
                      ) 
                      : null
                    }
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} flexDirection={"column"} sx={{ fontSize: "12px" }}>
                      <Grid item>
                        <ShowEvent landEvent={landEvent} />
                      </Grid>

                      {
                        landEvent.main_operation
                        ? (
                        <Grid item>
                          <ShowMainOperation landEvent={landEvent} />
                        </Grid>
                        )
                        : null
                      }

                      {
                        landEvent.transports && landEvent.transports.length > 0 
                        ? (
                          <Grid item>
                            <Transports rows={landEvent.transports}/>
                          </Grid>
                        )
                        : null
                      }

                      {
                        landEvent.applications && landEvent.applications.length > 0 
                        ? (
                          <Grid item>
                            <Applications rows={landEvent.applications}/>
                          </Grid>
                        )
                        : null
                      }

                    </Grid>

                    <Grid item sx={{ mt: 4 }}>
                      <ButtonEdit item={landEvent} handleEdit={handleEditEvent} params={item} />
                    </Grid>

                  </AccordionDetails>
                </CustomAccordion>
              );
            })
          }
        </Box>

      </Box>
    </Box>
  )
}

const mapStateToProps = (state) => {
  return {
    
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteLandEvent: (params) => dispatch(deleteLandEvent(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowLandEvents);
