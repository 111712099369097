import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { connect } from "react-redux";
import { Form, Formik } from "formik";
import validationSchema from "./ImplementValidationSchema";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import {
  fetchEnumerationImplementsSStype,
  fetchEnumerationImplementsStype,
  fetchEnumerationImplementsType,
} from "../../redux/enumeratedSlice";
import { addImplement, editImplements } from "../../redux/userAllSlice";
import PopoverCustom from "../layout/PopoverCustom";
import { numericField, numericOnly } from "../../hooks/functions";
import CloseIcon from "@mui/icons-material/CancelRounded";
import Loading from '../layout/Loading';

export const ImplementForm = (props) => {
  const {
    openImplementForm,
    setOpenImplementForm,
    user_id,
    mode,
    fetchEnumerationImplementsType,
    implementsType,
    fetchEnumerationImplementsStype,
    implementsStype,
    fetchEnumerationImplementsSStype,
    implementsSStype,
    addImplement,
    implementSelected,
    editImplements,
    property,
    origin,
    setImplementSelected,
    setImplementTransportSelected
    
  } = props;

  const [stypeSelected, setStypeSelected] = useState("");
  const [loading, setLoading] = useState(true)

  async function init(){
    setLoading(true)
    setTimeout(async () => {
      await numericField()
      setLoading(false)
    }, 2000);
  }

  // useEffect(()=> {
  //   init()
  // }, [])

  useLayoutEffect(()=> {
    init()
  }, [])


  useEffect(() => {
    fetchEnumerationImplementsType();
  }, [fetchEnumerationImplementsType]);

  useEffect(() => {
    fetchEnumerationImplementsStype();
  }, [fetchEnumerationImplementsStype]);

  useEffect(() => {
    if(stypeSelected){
      fetchEnumerationImplementsSStype(stypeSelected);
    }
  }, [fetchEnumerationImplementsSStype, stypeSelected]);

  const initialValues = {
    user_id: user_id,
    type: implementSelected.type || "",
    implement_name: implementSelected.implement_name || "",
    specific_type: implementSelected.specific_type || null,
    specific_subtype: implementSelected.specific_subtype || null,
    weight: implementSelected.weight || "",
    lifespan: implementSelected.lifespan || "",
    tset_lifespan: implementSelected.tset_lifespan || "",
    tset_weight: implementSelected.tset_weight || "",
    tset_rubbed_off: implementSelected.tset_rubbed_off || "",
  };

  const handleClose = () => {
    setOpenImplementForm(false);
  };

  const handleSubmit = async (values) => {

    let params = {};

    if (values.type === "generic") {
      params = {
        user_id: user_id,
        prop_id: property._id,
        payload: {
          type: values.type,
          implement_name: values.implement_name,
          specific_type: values.specific_type,
          specific_subtype: values.specific_subtype,
          weight: values.weight === "" ? null : values.weight,
          lifespan: values.lifespan === "" ? null : values.lifespan,
          tset_lifespan: values.tset_lifespan === "" ? null : values.tset_lifespan,
          tset_weight: values.tset_weight === "" ? null : values.tset_weight,
          tset_rubbed_off: values.tset_rubbed_off === "" ? null : values.tset_rubbed_off,
        },
      };
    }

    if (mode === "add") {
      const result = await addImplement(params);

      if(origin === "addEvent" || origin === "editEvent"){
        setImplementSelected(result.payload)
      } else if(origin === "addEventTransport"){
        setImplementTransportSelected(result.payload)
      }

      setStypeSelected("");
    }

    if (mode === "edit") {
      const paramsEdit = {
        impl_id: implementSelected._id,
        prop_id: property._id,
        payload: params.payload,
      };
      await editImplements(paramsEdit);
      setStypeSelected("");
    }
    handleClose();
  };

  useEffect(() => {
    setTimeout(() => {
      const dialog = document.querySelector('.dialog')
      if(dialog){
        dialog.scrollIntoView({behavior: 'smooth', block: 'start'})
      }
      setLoading(false)
      }, 1000);
  }, [])

  // async function init(){
  //   setTimeout(() => {
  //     numericField()
  //   }, 1000);
  // }

  // useEffect(()=> {
  //   init()
  // }, [])

  return (
    <div>
      <Dialog open={openImplementForm} onClose={handleClose} maxWidth="md">
        <DialogTitle
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <strong>{mode === "add" ? "Add Implement" : "Edit Implement"}</strong>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: "absolute", right: 8, top: 8, color: "green",}}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <Typography variant="body2" sx={{ ml: 3, mt: 1, color: "red", fontWeight: "bold" }}>
          * Required Fields
        </Typography>

        { loading ? (<Loading />) : null}

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => { handleSubmit(values); }}
        >
          {({ values, touched, errors, handleChange, handleBlur, isvalid, setFieldValue, resetForm,}) => {
            return (
              <Form noValidate autoComplete="off">
                <DialogContent>
                  <Grid container spacing={2}>

                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <TextField
                        disabled={mode === "edit"}
                        required
                        fullWidth
                        size="small"
                        name="type"
                        select={mode === "add"}
                        label="Type"
                        value={values.type}
                        onChange={handleChange}
                        error={touched.type && Boolean(errors.type)}
                        helperText={touched.type && errors.type}
                      >
                        {
                          mode === "add" && implementsType.registros && implementsType.registros.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))
                        }
                      </TextField>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <TextField
                        required
                        fullWidth
                        size="small"
                        name="implement_name"
                        label="Name"
                        value={values.implement_name}
                        onChange={handleChange}
                        error={ touched.implement_name && Boolean(errors.implement_name) }
                        helperText={ touched.implement_name && errors.implement_name }
                      />
                    </Grid>

                    {
                      mode === "add" 
                      ? (
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Autocomplete
                            disabled={mode === "edit"}
                            required
                            size="small"
                            name="specific_type"
                            options={implementsStype.registros}
                            onChange={(e, value) => {
                              setFieldValue("specific_subtype", null);
                              setFieldValue("specific_type", value || "");
                              setStypeSelected(value);
                            }}
                            value={values.specific_type}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                label="Specific Type"
                                error={ touched.specific_type && Boolean(errors.specific_type)}
                                helperText={ touched.specific_type && errors.specific_type }
                              />
                            )}
                          />
                        </Grid>
                      ) : (
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <TextField
                            required
                            disabled
                            fullWidth
                            size="small"
                            name="specific_type"
                            label="Specific Type"
                            value={values.specific_type}
                          />
                        </Grid>
                      )
                    }

                    {
                      mode === "add" 
                      ? (
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Autocomplete
                            disabled={ !Boolean(values.specific_type) || mode === "edit" }
                            required
                            size="small"
                            id="combo-box-demo"
                            name="specific_subtype"
                            options={implementsSStype.registros}
                            onChange={(e, value) => setFieldValue("specific_subtype", value || "") }
                            value={values.specific_subtype}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                label="Specific Subtype"
                                error={ touched.specific_subtype && Boolean(errors.specific_subtype)}
                                helperText={touched.specific_subtype && errors.specific_subtype}
                              />
                            )}
                          />
                        </Grid>
                      ) : (
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <TextField
                            required
                            disabled
                            fullWidth
                            size="small"
                            name="specific_subtype"
                            label="Specific Subtype"
                            value={values.specific_type}
                          />
                        </Grid>
                      )
                    }

                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel size="small" htmlFor="lifespan">
                          Lifespan
                        </InputLabel>
                        <OutlinedInput
                          fullWidth
                          size="small"
                          name="lifespan"
                          id="lifespan"
                          label="Lifespan"
                          value={values.lifespan}
                          onChange={handleChange}
                          onKeyDown={numericOnly}
                          error={touched.lifespan && Boolean(errors.lifespan)}
                          inputProps={{ step: 0.01, min: 0 }}
                          type="number"
                          endAdornment={
                            <InputAdornment position="end">
                              <Box mr={1} sx={{ whiteSpace: "normal", lineHeight: "1.0", }}>
                                [h]
                              </Box>
                              <PopoverCustom
                                info={"Lifespan of the implement"}
                              />
                            </InputAdornment>}
                        />
                        {
                          touched.lifespan && errors.lifespan && (
                            <FormHelperText error>
                              {errors.lifespan}
                            </FormHelperText>
                          )
                        }
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel size="small" htmlFor="tset_lifespan">
                          Tireset Lifespan
                        </InputLabel>
                        <OutlinedInput
                          fullWidth
                          size="small"
                          name="tset_lifespan"
                          id="tset_lifespan"
                          label="Tireset Lifespan"
                          value={ values.tset_lifespan }
                          onChange={handleChange}
                          onKeyDown={numericOnly}
                          error={ touched.tset_lifespan && Boolean(errors.tset_lifespan)}
                          inputProps={{ step: 0.01, min: 0 }}
                          type="number"
                          endAdornment={
                            <InputAdornment position="end">
                              <Box mr={1} sx={{ whiteSpace: "normal", lineHeight: "1.0", }}>
                                [h]
                              </Box>
                              <PopoverCustom
                                info={"Lifespan of the tireset"}
                              />
                            </InputAdornment>}
                        />
                        {
                          touched.tset_lifespan && errors.tset_lifespan && (
                            <FormHelperText error>
                              {errors.tset_lifespan}
                            </FormHelperText>
                          )
                        }
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel size="small" htmlFor="tset_weight">
                          Tireset Weight
                        </InputLabel>
                        <OutlinedInput
                          fullWidth
                          size="small"
                          name="tset_weight"
                          id="tset_weight"
                          label="Tireset Weight"
                          value={values.tset_weight}
                          onChange={handleChange}
                          onKeyDown={numericOnly}
                          error={touched.tset_weight && Boolean(errors.tset_weight)}
                          inputProps={{ step: 0.01, min: 0, max: 1 }}
                          type="number"
                          endAdornment={
                            <InputAdornment position="end">
                              <Box mr={1} sx={{ whiteSpace: "pre-wrap", lineHeight: "1.0", width:'85%', m:0 }}>
                                [kg tireset/ kg implement]
                              </Box>
                              <PopoverCustom
                                sx={{width:'10%'}}
                                info={"Relative weight of the tireset compared to the implement"}
                              />
                            </InputAdornment>
                          }
                        />
                        {
                          touched.tset_weight && errors.tset_weight && (
                            <FormHelperText error>
                              {errors.tset_weight}
                            </FormHelperText>
                          )
                        }
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel size="small" htmlFor="tset_rubbed_off">
                          Rubber Removed
                        </InputLabel>
                        <OutlinedInput
                          fullWidth
                          size="small"
                          name="tset_rubbed_off"
                          id="tset_rubbed_off"
                          label="Rubber Removed"
                          value={values.tset_rubbed_off}
                          onChange={handleChange}
                          onKeyDown={numericOnly}
                          error={touched.tset_rubbed_off && Boolean(errors.tset_rubbed_off)}
                          inputProps={{ step: 0.01, min: 0, max: 1 }}
                          type="number"
                          endAdornment={
                            <InputAdornment position="end">
                              <Box mr={1} sx={{ whiteSpace: "normal", lineHeight: "1.0" }}>
                                [kg rubber / kg tireset]
                              </Box>
                              <PopoverCustom info={"Rubber removed per tireset"}/>
                            </InputAdornment>
                          }
                        />
                        {
                          touched.tset_rubbed_off && errors.tset_rubbed_off && (
                            <FormHelperText error>
                              {errors.tset_rubbed_off}
                            </FormHelperText>
                          )
                        }
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel size="small" htmlFor="weight">
                          Weight
                        </InputLabel>
                        <OutlinedInput
                          fullWidth
                          size="small"
                          name="weight"
                          id="weight"
                          label="Weight"
                          value={values.weight}
                          onChange={handleChange}
                          onKeyDown={numericOnly}
                          error={touched.weight && Boolean(errors.weight)}
                          inputProps={{ step: 0.01, min: 0 }}
                          type="number"
                          endAdornment={
                            <InputAdornment position="end">
                              <Box>[kg]</Box>
                            </InputAdornment>
                          }
                        />
                        {
                          touched.weight && errors.weight && (
                            <FormHelperText error>{errors.weight}</FormHelperText>
                          )
                        }
                      </FormControl>
                    </Grid>

                  </Grid>

                </DialogContent>

                <Divider />

                <DialogActions>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    
                    <Button
                      size="small"
                      onClick={handleClose}
                      variant="outlined"
                      startIcon={<CancelRoundedIcon />}
                    >
                      Cancel
                    </Button>

                    <Button
                      size="small"
                      variant="contained"
                      startIcon={<SaveRoundedIcon />}
                      autoFocus
                      type="submit"
                    >
                      {mode === "add" ? "add" : "save"}
                    </Button>

                  </Box>
                </DialogActions>

              </Form>
            );
          }}
        </Formik>

      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    implementsType: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationImplementsType,
      error: state.enumerated.error,
    },
    implementsStype: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationImplementsStype,
      error: state.enumerated.error,
    },
    implementsSStype: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationImplementsSStype,
      error: state.enumerated.error,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEnumerationImplementsType: () => dispatch(fetchEnumerationImplementsType()),
    fetchEnumerationImplementsStype: () => dispatch(fetchEnumerationImplementsStype()),
    fetchEnumerationImplementsSStype: (stype) => dispatch(fetchEnumerationImplementsSStype(stype)),
    addImplement: (params) => dispatch(addImplement(params)),
    editImplements: (params) => dispatch(editImplements(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImplementForm);
