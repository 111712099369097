export const navItems = [
    {id: 1, title: 'Properties', route:'/home',externo: false},
    {id: 2, title: 'Machinery', route:'machinery',externo: false},
    {id: 3, title: 'Implements', route:'implements',externo: false},
    {id: 4, title: 'Agricultural Inputs', route:'products',externo: false},
    {id: 5, title: 'Results', route:'results',externo: false},
    {id: 6, title: 'SimaPro', route:'generateSimaPro',externo: false},
    {id: 7, title: 'Privacy Notice', route:'PrivacyNotice', externo: true, href: "https://www.cnpma.embrapa.br/privacidade/icvcalc/PrivacyNoticeICVCalcEmbrapa_EN.pdf"},
    {id: 8, title: 'Terms of Use', route:'TermsOfUse', externo: true, href: "https://www.cnpma.embrapa.br/privacidade/icvcalc/TermsUseICVCalcEmbrapa_EN.pdf"},
    {id: 9, title: 'Help', route:'help',externo: false},
    {id: 10, title: 'About', route:'about', externo: false},
]

