import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/CancelRounded";
import { Box, TextField, Typography } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { connect } from "react-redux";
import { isRegistered, saveNewPassword } from "../../redux/userSlice";
import { useEffect } from "react";
import { useState } from "react";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import Loading from '../layout/Loading';
import SnackbarCustom from '../layout/SnackbarCustom';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {
        onClose 
        ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            
          >
            <CloseIcon />
          </IconButton>
        ) 
        : null
      }
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};


export const ForgotPassword = (props) => {
  const { saveNewPassword, isRegistered, email } = props;

  const [open, setOpen] = useState(false);
  const [emailVerificado, setEmailVerificado] = React.useState(email || "");
  const [error, setError] = useState(false);
  const [isSaveNewPassword, setIsSaveNewPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msgError, setMsgError] = useState('');
  const [openError, setOpenError] = useState('')
  const [openSuccess, setOpenSuccess] = useState('')
  const [msgSuccess, setMsgSuccess] = useState('')

  useEffect(() => {
    setEmailVerificado(email || "");
  }, [email]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsSaveNewPassword(false);
    setEmailVerificado("");
    setError(false);
  };

  //// salvar a nova senha
  const handleSaveNewPassword = async () => {
    setLoading(true)
    setIsSaveNewPassword(false);

    const params = { email: emailVerificado || email };
    // Validar o e-mail
    const resultRegistered = params.email && ( await isRegistered(params.email) );
    
    if(resultRegistered.payload.error){
      setMsgError(resultRegistered.payload.message)
      setOpenError(true)
      handleClose()
      setLoading(false)
      return
    }

    if(resultRegistered.payload.result.isRegistered){

      const resultNewPassword = await saveNewPassword(params);

      if(resultNewPassword.payload.result.isSendMail){
        setMsgSuccess(`New password created successfully. The password has been sent to the email ${params.email} `)
        setOpenSuccess(true)
      } else {
        setMsgError('The new password could not be sent to the email. Please try again later or contact your system administrator.')
        setOpenError(true)
      }
    } 

    handleClose()
    setLoading(false)
  };

  //// enviar e-mail com a nova senha
  const handleChangeEmail = (event) => {
    setEmailVerificado(event.target.value);
    setError(false);
  };

  return (
    <div>
      <Button
        fullWidth
        variant="text"
        size="small"
        sx={{ minWidth: "150px", textTransform: "none" }}
        onClick={handleClickOpen}
        tabIndex={-1}
      >
        Forgot Password?
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <Box component="form" noValidate autoComplete="off">
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
            className="title"
          >
            Forgot Password?
          </BootstrapDialogTitle>

          <DialogContent dividers>
            {
              !isSaveNewPassword 
              ? (
                <Typography gutterBottom>
                  We will send a new password to the registered e-mail.
                </Typography>
              ) 
              : null
            }
            {
              !isSaveNewPassword 
              ? (
                <Box display={"flex"} flexDirection={"column"} minWidth={250} sx={{ px: 2 }}>
                  <TextField
                    required
                    fullWidth
                    label="E-mail"
                    value={emailVerificado || email || ""}
                    onChange={(event) => handleChangeEmail(event)}
                    error={error}
                    helperText={error}
                    sx={{ mt: 3, mb: 3 }}
                  />
                </Box>
              ) 
              : null
            }

            {
              loading 
              ? (
                <Loading />
              ) 
              : null
            }
          </DialogContent>

          <DialogActions>
            {
              isSaveNewPassword 
              ? (
                <Button
                  size="small"
                  onClick={handleClose}
                  variant="outlined"
                  startIcon={<CancelRoundedIcon />}
                >
                  Close
                </Button>
              ) 
              : (
                <Button
                  //disabled={!!error}
                  size="small"
                  variant="contained"
                  autoFocus
                  onClick={() => handleSaveNewPassword()}
                  startIcon={<SendIcon />}
                >
                  Send
                </Button>
              )
            }
          </DialogActions>
        </Box>
      </BootstrapDialog>
      {
        openError
        ? (
          <SnackbarCustom
            open={openError}
            setOpen={setOpenError}
            msg={msgError}
            severity='error'
          />
        )
        : null
      }
      {
        openSuccess
        ? (
          <SnackbarCustom
            open={openSuccess}
            setOpen={setOpenSuccess}
            msg={msgSuccess}
            severity='success'
          />
        )
        : null
      }
    </div>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  saveNewPassword: (params) => dispatch(saveNewPassword(params)),
  isRegistered: (email) => dispatch(isRegistered(email)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
