import { Box } from '@mui/material'
import React from 'react'

function ContainerPage(props) {

  const {children} = props

  return (
    <Box sx={{ width: { xs: 340, sm: 500, md: 800 } }}>
      {children}
    </Box>
  )
}

export default ContainerPage
