import { Box, Typography } from '@mui/material'
import React from 'react'
import ShowDataContainer from '../../layout/ShowDataContainer'

function ShowEvent(props) {

  const {landEvent} = props

  return (
    <ShowDataContainer>                            
      <Typography variant="subtitle2" sx={{width: "100%", flexShrink: 0, fontWeight: 600,}}>
        Event
      </Typography>

      {landEvent.type ? (<Box>Type: {landEvent.type}</Box>) : null}
      {landEvent.date ? (<Box>Date: {landEvent.date}</Box>) : null}
      {landEvent.benefit_time ? (<Box>Application Benefit Time:{" "}{landEvent.benefit_time}</Box>) : null}
      {landEvent.area_burnt ? (<Box>Burnt Area:{" "}{landEvent.area_burnt}</Box>) : null}
      {landEvent.comb_fuel ? (<Box>Biomass Fuel Burnt:{" "}{landEvent.comb_fuel}</Box>) : null}
      {landEvent.frequency ? (<Box>Frequency:{" "}{landEvent.frequency}</Box>) : null}
    </ShowDataContainer>
  )
}

export default ShowEvent
