import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, tableCellClasses } from '@mui/material';
import React from 'react'
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    fontSize: 12,
    lineHeight: 1.2,
    padding: "12px",
    opacity: 1,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    lineHeight: 1.2,
    padding: "6px 12px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    //border: 0,
  },
  "&:MuiTableCell-root": {
    lineHeight: 1.0,
  },
}));


const ShowGIS = (props) => {

  const {GIS} = props

  return (
    GIS && GIS.length ? (
      <>
        <TableContainer
          component={Paper}
          sx={{
            "& .MuiTableCell-root": {
              border: 0,
              px: 2,
              py: 0.5,
              fontSize: "0.8rem",
            },
          }}
        >
          <Table size="small">
            <TableHead>
              <StyledTableRow
                sx={{ borderBottom: "1px solid white" }}
              >
                <StyledTableCell
                  colSpan={3}
                  align="center"
                >
                  GIS
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell>#</StyledTableCell>
                <StyledTableCell align="right">
                  Latitude
                </StyledTableCell>
                <StyledTableCell align="right">
                  Longitude
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody sx={{ fontSize: 11 }}>
              {GIS.length > 0 &&
                GIS.map((el, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell>
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {el[0]}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {el[1]}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    ) : null
  )
}

export default ShowGIS
