import { Box } from '@mui/material'
import React from 'react'

function ShowDataContainer(props) {

  const {children} = props

  return (
    <Box 
      sx={{
        border: "1px solid green", 
        borderLeft: "5px solid green", 
        borderRadius: 2, p: 2, mb: 2,
      }}
    >
      {children}
    </Box>
  )
}

export default ShowDataContainer
