import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import validationSchema from "./plotEventValidationSchema";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import PopoverCustom from "../../layout/PopoverCustom";
import {
  fetchEnumerationEventOperationType,
  fetchEnumerationEventTypeCrop,
  fetchEnumerationNameOperation,
  fetchEnumerationEventApplicationType,
  fetchEnumerationEventApplicationUnit,
  fetchEnumeracionEventOperationMachinery,
  fetchEnumerationEventOperationMachineryImplements,
  fetchEnumerationEventOperationEcoinvet,
  fetchEnumerationEventMainOperationProducts,
  fetchEnumerationEventGenericHasTransport,
  fetchEnumerationEventGenericTransportType,
  fetchEnumerationEventGenericTransportMachinery,
  fetchEnumerationEventGenericTransportImplements,
  resetEnumerationEventOperationMachineryImplements,
  resetEnumerationEventGenericTransportImplements,
  checkEventGenericHasMachinery,
  checkEventTransportHasMachinery,
  fetchEnumerationPesticideFoliarInterception,
  fetchEnumerationPesticideApplicationMethods,
} from "../../../redux/enumeratedSlice";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import ProductsForm from "../../products/ProductsForm";
import MachineryForm from "../../machinery/MachineryForm";
import ImplementForm from "../../implements/ImplementForm";
import {
  addPlotEvent,
  checkEventDateIsNotConflicted,
  editPlotEvent,
  fetchUserAll,
} from "../../../redux/userAllSlice";
import { numericField, numericOnly } from "../../../hooks/functions";
import CloseIcon from "@mui/icons-material/CancelRounded";
import dayjs from 'dayjs';

export const PlotEventForm = (props) => {

  const formikRef = useRef();

  const {
    userAll,
    cropName,
    type_,
    openPlotEventForm,
    setOpenPlotEventForm,
    fetchEnumerationEventOperationType,
    eventOperationType,
    plot_terrain,
    fetchEnumerationEventTypeCrop,
    eventTypeCrop,
    fetchEnumerationNameOperation,
    nameOperation,
    fetchEnumerationEventApplicationType,
    fetchEnumerationEventApplicationUnit,
    fetchEnumeracionEventOperationMachinery,
    eventOperationMachinery,
    fetchEnumerationEventGenericTransportImplements,
    fetchEnumerationEventOperationMachineryImplements,
    eventOperationMachineryImplements,
    fetchEnumerationEventOperationEcoinvet,
    eventOperationEcoinvent,
    fetchEnumerationEventMainOperationProducts,
    plot,
    addPlotEvent,
    fetchEnumerationEventGenericHasTransport,
    eventGenericHasTransport,
    fetchEnumerationEventGenericTransportType,
    eventGenericTransportType,
    fetchEnumerationEventGenericTransportMachinery,
    eventGenericTransportMachinery,
    resetEnumerationEventOperationMachineryImplements,
    resetEnumerationEventGenericTransportImplements,
    fetchUserAll,
    plotEventSelected,
    modeEvent,
    checkEventGenericHasMachinery,
    checkEventTransportHasMachinery,
    fetchEnumerationPesticideFoliarInterception,
    fetchEnumerationPesticideApplicationMethods,
    checkEventDateIsNotConflicted,
    //eventMainOperationProducts
  } = props;

  const [eventType, setEventType] = useState("");
  const [openProductForm, setOpenProductForm] = useState(false);
  const [openMachineryForm, setOpenMachineryForm] = useState(false);
  const [openImplementForm, setOpenImplementForm] = useState(false);
  const [productSelected, setProductSelected] = useState("");
  const [operationNameSelected, setOperationNameSelected] = useState("");
  const [operationMachineryTypeSelected, setOperationMachineryTypeSelected] = useState("");
  const [ecoinventAmountUnit, setEcoinventAmountUnit] = useState("");
  const [listTypeApplicationIncluded, setListTypeApplicationIncluded] = useState([]);
  const [hasMachinery, setHasMachinery] = useState(false);
  const [loadingEnum, setLoadingEnum] = useState(false);
  const [typeApplicationAdd, setTypeApplicationAdd] = useState("");
  const [errorAdd, setErrorAdd] = useState("");
  const [indexAddApplication, setIndexAddApplication] = useState(0);
  const [startDate, setStarDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [origin, setOrigin] = useState('');
  const [machinerySelected, setMachinerySelected] = useState({})
  const [machineryTransportSelected, setMachineryTransportSelected] = useState({})
  const [transportNameSelected, setTransportNameSelected] = useState("")
  const [machineryTransportList, setMachineryTransportList] = useState([])
  const [indexAddMachinery, setIndexAddMachinery] = useState(0)
  const [update, setUpdate] = useState(0)
  const [implementsTransportList, setImplementsTransportList] = useState([])
  const [implementSelected, setImplementSelected] = useState({})
  const [transportsImplementsSelected, setTransportsImplementsSelected] = useState([])
  
  const user = JSON.parse(localStorage.getItem("user"));
  const property = JSON.parse(localStorage.getItem("property"));

  const initialValues = {
    type: "",
    date: "",
    operations: {
      type: "",
      name: "",
      ecoinvent: "",
      amount: "",
      machinery: {
        machinery_id: "",
        implements: [],
      },
      machineryList: [],
      hasMachinery: "",
    },
    benefit_time: "",
    applications: [
      {
        type: "",
        typeList: [],
        product: "",
        productsList: [],
        amount: "",
        amount_unit: "",
        unitList: [],
        foliar_interception: "",
        foliarInterceptionList: [],
        application_method: "",
        applicationMethodList: [],
        fi_amount: "",
      },
    ],
    area_burnt: "",
    comb_fuel: "",
    water_cons: "",
    frequency: "",
  };

  useEffect(()=>{
    const getMachineryList = async () => {

      setMachineryTransportList([])

      if( eventType !== "" && operationNameSelected !== "") {
        const params = {
          user_id: user.user_icv_id,
          prop_id: property._id,
          event_type: eventType,
          op_name: operationNameSelected,
        };
        await fetchEnumeracionEventOperationMachinery(params);
      }
    }
    getMachineryList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machinerySelected])

  useEffect(()=>{
    const valuesFormik = formikRef.current
    if (operationNameSelected !== "" && transportNameSelected !== "") {
      const getMachineryTransportList = async () => {
        const params1 = {
          user_id: user.user_icv_id,
          prop_id: property._id,
          op_name: operationNameSelected,
          t_name: transportNameSelected,
        };
        const result = await fetchEnumerationEventGenericTransportMachinery(params1);
        let atualList = machineryTransportList
        let newItem = { index:indexAddMachinery, data: result.payload }
        atualList.splice(indexAddMachinery, 1, newItem)
        await setMachineryTransportList(atualList)
        valuesFormik.setFieldValue(`transports[${indexAddMachinery}].machineryList`, newItem)
        setUpdate(update + 1)
      }
      getMachineryTransportList()
      setUpdate(update + 1)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machineryTransportSelected])

  useEffect(()=>{
    if(!!typeApplicationAdd){
      const getProdutcs = async () => {
        const params = {
          user_id: user.user_icv_id,
          type_: typeApplicationAdd,
          prop_id: property._id,
        };
        const result = await fetchEnumerationEventMainOperationProducts(params);
        
        if (formikRef.current) {
          formikRef.current.setFieldValue(
            `applications[${indexAddApplication}].productsList`,
            result.payload
          );
        }

      }
      getProdutcs()
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productSelected])

  useEffect(() => {
    if(!!operationMachineryTypeSelected && !!operationNameSelected){
      const getImplements = async () => {
        const params = {
          user_id: user.user_icv_id,
          prop_id: property._id,
          mach_spectype: operationMachineryTypeSelected,
          op_name: operationNameSelected,
        };
          await fetchEnumerationEventOperationMachineryImplements(params);
      }
      getImplements()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operationMachineryTypeSelected, implementSelected]);

  useEffect(()=>{
    var a = dayjs(plot.start_date)
    const period = plot.crop.planting_period
    var b = a.add(period, 'day')
    setStarDate(dayjs(a).format('YYYY-MM-DD'))
    setEndDate(dayjs(b).format('YYYY-MM-DD'))
  },[plot])

  const handleOpenProductForm = async (setFieldValue, values, index) => {
    setFieldValue(`applications[${index}].product`, "");
    setTypeApplicationAdd(values.applications[index].type);
    setOrigin('addEvent')
    await setOpenProductForm(true);
  };

  const handleOpenMachineryForm = async (setFieldValue, values) => {
    setOrigin("addEvent")
    await setOpenMachineryForm(true);
  };

  const handleOpenImplementForm = async (setFieldValue, values) => {
    setOrigin('addEvent')
    await setOpenImplementForm(true);
  };

  const handleOpenMachineryFormTransport = async (setFieldValue, values, index) => {
    setOrigin("addEventTransport")
    setIndexAddMachinery(index)
    await setOpenMachineryForm(true);
  };

  const handleOpenImplementFormTransport = async (setFieldValue, values, index) => {
    setOrigin('addEventTransport')
    await setOpenImplementForm(true);
  };

  useEffect(() => {
    const getApplicationTypes = async () => {
      const params = {
        op_name: operationNameSelected,
        payload: { prod_list: listTypeApplicationIncluded === "" ? [] : listTypeApplicationIncluded, },
      };
      if (operationNameSelected) {
        await fetchEnumerationEventApplicationType(params);
      }
    };
    getApplicationTypes();
  }, [fetchEnumerationEventApplicationType, listTypeApplicationIncluded, operationNameSelected,]);

  useEffect(() => {
    const getEventType =  async () => {
      const params = {
        crop: cropName,
        p_type: "plot",
      };
      
      if (cropName) {
        await fetchEnumerationEventTypeCrop(params);
      }
    }
    getEventType()
  }, [cropName, fetchEnumerationEventTypeCrop]);

  useEffect(() => {
    eventType && fetchEnumerationEventOperationType(eventType);
  }, [eventType, fetchEnumerationEventOperationType, modeEvent]);

  var eventOperationTypeSort = eventOperationType.registros.map((el) => {return el;}).sort((a, b) => (a > b ? 1 : -1));

  useEffect(() => {
    if(productSelected._id){
      fetchEnumerationEventApplicationUnit(productSelected._id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productSelected]);

  useEffect(() => {
    const getImplements = async () => {
      if (operationMachineryTypeSelected === 'detailed, generic' || operationMachineryTypeSelected === 'detailed, tractor'){
        const params = {
          user_id: user.user_icv_id,
          prop_id: property._id,
          mach_spectype: operationMachineryTypeSelected,
          op_name: operationNameSelected,
        };
        await fetchEnumerationEventOperationMachineryImplements(params);
      }
    };
    getImplements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operationMachineryTypeSelected]);

  var eventTypeCropSort = eventTypeCrop.registros.map((el) => {return el;}).sort((a, b) => (a > b ? 1 : -1));

  const handleClose = () => {
    setEventType("");
    setOpenPlotEventForm(false);
    resetEnumerationEventOperationMachineryImplements();
    resetEnumerationEventGenericTransportImplements();
  };

  const handleChangeEventType = async (event, setFieldValue, values, resetForm) => {
    await init()
    resetForm();
    setEventType(event.target.value);
    setFieldValue("type", event.target.value);
  };

  const handleChangeImplements = (event, setFieldValue, value) => {
    const impl = value.map((el) => el);
    setFieldValue("operations.machinery.implements", impl);
  };

  const handleChangeTransportsImplements = (event, setFieldValue, value, index) => {
    const impl = value.map((el) => el);
    setFieldValue(`transports[${index}].machinery.implements`, impl);
    let newItem = transportsImplementsSelected
    newItem[index]= impl
    setTransportsImplementsSelected(newItem)
  };

  useEffect(()=>{
    //----------------------------------------------------------
    // Atualizar lista de implementos dentro de values do Formik
    //----------------------------------------------------------
    if(transportsImplementsSelected){
      atualizarImplementos()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[transportsImplementsSelected])


  const atualizarImplementos = async () => {
    
    if(typeof formikRef.current !== "undefined"){
      const valuesFormik = formikRef.current.values
      const op_name = valuesFormik.operations.name
      
      valuesFormik.transports.map((el, index) => {
        //-------------------------------------------------
        // Obter o tipo (specific_type) do maquinário
        // para que seja possível pesquisar os implementos
        //-------------------------------------------------
        const mach_id = el.machinery._id
        const mach_sel = el.machineryList.length > 0 ? el.machineryList.filter((m) => m._id === mach_id) : []
        //-------------------------------------------------
        // Montar o objeto contendo o body da requisição
        //-------------------------------------------------
        const params = {
          user_id: user.user_icv_id,
          prop_id: property._id,
          op_name,
          t_name: el.name,
          mach_spectype: mach_sel[0]?.specific_type || null,
          mach_type: mach_sel[0]?.type
        }
        fetchImplements(params, index, el)
        return true
      })
    }
  }

  const fetchImplements = async (params, index, el) => {
    //const valuesFormik = formikRef.current
    
    let listAtual = implementsTransportList
    let implementsList = 
      await fetchEnumerationEventGenericTransportImplements(params)
        .then(res => res.payload);

    const reg = {
      index,
      type: 'generic',
      name: el.name,
      machinery_id: el.machinery._id,
      machinery_specific_type: params.mach_spectype,
      machinery_type: params.mach_type,
      implementsList
    }
    
    listAtual.splice(index, 1, reg)

    setImplementsTransportList(listAtual)
    setUpdate(update + 1)
  }

  const handleChangeApplicationType = async ( values, event, setFieldValue, index) => {
    setFieldValue(`applications[${index}].type`, event.target.value);
    setFieldValue(`applications[${index}].amount_unit`, "");
    const params = {
      user_id: user.user_icv_id,
      prop_id: property._id,
      type_: event.target.value,
    };
    const result = await fetchEnumerationEventMainOperationProducts(params);
    await setFieldValue(`applications[${index}].productsList`, result.payload);
    
    // Pesticide Foliar interception
    if (event.target.value === "pesticide") {
      const params = {
        crop: cropName,
      };
      const resultFoliar = await fetchEnumerationPesticideFoliarInterception(params);
      setFieldValue(`applications[${index}].foliarInterceptionList`, resultFoliar.payload);

      const resultMethods = await fetchEnumerationPesticideApplicationMethods(params);
      setFieldValue(`applications[${index}].applicationMethodList`, resultMethods.payload);
    }
  };

  const handleChangeApplicationsProduct = async (event, index, setFieldValue ) => {
    setFieldValue(`applications[${index}].product`, event.target.value);
    setProductSelected(event.target.value);
    setFieldValue(`applications[${index}].amount_unit`, "");
    const result = await fetchEnumerationEventApplicationUnit(event.target.value);
    setFieldValue(`applications[${index}].unitList`, result.payload);
    setUpdate(update + 1)
  };

  const clearChangeParamsOperations = (values) => {
    values.operations.amount = "";
    values.operations.name = "";
    values.operations.ecoinvent = "";
    values.applications = [
      {
        type: "",
        typeList: [],
        product: "",
        productsList: [],
        amount: "",
        amount_unit: "",
        unitList: [],
        foliar_interception: "",
        foliarInterceptionList: [],
        fi_amount: "",
        application_method: "",
        applicationMethodList: [],
      },
    ];
    values.transports = [
      {
        type: "generic",
        name: "",
        machinery: {
          _id: "",
          implements: [],
        },
      },
    ];
  };

  const handleChangeOperationName = async (event, setFieldValue, values, setFieldError, value) => {
    setLoadingEnum(true);
    setOperationNameSelected("");
    setFieldValue("operations.name", value);
    setFieldValue("operations.machinery.machinery_id", "");
    setFieldValue("operations.applications", []);
    setFieldValue("transports", [])
    clearChangeParamsOperations(values);
    setOperationNameSelected(value);
    setListTypeApplicationIncluded(values.applications.map((ap) => ap.type));
    fetchEnumerationEventGenericHasTransport(value);
    fetchEnumerationEventGenericTransportType(value);
    resetEnumerationEventOperationMachineryImplements();
    resetEnumerationEventGenericTransportImplements();
    
    //// verificar se é exigido maquinário
    const result1 = await checkEventGenericHasMachinery(value)
    //setHasMachinery(false);
    setFieldValue('operations.hasMachinery', result1.payload)

    if (result1.payload) {
      setHasMachinery(result1.payload);
      const params = {
        user_id: user.user_icv_id,
        prop_id: property._id,
        event_type: eventType,
        op_name: value,
      };
      await fetchEnumeracionEventOperationMachinery(params);
    }

    if (value) {
      setFieldValue("applications", [
        {
          type: "",
          typeList: [],
          product: "",
          productsList: [],
          amount: "",
          amount_unit: "",
          foliar_interception: "",
          foliarInterceptionList: [],
          fi_amount: "",
          application_method: "",
          applicationMethodList: [],
        },
      ]);

      const params1 = {
        op_name: value,
        payload: {
          prod_list:
            listTypeApplicationIncluded === ""
              ? []
              : listTypeApplicationIncluded,
        },
      };
      const result2 = await fetchEnumerationEventApplicationType(params1);
      setFieldValue("applications[0].typeList", result2.payload);
    }

    setLoadingEnum(false);
  };

  const handleChangeOperationMachinery = async (event, setFieldValue, values) => {
    const machinery_id = event.target.value;

    await setFieldValue("operations.machinery.machinery_id", machinery_id);
    await setFieldValue("operations.machinery.implements", [])
    await resetEnumerationEventOperationMachineryImplements();

    const indexProp = userAll.registro.properties.findIndex((reg) => reg._id === property._id);
    const machinery = userAll.registro.properties[indexProp].prop_machinery.filter((el) => el._id === machinery_id);

    if (machinery[0]?.type === 'detailed, generic' || machinery[0]?.type === 'detailed, tractor'){
      setOperationMachineryTypeSelected(machinery[0]?.specific_type);
      const params = {
        user_id: user.user_icv_id,
        prop_id: property._id,
        mach_spectype: machinery[0]?.specific_type,
        op_name: operationNameSelected,
      };
      await fetchEnumerationEventOperationMachineryImplements(params);
    }
  };

  const handleChangeOperationType = async (values, event, setFieldValue) => {
    setOperationNameSelected("");
    setFieldValue("operations.type", event.target.value);
    clearChangeParamsOperations(values);
    resetEnumerationEventOperationMachineryImplements();
    resetEnumerationEventGenericTransportImplements();

    if (event.target.value === "from ecoinvent") {
      const params = {
        crop: cropName,
        event_type: values.type,
        p_type: "plot",
      };
      cropName && params.event_type && (await fetchEnumerationEventOperationEcoinvet(params));
    } else {
      const params = {
        crop: cropName,
        p_type: type_,
        event_type: eventType,
      };
      await fetchEnumerationNameOperation(params);
    }
  };

  const handleChangeOperationEcoinvent = async (event, setFieldValue, value) => {
    setOperationNameSelected(value);
    setFieldValue("operations.ecoinvent", value);
    const getUnit = value.split(",");
    setEcoinventAmountUnit(getUnit[getUnit.length - 1]);

    setFieldValue("applications", [
      {
        type: "",
        typeList: [],
        product: "",
        productsList: [],
        amount: "",
        amount_unit: "",
        foliar_interception: "",
        foliarInterceptionList: [],
        fi_amount: "",
        application_method: "",
        applicationMethodList: [],
      },
    ]);

    const params = {
      op_name: value,
      payload: {
        prod_list:
          listTypeApplicationIncluded === "" ? [] : listTypeApplicationIncluded,
      },
    };
    const result = await fetchEnumerationEventApplicationType(params);
    setFieldValue("applications[0].typeList", result.payload);
  };

  const handleAddItemApplication = async (values, setFieldValue, index) => {
    const params = {
      op_name: values.operations.name || values.operations.ecoinvent,
      payload: {
        prod_list: "",
      },
    };
    const result = await fetchEnumerationEventApplicationType(params);
    setFieldValue(`applications[${indexAddApplication}].typeList`, result.payload);

    // Pesticide Foliar interception
    setTimeout( async () => {
      if (values.applications.length > 0 && values.applications[index]?.type === "pesticide") {
        const params = {
          crop: cropName,
        };
        const resultFoliar = await fetchEnumerationPesticideFoliarInterception(params);
        setFieldValue(`applications[${index}].foliarInterceptionList`, resultFoliar.payload);
  
        const resultMethods = await fetchEnumerationPesticideApplicationMethods(params);
        setFieldValue(`applications[${index}].applicationMethodList`, resultMethods.payload);
      }
    }, 1000);
    setIndexAddApplication(indexAddApplication + 1)
  };

  const handleChangeTransportsName = async (event, index, setFieldValue, values, value) => {
    setLoadingEnum(true);
    setTransportNameSelected(value)
    setFieldValue(`transports[${index}].name`, value);
    setFieldValue(`transports[${index}].machinery`, {});
    setFieldValue(`transports[${index}].errorImplements`, '')

    ///// verificar se é exigido maquinário
    const params = {
      op_name: values.operations.name,
      t_name: value,
    };
    const result = await checkEventTransportHasMachinery(params);

    if (result.payload) {
      setFieldValue(`transports[${index}].hasMachineryTransports`, result.payload);

      const params1 = {
        user_id: user.user_icv_id,
        prop_id: property._id,
        op_name: values.operations.name,
        t_name: value,
      };
      const result1 = await fetchEnumerationEventGenericTransportMachinery(params1);

      if(!!result1.payload.detail){
        setFieldValue(`transports[${index}].errorMachinery`, result1.payload)
      } else {
        setFieldValue(`transports[${index}].machineryList`, result1.payload || [])
        
        const newItem = {index: index, data: result1.payload }
        let atual = machineryTransportList.slice()
        const newItems = [ ...atual, newItem ]
        setMachineryTransportList(newItems)
      }
    } else {
      setFieldValue(`transports[${index}].hasMachineryTransports`, false);
    }
    setLoadingEnum(false);
  };

  const handleChangeTransportsMachinery1 = async (event, setFieldValue, index, values) => {

    if(event.target.value !== ""){
      await setFieldValue(`transports[${index}].machinery._id`, event.target.value)
      let listAtual = implementsTransportList

      const machinery_specific_type = eventGenericTransportMachinery.registros.data[0].find(el => el._id === event.target.value).specific_type
      const machinery_type = eventGenericTransportMachinery.registros.data[0].find(el => el._id === event.target.value).type

      let implementsList = null
      if(machinery_specific_type){
        implementsList = await fetchEnumerationEventGenericTransportImplements(
          {
            user_id: user.user_icv_id,
            prop_id: property._id,
            op_name: values.operations.name,
            t_name: values.transports[index].name,
            mach_spectype: machinery_specific_type || null,
          }
        ).then(res => res.payload)
      } else {
        implementsList = []
      }

      const reg = {
        index,
        type: 'generic',
        name: values.transports[index].name,
        machinery_id: event.target.value,
        machinery_specific_type,
        machinery_type,
        implementsList
      }
      
      listAtual.splice(index, 1, reg)

      setImplementsTransportList(listAtual)
      setFieldValue(`transports[${index}].machinery.implements`, [])
      setUpdate(update + 1)
    }
  }

  const handleSubmit = async (values, resetForm) => {
    const ident = {
      user_id: user.user_icv_id,
      prop_id: property._id,
      plot_ter_id: plot_terrain._id,
      plot_id: plot._id,
      event_id: plotEventSelected ? plotEventSelected._id : "",
    };

    let operationMachineryImplements = values.operations.machinery.implements.map((el) => el._id);
    let operationsMachinery = [];

    if (values.operations.machinery.machinery_id !== "") {
      operationsMachinery = [
        {
          machinery_id: values.operations.machinery.machinery_id,
          implements: operationMachineryImplements,
        },
      ];
    }

    //// Transports
    let transportsArray = [];
    values.transports && 
      values.transports.map( (el) => {
      
      transportsArray.push({
        type: el.type,
        name: el.name,
        machinery: el.machinery._id
          ? [
              {
                machinery_id: el.machinery._id,
                implements: el.machinery.implements.map((item) => {
                  if(item._id) {
                    return item._id
                  }
                  return item
                }
                ),
              },
            ]
          : [],
      })
      return true
    });

    let params = {};
    let applicationsArray = [];

    values.applications.map((el, index) => el.type !== "" &&
        applicationsArray.push({
          type: el.type,
          amount: el.amount,
          amount_unit: el.amount_unit,
          product: el.product,
          foliar_interception: el.foliar_interception === "" ? null : el.foliar_interception,
          fi_amount: el.fi_amount === "" ? null : el.fi_amount,
          application_method: el.application_method === "" ? null : el.application_method,
        })
    );

    if (values.type === "application" && values.operations.type === "from ecoinvent") {
      params = {
        ...ident,
        payload: {
          type: values.type,
          date: values.date,
          main_operation: {
            type: values.operations.type,
            ecoinvent: values.operations.ecoinvent,
            amount: values.operations.amount,
          },
          applications: applicationsArray,
        },
      };
    }

    if (values.type === "application" && values.operations.type === "generic") {
      if(transportsArray.length > 0){
        params = {
          ...ident,
          payload: {
            type: values.type,
            date: values.date,
            main_operation: {
              type: values.operations.type,
              name: values.operations.name,
              machinery: operationsMachinery,
            },
            transports: transportsArray, 
            applications: applicationsArray,
          },
        };
      } else {
        params = {
          ...ident,
          payload: {
            type: values.type,
            date: values.date,
            main_operation: {
              type: values.operations.type,
              name: values.operations.name,
              machinery: operationsMachinery,
            },
            applications: applicationsArray,
          },
        };
      }
    }

    if (values.type === "burning" && values.operations.type === "from ecoinvent") {
      params = {
        ...ident,
        payload: {
          type: values.type,
          date: values.date,
          area_burnt: values.area_burnt,
          comb_fuel: values.comb_fuel,
          main_operation: {
            type: values.operations.type,
            ecoinvent: values.operations.ecoinvent,
            amount: values.operations.amount,
          },
        },
      };
    }

    if (values.type === "burning" && values.operations.type === "generic") {
      params = {
        ...ident,
        payload: {
          type: values.type,
          date: values.date,
          area_burnt: values.area_burnt,
          comb_fuel: values.comb_fuel,
          main_operation: {
            type: values.operations.type,
            name: values.operations.name,
            machinery: operationsMachinery,
          },
        },
      };
    }

    if (values.type === "generic" && values.operations.type === "from ecoinvent") {
      params = {
        ...ident,
        payload: {
          type: values.type,
          date: values.date,
          main_operation: {
            type: values.operations.type,
            ecoinvent: values.operations.ecoinvent,
            amount: values.operations.amount,
          },
        },
      };
    }

    if (values.type === "generic" && values.operations.type === "generic") {
      params = {
        ...ident,
        payload: {
          type: values.type,
          date: values.date,
          main_operation: {
            type: values.operations.type,
            name: values.operations.name,
            machinery: operationsMachinery,
          },
          //transports: transportsArray,
        },
      };

      if (transportsArray.length > 0) {
        params.payload = { ...params.payload, transports: transportsArray };
      }
    }

    if (values.type === "irrigation, generic" &&  values.operations.type === "from ecoinvent") {
      params = {
        ...ident,
        payload: {
          type: values.type,
          date: values.date,
          main_operation: {
            type: values.operations.type,
            ecoinvent: values.operations.ecoinvent,
            amount: values.operations.amount,
          },
          applications: applicationsArray,
        },
      };
    }

    if (values.type === "irrigation, generic" && values.operations.type === "generic") {
      if(transportsArray.length > 0){
        params = {
          ...ident,
          payload: {
            type: values.type,
            date: values.date,
            main_operation: {
              type: values.operations.type,
              name: values.operations.name,
              machinery: operationsMachinery,
            },
            applications: applicationsArray,
            transports: transportsArray,
          },
        };
      } else {
        params = {
          ...ident,
          payload: {
            type: values.type,
            date: values.date,
            main_operation: {
              type: values.operations.type,
              name: values.operations.name,
              machinery: operationsMachinery,
            },
            applications: applicationsArray,
          },
        };
      }
    }

    if (values.type === "irrigation, periodic" && values.operations.type === "from ecoinvent") {
      params = {
        ...ident,
        payload: {
          type: values.type,
          date: values.date,
          frequency: parseInt(values.frequency),
          main_operation: {
            type: values.operations.type,
            ecoinvent: values.operations.ecoinvent,
            amount: values.operations.amount,
          },
          applications: applicationsArray,
        },
      };
    }

    if ( values.type === "irrigation, periodic" && values.operations.type === "generic") {
      if(transportsArray.length > 0){
        params = {
          ...ident,
          payload: {
            type: values.type,
            date: values.date,
            frequency: parseInt(values.frequency),
            main_operation: {
              type: values.operations.type,
              name: values.operations.name,
              machinery: operationsMachinery,
            },
            applications: applicationsArray,
            transports: transportsArray,
          },
        };
      } else {
        params = {
          ...ident,
          payload: {
            type: values.type,
            date: values.date,
            frequency: parseInt(values.frequency),
            main_operation: {
              type: values.operations.type,
              name: values.operations.name,
              machinery: operationsMachinery,
            },
            applications: applicationsArray,
          },
        };
      }
    }
    
    const result = await addPlotEvent(params);

    if (result.payload.error) {
      setErrorAdd(result.payload.message);
    } else {
      resetForm();
      fetchUserAll(params.user_id);
      handleClose();
    }
  };

  const handleChangeFoliarInterception = (e, index, setFieldValue) => {
    setFieldValue(`applications[${index}].foliar_interception`, e.target.value);
    setFieldValue(`applications[${index}].application_method`, "");
    setFieldValue(`applications[${index}].fi_amount`, "");
  };

  const handleChangeDate = async (e, setFieldValue, setFieldError) => {
    const ev_date = e.target.value;
    setFieldValue("date", ev_date);
    
    //// Verificar conflito de data
    const event_date = ev_date.substring(8) + "-" + ev_date.substring(5, 7) + "-" + ev_date.substring(0, 4);
    const params = {
      event_date: event_date,
      plot_id: plot._id,
    };

    const result = await checkEventDateIsNotConflicted(params);
    
    if (result.payload?.error) {
      setFieldError("date", result.payload.message.detail);
    }
  };

  async function init(){
    setTimeout(() => {
      numericField()
    }, 500);
  }

  useEffect(()=> {
    init()
  }, [eventType, eventOperationEcoinvent])

  return (
    <div>
      <Dialog open={openPlotEventForm} onClose={handleClose} maxWidth="md" scroll="body">
        
        <DialogTitle display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
          Add Plot Activity Event
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{position: "absolute", right: 8, top: 8, color: "green",}}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <Typography variant="body2" sx={{ ml: 3, mt: 1, color: "red", fontWeight: "bold" }}>
          * Required Fields
        </Typography>

        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => { handleSubmit(values, resetForm);}}
        >
          {({ values, touched, errors, handleChange, setFieldValue, setFieldError, resetForm, isValid,}) => {
            return (
              <Form noValidate autoComplete="off">
                {
                  userAll.loading || loadingEnum 
                  ? (
                    <Backdrop open={true} sx={{ color: "#fff",  zIndex: (theme) => theme.zIndex.drawer + 1,}} >
                      <CircularProgress color="secondary" />
                    </Backdrop>
                  ) 
                  : null
                }

                <DialogContent sx={{ minWidth: { lg: "900px", md: "800px", sm: "300px" } }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <TextField
                        required
                        fullWidth
                        size="small"
                        name="type"
                        select
                        label="Type"
                        value={values.type || eventType}
                        onChange={(e) => handleChangeEventType(e, setFieldValue, values, resetForm)}
                        error={touched?.type && Boolean(errors?.type)}
                        helperText={Boolean(touched?.type) && errors?.type}
                      >
                        {
                          eventTypeCropSort.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))
                        }
                      </TextField>
                    </Grid>
                    
                    {
                      values.type !== "" && (
                        <>
                          <Grid item xs={12} sm={6} md={4} lg={4}>
                            <TextField
                              required
                              fullWidth
                              size="small"
                              name="date"
                              label="Date"
                              type="date"
                              value={values.date || ""}
                              onChange={(e) => handleChangeDate(e, setFieldValue, setFieldError)}
                              InputLabelProps={{ shrink: true }}
                              error={Boolean(errors?.date)}
                              helperText={errors?.date}
                              inputProps={{
                                min: startDate, 
                                max: endDate 
                              }}
                            />
                          </Grid>

                          {
                            eventType === "burning" 
                            ? (
                              <>
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                  <FormControl fullWidth variant="outlined">
                                    <InputLabel size="small" htmlFor="area_burnt">Burnt Area</InputLabel>
                                    <OutlinedInput
                                      fullWidth
                                      size="small"
                                      name="area_burnt"
                                      id="area_burnt"
                                      label="Burnt Area"
                                      value={ values.area_burnt }
                                      onChange={handleChange}
                                      //onKeyDown={numericOnly}
                                      error={touched.area_burnt && Boolean(errors.area_burnt)}
                                      inputProps={{ step: 0.01, min: 0 }}
                                      type="number"
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <Box mr={1}>[ha]</Box>
                                          <PopoverCustom info={"Area in which the burning occurred"}/>
                                        </InputAdornment>
                                      }
                                    />
                                  </FormControl>

                                  {
                                    touched.area_burnt && errors.area_burnt && (
                                      <FormHelperText error>{errors.area_burnt}</FormHelperText>
                                    )
                                  }
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                  <FormControl fullWidth variant="outlined">
                                    <InputLabel size="small" htmlFor="comb_fuel">Biomass Fuel Burnt</InputLabel>
                                    <OutlinedInput
                                      fullWidth
                                      size="small"
                                      name="comb_fuel"
                                      id="comb_fuel"
                                      label="Biomass Fuel Burnt"
                                      value={ values.comb_fuel }
                                      onChange={handleChange}
                                      //onKeyDown={numericOnly}
                                      error={ touched.comb_fuel && Boolean(errors.comb_fuel)}
                                      inputProps={{ step: 0.01, min: 0 }}
                                      type="number"
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <Box mr={1}>[kg / ha]</Box>
                                        </InputAdornment>
                                      }
                                    />
                                  </FormControl>
                                  
                                  {
                                    touched.comb_fuel && errors.comb_fuel && (
                                      <FormHelperText error>
                                        {errors.comb_fuel}
                                      </FormHelperText>
                                    )
                                  }

                                </Grid>
                              </>
                            ) 
                            : null
                          }
                          {
                            eventType === "irrigation, periodic" 
                            ? (
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel size="small" htmlFor="frequency">Frequency of application</InputLabel>
                                  <OutlinedInput
                                    fullWidth
                                    size="small"
                                    name="frequency"
                                    id="frequency"
                                    label="Frequency of application"
                                    value={values.frequency }
                                    onChange={handleChange}
                                    // onKeyDown={numericOnly}
                                    error={touched.frequency && Boolean(errors.frequency)}
                                    inputProps={{ step: 1, min: 1 }}
                                    type="number"
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <Box sx={{ whiteSpace: "normal",lineHeight: "1.2",}}>
                                          [days]
                                        </Box>
                                      </InputAdornment>
                                    }
                                  />
                                </FormControl>
                                {
                                  touched.frequency && errors.frequency && (
                                    <FormHelperText error>
                                      {errors.frequency}
                                    </FormHelperText>
                                  )
                                }
                              </Grid>
                            ) 
                            : null
                          }

                          <Grid item xs={12} display={"flex"} alignItems="center" gap={2}
                            sx={{
                              backgroundColor: "#F5F5F5",
                              paddingBottom: 2,
                              marginLeft: 2,
                              marginTop: 1,
                              borderRadius: 3,
                            }}
                          >
                            <Typography variant="subtitle" sx={{ fontWeight: "bold" }}>
                              Main Operation
                            </Typography>

                            <PopoverCustom info = {"Operations that happened in the event. Must have one main operation and may have other transport operations."}/>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                              required
                              fullWidth
                              size="small"
                              name={"operations.type"}
                              select
                              label="Type"
                              value={values.operations.type}
                              onChange={(event) => handleChangeOperationType( values, event, setFieldValue)}
                              error={touched.operations?.type && Boolean(errors.operations?.type)}
                              helperText={touched.operations?.type && errors.operations?.type}
                            >
                              {
                                eventOperationTypeSort 
                                && eventOperationTypeSort.map((option) => (
                                  <MenuItem key={option} value={option}>
                                    {option}
                                  </MenuItem>
                                ))
                              }
                            </TextField>
                          </Grid>

                          {
                            values.operations.type === "generic" && (
                            <>
                              <Grid item xs={12} sm={6} md={4} lg={5}>
                                <Autocomplete
                                  size="small"
                                  id={`operations.name`}
                                  name={`operations.name`}
                                  options={nameOperation.registros || []}
                                  getOptionLabel={(option) => option}
                                  onChange={(event, value) => handleChangeOperationName(event, setFieldValue, values, setFieldError, value)}
                                  value={values.operations.name || null}
                                  renderInput={(params) => (
                                    <TextField
                                      required
                                      {...params}
                                      label="Name"
                                      placeholder="Name"
                                      error={touched.operations?.name && Boolean(errors.operations?.name)}
                                      helperText={touched.operations?.name && errors.operations?.name}
                                    />
                                  )}
                                />
                              </Grid>

                              {
                                hasMachinery 
                                && (operationNameSelected || values.operations.name) 
                                && (
                                  <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <TextField
                                      required
                                      fullWidth
                                      size="small"
                                      name={`operations.machinery.machinery_id`}
                                      select
                                      label="Machinery"
                                      value={ values.operations.machinery.machinery_id || ""}
                                      defaultValue={values.operations.machinery.machinery_id}
                                      onChange={(event) => { handleChangeOperationMachinery(event, setFieldValue, values) }}
                                      InputProps={{
                                        endAdornment: (
                                          <Box sx={{ mr: 3 }}>
                                            <PopoverCustom info={"Machinery used in the operation"} />
                                          </Box>
                                        ),
                                      }}
                                      error={!Boolean(values.operations.machinery.machinery_id)}
                                      helperText={!Boolean(values.operations.machinery.machinery_id) && errors.operations?.machinery?.machinery_id}
                                    >
                                      <MenuItem key={0} value={""}>
                                          <Button
                                            size='small'
                                            fullWidth
                                            color="primary"
                                            variant="contained"
                                            component="label"
                                            onClick={() => handleOpenMachineryForm(setFieldValue, values)}
                                            sx={{fontSize: 13, textTransform: 'none'}}
                                            startIcon={<AddCircleOutlineRoundedIcon sx={{fontSize: 15, mr: 1,}}/>}
                                          >
                                            Add machinery
                                          </Button>
                                      </MenuItem>
                                      <Divider sx={{ my: 2, border: '1px solid green' }} />
                                      {
                                        eventOperationMachinery.registros?.data 
                                        && eventOperationMachinery.registros?.data?.length > 0 
                                        ? (
                                          eventOperationMachinery?.registros?.data
                                          .slice()
                                          .sort((a,b)=> a.machine_name.toUpperCase() < b.machine_name.toUpperCase() ? -1: 1)
                                          .map((option, index) => (
                                              <MenuItem key={index} value={option._id}>
                                                {option.machine_name || ""}
                                              </MenuItem>
                                            )
                                          )
                                        ) 
                                        : (
                                          <MenuItem key={"no_options"} value={"no_options"} disabled>
                                            {"No options"}
                                          </MenuItem>
                                        )
                                      }
                                    </TextField>
                                    { 
                                      eventOperationMachinery.registros?.error ? (
                                        <FormHelperText error sx={{ml:1.75}}>
                                          {eventOperationMachinery.registros?.error}
                                        </FormHelperText>
                                      ) : null 
                                    }
                                  </Grid>
                                )
                              }

                              <Box sx={{ ml:2, mt:2, width:'100%',}}>
                                {
                                  //--------------------------------------------------------
                                  // Se possuir Machinery e existir registros em 
                                  // eventOperationMachineryImplements exibir a input Select
                                  // com a lista de implementos possíveis
                                  //--------------------------------------------------------
                                  values.operations.machinery.machinery_id
                                  && eventOperationMachineryImplements.registros.length !== 0
                                  ?(
                                    <>
                                      <Autocomplete
                                        fullWidth
                                        multiple
                                        id={`operations.machinery.implements`}
                                        name={`operations.machinery.implements`}
                                        options={eventOperationMachineryImplements.registros ||[]}
                                        getOptionLabel={(option) => option?.implement_name}
                                        filterSelectedOptions
                                        onChange={(event, value) => { handleChangeImplements(event, setFieldValue, value);}}
                                        value={ values.operations.machinery.implements || null }
                                        isOptionEqualToValue={(option, value) => {
                                          if(typeof value === "string"){
                                            return option._id === value
                                          } else {
                                            return option._id === value._id
                                          }
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Implements"
                                            placeholder="Implements"
                                            InputProps={{
                                              ...params.InputProps,
                                              endAdornment: (
                                                <>
                                                  <InputAdornment position="end">
                                                    <PopoverCustom
                                                      info={"Implements used with the machinery, can be empty in case no implement is used"}
                                                    />
                                                  </InputAdornment>
                                                  {params.InputProps.endAdornment}
                                                </>
                                              ),
                                            }}
                                          />
                                        )}
                                      />
                                    </>
                                  )
                                  : null
                                }
                              </Box>

                              {
                                //--------------------------------------------------------
                                // Se possuir Machinery exibir o botão Adicionar 
                                // Implemento
                                //--------------------------------------------------------
                                values.operations.machinery.machinery_id
                                ? (
                                  <Box sx={{ml:2}}>
                                    <Button
                                      size='small'
                                      color="primary"
                                      variant='contained'
                                      component="label"
                                      onClick={() => handleOpenImplementForm(setFieldValue, values)}
                                      sx={{fontSize: 13, textTransform: 'none', mr:2, mt:1}}
                                      startIcon={<AddCircleOutlineRoundedIcon sx={{fontSize: 15, mr: 1,}}/>}
                                    >
                                      Add implement
                                    </Button>
                                  </Box>
                                )
                                : null
                              }
                            </>
                          )}
                          {
                            values.operations.type === "from ecoinvent" && (
                              <>
                                <Grid item xs={12} sm={6} md={4} lg={6} sx={{ mb: 2 }}>
                                  <Autocomplete
                                    size="small"
                                    id="operations.ecoinvent"
                                    name="operations.ecoinvent"
                                    options={eventOperationEcoinvent.registros}
                                    onChange={(e, value) => handleChangeOperationEcoinvent(e, setFieldValue, value)}
                                    value={values.operations.ecoinvent || null}
                                    renderInput={(params) => (
                                      <TextField
                                        required
                                        {...params}
                                        autoFocus
                                        label="Name (ecoinvent)"
                                        error={ touched.operations?.ecoinvent && Boolean(errors.operations?.ecoinvent)}
                                        helperText={ touched.operations?.ecoinvent && errors.operations?.ecoinvent}
                                      />
                                    )}
                                  />
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                  <FormControl fullWidth variant="outlined">
                                    <InputLabel
                                      required
                                      size="small"
                                      htmlFor="amount"
                                      error={ touched.operations?.amount && Boolean(errors.operations?.amount) }
                                    >
                                      Amount
                                    </InputLabel>
                                    <OutlinedInput
                                      required
                                      fullWidth
                                      size="small"
                                      name={`operations.amount`}
                                      id="amount"
                                      label="Amount"
                                      value={values.operations.amount}
                                      onChange={handleChange}
                                      //onKeyDown={numericOnly}
                                      inputProps={{ step: 0.01, min: 0 }}
                                      type="number"
                                      error={ touched.operations?.amount && Boolean(errors.operations?.amount)}
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <Box>
                                            {
                                              ecoinventAmountUnit === " m3"
                                              ? "m³"
                                              : ecoinventAmountUnit
                                            }
                                          </Box>
                                          <PopoverCustom info={"Amount of area where the agricultural operation was carried out"}/>
                                        </InputAdornment>
                                      }
                                    />

                                    {
                                      touched.operations?.amount &&  errors.operations?.amount && (
                                        <FormHelperText error> {errors.operations?.amount}</FormHelperText>
                                      )
                                    }
                                  </FormControl>
                                </Grid>
                              </>
                            )
                          }

                          {
                            !!values.operations.name
                            && values.operations.type === "generic" 
                            && eventGenericHasTransport.registro && (
                              <>
                                <Grid item xs={12} display={"flex"} alignItems="center" gap={1}
                                  sx={{
                                    backgroundColor: "#F5F5F5",
                                    paddingBottom: 2,
                                    marginLeft: 2,
                                    marginTop: 1,
                                    borderRadius: 3,
                                  }}
                                >
                                  <Typography variant="subtitle" sx={{ fontWeight: "bold" }}>
                                    Additional Transports
                                  </Typography>
                                </Grid>

                                <FieldArray
                                  name="transports"
                                  render={(arrayHelpers) => (
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                      <>
                                        {
                                          values.transports 
                                          && values.transports.length > 0
                                          ? (
                                              values.transports.map((item, index) => (
                                                <Box key={index}>
                                                  <Grid container sx={{mb:0}} spacing={1}>
                                                    <Grid item xs={11} sm={3} md={2} lg={2}>
                                                      <TextField
                                                        fullWidth
                                                        size="small"
                                                        name={`transports[${index}].type`}
                                                        label="Type"
                                                        value={values.transports[index].type === "" ? "generic": values.transports[index].type}
                                                        onChange={handleChange}
                                                        disabled
                                                      />
                                                    </Grid>
                                                    
                                                    <Grid item xs={12} sm={6} md={5} lg={5} sx={{ mb: 2 }}>
                                                      <Autocomplete
                                                        size="small"
                                                        id={`transports[${index}].name`}
                                                        name={`transports[${index}].name`}
                                                        options={eventGenericTransportType.registros || []}
                                                        onChange={(event,value) => handleChangeTransportsName(event, index, setFieldValue, values, value)}
                                                        value={values.transports[index].name || null}
                                                        renderInput={( params ) => (
                                                          <TextField
                                                            {...params}
                                                            autoFocus
                                                            label="Name"
                                                          />
                                                        )}
                                                      />
                                                    </Grid>

                                                    {
                                                      values.transports[index].hasMachineryTransports 
                                                      ? (
                                                        <Grid item xs={12} sm={6} md={4} lg={4}>
                                                          <TextField
                                                            required
                                                            fullWidth
                                                            size="small"
                                                            name={`transports[${index}].machinery._id`}
                                                            select
                                                            label="Machinery"
                                                            value={values.transports[index].machinery._id || ''}
                                                            onChange={(event) => { handleChangeTransportsMachinery1(event, setFieldValue, index, values)} }
                                                            
                                                            InputProps={{
                                                              endAdornment: (
                                                                <Box sx={{ mr: 3 }}>
                                                                  <PopoverCustom info={"Machinery used in the operation"}/>
                                                                </Box>
                                                              ),
                                                            }}
                                                            error={!Boolean(values.transports[index].machinery._id)}
                                                            helperText={!Boolean(values.transports[index].machinery._id) && errors?.transports[index]?.machinery}
                                                          >
                                                            <MenuItem key={"no_options"} value={""}>
                                                              <Box display={"flex"} justifyContent={"space-between"} gap={2} width={"100%"}>
                                                                <Button
                                                                  fullWidth
                                                                  size='small'
                                                                  color="primary"
                                                                  variant='contained'
                                                                  component="label"
                                                                  onClick={() => handleOpenMachineryFormTransport(setFieldValue, values, index)}
                                                                  sx={{fontSize: 13, textTransform: 'none'}}
                                                                  startIcon={<AddCircleOutlineRoundedIcon sx={{fontSize: 15, }}/>}
                                                                >
                                                                  Add machinery
                                                                </Button>
                                                              </Box>
                                                            </MenuItem>

                                                            <Divider sx={{ my: 2, border: '1px solid green' }} />
                                                            
                                                            {
                                                              machineryTransportList.length > 0
                                                              && machineryTransportList[index]
                                                              && machineryTransportList[index].data
                                                                .slice()
                                                                .sort((a,b) => (a.machine_name.toUpperCase() > b.machine_name.toUpperCase()) ? 1 : -1)
                                                                .map((option, index) => (
                                                                  <MenuItem key={index} value={option._id}>
                                                                    {option.machine_name || ""}
                                                                  </MenuItem>
                                                                ))
                                                            }
                                                          </TextField>

                                                          {
                                                            !!eventGenericTransportMachinery.registros?.error && (
                                                              <Typography component="div" variant="body2" color="red">
                                                                { eventGenericTransportMachinery.registros?.error }
                                                              </Typography>
                                                            )
                                                          }
                                                          <Box> { eventGenericTransportMachinery.error.msg } </Box>
                                                        </Grid>
                                                      ) 
                                                      : null
                                                    }

                                                    <Grid item xxs={12} xs={12} sm={5} md={1}>
                                                      <Button type="button" onClick={() => {
                                                          arrayHelpers.remove(index)
                                                          if(index >= 0){
                                                            implementsTransportList.splice(index, 1)
                                                          }
                                                        }}
                                                      >
                                                        <RemoveCircleOutlineRoundedIcon />
                                                      </Button>
                                                    </Grid>
                                                    {
                                                      //--------------------------------------------------------
                                                      // Se existir maquinário e implementos selecionados para 
                                                      // este maquinário, exibir a input select com a lista de 
                                                      // implementos  
                                                      //--------------------------------------------------------
                                                        
                                                        values.transports[index].machinery._id
                                                        && values.transports[index].machinery.implements !== undefined
                                                        ? (
                                                          <Grid item xs={11}>
                                                            <Autocomplete
                                                              multiple
                                                              id={`transports[${index}].machinery.implements`}
                                                              name={`transports[${index}].machinery.implements`}
                                                              options={
                                                                typeof implementsTransportList[index]?.implementsList !== 'undefined'
                                                                ? implementsTransportList[index]?.implementsList
                                                                  .slice()
                                                                  .sort((a,b)=> a.implement_name.toUpperCase() > b.implement_name.toUpperCase() ? 1: -1) 
                                                                : []
                                                              }
                                                              key={(option) => option._id}
                                                              getOptionLabel={(option) => option?.implement_name}
                                                              
                                                              filterSelectedOptions
                                                              value={ values.transports[index].machinery.implements }
                                                              onChange={(event, value) => {handleChangeTransportsImplements(event,setFieldValue,value, index)}}
                                                              isOptionEqualToValue={(option, value) => {
                                                                if(typeof value === "string"){
                                                                  return option._id === value
                                                                } else {
                                                                  return option._id === value._id
                                                                }
                                                              }}
                                                              renderInput={(params) => (
                                                                <TextField
                                                                  {...params}
                                                                  label="Implements"
                                                                  placeholder="Implements"
                                                                  InputProps={{
                                                                    ...params.InputProps,
                                                                    endAdornment: (
                                                                      <>
                                                                        <InputAdornment position="end">
                                                                          <PopoverCustom
                                                                            info={"Implements used with the machinery, can be empty in case no implement is used"}
                                                                          />
                                                                        </InputAdornment>
                                                                        {params.InputProps.endAdornment}
                                                                      </>
                                                                    ),
                                                                  }}
                                                                />
                                                              )}
                                                            />
                                                          </Grid>
                                                        )
                                                        : null
                                                      
                                                    }
                                                    {
                                                      //--------------------------------------------------------
                                                      // Se existir maquinário no transportes, então exibir o 
                                                      // botão Add implement 
                                                      //--------------------------------------------------------
                                                      values.transports[index].machinery._id
                                                      ? (
                                                        <>
                                                          <Box display='flex' gap={2} ml={1} mt={1}>
                                                            <Button
                                                              size='small'
                                                              color="primary"
                                                              variant='contained'
                                                              component="label"
                                                              onClick={() => handleOpenImplementFormTransport(setFieldValue, values)}
                                                              sx={{fontSize: 13, textTransform: 'none', }}
                                                              startIcon={<AddCircleOutlineRoundedIcon sx={{fontSize: 15, mr: 1,}}/>}
                                                            >
                                                              Add implement
                                                            </Button>
                                                          </Box>
                                                        </>
                                                      )
                                                      : null
                                                    }
                                                  </Grid>
                                                  <Divider sx={{ my: 2, border: '1px solid green' }} />      
                                                </Box>
                                              ))
                                            )
                                          : null
                                        }
                                        <Box sx={{mt:2}}>
                                          <Button
                                            type="button"
                                            variant="outlined"
                                            onClick={() =>
                                              arrayHelpers.push({
                                                type: "generic",
                                                name: "",
                                                machinery: {
                                                  _id: "",
                                                  implements: [],
                                                },
                                              })
                                            }
                                            startIcon={
                                              <AddCircleOutlineRoundedIcon />
                                            }
                                          >
                                            Add Additional Transports
                                          </Button>
                                        </Box>
                                      </>
                                    </Grid>
                                  )}
                                />
                              </>
                            )
                          }

                          {
                            (values.type === "application" || values.type === "irrigation, generic" || values.type === "irrigation, periodic") 
                            && (values.operations.name || values.operations.ecoinvent) 
                            ? (
                              <>
                                <Grid item xs={12} display={"flex"} alignItems="center" gap={2}
                                  sx={{
                                    backgroundColor: "#F5F5F5",
                                    paddingBottom: 2,
                                    marginLeft: 2,
                                    marginTop: 1,
                                    borderRadius: 3,
                                  }}
                                >
                                  <Typography variant="subtitle" sx={{ fontWeight: "bold" }}>
                                    Applications
                                  </Typography>

                                  <PopoverCustom info={"Applications done in the context of the event."}/>
                                </Grid>
                                
                                <FieldArray
                                  name="applications"
                                  render={(arrayHelpers) => (
                                    <>
                                      <Grid item xs={12} sm={12} md={12} lg={12}>
                                        {
                                          values.applications &&
                                          values.applications.length > 0
                                          ? values.applications.map((item, index) =>  (
                                            
                                            <Box key={index}>
                                              <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                                  <TextField
                                                    required
                                                    fullWidth
                                                    size="small"
                                                    name={`applications[${index}].type`}
                                                    select
                                                    label="Type"
                                                    value={ values.applications[index].type}
                                                    onChange={(event) => {handleChangeApplicationType(values, event, setFieldValue, index);}}
                                                    error={typeof errors.applications !== "undefined" && Boolean(errors.applications[index]?.type)}
                                                    helperText={typeof errors.applications !=="undefined" && Boolean(errors.applications[index]?.type) && errors.applications[index]?.type}
                                                  >
                                                    <MenuItem key={'none'} value={ "" }>
                                                      {'none'}
                                                    </MenuItem>
                                                    {
                                                      values.applications[index].typeList 
                                                      ? (
                                                          values.applications[index].typeList.map((option) => (
                                                          <MenuItem key={option} value={option}>
                                                            {option}
                                                          </MenuItem>
                                                        ))
                                                      ) 
                                                      : (
                                                        <MenuItem key={"no_operation"} value={"no_option"}>
                                                          {"No option"}
                                                        </MenuItem>
                                                      )
                                                    }
                                                  </TextField>
                                                </Grid>

                                                {
                                                  !!values.applications[index].type && (
                                                    <>
                                                      <Grid item xs={12} sm={5} md={4} lg={4}>
                                                        <TextField
                                                          required
                                                          fullWidth
                                                          size="small"
                                                          name={`applications[${index}].product`}
                                                          select
                                                          label="Agricultural input"
                                                          value={ values.applications[index].product}
                                                          onChange={(event) => {handleChangeApplicationsProduct(event, index, setFieldValue);}}
                                                          error={typeof errors.applications !== "undefined" && Boolean(errors.applications[index]?.product)}
                                                          helperText={typeof errors.applications !== "undefined" && Boolean(errors.applications[index]?.product) && errors.applications[index]?.product}
                                                        >
                                                          <MenuItem key={0} value={""}>
                                                            <Button
                                                              fullWidth
                                                              size='small'
                                                              color="primary"
                                                              variant='contained'
                                                              component="label"
                                                              onClick={() => handleOpenProductForm(setFieldValue, values, index)}
                                                              sx={{fontSize: 13, textTransform: 'none', mt:1}}
                                                              startIcon={<AddCircleOutlineRoundedIcon sx={{fontSize: 15, mr: 1,}}/>}
                                                            >
                                                              Add agricultural input
                                                            </Button>
                                                          </MenuItem>

                                                          <Divider sx={{ my: 2, border: '1px solid green' }} />

                                                          <MenuItem key={'none'} value={ "" }>
                                                            {'none'}
                                                          </MenuItem>

                                                          {
                                                            values.applications[index].productsList
                                                            && values.applications[index].productsList
                                                            .slice()
                                                            .sort((a, b)=> (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : -1)
                                                            .map((option) => (
                                                              <MenuItem key={option._id} value={option._id}>
                                                                {option.name}
                                                              </MenuItem>
                                                            ))
                                                          }
                                                        </TextField>
                                                      </Grid>
                                                      
                                                      <Grid item xs={12} sm={6} md={2} lg={2}>
                                                        <TextField
                                                          required
                                                          fullWidth
                                                          size="small"
                                                          name={`applications[${index}].amount`}
                                                          label="Amount"
                                                          value={values.applications[index].amount}
                                                          onChange={handleChange}
                                                          onKeyDown={numericOnly}
                                                          type="number"
                                                          error={typeof errors.applications !== "undefined" && Boolean(errors.applications[index]?.amount)}
                                                          helperText={typeof errors.applications !== "undefined" && Boolean(errors.applications[index]?.amount) && errors.applications[index]?.amount}
                                                          InputProps={{
                                                            endAdornment: (
                                                              <Box>
                                                                <PopoverCustom info={"Amount of applied agricultural inputs"}/>
                                                              </Box>
                                                            ),
                                                          }}
                                                        />
                                                      </Grid>
                                                      
                                                      <Grid item xs={12} sm={5} md={2} lg={2}>
                                                        <TextField
                                                          required
                                                          fullWidth
                                                          size="small"
                                                          name={`applications[${index}].amount_unit`}
                                                          select
                                                          label="Unit"
                                                          value={values?.applications[index].amount_unit ? values?.applications[index].amount_unit : ""}
                                                          onChange={handleChange}
                                                          error={typeof errors.applications !=="undefined" && Boolean(errors.applications[index]?.amount_unit)}
                                                          helperText={typeof errors.applications !=="undefined" && Boolean(errors.applications[index]?.amount_unit) && errors.applications[index]?.amount_unit}
                                                        >
                                                          <MenuItem key={'none'} value={ "" }>
                                                            {'none'}
                                                          </MenuItem>
                                                          {
                                                            values.applications[index].unitList 
                                                            ? (
                                                                values.applications[index].unitList.map((option) => (
                                                                  <MenuItem key={option} value={option || ""}>
                                                                    {option}
                                                                  </MenuItem>
                                                                ))
                                                            ) 
                                                            : (
                                                              <MenuItem key={"no_option"} value={""}>
                                                                {"No options"}
                                                              </MenuItem>
                                                            )
                                                          }
                                                        </TextField>
                                                      </Grid>

                                                      {
                                                        values.applications[index].type === "pesticide" 
                                                        ? (
                                                          <>
                                                            <Grid item xs={12} sm={5} md={5} lg={5}>
                                                              <TextField
                                                                fullWidth
                                                                size="small"
                                                                name={`applications[${index}].foliar_interception`}
                                                                label="Foliar interception"
                                                                select
                                                                value={values.applications[index].foliar_interception || ''}
                                                                onChange={(e) => handleChangeFoliarInterception(e, index, setFieldValue)}
                                                                error={typeof errors.applications !== "undefined" && Boolean(errors.applications[index]?.foliar_interception)}
                                                                helperText={typeof errors.applications !== "undefined" && Boolean(errors.applications[index]?.foliar_interception) && errors.applications[index]?.foliar_interception}
                                                              >
                                                                <MenuItem key={'none'} value={ "" }>
                                                                  {'none'}
                                                                </MenuItem>

                                                                {
                                                                  values.applications[index].foliarInterceptionList
                                                                  && values.applications[index].foliarInterceptionList.map((option) => (
                                                                    <MenuItem key={option} value={ option || "" }>
                                                                      {option}
                                                                    </MenuItem>
                                                                  ))
                                                                }
                                                              </TextField>
                                                            </Grid>

                                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                              <TextField
                                                                fullWidth
                                                                size="small"
                                                                name={`applications[${index}].application_method`}
                                                                label="Application method"
                                                                select
                                                                value={ values.applications[index].application_method || ''}
                                                                onChange={handleChange}
                                                                error={typeof errors.applications !=="undefined" && Boolean(errors.applications[index]?.application_method)}
                                                                helperText={typeof errors.applications !== "undefined" && Boolean(errors.applications[index]?.application_method) && errors.applications[index]?.application_method}
                                                              >
                                                                <MenuItem key={'none'} value={ "" }>
                                                                  {'none'}
                                                                </MenuItem>
                                                                {
                                                                  values.applications[index].applicationMethodList
                                                                  && values.applications[index].applicationMethodList.map((option) => (
                                                                    <MenuItem key={option} value={option || ""} sx={{maxWidth: '272px', textWrap: 'wrap', whiteSpace: 'normal'}}>
                                                                      {option}
                                                                    </MenuItem>
                                                                  ))
                                                                }
                                                              </TextField>
                                                            </Grid>

                                                            {
                                                              values.applications[index].foliar_interception === "Other" 
                                                              ? (
                                                                  <Grid item xs={12} sm={5} md={3} lg={3}>
                                                                    <TextField
                                                                      fullWidth
                                                                      size="small"
                                                                      name={`applications[${index}].fi_amount`}
                                                                      type="number"
                                                                      label="Foliar interception amount"
                                                                      inputProps={{ step: 0.01, min: 0, max: 1, }}
                                                                      value={ values.applications[index].fi_amount}
                                                                      onChange={handleChange}
                                                                    />
                                                                  </Grid>
                                                              ) 
                                                              : null
                                                            }
                                                          </>
                                                        ) 
                                                        : null
                                                      }

                                                      
                                                    </>
                                                  )
                                                }
                                                <Grid item xs={12} sm={12} md={1}>
                                                  <Button type="button"
                                                    onClick={() => {
                                                      arrayHelpers.remove(index);
                                                    }}
                                                  >
                                                    <RemoveCircleOutlineRoundedIcon />
                                                  </Button>
                                                </Grid>
                                              </Grid>
                                              
                                              <code>{JSON.stringify(values?.applications[index]?.productList, null, 2)}</code>
                                              
                                              <Divider sx={{ my: 2, border: '1px solid green' }} />
                                            </Box>
                                          ))
                                          : null
                                        }
                                      </Grid>

                                      <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Button
                                          type="button"
                                          variant="outlined"
                                          onClick={() => { 
                                            handleAddItemApplication(values, setFieldValue, indexAddApplication);
                                            arrayHelpers.push({
                                              type: "",
                                              typeList: values.applications[indexAddApplication]?.typeList || [],
                                              product: "",
                                              productsList: [],
                                              amount: "",
                                              amount_unit: "",
                                              unitList: [],
                                            });
                                          }}
                                          startIcon={
                                            <AddCircleOutlineRoundedIcon />
                                          }
                                        >
                                          Add Application
                                        </Button>
                                      </Grid>
                                    </>
                                  )
                                }
                                />
                              </>
                            ) 
                            : null
                          }
                        </>
                      )
                    }
                    
                    <Grid item xs={12} display={"flex"} gap={2}></Grid>
                    
                  </Grid>
                </DialogContent>

                <DialogActions>
                  {
                    errorAdd 
                    ? (
                      <Box sx={{ p: 0.5, pl: 2 }}>
                        <Typography variant="body2" color="error">
                          Error: {errorAdd}
                        </Typography>
                      </Box>
                    ) 
                    : null
                  }
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Button
                      size="small"
                      onClick={handleClose}
                      variant="outlined"
                      startIcon={<CancelRoundedIcon />}
                      type="reset"
                    >
                      Cancel
                    </Button>

                    <Button
                      //disabled={!isValid}
                      size="small"
                      variant="contained"
                      startIcon={<SaveRoundedIcon />}
                      autoFocus
                      type="submit"
                    >
                      Add
                    </Button>

                  </Box>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
      {
        openProductForm ? (
          <ProductsForm
            openProductForm={openProductForm}
            setOpenProductForm={setOpenProductForm}
            user_id={user.user_icv_id}
            setProductSelected={setProductSelected}
            origin={origin}
          />
        ) : null
      }
      {
        openMachineryForm ? (
          <MachineryForm
            openMachineryForm={openMachineryForm}
            setOpenMachineryForm={setOpenMachineryForm}
            user_id={user.user_icv_id}
            property={property}
            machinerySelected={{}}
            mode='add'
            origin={origin}
            setMachinerySelected={setMachinerySelected}
            setMachineryTransportSelected={setMachineryTransportSelected}
          />
        ) : null
      }
      {
        openImplementForm ? (
          <ImplementForm 
            openImplementForm={openImplementForm}
            setOpenImplementForm={setOpenImplementForm}
            property={property}
            user_id={user.user_icv_id}
            mode={"add"}
            origin={origin}
            setImplementSelected={setImplementSelected}
            implementSelected={implementSelected}
            setTransportsImplementsSelected={setTransportsImplementsSelected}
            transportsImplementsSelected= {transportsImplementsSelected}
          />
        ) : null
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userAll: {
      loading: state.userAll.loading,
      registro: state.userAll.registro,
      error: state.userAll.error,
    },
    eventTypeCrop: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationEventType,
      error: state.enumerated.error,
    },
    eventOperationType: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationEventOperationType,
      error: state.enumerated.error,
    },
    nameOperation: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationNameOperation,
      error: state.enumerated.error,
    },
    products: {
      loading: state.userAll.loading,
      registros: state.userAll.registro.user_products,
      error: state.userAll.error,
    },
    eventOperationMachinery: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationEventOperationMachinery,
      error: state.enumerated.error,
    },
    eventOperationMachineryImplements: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationEventOperationMachineryImplements,
      error: state.enumerated.error,
    },
    eventGenericTransportMachinery: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationEventGenericTransportMachinery,
      error: state.enumerated.error,
    },
    eventOperationEcoinvent: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationEventOperationEcoinvet,
      error: state.enumerated.error,
    },
    eventGenericHasTransport: {
      loading: state.enumerated.loading,
      registro: state.enumerated.enumerationEventGenericHasTransport,
      error: state.enumerated.error,
    },
    eventGenericTransportType: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationEventGenericTransportType,
      error: state.enumerated.error,
    },
    eventGenericTransportImplements:{
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationEventGenericTransportImplements,
      error: state.enumerated.error,
    },
    eventMainOperationProducts:{
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationEventMainOperationProducts,
      error: state.enumerated.error,
    },
    loadingEnumeration: state.enumerated.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEnumerationEventOperationType: (event_type) => dispatch(fetchEnumerationEventOperationType(event_type)),
    fetchEnumerationEventTypeCrop: (params) => dispatch(fetchEnumerationEventTypeCrop(params)),
    fetchEnumerationNameOperation: (params) => dispatch(fetchEnumerationNameOperation(params)),
    fetchEnumerationEventApplicationType: (op_name) => dispatch(fetchEnumerationEventApplicationType(op_name)),
    fetchEnumerationEventApplicationUnit: (prod_id) => dispatch(fetchEnumerationEventApplicationUnit(prod_id)),
    fetchEnumeracionEventOperationMachinery: (par) => dispatch(fetchEnumeracionEventOperationMachinery(par)),
    fetchEnumerationEventOperationMachineryImplements: (par) => dispatch(fetchEnumerationEventOperationMachineryImplements(par)),
    fetchEnumerationEventOperationEcoinvet: (params) => dispatch(fetchEnumerationEventOperationEcoinvet(params)),
    addPlotEvent: (params) => dispatch(addPlotEvent(params)),
    fetchEnumerationEventMainOperationProducts: (params) => dispatch(fetchEnumerationEventMainOperationProducts(params)),
    fetchEnumerationEventGenericHasTransport: (op_name) => dispatch(fetchEnumerationEventGenericHasTransport(op_name)),
    fetchEnumerationEventGenericTransportType: (op_name) => dispatch(fetchEnumerationEventGenericTransportType(op_name)),
    fetchEnumerationEventGenericTransportMachinery: (params) => dispatch(fetchEnumerationEventGenericTransportMachinery(params)),
    fetchEnumerationEventGenericTransportImplements: (params) =>  dispatch(fetchEnumerationEventGenericTransportImplements(params)),
    fetchUserAll: (user_id) => dispatch(fetchUserAll(user_id)),
    resetEnumerationEventOperationMachineryImplements: () => dispatch(resetEnumerationEventOperationMachineryImplements()),
    resetEnumerationEventGenericTransportImplements: () => dispatch(resetEnumerationEventGenericTransportImplements()),
    editPlotEvent: (params) => dispatch(editPlotEvent(params)),
    checkEventGenericHasMachinery: (op_name) => dispatch(checkEventGenericHasMachinery(op_name)),
    checkEventTransportHasMachinery: (params) =>  dispatch(checkEventTransportHasMachinery(params)),
    fetchEnumerationPesticideFoliarInterception: (params) => dispatch(fetchEnumerationPesticideFoliarInterception(params)),
    fetchEnumerationPesticideApplicationMethods: (params) => dispatch(fetchEnumerationPesticideApplicationMethods(params)),
    checkEventDateIsNotConflicted: (params) => dispatch(checkEventDateIsNotConflicted(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlotEventForm);
