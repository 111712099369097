import * as Yup from 'yup';

const validationSchema = () => Yup.object().shape({
    type: Yup
        .string()
        .required('Required field'),
    implement_name: Yup
        .string()
        .required('Required field'),
    specific_type: Yup
        .string()
        .required('Required field'),
    specific_subtype: Yup
        .string()
        .required('Required field'),
    lifespan: Yup
        .number()
        .positive('Must be greater than 0')
        .notOneOf([0], 'This field cannot accept a zero value'),
    tset_lifespan: Yup
        .number()
        .positive('Must be greater than 0')
        .notOneOf([0], 'This field cannot accept a zero value'),
    tset_weight: Yup
        .number()
        .positive('Must be greater than 0')
        .notOneOf([0], 'This field cannot accept a zero value')
        .max(1, 'must be less than or equal to 1'),
    tset_rubbed_off: Yup
        .number()
        .positive('Must be greater than 0')
        .notOneOf([0], 'This field cannot accept a zero value')
        .max(1, 'must be less than or equal to 1'),
    weight: Yup
        .number()
        .positive('Must be greater than 0')
        .notOneOf([0], 'This field cannot accept a zero value'),
})

export default validationSchema

