import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
export default function BasicPopover(props) {

    const { info } = props
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <IconButton
                aria-describedby={id}
                variant="contained"
                onClick={handleClick}
                sx={{ m: 0, p: 0, mr: -1, }}
                tabIndex= {-1}
            >
                <HelpOutlineRoundedIcon color="tertiary" fontSize="small" />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                elevation={8}
                marginThreshold={25}
            >
                <Typography variant='body2' sx={{ p: 2, maxWidth: '700px' }}>{info}</Typography>
            </Popover>
        </div>
    );
}