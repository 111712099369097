import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from './Header'
import { Box, Container } from '@mui/material'

function Layout() {
    return (
        <>
            <Container sx={{ minHeight: '90vh' }}>
                <Header />
                <Box
                    display='flex'
                    justifyContent={'center'}
                    sx={{ height: '80vh', pt: { xs: 0, sm: 2, md: 3 }, mb: 10 }}
                >
                    <Outlet />
                </Box>
            </Container>
        </>
    )
}

export default Layout