import { Box, Typography } from '@mui/material'
import React from 'react'

const ShowRegister = (props) => {

  const {item} = props

  return (
    <>
      <Typography
        variant="subtitle2"
        sx={{
          width: "33%",
          flexShrink: 0,
          fontWeight: 600,
        }}
      >
        Register
      </Typography>
      <Typography variant="subtitle2">
        {item.city_code ? (<Box><strong>City Code: </strong>{item.city_code}</Box>) : null}
      </Typography>
    </>
  )
}

export default ShowRegister
