import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ImplementForm from "./ImplementForm";
import { connect } from "react-redux";
import HeaderProperty from "../layout/HeaderProperty";
import { deleteImplement, fetchUserAll } from "../../redux/userAllSlice";
import ConfirmDialog from "../layout/ConfirmDialog";
import { objetoVazio } from "../../hooks/functions";
import Loading from '../layout/Loading';
import TitlePage from '../layout/TitlePage';
import ShowSummary from '../layout/ShowSummary';
import ShowSummaryActions from '../layout/ShowSummaryActions';
import EditButton from '../layout/ButtonEdit';
import ShowRegister from './ShowRegister';
import SnackbarCustom from '../layout/SnackbarCustom';

export const Implements = (props) => {
  const { fetchUserAll, userAll, deleteImplement } = props;

  const user = JSON.parse(localStorage.getItem("user"));
  const property = JSON.parse(localStorage.getItem("property"));

  const [expanded, setExpanded] = useState(false);
  const [openImplementForm, setOpenImplementForm] = useState(false);
  const [mode, setMode] = useState("add");
  const [implementSelected, setImplementSelected] = useState({});
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [user_implements, setUser_implements] = useState([]);
  const [openNoDelete, setOpenNoDelete] = useState(false)
  const [msgErroNoDelete] = useState("")

  useEffect(() => {
    fetchUserAll(user.user_icv_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!objetoVazio(userAll.registro)) {
      const index_pro = userAll.registro.properties?.findIndex(
        (reg) => reg._id === property._id
      );
      setUser_implements(
        userAll.registro.properties[index_pro]?.prop_implements
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAll]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleOpenImplementForm = () => {
    setOpenImplementForm(true);
  };

  const handleDeleteImplement = async (item) => {
    const params = {
      user_id: user.user_icv_id,
      prop_id: property._id,
      impl_id: item._id,
    };
    await deleteImplement(params);
  };

  const handleEdit = (item) => {
    setMode("edit");
    setImplementSelected(item);
    handleOpenImplementForm();
  };

  const handleAdd = () => {
    setMode("add");
    handleOpenImplementForm();
  };

  return (
    <Box sx={{ width: { xs: 340, sm: 500, md: 800 } }}>
      
      <HeaderProperty property={property} />
      
      {
        userAll.loading ? (
          <Loading />
        ) : (
          <>
            <TitlePage 
              title="Implements List" 
              handleAdd={handleAdd} 
              info={
                <Typography component={'span'}>
                  The <strong>Implements</strong> list functions as a comprehensive repository for 
                  all information related to the <strong>implements</strong> utilized alongside 
                  <strong> machines</strong> on a specific <strong>Property</strong>. Implements may 
                  include equipment such as plows, platforms, spreaders, planters, and more. 
                  It's crucial to note that the details stored in this list will contribute to the 
                  inventory calculations <strong>only</strong> when a specific <strong>implement</strong> is 
                  employed in conjunction with a <strong>machine</strong> within an <strong>Event </strong> 
                  with a <strong>generic Main Operation</strong>.<br/><br/>
	                
                  To gain a deeper understanding of how <strong>Implements</strong> factors into the 
                  broader context of <strong>Events</strong>, refer to the <a href="/icvcalc/help" 
                  style={{ cursor: 'pointer' }}><strong>Help</strong></a>{' '} tab for more 
                  detailed information on <strong>Events</strong> and their implications on 
                  inventory calculations."
                </Typography>
              }
            />
            {
              user_implements && user_implements
              .slice()
              .sort((a,b)=>(a.implement_name.toUpperCase() > b.implement_name.toUpperCase()) ? 1 : -1)
              .map((item, index) => {
                return (
                  <Accordion
                    key={index}
                    expanded={expanded === `panel${index}`}
                    onChange={handleChange(`panel${index}`)}
                    elevation={2}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <ShowSummary 
                        item={item} 
                        title={item.implement_name} 
                        subtitle={`${item.specific_type} ${item.specific_subtype}`}
                      />

                      <ShowSummaryActions 
                        item={item} 
                        setSelected={setImplementSelected} 
                        setConfirmOpen={setConfirmOpen}
                      />

                      {
                        item._id === implementSelected._id 
                        ? (
                            <ConfirmDialog
                              name="Delete Implements"
                              open={confirmOpen}
                              setOpen={setConfirmOpen}
                              onConfirm={() => handleDeleteImplement(item)}
                              title={`${item.implement_name} ${item?.type}`}
                              subtitle={`${item.specific_type} ${item?.specific_subtype}`}
                            />
                        ) : null
                      }

                    </AccordionSummary>

                    <AccordionDetails>

                      <Grid container spacing={2} display={"flex"}>
                        <Grid item>
                          <ShowRegister item={item} />
                        </Grid>
                      </Grid>

                      <EditButton item={item} handleEdit={handleEdit} />

                    </AccordionDetails>

                  </Accordion>
                );
              })
            }
            <Box sx={{ pb: "100px" }}></Box>
            
            {
              openImplementForm ? (
                <ImplementForm
                  openImplementForm={openImplementForm}
                  setOpenImplementForm={setOpenImplementForm}
                  user_id={user.user_icv_id}
                  mode={mode}
                  implementSelected={mode === "edit" ? implementSelected : {}}
                  property={property}
                />
              ) : null
            }

            {
              openNoDelete
              ? (
                <SnackbarCustom
                  open={openNoDelete}
                  setOpen={setOpenNoDelete}
                  msg={msgErroNoDelete}
                />
              )
              : null
            }
          </>
        )
      }
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    userAll: {
      loading: state.userAll.loading,
      registro: state.userAll.registro,
      error: state.userAll.error,
    },
    propertySelected: {
      loading: state.userAll.loading,
      registro: state.userAll.selectedProperty,
      error: state.userAll.error,
    },
    user_implements: {
      loading: state.userAll.loading,
      registros: state.userAll.registro.user_implements,
      error: state.userAll.error,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserAll: (user_id) => dispatch(fetchUserAll(user_id)),
    deleteImplement: (params) => dispatch(deleteImplement(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Implements);
