import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import {
  //checkGisIsValid,
  editProperty,
  editSelectedProperty,
  getCities,
} from "../../redux/userAllSlice";
import CloseIcon from '@mui/icons-material/CancelRounded';
import Loading from '../layout/Loading'
import './property.css'
import {objetoVazio} from '../../hooks/functions'

const validationSchema = Yup.object({
  state_code: Yup.string().required("Required field"),
  city_code: Yup.string().required("Required field"),
  name: Yup.string().required("Required field"),
});

export const PropertyFormEdit = (props) => {
  const {
    openPropertiesForm,
    setOpenPropertiesForm,
    propertySelected,
    editSelectedProperty,
    property,
    getCities,
    editProperty,
  } = props;

  const [initialValues, setInitialValues] = useState({});
  const [ufs, setUfs] = useState([]);
  const [selectedUf, setSelectedUf] = useState("");
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [nameProperty, setNameProperty] = useState("");
  const [loading, setLoading] = useState(true)

  const user = JSON.parse(localStorage.getItem("user")) || {};

  useEffect(() => {
    const getData = async () => {
      if (!objetoVazio(propertySelected)) {
        const result = await editSelectedProperty(propertySelected);
        await setInitialValues({
          state_code: result.payload.state || "",
          city_code: result.payload.city_code || "",
          name: result.payload.name || "",
          GIS: result.payload.GIS.map((el) => el) || [],
        });
      }
    };
    getData();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!objetoVazio(property.registro)) {
      setUfs(property.registro.selectUfs || []);
      const ufAtual =
        !objetoVazio(property.registro) &&
        property.registro.selectUfs.find(
          (el) => el.id === property.registro.state
        );
      setSelectedUf(ufAtual);
      setCities(property.registro.selectUfCities || []);
      const cityAtual =
        !objetoVazio(property.registro) &&
        property.registro.selectUfCities.find(
          (el) => el.id === property.registro.city_code
        );
      setSelectedCity(cityAtual);
      setNameProperty(property.registro.name);
    }
  }, [property, propertySelected]);

  const handleClose = () => {
    setOpenPropertiesForm(false);
  };

  const handleChangeUf = async (newValue, setFieldValue) => {
    setSelectedCity("");
    setSelectedUf(newValue);
    setFieldValue("state_code", newValue.id);
    const result = await getCities(newValue.id);
    setCities(result.payload);
  };
  const handleChangeCities = (newValue, setFieldValue) => {
    setSelectedCity(newValue);
    setFieldValue("city_code", newValue.id);
  };

  const handleSubmit = async (values, setFieldValue) => {

    const params = {
      user_id: user.user_icv_id,
      prop_id: propertySelected._id,
      payload: {
        city_code: values.city_code,
        name: values.name,
        GIS: values.GIS,
      },
    };

    await editProperty(params)
    handleClose();
  };

  useEffect(() => {
    setTimeout(() => {
      const dialog = document.querySelector('.dialog')
      if(dialog){
        dialog.scrollIntoView({behavior: 'smooth', block: 'start'})
      }
      setLoading(false)
      }, 1500);
  }, [initialValues])
  
  return (
    <div>
      <Dialog 
        open={openPropertiesForm} 
        onClose={handleClose} 
        scroll="body"
      >
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm, setFieldValue }) => {
            handleSubmit(values, setFieldValue);
          }}
        >
          {({ values, touched, errors, handleChange, setFieldValue, initialValues, }) => {
            return (
              <>
                <Form noValidate autoComplete="off">
                  <DialogTitle className='dialog' display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <strong>Edit Property</strong>
                    <IconButton
                      aria-label="close"
                      onClick={handleClose}
                      sx={{ position: 'absolute', right: 8, top: 8,}}
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>

                  <Typography variant="body2" sx={{ ml: 3, mt: 1, color: "red", fontWeight: "bold" }}>
                    * Required fields
                  </Typography>

                  {
                    loading || property.loading 
                    ? ( <Loading />) 
                    : (
                      <DialogContent sx={{ minWidth: { xs: 400, sm: 500, md: 500, lg: 600 } }}>
                        <Grid container spacing={2}>
                          
                          <Grid item xxs={12} sm={6}>
                            <Autocomplete
                              autoFocus
                              size="small"
                              fullWidth
                              id="state_code"
                              name="state_code"
                              options={ufs}
                              getOptionLabel={(option) => option.nome || ""}
                              onChange={(value, newValue) =>
                                handleChangeUf(newValue, setFieldValue)
                              }
                              value={selectedUf}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  required
                                  label="State of property"
                                  error={touched.state_code && Boolean(errors.state_code)}
                                  helperText={touched.state_code && errors.state_code}
                                />
                              )}
                            />
                          </Grid>

                          <Grid item xxs={12} sm={6}>
                            <Autocomplete
                              size="small"
                              fullWidth
                              id="city_code"
                              name="city_code"
                              options={cities || []}
                              getOptionLabel={(option) => option.nome || ""}
                              onChange={(value, newValue) =>
                                handleChangeCities(newValue, setFieldValue)
                              }
                              value={selectedCity}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  required
                                  label="City of property"
                                  error={
                                    touched.city_code && Boolean(errors.city_code)
                                  }
                                  helperText={touched.city_code && errors.city_code}
                                />
                              )}
                            />
                          </Grid>

                          <Grid item xxs={12}>
                            <TextField
                              required
                              fullWidth
                              size="small"
                              name="name"
                              label="Name of property"
                              value={nameProperty}
                              onChange={(e) => {
                                setFieldValue("name", e.target.value);
                                setNameProperty(e.target.value);
                              }}
                              error={touched.name && Boolean(errors.name)}
                              helperText={touched.name && errors.name}
                            />
                          </Grid>

                        </Grid>
                      </DialogContent>
                    )
                  }
                  <DialogActions>
                    <Box
                      display={"flex"}
                      gap={2}
                      justifyContent="flex-end"
                      mt={2}
                    >
                      <Button
                        size="small"
                        onClick={handleClose}
                        variant="outlined"
                        startIcon={<CancelRoundedIcon />}
                      >
                        Cancel
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        startIcon={<SaveRoundedIcon />}
                        autoFocus
                        type="submit"
                      >
                        Save
                      </Button>
                    </Box>
                  </DialogActions>
                </Form>
              </>
            );
          }}
        </Formik>
      </Dialog>
     
      <Box sx={{ mb: 10 }}></Box>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    property: {
      loading: state.userAll.property.loading,
      registro: state.userAll.property.registro,
      error: state.userAll.property.error,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editSelectedProperty: (params) => dispatch(editSelectedProperty(params)),
    getCities: (uf_code) => dispatch(getCities(uf_code)),
    editProperty: (params) => dispatch(editProperty(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PropertyFormEdit);
