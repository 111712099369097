import { Box, Typography } from '@mui/material'
import React from 'react'

function ShowRegister(props) {

  const {item} = props

  return (
    <Box sx={{border: "1px solid green", borderLeft: "5px solid green", borderRadius: 2, p: 2, mb: 2,}}>
                                
      <Typography variant="subtitle2" sx={{width: "33%", flexShrink: 0, fontWeight: 600,}}>
        Register
      </Typography>

      <Typography variant="subtitle2" sx={{ lineHeight: 1.3, fontSize: "0.8rem" }}>
        {item.type ? (<Box><strong>Type: </strong>{item.type}</Box>) : null}
        {item.power_type ? (<Box><strong>Power Type: </strong>{item.power_type}</Box>) : null}
        {item.type ? (<Box><strong>Name: </strong>{item.machine_name}</Box>) : null}
        {item.type === "irrigation, generic" && item.power_type === "energy" ? (<Box><strong>Energy Consumption: </strong>{item.energy_cons}{" kWh"}</Box>) : null}
        {item.model ? (<Box><strong>Model: </strong>{item.model}</Box>) : null}
        {item.specific_type ? (<Box><strong>Specific Type: </strong>{item.specific_type}</Box>) : null}
        {item.specific_subtype ? (<Box><strong>Specific Subtype: </strong>{item.specific_subtype}</Box>) : null}
        {item.operating_yield ? (<Box><strong>Operating Yield: </strong>{item.operating_yield}{" ha/h"}</Box>) : null}
        {item.energy_type ? (<Box><strong>Energy Type: </strong>{item.energy_type}</Box>) : null}
      </Typography>

    </Box>
  )
}

export default ShowRegister
