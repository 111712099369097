import React from 'react'
import ShowDataContainer from '../../layout/ShowDataContainer'
import { Box, Typography } from '@mui/material'
import { formatNumberFloat } from '../../../hooks/functions'

function ShowSeed(props) {

  const {itemPlot} = props

  return (
    <ShowDataContainer>
      <Typography variant="subtitle2" sx={{width: "100%", flexShrink: 0, fontWeight: 600,}}>
        PROPAGATING MATERIAL
      </Typography>  
      {itemPlot.crop.seed.type ? (<Box><strong>Type of Propagating Material:</strong>{" "}{itemPlot.crop.seed.type}</Box>) : null}
      {itemPlot.crop.seed.amount ? (<Box><strong>Amount:</strong>{" "}{formatNumberFloat(itemPlot.crop.seed.amount)}</Box>) : null}
      {itemPlot.crop.seed.unit ? (<Box><strong>Unit:</strong> {itemPlot.crop.seed.unit}</Box>) : null}
    </ShowDataContainer>
  )
}

export default ShowSeed
