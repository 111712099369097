import { Box, Typography } from '@mui/material'
import React from 'react'

const ShowSummary = (props) => {

  const {title, subtitle, size} = props

  return (
    <Box
      width={"100%"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={'center'}
    >
      <Typography
        sx={ 
          size==='small'
          ? {width: "90%", flexShrink: 0, fontSize:'0.80rem'}
          : {width: "90%", flexShrink: 0, fontWeight: 600,}
        }
      >
        {title}
      </Typography>

      <Typography
        variant="body2"
        sx={{ width: "90%", flexShrink: 0 }}
      >
        {subtitle}
      </Typography>
      
    </Box>
  )
}

export default ShowSummary
