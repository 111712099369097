import { Button } from '@mui/material'
import React from 'react'
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';

const ButtonEdit = (props) => {

  const {item, handleEdit, params} = props

  return (
    <Button
      size="small"
      variant="contained"
      sx={{textTransform:"none"}}
      //sx={{ py: "6px", height: "20px", textTransform: "none",}}
      onClick={() => handleEdit(item, params)}
      startIcon={<BorderColorRoundedIcon />}
    >
      Edit
    </Button>
  )
}

export default ButtonEdit
