import { Box, Typography } from '@mui/material'
import React from 'react'

function ShowRegister(props) {

  const {item} = props

  return (
    <Box sx={{ border: "1px solid green", borderLeft: "5px solid green", borderRadius: 2, p: 2, mb: 2,}}>
                              
        <Typography variant="subtitle2" sx={{ flexShrink: 0, fontWeight: 600 }}>
          Register
        </Typography>

        <Typography variant="subtitle2" sx={{ lineHeight: 1.3, fontSize: "0.8rem" }}>
          {item.main_type ? (<Box><strong>Type: </strong> {item.main_type}</Box>) : null}
          {item.type ? (<Box><strong>Specific Type: </strong>{item.type}</Box>) : null}
          {item.dens ? (<Box><strong>Density: </strong>{item.dens}{" kg/l"}</Box>) : null}
          {item.N ? (<Box><strong>N Concentration: </strong>{item.N}{" kg N/kg"}</Box>) : null}
          {item.P ? (<Box><strong>P Concentration: </strong>{item.P}{" kg P/kg"}</Box>) : null}
          {item.K ? (<Box><strong>K Concentration: </strong>{item.K}{" kg K/kg"}</Box>) : null}
          {item.total_n_content ? (<Box><strong>Total n Content: </strong>{item.total_n_content}</Box>) : null}
          {item.CaCO3 ? (<Box><strong>CaCO3 Content: </strong>{item.CaCO3}</Box>) : null}
        </Typography>

      </Box>
  )
}

export default ShowRegister
