import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import validationSchema from "./plotEventEditValidationSchema";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import PopoverCustom from "../../layout/PopoverCustom";
import {
  fetchEnumerationEventOperationType,
  fetchEnumerationEventTypeCrop,
  fetchEnumerationNameOperation,
  fetchEnumerationEventApplicationType,
  fetchEnumerationEventApplicationUnit,
  fetchEnumeracionEventOperationMachinery,
  fetchEnumerationEventOperationMachineryImplements,
  fetchEnumerationEventOperationEcoinvet,
  fetchEnumerationEventMainOperationProducts,
  fetchEnumerationEventGenericHasTransport,
  fetchEnumerationEventGenericTransportType,
  fetchEnumerationEventGenericTransportMachinery,
  fetchEnumerationEventGenericTransportImplements,
  resetEnumerationEventOperationMachineryImplements,
  checkEventGenericHasMachinery,
  checkEventTransportHasMachinery,
  fetchEnumerationPesticideFoliarInterception,
  fetchEnumerationPesticideApplicationMethods,
  resetEnumerationEventGenericTransportImplements,
} from "../../../redux/enumeratedSlice";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import ProductsForm from "../../products/ProductsForm";
import {
  addLandEvent,
  editLandEvent,
  fetchEditEventData,
  fetchEventForEdition,
  fetchUserAll,
} from "../../../redux/userAllSlice";
import {
  numericField,
  numericOnly,
  objetoVazio,
} from "../../../hooks/functions";
import { v4 as uuidv4 } from "uuid";
import CloseIcon from "@mui/icons-material/CancelRounded";
import MachineryForm from "../../machinery/MachineryForm";
import ImplementForm from '../../implements/ImplementForm';
import Loading from '../../layout/Loading';

export const PlotEventForm = (props) => {
  const {
    userAll,
    cropName,
    type_,
    openLandEventFormEdit,
    setOpenLandEventFormEdit,
    landEventSelected,
    fetchEnumerationEventOperationType,
    plot_terrain,
    fetchEnumerationEventTypeCrop,
    fetchEnumerationNameOperation,
    fetchEnumeracionEventOperationMachinery,
    eventOperationMachinery,
    fetchEnumerationEventApplicationUnit,
    eventApplicationUnit,
    fetchEnumerationEventOperationMachineryImplements,
    eventOperationMachineryImplements,
    editLandEvent,
    fetchEnumerationEventGenericHasTransport,
    eventGenericHasTransport,
    fetchEnumerationEventGenericTransportMachinery,
    eventGenericTransportMachinery,
    fetchUserAll,
    fetchEditEventData,
    fetchEnumerationEventApplicationType,
    fetchEnumerationEventMainOperationProducts,
    eventMainOperationProducts,
    checkEventGenericHasMachinery,
    checkEventTransportHasMachinery,
    fetchEnumerationEventGenericTransportImplements,
    eventGenericTransportImplements,
  } = props;

  const [ecoinventAmountUnit, setEcoinventAmountUnit] = useState("");
  const [eventType, setEventType] = useState("");
  const [hasMachinery, setHasMachinery] = useState(false);
  const [indexAddImplement, setIndexAddImplement] = useState(0)
  const [indexAddMachinery, setIndexAddMachinery] = useState(0)
  const [indexAddProduct, setIndexAddProduct] = useState(0)
  const [initialValues, setInitialValues] = useState({});
  const [implementSelected, setImplementSelected] = useState({})
  const [implementsTransportList, setImplementsTransportList] = useState([])
  const [implementTransportSelected, setImplementTransportSelected] = useState({})
  const [loading, setLoading] = useState(true)
  const [loadingForm, setLoadingForm] = useState(false);
  const [machinerySelected, setMachinerySelected] = useState({})
  const [machineryTransportList, setMachineryTransportList] = useState([])
  const [machineryTransportSelected, setMachineryTransportSelected] = useState([]);
  const [openImplementForm, setOpenImplementForm] = useState(false);
  const [openMachineryForm, setOpenMachineryForm] = useState(false);
  const [openProductForm, setOpenProductForm] = useState(false);
  const [operationMachineryTypeSelected, setOperationMachineryTypeSelected] = useState("");
  const [operationNameSelected, setOperationNameSelected] = useState("")
  const [origin, setOrigin] = useState('');
  const [possibleTransport] = useState([]);
  const [productSelected, setProductSelected] = useState("");
  const [renderForm, setRenderForm] = useState(false);
  const [transportNameSelected, setTransportNameSelected] = useState("")
  const [transportsImplementsSelected, setTransportsImplementsSelected] = useState([])
  const [typeApplication, setTypeApplication] = useState([]);
  const [typeApplicationAdd, setTypeApplicationAdd] = useState("");
  const [update, setUpdate] = useState(0)

  const user = JSON.parse(localStorage.getItem("user"));
  const property = JSON.parse(localStorage.getItem("property"));

  // Obter os dados e listas iniciais do evento selecionado para alteração
  useEffect(() => {
    async function getEditEventData() {
      setLoadingForm(true);
      const params = {
        user_id: user.user_icv_id,
        prop_id: property._id,
        event_id: landEventSelected._id,
        plot_id: "",
      };
      const result = await fetchEditEventData(params);

      if (result.payload.main_operation.ecoinvent) {
        const getUnit = result.payload.main_operation.ecoinvent.split(",");
        setEcoinventAmountUnit(getUnit[getUnit.length - 1]);
      }

      setEventType(result.payload.type)
      setOperationNameSelected(result.payload.main_operation.name || result.payload.main_operation.ecoinvent)

      if(result.payload?.transports) {
        setTransportNameSelected(result.payload?.transports[indexAddMachinery].name)
      }

      if(result.payload.main_operation.name){
        const paramsMachinery = {
          user_id: user.user_icv_id,
          prop_id: property._id,
          event_type: result.payload.type,
          op_name: result.payload.main_operation.name,
        };
        await fetchEnumeracionEventOperationMachinery(paramsMachinery);
      }

      setMachineryTransportSelected(result.payload.transports);
      
      if(result.payload.main_operation.name){
        const resultHasMachinery = await checkEventGenericHasMachinery(result.payload.main_operation.name);
        setHasMachinery(resultHasMachinery.payload);
      }

      if(result.payload.transports){
        const listAtual = result.payload.transports.map((el, index) => {
          return result.payload.transports[index].machinery[0].implementList
        })
        setImplementsTransportList(listAtual)
      }

      const nameOperation =
        result.payload.main_operation.name ||
        result.payload.main_operation.ecoinvent;
      const params1 = {
        op_name: nameOperation,
      };
      const resultTypeList = await fetchEnumerationEventApplicationType(
        params1
      );
      setTypeApplication(resultTypeList.payload || []);

      let transports = []

      result.payload.transports &&
        result.payload.transports.map((t, ind) => {
        ///// verificar se é exigido maquinário
        let transports = []
        const hasMachineryTransports = getHasTransportHasMachinery(result.payload.main_operation.name,t.name)
          .then((res) => {result.payload.transports[ind].hasMachineryTransports = res;})
       
        if (result.payload.transports[ind].possibleMachineryList){
          result.payload.transports[ind].machineryList = result.payload.transports[ind].possibleMachineryList;
        } else {
          result.payload.transports[ind].machineryList = result.payload.transports[ind].machineryList = result.payload.transports[ind].machinery[0].machineryList;
        }

        transports.push({
          _id: t._id,
          type: t.type,
          name: t.name,
          nameList: t.nameList || [],
          hasMachineryTransports,
          machinery: [
            {
              _id: "",
              implements: [],
              implementList: [],
            },
          ],
          machineryList: t.possibleMachineryList || [],
        })
        return true
      });

      if(result.payload.main_operation.machinery){
        const paramsImplements = {
          user_id: user.user_icv_id,
          prop_id: property._id,
          mach_spectype: result.payload.main_operation.machinery[0].specific_type,
          op_name: result.payload.main_operation.name,
        };
        await fetchEnumerationEventOperationMachineryImplements(paramsImplements);
      }

      if(result.payload.transports){
        const list = result.payload.transports.map((el, index) => {
          return el.machineryList
        })
        setMachineryTransportList(list)
      }

      // Montar a lista inicial de Agricultural Inputs do evento selecionado para edição
      const paramsAgriculturalInput = {
        user_id: user.user_icv_id,
        type_: result.payload.applications[indexAddProduct].type,
        prop_id: property._id,
      };

      await fetchEnumerationEventMainOperationProducts(paramsAgriculturalInput);
      setUpdate(update + 1)

      setInitialValues({
        ...result.payload,
        ...transports
      })
       
      await fetchEnumerationEventGenericHasTransport(
        result.payload.main_operation.name ||
          result.payload.main_operation.ecoinvent
      );

      if (result.payload.applications) {
        setProductSelected(result.payload.applications.map((ap) => ap.product));
      }
      setRenderForm(true);
      setLoadingForm(false);
    }

    if (!objetoVazio(landEventSelected)) {
      getEditEventData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landEventSelected]);

  async function init(){
    setTimeout(() => {
      numericField()
    }, 1000);
  }

  useEffect(()=> {
    init()
  }, [loading, loadingForm])


  useEffect(()=>{
    const getMachineryList = async () => {
      if( eventType !== "" && operationNameSelected !== "") {
        const params = {
          user_id: user.user_icv_id,
          prop_id: property._id,
          event_type: eventType,
          op_name: operationNameSelected,
        };
        await fetchEnumeracionEventOperationMachinery(params);
      }
    }
    getMachineryList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machinerySelected])

  useEffect(()=>{
    if(typeApplicationAdd !== ""){
      const getProdutcs = async () => {
        const params = {
          user_id: user.user_icv_id,
          type_: typeApplicationAdd,
          prop_id: property._id,
        };
        await fetchEnumerationEventMainOperationProducts(params);
        setUpdate(update + 1)
      }
      getProdutcs()
      setUpdate(update + 1)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productSelected])

  useEffect(() => {
    // Atualizar a lista de unidades de medida de produtos em applications
    if(productSelected.length && productSelected[0].name){
      const getUnit = async () => {
        await  fetchEnumerationEventApplicationUnit(productSelected[0]._id)
      }
      getUnit()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productSelected]);

  useEffect(()=>{
    if (operationNameSelected !== "" && transportNameSelected !== "") {
      const getMachineryTransportList = async () => {
        const params1 = {
          user_id: user.user_icv_id,
          prop_id: property._id,
          op_name: operationNameSelected,
          t_name: transportNameSelected,
        };
        const result = await fetchEnumerationEventGenericTransportMachinery(params1);
        let atualList = machineryTransportList
        let newItem = result.payload
        atualList.splice(indexAddMachinery, 1, newItem)
        await setMachineryTransportList(atualList)
        setUpdate(update + 1)
      }
      getMachineryTransportList()
      setUpdate(update + 1)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machineryTransportSelected])

  useEffect(() => {
    // Atualizar a lista de implementos no item em que o botão "Add implement" foi acionado
    // ao pressionar "Add implement" o sistema ira gravar o índice do item em 
    // indexAddImplement.
    // o item selecionado será atualizados pelo formImplements e ficará armazenado em 
    // implementTransportSelected
    // transportsImplementsSelected[index] deverá ser atualizado com o novo implemento
    // incluído, se este satisfizer as condições dos parâmetros exigidos. 
    if(operationNameSelected !== "" && transportNameSelected !== ""){
      const getImplementsTransport = async () => {
        const indexProp = userAll.registro.properties.findIndex((reg) => reg._id === property._id);
        const mach = userAll.registro.properties[indexProp].prop_machinery.filter((el) => el._id === machineryTransportSelected);
        const params = {
          user_id: user.user_icv_id,
          prop_id: property._id,
          op_name: operationNameSelected,
          t_name: transportNameSelected,
          mach_spectype: mach[0]?.specific_type || null,
        };
        const result = await fetchEnumerationEventGenericTransportImplements(params);
        let atualList = implementsTransportList
        let newItem = result.payload
        atualList.splice(indexAddImplement, 1, newItem)
        await setImplementsTransportList(atualList)
        setUpdate(update + 1)
      }
      getImplementsTransport()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [implementTransportSelected]);



  useEffect(() => {
    if(implementSelected.data){
      const getImplements = async () => {
        const params = {
          user_id: user.user_icv_id,
          prop_id: property._id,
          mach_spectype: operationMachineryTypeSelected,
          op_name: operationNameSelected,
        };
        await fetchEnumerationEventOperationMachineryImplements(params);
      }
      getImplements()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [implementSelected]);

  

  
  const getHasTransportHasMachinery = async (op_name, t_name) => {
    const params = {
      op_name,
      t_name,
    };
    const result = await checkEventTransportHasMachinery(params);
    return result.payload;
  };

  const handleOpenProductForm = (setFieldValue, values, index) => {
    setFieldValue(`applications[${index}].product`, "");
    setTypeApplicationAdd(values.applications[index].type);
    setOrigin('addEvent')
    setIndexAddProduct(index)
    setOpenProductForm(true);
  };

  useEffect(() => {
    const getType = async () => {
      const params = {
        crop: "", 
        p_type: "plot_terrain",
      };
      await fetchEnumerationEventTypeCrop(params);
    };
    getType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventType]);

  useEffect(() => {
    eventType && fetchEnumerationEventOperationType(eventType);
  }, [eventType, fetchEnumerationEventOperationType, landEventSelected]);

  useEffect(() => {
    const params = {
      crop: cropName,
      type_: type_,
      e_type: eventType,
    };
    cropName && type_ && eventType && fetchEnumerationNameOperation(params);
  }, [cropName, eventType, fetchEnumerationNameOperation, type_]);

  useEffect(() => {
    if(productSelected._id){
      fetchEnumerationEventApplicationUnit(productSelected._id);
    }
  }, [fetchEnumerationEventApplicationUnit, productSelected]);

  const handleClose = () => {
    setEventType("");
    setOpenLandEventFormEdit(false);
  };

  const handleChangeImplements = (event, setFieldValue, value) => {
    setFieldValue("main_operation.machinery[0].implements", value);
  };

  const handleChangeApplicationsProduct = async (event, index, setFieldValue, value, values, option) => {
    const prod = eventMainOperationProducts.registros.filter((p)=> p._id === value.props.value)

    if (prod.length > 0) {
      productSelected[index] = prod[0];
      setFieldValue(`applications[${index}].product`, prod[0]);
      setFieldValue(`applications[${index}].amount_unit`, "");
      
      const result = await fetchEnumerationEventApplicationUnit(event.target.value);

      await values.applications[index].productList.map((p, ind) => {
        if (prod.length > 0 && p._id === prod[0]?._id) {
          setFieldValue(`applications[${index}].productList[${ind}].unitList`, result.payload);
        }
        return true;
      });
    }
  };

  const handleChangeOperationMachinery = async (event, setFieldValue, values) => {
    setLoadingForm(true)
    const machinery_id = event.target.value;
    setFieldValue("main_operation.machinery[0]._id", machinery_id);
    setFieldValue("main_operation.machinery[0].implements", [])
    await resetEnumerationEventOperationMachineryImplements();
    const indexProp = userAll.registro.properties.findIndex((reg) => reg._id === property._id);
    const machinery = userAll.registro.properties[indexProp].prop_machinery.filter((el) => el._id === machinery_id);
    
    if (machinery[0]?.type === 'detailed, generic' || machinery[0]?.type === 'detailed, tractor'){
      setOperationMachineryTypeSelected(machinery[0]?.specific_type);
      const params = {
        user_id: user.user_icv_id,
        prop_id: property._id,
        mach_spectype: machinery[0]?.specific_type,
        op_name: operationNameSelected,
      };
      const result = await fetchEnumerationEventOperationMachineryImplements(params);
      setFieldValue('main_operation.machinery[0].implementList', result.payload)
    }

    setLoadingForm(false)
  };

  const handleChangeTransportsMachinery = async (event, value, values, setFieldValue, index) => {
    if (event.target.value !== 'add_options'){
      setFieldValue(`transports[${index}].machinery[0]._id`, value.props.value);
      setMachineryTransportSelected(value.props.value)
      // Limpar implementos
      setFieldValue(`transports[${index}].machinery[0].implements`, [])
      // Limpar implementos no estado: 
      // implementsTransportList
      let listAtual = implementsTransportList
      listAtual.splice(index, 1, [])
      setImplementsTransportList(listAtual)
      // Limpar implementos no estado: 
      // transportsImplementsSelected  
      let selectedAtual = transportsImplementsSelected
      selectedAtual.splice(index, 1, [])
      setTransportsImplementsSelected(selectedAtual)
      resetEnumerationEventGenericTransportImplements()
      //// Limpar msg erro do item
      setFieldValue('transports.errorImplements', '')
      const mach_id = value.props.value;
      const mach = eventGenericTransportMachinery.registros?.data[0].filter((el) => el._id === mach_id);
      const params = {
        user_id: user.user_icv_id,
        prop_id: property._id,
        op_name: values.main_operation.name,
        t_name: values.transports[index].name,
        mach_spectype: mach[0]?.specific_type || null,
      };
      const result = await fetchEnumerationEventGenericTransportImplements(params);
      const newItem = result.payload
      let atual = implementsTransportList
      atual.splice(index, 1, newItem)
      setImplementsTransportList(atual)
      if(!!result.payload.detail){
        setFieldValue(`transports[${index}].errorImplements`, result.payload.detail)
      } else {
        setFieldValue(`transports[${index}].implementsList`, result.payload)
      }
    }
  };

  const handleChangeTransportsName = async (event, index, setFieldValue, values, value) => {
    setTransportNameSelected(value)
    setFieldValue(`transports[${index}].name`, value);
    setFieldValue(`transports[${index}].machinery`, {});
    setFieldValue(`transports[${index}].errorImplements`, '')
    ///// verificar se é exigido maquinário
    const params = {
      op_name: values.main_operation.name,
      t_name: value,
    };
    const result = await checkEventTransportHasMachinery(params);

    if (result.payload) {
      setFieldValue(`transports[${index}].hasMachineryTransports`, result.payload);
      const params1 = {
        user_id: user.user_icv_id,
        prop_id: property._id,
        op_name: values.main_operation.name,
        t_name: value,
      };
      const result1 = await fetchEnumerationEventGenericTransportMachinery(params1);
      if(!!result1.payload.detail){
        setFieldValue(`transports[${index}].errorMachinery`, result1.payload)
      } else {
        setFieldValue(`transports[${index}].machineryList`, result1.payload || [])
        const newItem = result1.payload
        let atual = machineryTransportList.slice()
        const newItems = [ ...atual, newItem ]
        setMachineryTransportList(newItems)
      }
    } else {
      setFieldValue(`transports[${index}].hasMachineryTransports`, false);
    }
  };

  const handleChangeApplicationType = async (event, values, setFieldValue, index) => {
    const type = event.target.value;
    setFieldValue(`applications[${index}].type`, type);
    const params = {
      user_id: user.user_icv_id,
      prop_id: property._id,
      type_: type,
    };
    const result = await fetchEnumerationEventMainOperationProducts(params);
    setFieldValue(`applications[${index}].productList`, result.payload);
    // Pesticide Foliar interception
    if (event.target.value === "pesticide") {
      const params = {
        crop: cropName,
      };
      const resultFoliar = await fetchEnumerationPesticideFoliarInterception(params);
      setFieldValue(`applications[${index}].foliarInterceptionList`, resultFoliar.payload);
      const resultMethods = await fetchEnumerationPesticideApplicationMethods(params);
      setFieldValue(`applications[${index}].applicationMethodList`, resultMethods.payload);
    }
  };

  const handleChangeTransportsImplements = (event, setFieldValue, value, index) => {
    const impl = value.map((el) => el);
    setFieldValue(`transports[${index}].machinery[0].implements`, impl);
  };

  const handleSubmit = async (values, resetForm, setFieldError) => {
    let validado = true;
    const ident = {
      user_id: user.user_icv_id,
      prop_id: property._id,
      plot_ter_id: plot_terrain._id,
      event_id: values._id,
      crop: "",
    };
    //// Operations machinery
    let operationsMachinery = [];
    if (values.main_operation.machinery) {
      if (
        values.main_operation.machinery.length !== 0 &&
        values.main_operation.machinery[0]._id !== ""
      ) {
        operationsMachinery = [
          {
            machinery_id: values.main_operation.machinery[0]._id || '',
            implements: values.main_operation.machinery[0].implements.map((el) => 
              {
                if(typeof el === 'object') {
                  return el._id
                } else {
                  return el
                }
              }
            )
          },
        ];
      }
    }
    //// Transports
    let transportsArray = [];
    values.transports &&
      values.transports.map((el, index) => {
        let ta = {
          _id: el._id || uuidv4(),
          type: el.type || 'generic',
          name: el.name,
        };
        if (el.hasMachineryTransports) {
          ta = {
            ...ta,
            machinery: el.machinery[0]
              ? [
                  {
                    machinery_id: el.machinery[0]._id,
                    implements: el.machinery[0].implements.map(item => {
                      if(item._id)
                        return item._id
                      return item
                    }),
                  },
                ]
              : [],
          };
          transportsArray.push(ta);
        }
        return true;
      });
    let params = {};
    let applicationsArray = [];

    //// Applications
    if (values.applications) {
      values.applications.map((el, index) =>
          el.type !== "" && 
          applicationsArray.push({
            _id: el._id,
            type: el.type,
            amount: el.amount,
            amount_unit: el.amount_unit,
            product: typeof el.product === "string" ? el.product : el.product._id,
            foliar_interception: el.foliar_interception === "" ? null : el.foliar_interception,
            fi_amount: el.fi_amount === "" ? null : el.fi_amount,
            application_method: el.application_method === "" ? null : el.application_method,
          })
      );
    }

    if (values.type === "application" &&  values.main_operation.type === "from ecoinvent") {
      params = {
        ...ident,
        payload: {
          type: values.type,
          date: values.date,
          benefit_time: values.benefit_time,
          main_operation: {
            type: values.main_operation.type,
            ecoinvent: values.main_operation.ecoinvent,
            amount: values.main_operation.amount,
          },
          applications: applicationsArray,
        },
      };
    }
    if (values.type === "application" && values.main_operation.type === "generic") {
      params = {
        ...ident,
        payload: {
          type: values.type,
          date: values.date,
          benefit_time: values.benefit_time,
          main_operation: {
            _id: values.main_operation._id,
            type: values.main_operation.type,
            name: values.main_operation.name,
            machinery: operationsMachinery,
          },
          transports: transportsArray,
          applications: applicationsArray,
        },
      };
    }
    if (values.type === "burning" && values.main_operation.type === "from ecoinvent") {
      params = {
        ...ident,
        payload: {
          type: values.type,
          date: values.date,
          area_burnt: values.area_burnt,
          comb_fuel: values.comb_fuel,
          //benefit_time: values.benefit_time,
          main_operation: {
            type: values.main_operation.type,
            ecoinvent: values.main_operation.ecoinvent,
            amount: values.main_operation.amount,
          },
        },
      };
    }
    if (values.type === "burning" && values.main_operation.type === "generic") {
      params = {
        ...ident,
        payload: {
          type: values.type,
          date: values.date,
          area_burnt: values.area_burnt,
          comb_fuel: values.comb_fuel,
          //benefit_time: values.benefit_time,
          main_operation: {
            type: values.main_operation.type,
            name: values.main_operation.name,
            machinery: operationsMachinery,
          },
        },
      };
    }
    if (values.type === "generic" && values.main_operation.type === "from ecoinvent") {
      params = {
        ...ident,
        payload: {
          type: values.type,
          date: values.date,
          main_operation: {
            type: values.main_operation.type,
            ecoinvent: values.main_operation.ecoinvent,
            amount: values.main_operation.amount,
          },
        },
      };
    }
    if (values.type === "generic" && values.main_operation.type === "generic") {
      params = {
        ...ident,
        payload: {
          type: values.type,
          date: values.date,
          main_operation: {
            type: values.main_operation.type,
            name: values.main_operation.name,
            machinery: operationsMachinery,
          },
          //transports: transportsArray,
        },
      };
      if (transportsArray.length > 0) {
        params.payload = { ...params.payload, transports: transportsArray };
      }
    }
    if (values.type === "irrigation, generic" && values.main_operation.type === "from ecoinvent") {
      params = {
        ...ident,
        payload: {
          type: values.type,
          date: values.date,
          main_operation: {
            type: values.main_operation.type,
            ecoinvent: values.main_operation.ecoinvent,
            amount: values.main_operation.amount,
          },
          applications: applicationsArray,
        },
      };
    }
    if (values.type === "irrigation, generic" && values.main_operation.type === "generic") {
      params = {
        ...ident,
        payload: {
          type: values.type,
          date: values.date,
          main_operation: {
            type: values.main_operation.type,
            name: values.main_operation.name,
            machinery: operationsMachinery,
          },
          applications: applicationsArray,
          transports: transportsArray,
        },
      };
    }
    if (values.type === "irrigation, periodic" && values.main_operation.type === "from ecoinvent") {
      params = {
        ...ident,
        payload: {
          type: values.type,
          date: values.date,
          frequency: values.frequency,
          main_operation: {
            type: values.main_operation.type,
            ecoinvent: values.main_operation.ecoinvent,
            amount: values.main_operation.amount,
          },
          applications: applicationsArray,
        },
      };
    }
    if (values.type === "irrigation, periodic" && values.main_operation.type === "generic") {
      params = {
        ...ident,
        payload: {
          type: values.type,
          date: values.date,
          frequency: values.frequency,
          main_operation: {
            type: values.main_operation.type,
            name: values.main_operation.name,
            machinery: operationsMachinery,
          },
          applications: applicationsArray,
          transports: transportsArray,
        },
      };
    }
    if (validado) {
      await editLandEvent(params);
      resetForm();
      setInitialValues({});
      handleClose();
      await fetchUserAll(user.user_icv_id);
    }
  };

  const handleChangeFoliarInterception = (e, index, setFieldValue) => {
    setFieldValue(`applications[${index}].foliar_interception`, e.target.value);
    setFieldValue(`applications[${index}].application_method`, "");
    setFieldValue(`applications[${index}].fi_amount`, "");
  };

  const handleOpenMachineryForm = async (setFieldValue, values) => {
    setOrigin("addEvent")
    await setOpenMachineryForm(true);
  };

  useEffect(() => {
    setTimeout(() => {
      const dialog = document.querySelector('.dialog')
      if(dialog){
        dialog.scrollIntoView({behavior: 'smooth', block: 'start'})
      }
      setLoading(false)
      }, 1500);
  }, [initialValues])

  // const handleOpenImplementForm = async (setFieldValue, values) => {
  //   setOrigin('editEvent')
  //   await setOpenImplementForm(true);
  // };

  const handleOpenImplementForm = async (setFieldValue, values) => {
    setOperationMachineryTypeSelected(values.main_operation.machinery[0].specific_type)
    setOperationNameSelected(values.main_operation.name)
    setOrigin('editEvent')
    await setOpenImplementForm(true);
  };

  const handleOpenMachineryFormTransport = async (setFieldValue, values, index) => {
    setOrigin("addEventTransport")
    setIndexAddMachinery(index)
    await setOpenMachineryForm(true);
  };

  const handleOpenImplementFormTransport = async (setFieldValue, values, index) => {
    setOrigin('addEventTransport')
    setIndexAddImplement(index)
    await setOpenImplementForm(true);
  };

  return (
    <div>
      { loading || loadingForm ? (<Loading />) : null }
      <Dialog
        open={openLandEventFormEdit}
        onClose={handleClose}
        maxWidth="md"
        scroll="body"
      >
        <DialogTitle
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          className='dialog'
        >
          Edit Plot Activity Event
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{position: "absolute", right: 8, top: 8, color: "green",}}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Typography variant="body2" sx={{ ml: 3, mt: 1, color: "red", fontWeight: "bold" }}>
          * Required Fields
        </Typography>
        <Formik
          initialValues={initialValues}
          enableReinitialize={renderForm}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm, setFieldError }) => {
            handleSubmit(values, resetForm, setFieldError);
          }}
        >
          {({values, touched, errors, handleChange, setFieldValue, setFieldError, isValid,}) => {
            return (
              <Form noValidate autoComplete="off">
                { loading || loadingForm ? (<Loading />) : null }
                <DialogContent sx={{ minWidth: { lg: "900px", md: "800px", sm: "300px" } }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <TextField
                        disabled
                        required
                        fullWidth
                        size="small"
                        name="type"
                        label="Type"
                        value={values.type || eventType}
                      />
                    </Grid>
                    {
                      values.type !== "" 
                      ? (
                        <>
                          <Grid item xs={12} sm={6} md={4} lg={4}>
                            <TextField
                              required
                              fullWidth
                              size="small"
                              name="date"
                              label="Date"
                              type="date"
                              value={values.date || ""}
                              onChange={handleChange}
                              InputLabelProps={{ shrink: true }}
                              error={Boolean(errors?.date)}
                              helperText={errors?.date}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} md={4} lg={4}>
                            <FormControl fullWidth variant="outlined">
                              <InputLabel size="small" htmlFor="area_burnt">
                                Application Benefit Time
                              </InputLabel>
                              <OutlinedInput
                                fullWidth
                                size="small"
                                name="benefit_time"
                                id="benefit_time"
                                label="Application Benefit Time"
                                value={values.benefit_time || ""}
                                onChange={handleChange}
                                error={touched.benefit_time && Boolean(errors.benefit_time)}
                                inputProps={{ step: 1, min: 1 }}
                                type="number"
                                endAdornment={
                                  <InputAdornment position="end">
                                    <Box mr={1}>[days]</Box>
                                  </InputAdornment>
                                }
                              />
                            </FormControl>
                            {
                              touched.benefit_time && errors.benefit_time && (
                                <FormHelperText error>
                                  {errors.benefit_time}
                                </FormHelperText>
                              )
                            }
                          </Grid>
                          
                          {
                            values.type === "burning" 
                            ? (
                              <>
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                  <FormControl fullWidth variant="outlined">
                                    <InputLabel size="small" htmlFor="area_burnt">
                                      Burnt Area
                                    </InputLabel>
                                    <OutlinedInput
                                      fullWidth
                                      size="small"
                                      name="area_burnt"
                                      id="area_burnt"
                                      label="Burnt Area"
                                      value={values.area_burnt ? values.area_burnt.toString(): ""}
                                      onChange={handleChange}
                                      onKeyDown={numericOnly}
                                      error={touched.area_burnt && Boolean(errors.area_burnt)}
                                      inputProps={{ step: 0.01, min: 0 }}
                                      type="number"
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <Box mr={1}>[ha]</Box>
                                          <PopoverCustom info={ "Area in which the burning occurred"}/>
                                        </InputAdornment>
                                      }
                                    />
                                  </FormControl>
                                  {
                                    touched.area_burnt && errors.area_burnt && (
                                      <FormHelperText error>
                                        {errors.area_burnt}
                                      </FormHelperText>
                                    )
                                  }
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                  <FormControl fullWidth variant="outlined">
                                    <InputLabel size="small" htmlFor="comb_fuel">
                                      Biomass Fuel Burnt
                                    </InputLabel>
                                    <OutlinedInput
                                      fullWidth
                                      size="small"
                                      name="comb_fuel"
                                      id="comb_fuel"
                                      label="Biomass Fuel Burnt"
                                      value={values.comb_fuel ? values.comb_fuel.toString() : ""}
                                      onChange={handleChange}
                                      onKeyDown={numericOnly}
                                      error={ touched.comb_fuel && Boolean(errors.comb_fuel)}
                                      inputProps={{ step: 0.01, min: 0 }}
                                      type="number"
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <Box mr={1}>[kg / ha]</Box>
                                        </InputAdornment>
                                      }
                                    />
                                  </FormControl>
                                  {
                                    touched.comb_fuel && errors.comb_fuel && (
                                      <FormHelperText error>
                                        {errors.comb_fuel}
                                      </FormHelperText>
                                    )
                                  }
                                </Grid>
                              </>
                            ) 
                            : null
                          }
                          {
                            values.type === "irrigation, periodic" 
                            ? (
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel size="small" htmlFor="frequency">
                                    Frequency of application
                                  </InputLabel>
                                  <OutlinedInput
                                    fullWidth
                                    size="small"
                                    name="frequency"
                                    id="frequency"
                                    label="Frequency of application"
                                    value={values.frequency ? values.frequency : ""}
                                    onChange={handleChange}
                                    onKeyDown={numericOnly}
                                    error={touched.frequency && Boolean(errors.frequency)}
                                    inputProps={{ step: 1, min: 1 }}
                                    type="number"
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <Box sx={{ whiteSpace: "normal",lineHeight: "1.2",}}>
                                          [days]
                                        </Box>
                                      </InputAdornment>
                                    }
                                  />
                                </FormControl>
                                {
                                  touched.frequency && errors.frequency && (
                                    <FormHelperText error>
                                      {errors.frequency}
                                    </FormHelperText>
                                  )
                                }
                              </Grid>
                            ) 
                            : null
                          }
                          <Grid item xs={12} display={"flex"} alignItems="center" gap={2}
                            sx={{
                              backgroundColor: "#F5F5F5",
                              paddingBottom: 2,
                              marginLeft: 2,
                              marginTop: 1,
                              borderRadius: 3,
                            }}
                          >
                            <Typography variant="subtitle" sx={{ fontWeight: "bold" }}>
                              Main Operation
                            </Typography>
                            <PopoverCustom info={"Operations that happened in the event. Must have one main operation and may have other transport.main_operation."}/>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                              disabled
                              required
                              fullWidth
                              size="small"
                              name={"values.main_operation.type"}
                              label="Type"
                              value={values.main_operation?.type || ""}
                            />
                          </Grid>
                          {
                            values.main_operation?.type === "generic" && (
                              <>
                                <Grid item xs={12} sm={6} md={4} lg={5}>
                                  <TextField
                                    disabled
                                    required
                                    fullWidth
                                    size="small"
                                    name="main_operation.name"
                                    label="Name"
                                    value={values.main_operation.name}
                                  />
                                </Grid>
                                {
                                  hasMachinery 
                                  ? (
                                    <Grid item xs={12} sm={6} md={4} lg={4} sx={{ mb: 2 }}>
                                      <TextField
                                        required
                                        fullWidth
                                        size="small"
                                        name="main_operation.machinery[0]._id"
                                        label="Machinery"
                                        value={ values.main_operation.machinery[0]._id || ""}
                                        select
                                        onChange={(event) =>{
                                          handleChange(event) 
                                          handleChangeOperationMachinery(event, setFieldValue, values)
                                        }}
                                        InputProps={{
                                          endAdornment: (
                                            <Box sx={{ mr: 3 }}>
                                              <PopoverCustom info={"Machinery used in the operation"} />
                                            </Box>
                                          ),
                                        }}
                                      >
                                        <MenuItem key={0} value={""}>
                                          <Button
                                            fullWidth
                                            size='small'
                                            color="primary"
                                            variant="contained"
                                            component="label"
                                            onClick={() => handleOpenMachineryForm(setFieldValue, values)}
                                            sx={{fontSize: 13, textTransform: 'none'}}
                                            startIcon={<AddCircleOutlineRoundedIcon sx={{fontSize: 15, mr: 1,}}/>}
                                          >
                                            Add machinery
                                          </Button>
                                        </MenuItem>
                                        <Divider sx={{ my: 2, border: '1px solid green' }} />
                                        
                                        {
                                          eventOperationMachinery.registros.data 
                                          && eventOperationMachinery.registros.data.length > 0 
                                          ? (
                                            eventOperationMachinery.registros.data
                                            .slice()
                                            .sort((a, b) => (a.machine_name.toUpperCase() > b.machine_name.toUpperCase() ? 1 : -1))
                                            .map((option, index) => (
                                                <MenuItem key={index} value={option._id}>
                                                  {option.machine_name || ""}
                                                </MenuItem>
                                              )
                                            )
                                          ) 
                                          : (
                                            <MenuItem key={"no_options"} value={""} disabled>
                                              {"No options"}
                                            </MenuItem>
                                          )
                                        }
                                      </TextField>
                                      { 
                                        eventOperationMachinery.registros.error
                                        ? (
                                          <FormHelperText error sx={{ml:1.75}}>
                                            {eventOperationMachinery.registros.error }
                                          </FormHelperText>
                                        ) : null 
                                      }
                                      { 
                                        !eventOperationMachinery.registros.data
                                        && typeof eventOperationMachinery.error === 'string'
                                        ? (
                                          <FormHelperText error sx={{ml:1.75}}>
                                            {eventOperationMachinery.error }
                                          </FormHelperText>
                                        ) : null 
                                      }
                                    </Grid>
                                  ) 
                                  : null
                                }

                                {
                                  hasMachinery 
                                  && !eventOperationMachineryImplements.registros.detail 
                                  && eventOperationMachineryImplements.registros.length > 0 
                                  && values.main_operation.machinery[0]._id 
                                  ? (
                                    <Grid item xs={12}>
                                      <Autocomplete
                                        multiple
                                        id={`main_operation.machinery[0].implements`}
                                        name={`main_operation.machinery[0].implements`}
                                        options={
                                          eventOperationMachineryImplements.registros
                                          .slice()
                                          .sort((a,b)=> a.implement_name.toUpperCase() < b.implement_name.toUpperCase() ? -1: 1) || []
                                        }
                                        getOptionLabel={(option) => {
                                          if(typeof option === 'string'){
                                            const implement = eventOperationMachineryImplements.registros.filter((el) => el._id === option)
                                            return implement[0]?.implement_name
                                          } else {
                                            return option?.implement_name
                                          }
                                        }}
                                        filterSelectedOptions
                                        onChange={(event, value) => { handleChangeImplements(event, setFieldValue, value);}}
                                        value={ values.main_operation.machinery[0].implements || null }
                                        isOptionEqualToValue={(option, value) => {
                                          if(typeof value === "string"){
                                            return option._id === value
                                          } else {
                                            return option._id === value._id
                                          }
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Implements"
                                            placeholder="Implements"
                                            InputProps={{
                                              ...params.InputProps,
                                              endAdornment: (
                                                <>
                                                  <InputAdornment position="end">
                                                    <PopoverCustom
                                                      info={"Implements used with the machinery, can be empty in case no implement is used"}
                                                    />
                                                  </InputAdornment>
                                                  {params.InputProps.endAdornment}
                                                </>
                                              ),
                                            }}
                                          />
                                        )}
                                      />
                                      <Button
                                        size='small'
                                        color="primary"
                                        variant='contained'
                                        component="label"
                                        onClick={() => handleOpenImplementForm(setFieldValue, values)}
                                        sx={{fontSize: 13, textTransform: 'none', mr:2, mt:1}}
                                        startIcon={<AddCircleOutlineRoundedIcon sx={{fontSize: 15, mr: 1,}}/>}
                                      >
                                        Add implement
                                      </Button>
                                    </Grid>
                                    ) 
                                  : (
                                    <Grid item xs={12} mt={-4}>
                                      {
                                        eventOperationMachineryImplements.registros.detail && (
                                          <FormHelperText error>
                                            {
                                              typeof eventOperationMachineryImplements ==="object"
                                              ? eventOperationMachineryImplements.registros.detail[0].msg
                                              : eventOperationMachineryImplements.registros.detail
                                            }
                                          </FormHelperText>
                                        )
                                      }
                                    </Grid>
                                  )
                                }
                              </>
                            )
                          }
                          {
                            values.main_operation?.type === "from ecoinvent" && (
                              <>
                                <Grid item xs={12} sm={6} md={4} lg={6}>
                                  <TextField
                                    disabled
                                    required
                                    fullWidth
                                    size="small"
                                    name="main_operation.ecoinvent`"
                                    label="Name (ecoinvent)"
                                    value={values.main_operation.ecoinvent}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                  <FormControl fullWidth variant="outlined">
                                    <InputLabel required size="small" htmlFor="amount">
                                      Amount
                                    </InputLabel>
                                    <OutlinedInput
                                      required
                                      fullWidth
                                      size="small"
                                      name={`main_operation.amount`}
                                      id="amount"
                                      label="Amount"
                                      value={values.main_operation.amount}
                                      onChange={handleChange}
                                      onKeyDown={numericOnly}
                                      inputProps={{ step: 0.01, min: 0 }}
                                      type="number"
                                      error={touched.main_operation?.amount && Boolean(errors.main_operation?.amount)}
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <Box> {ecoinventAmountUnit === " m3" ? "m³" : ecoinventAmountUnit}</Box>
                                          <PopoverCustom info={"Amount of area where the agricultural operation was carried out"}/>
                                        </InputAdornment>
                                      }
                                    />
                                    {
                                      touched.main_operation?.amount && errors.main_operation?.amount && (
                                        <FormHelperText error>
                                          {errors.main_operation?.amount}
                                        </FormHelperText>
                                      )
                                    }
                                  </FormControl>
                                </Grid>
                              </>
                            )
                          }

                          {
                            values.main_operation?.type === "generic" 
                            && (eventGenericHasTransport.registro || values.possibleTransportNameList) 
                            && (
                              <>
                                <Grid item xs={12} display={"flex"} alignItems="center" gap={2}
                                  sx={{
                                    backgroundColor: "#F5F5F5",
                                    paddingBottom: 2,
                                    marginLeft: 2,
                                    marginTop: 1,
                                    borderRadius: 3,
                                  }}
                                >
                                  <Typography variant="subtitle" sx={{ fontWeight: "bold" }}>
                                    Additional Transports
                                  </Typography>
                                </Grid>
                                
                                <FieldArray
                                  name="transports"
                                  render={(arrayHelpers, ind) => (
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                      {
                                        
                                        values.transports
                                        ? (
                                          values.transports 
                                          ? (
                                            values?.transports?.length > 0 
                                            ? (
                                              values.transports.map((item, index) => {
                                                return (
                                                  <Box key={index}>
                                                    <Grid container spacing={2} mb={2}>
                                                      <Grid item xs={11} sm={3} md={2} lg={2}>
                                                        <TextField
                                                          disabled
                                                          fullWidth
                                                          size="small"
                                                          name={`transports[${index}].type`}
                                                          label="Type"
                                                          value={ item.type === "" ? "generic" : item.type}
                                                          onChange={handleChange}
                                                        />
                                                      </Grid>
                                                      <Grid item xs={12} sm={6} md={5} lg={5} >
                                                        <Autocomplete
                                                          size="small"
                                                          id={`transports[${index}].name`}
                                                          name={`transports[${index}].name`}
                                                          getOptionLabel={(option) => option}
                                                          options={item.nameList || ["No options",]}
                                                          onChange={(event, value) => handleChangeTransportsName(event, index, setFieldValue, values, value)}
                                                          value={item.name || null}
                                                          isOptionEqualToValue={(option, value) => option === value}
                                                          renderInput={(params) => (
                                                            <TextField
                                                              {...params}
                                                              autoFocus
                                                              label="Name"
                                                            />
                                                          )}
                                                        />
                                                      </Grid>
                                                      {
                                                        values.transports[index].hasMachineryTransports 
                                                        ? (
                                                          <Grid item xs={12} sm={6} md={4} lg={4} sx={{ mb: 2 }}>
                                                            <TextField
                                                              fullWidth
                                                              size="small"
                                                              name={`transports[${index}].machinery[0]._id`}
                                                              select
                                                              label="Machinery"
                                                              value={values.transports[index]?.machinery[0]?._id || ''}
                                                              onChange={(event,value) => handleChangeTransportsMachinery(event, value, values, setFieldValue, index)}
                                                              InputProps={{
                                                                endAdornment: (
                                                                  <Box sx={{ mr: 3 }}>
                                                                    <PopoverCustom info={"Machinery used in the operation"}/>
                                                                  </Box>
                                                                ),
                                                              }}
                                                            >
                                                              <MenuItem key={"add_options"} value={"add_options"}>
                                                                <Button
                                                                  fullWidth
                                                                  size='small'
                                                                  color="primary"
                                                                  variant='contained'
                                                                  component="label"
                                                                  onClick={() => handleOpenMachineryFormTransport(setFieldValue, values, index)}
                                                                  sx={{fontSize: 13, textTransform: 'none'}}
                                                                  startIcon={<AddCircleOutlineRoundedIcon sx={{fontSize: 15, }}/>}
                                                                >
                                                                  Add machinery
                                                                </Button>
                                                              </MenuItem>
                                                              <Divider sx={{ my: 2, border: '1px solid green' }} />
                                                              {
                                                                machineryTransportList
                                                                && machineryTransportList[index]?.length > 0
                                                                && !machineryTransportList[index]?.detail
                                                                && machineryTransportList[index]
                                                                  .slice()
                                                                  .sort((a,b) => (a.machine_name > b.machine_name) ? 1 : -1)
                                                                  .map((option, index) => (
                                                                    <MenuItem key={index} value={option._id}>
                                                                      {option.machine_name || ""}
                                                                    </MenuItem>
                                                                  ))
                                                              }
                                                            </TextField>
                                                            {
                                                              !!eventGenericTransportMachinery.registros?.error && (
                                                                <Typography component="div" variant="body2" color="red">
                                                                  { eventGenericTransportMachinery.registros?.error }
                                                                </Typography>
                                                              )
                                                            }
                                                            <Box> { eventGenericTransportMachinery.error.msg } </Box>
                                                          </Grid>
                                                        ) 
                                                        : null
                                                      }
                                                      <Grid item xs={1} sm={1} md={1} lg={1}>
                                                        <Button type="button" onClick={() => arrayHelpers.remove(index)}>
                                                          <RemoveCircleOutlineRoundedIcon />
                                                        </Button>
                                                      </Grid>
                                                      <Grid item xxs={12}>
                                                        
                                                        {
                                                          values.transports[index].hasMachineryTransports
                                                          && values.transports[index]?.machinery[0]?._id 
                                                          ? (
                                                            //Verificar se há erro
                                                            <>
                                                              {
                                                                implementsTransportList[index]
                                                                && eventGenericTransportImplements.registros.detail
                                                                ? (
                                                                  <FormHelperText error >
                                                                    Implements: {eventGenericTransportImplements.registros.detail}
                                                                    <Box display={'flex'} gap={2} sx={{padding:'10'}}>
                                                                      <Button
                                                                        size="small"
                                                                        color="primary"
                                                                        variant='contained'
                                                                        component="label"
                                                                        onClick={() => handleOpenImplementFormTransport(setFieldValue, values, index)}
                                                                        sx={{fontSize: 13, textTransform: 'none', mt:1}}
                                                                        startIcon={<AddCircleOutlineRoundedIcon sx={{fontSize: 15, mr: 1,}}/>}
                                                                      >
                                                                        Add implement
                                                                      </Button>
                                                                    </Box>
                                                                  </FormHelperText>
                                                                ) : (
                                                                  <Grid item xs={11}>
                                                                    <Autocomplete
                                                                      multiple
                                                                      id={`transports[${index}].machinery[0].implements`}
                                                                      name={`transports[${index}].machinery[0].implements`}
                                                                      options={
                                                                        implementsTransportList[index]
                                                                        ? implementsTransportList[index]
                                                                          .slice()
                                                                          .sort((a,b)=>(a.implement_name.toUpperCase()>b.implement_name.toUpperCase())? 1: -1)
                                                                        : [] 
                                                                      }
                                                                      getOptionLabel={(option) => {
                                                                        if (typeof option === "string") {
                                                                          // eslint-disable-next-line array-callback-return
                                                                          return values.transports[index].machinery[0].implementList.map((im) => {
                                                                              if (im._id === option) {
                                                                                return im.implement_name;
                                                                              }
                                                                            }
                                                                          );
                                                                        } else {
                                                                          return option.implement_name;
                                                                        }
                                                                      }}
                                                                      filterSelectedOptions
                                                                      value={ values.transports[index].machinery[0].implements || [] }
                                                                      onChange={(event, value) => {handleChangeTransportsImplements(event,setFieldValue,value, index)}}
                                                                      isOptionEqualToValue={(option, value) => {
                                                                        if(typeof value === "string"){
                                                                          return option._id === value
                                                                        } else {
                                                                          return option._id === value._id
                                                                        }
                                                                      }}
                                                                      renderInput={(params) => (
                                                                        <TextField
                                                                          {...params}
                                                                          label="Implements"
                                                                          placeholder="Implements"
                                                                          InputProps={{
                                                                            ...params.InputProps,
                                                                            endAdornment: (
                                                                              <>
                                                                                <InputAdornment position="end">
                                                                                  <PopoverCustom
                                                                                    info={"Implements used with the machinery, can be empty in case no implement is used"}
                                                                                  />
                                                                                </InputAdornment>
                                                                                {params.InputProps.endAdornment}
                                                                              </>
                                                                            ),
                                                                          }}
                                                                        />
                                                                      )}
                                                                    />
                                                                    <Button
                                                                      size='small'
                                                                      color="primary"
                                                                      variant='contained'
                                                                      component="label"
                                                                      onClick={() => handleOpenImplementFormTransport(setFieldValue, values, index)}
                                                                      sx={{fontSize: 13, textTransform: 'none', mt:1, mr:2}}
                                                                      startIcon={<AddCircleOutlineRoundedIcon sx={{fontSize: 15, mr: 1,}}/>}
                                                                    >
                                                                      Add implement
                                                                    </Button>
                                                                  </Grid>
                                                                )
                                                              }
                                                            </>
                                                          )
                                                          : null
                                                        }
                                                      </Grid>
                                                    </Grid>
                                                    <Divider sx={{ my: 2, border: '1px solid green' }} />
                                                  </Box>
                                                );
                                              })
                                            ) 
                                            : null
                                          ) 
                                          : values.possibleTransportNameList 
                                            ? (
                                            <Box>
                                              <Grid container spacing={2}>
                                                <Grid item xs={11} sm={3} md={2} lg={2}>
                                                  <TextField
                                                    disabled
                                                    fullWidth
                                                    size="small"
                                                    name={`transports[0].type`}
                                                    label="Type"
                                                    value={possibleTransport.type}
                                                    onChange={handleChange}
                                                  />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={5} lg={5} sx={{ mb: 2 }}>
                                                  <Autocomplete
                                                    size="small"
                                                    id={`transports[0].name`}
                                                    name={`transports[0].name`}
                                                    getOptionLabel={(option) => option}
                                                    options={ values.possibleTransportNameList }
                                                    onChange={(event, value) => handleChangeTransportsName(event, 0, setFieldValue, values, value)}
                                                    value={possibleTransport.name || null}
                                                    isOptionEqualToValue={(option, value) => option === value}
                                                    renderInput={(params) => (
                                                      <TextField
                                                        {...params}
                                                        autoFocus
                                                        label="Name"
                                                      />
                                                    )}
                                                  />
                                                </Grid>
                                              </Grid>
                                            </Box>
                                            ) 
                                            : null
                                          )
                                        : null
                                      }
                                      <Box sx={{mt:2}}>
                                        <Button
                                          type="button"
                                          variant="outlined"
                                          onClick={() =>
                                            arrayHelpers.push(
                                            {
                                              _id: uuidv4(),
                                              type: "generic",
                                              name: "",
                                              hasMachineryTransports: "",
                                              nameList: values.transports ? values?.transports[0]?.nameList : values.possibleTransportNameList,
                                              machinery: [
                                                {
                                                  _id: "",
                                                  implements: [],
                                                  implementList: [],
                                                  machineryList: [],
                                                },
                                              ],
                                            })
                                          }
                                          startIcon={<AddCircleOutlineRoundedIcon />}
                                        >
                                          Add Additional Transports
                                        </Button>
                                      </Box>
                                    </Grid>
                                  )}
                                />
                              </>
                            )}
                          {
                            (values.type === "application" || values.type === "irrigation, generic" ||  values.type === "irrigation, periodic") 
                            && (values.main_operation.name || values.main_operation.ecoinvent) 
                            ? (
                              <>
                                <Grid
                                  item xs={12} display={"flex"} alignItems="center" gap={2}
                                  sx={{
                                    backgroundColor: "#F5F5F5",
                                    paddingBottom: 2,
                                    marginLeft: 2,
                                    marginTop: 1,
                                    borderRadius: 3,
                                  }}
                                >
                                  <Typography variant="subtitle" sx={{ fontWeight: "bold" }}>
                                    Applications
                                  </Typography>
                                  <PopoverCustom info={"Applications done in the context of the event."}/>
                                </Grid>
                                <FieldArray
                                  name="applications"
                                  render={(arrayHelpers) => (
                                    <>
                                      <Grid item xs={12} sm={12} md={12} lg={12}>
                                        {
                                          values.applications &&
                                          (
                                            values.applications.length > 0
                                            ? values.applications.map((item, index) => {
                                                  return (
                                                    <Box key={index}>
                                                      <Grid container spacing={2}>
                                                        <Grid item xs={11} sm={6} md={3} lg={3} >
                                                          <TextField
                                                            disabled={!!values.applications[index].type}
                                                            required
                                                            fullWidth
                                                            select
                                                            size="small"
                                                            name={`applications[${index}].type`}
                                                            label="Type"
                                                            value={values.applications[index].type}
                                                            onChange={(event) => { handleChangeApplicationType(event, values, setFieldValue, index);}}
                                                          >
                                                            {
                                                              typeApplication.map((option) => (
                                                                <MenuItem key={option} value={option}>
                                                                  {option}
                                                                </MenuItem>
                                                              ))
                                                            }
                                                          </TextField>
                                                        </Grid>
                                                        {
                                                          !!values.applications[index].type 
                                                          && (
                                                          <>
                                                            <Grid item xs={11} sm={5} md={4} lg={4}>
                                                              <TextField
                                                                required
                                                                fullWidth
                                                                size="small"
                                                                name={`applications[${index}].product`}
                                                                select
                                                                label="Agricultural input"
                                                                value={values.applications[index].product?._id || values.applications[index].product}
                                                                onChange={(event, value, option) => {handleChangeApplicationsProduct(event, index, setFieldValue, value, values, option);}}
                                                                error={typeof errors.applications !== "undefined" && Boolean(errors.applications[index]?.product)}
                                                                helperText={typeof errors.applications !== "undefined" && Boolean(errors.applications[index]?.product) && errors.applications[index]?.product}
                                                              >
                                                                <MenuItem key={0} value={""}>
                                                                  <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
                                                                    <Button
                                                                      fullWidth
                                                                      size='small'
                                                                      color="primary"
                                                                      variant='contained'
                                                                      component="label"
                                                                      onClick={() => handleOpenProductForm(setFieldValue, values, index)}
                                                                      sx={{fontSize: 13, textTransform: 'none'}}
                                                                      startIcon={<AddCircleOutlineRoundedIcon sx={{fontSize: 15, mr: 1,}}/>}
                                                                    >
                                                                      Add agricultural input
                                                                    </Button>
                                                                  </Box>
                                                                </MenuItem>
                                                                <Divider sx={{ my: 2, border: '1px solid green' }} />
                                                                <MenuItem key={"no_options"} value={"no_options"} disabled>
                                                                  {"Select..."}
                                                                </MenuItem>
                                                                {
                                                                  eventMainOperationProducts.registros
                                                                  && eventMainOperationProducts.registros
                                                                  .slice()
                                                                  .sort((a, b)=> (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : -1)
                                                                  .map((option) => (
                                                                    <MenuItem key={option._id} value={option._id}>
                                                                      {option.name}
                                                                    </MenuItem>
                                                                  ))
                                                                }
                                                              </TextField>
                                                            </Grid>
                                                            <Grid item xs={11} sm={6} md={2} lg={2}>
                                                              <TextField
                                                                required
                                                                fullWidth
                                                                size="small"
                                                                name={`applications[${index}].amount`}
                                                                label="Amount"
                                                                value={values.applications[index].amount}
                                                                onChange={handleChange}
                                                                type="number"
                                                                error={typeof errors.applications !== "undefined" && Boolean(errors.applications[index]?.amount)}
                                                                helperText={typeof errors.applications !== "undefined" && Boolean(errors.applications[index]?.amount) && errors.applications[index]?.amount}
                                                              />
                                                            </Grid>
                                                            {
                                                              values.applications[index].product ? (
                                                                <Grid item xs={11} sm={5} md={2} lg={2}>
                                                                  <TextField
                                                                    required
                                                                    fullWidth
                                                                    size="small"
                                                                    name={`applications[${index}].amount_unit`}
                                                                    select
                                                                    label="Unit"
                                                                    value={values.applications[index].amount_unit ? values.applications[index].amount_unit : ""}
                                                                    onChange={handleChange}
                                                                    error={typeof errors.applications !== "undefined" && Boolean(errors.applications[index]?.amount_unit)}
                                                                    helperText={typeof errors.applications !== "undefined" && Boolean(errors.applications[index]?.amount_unit) && errors.applications[index]?.amount_unit}
                                                                  >
                                                                    <MenuItem key={"no_options"} value={"no_options"} disabled>
                                                                      {"Select..."}
                                                                    </MenuItem>
                                                                    {
                                                                      eventApplicationUnit
                                                                      && eventApplicationUnit.registros.map((option) => {
                                                                        return (
                                                                          <MenuItem key={option} value={option || ""}>
                                                                            {option}
                                                                          </MenuItem>
                                                                        );
                                                                      })
                                                                    }
                                                                  </TextField>
                                                                </Grid>
                                                              ) 
                                                              : null
                                                            }
                                                            {
                                                              values.applications[index].type ==="pesticide" 
                                                              ? (
                                                                <>
                                                                  <Grid item xs={11} sm={5} md={4} lg={4}>
                                                                    <TextField
                                                                      fullWidth
                                                                      size="small"
                                                                      name={`applications[${index}].foliar_interception`}
                                                                      label="Foliar interception"
                                                                      select
                                                                      value={values.applications[index].foliar_interception}
                                                                      onChange={(e) => handleChangeFoliarInterception(e, index, setFieldValue)}
                                                                      error={typeof errors.applications !== "undefined" && Boolean(errors.applications[index]?.foliar_interception)}
                                                                      helperText={typeof errors.applications !=="undefined" && Boolean(errors.applications[index]?.foliar_interception) && errors.applications[index]?.foliar_interception}
                                                                    >
                                                                      {
                                                                        values.applications[index].foliarInterceptionList.map((option) => (
                                                                          <MenuItem key={option} value={option || ""}>
                                                                            {option}
                                                                          </MenuItem>
                                                                        ))
                                                                      }
                                                                    </TextField>
                                                                  </Grid>
                                                                  <Grid item xs={11} sm={5} md={4} lg={4}>
                                                                    <TextField
                                                                      fullWidth
                                                                      size="small"
                                                                      name={`applications[${index}].application_method`}
                                                                      label="Application method"
                                                                      select
                                                                      value={ values.applications[index].application_method}
                                                                      onChange={handleChange}
                                                                      error={typeof errors.applications !== "undefined" && Boolean(errors.applications[index]?.application_method)}
                                                                      helperText={typeof errors.applications !== "undefined" && Boolean(errors.applications[index]?.application_method) && errors.applications[index]?.application_method}
                                                                    >
                                                                      {
                                                                        values.applications[index].applicationMethodList.map((option) => (
                                                                          <MenuItem key={option} value={option || ""} sx={{maxWidth: '272px', textWrap: 'wrap'}}>
                                                                            {option}
                                                                          </MenuItem>
                                                                        ))
                                                                      }
                                                                    </TextField>
                                                                  </Grid>
                                                                  {
                                                                    values.applications[index].foliar_interception === "Other" 
                                                                    ? (
                                                                      <Grid item xs={11} sm={5} md={3} lg={3}>
                                                                        <TextField
                                                                          fullWidth
                                                                          size="small"
                                                                          name={`applications[${index}].fi_amount`}
                                                                          type="number"
                                                                          label="Foliar interception amount"
                                                                          inputProps={{ step: 0.01, min: 0, max: 1, }}
                                                                          value={values.applications[index].fi_amount}
                                                                          onChange={handleChange}
                                                                          error={typeof errors.applications !== "undefined" && Boolean(errors.applications[index]?.fi_amount)}
                                                                          helperText={typeof errors.applications !== "undefined" && Boolean(errors.applications[index]?.fi_amount) && errors.applications[index]?.fi_amount}
                                                                        />
                                                                      </Grid>
                                                                    ) 
                                                                    : null
                                                                  }
                                                                </>
                                                              ) 
                                                              : null
                                                            }
                                                            <Grid item xs={1} sm={1} md={1} lg={1}>
                                                              <Button
                                                                type="button"
                                                                onClick={() => arrayHelpers.remove(index)}
                                                              >
                                                                <RemoveCircleOutlineRoundedIcon />
                                                              </Button>
                                                            </Grid>
                                                          </>
                                                        )}
                                                      </Grid>
                                                      <Divider sx={{ my: 2, border: '1px solid green' }} />
                                                    </Box>
                                                  );
                                                }
                                            )
                                            : null
                                          )
                                        }
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Button
                                          type="button"
                                          variant="outlined"
                                          onClick={() => {
                                            arrayHelpers.push({
                                              _id: uuidv4(),
                                              type: "",
                                              product: "",
                                              productList: [
                                                {
                                                  _id: "",
                                                  name: "",
                                                  unitList: [],
                                                },
                                              ],
                                              amount: "",
                                              amount_unit: "",
                                              foliar_interception: "",
                                              foliarInterceptionList: [],
                                              application_method: "",
                                              applicationMethodList: [],
                                              fi_amount: "",
                                            });
                                          }}
                                          startIcon={<AddCircleOutlineRoundedIcon />}
                                        >
                                          Add Application
                                        </Button>
                                      </Grid>
                                    </>
                                  )}
                                />
                              </>
                            ) 
                            : null
                          }
                        </>
                      ) 
                      : null
                    }
                    <Grid item xs={12} display={"flex"} gap={2}></Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Button
                      size="small"
                      onClick={handleClose}
                      variant="outlined"
                      startIcon={<CancelRoundedIcon />}
                      type="reset"
                    >
                      Cancel
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      startIcon={<SaveRoundedIcon />}
                      autoFocus
                      type="submit"
                    >
                      Save
                    </Button>
                  </Box>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
      {
        openProductForm 
        ? (
          <ProductsForm
            openProductForm={openProductForm}
            setOpenProductForm={setOpenProductForm}
            user_id={user.user_icv_id}
            setProductSelected={setProductSelected}
            origin={origin}
          />
        ) : null
      }
      {
        openMachineryForm ? (
          <MachineryForm
            openMachineryForm={openMachineryForm}
            setOpenMachineryForm={setOpenMachineryForm}
            user_id={user.user_icv_id}
            property={property}
            machinerySelected={{}}
            mode='add'
            origin={origin}
            setMachinerySelected={setMachinerySelected}
            setMachineryTransportSelected={setMachineryTransportSelected}
          />
        ) : null
      }
      {
        openImplementForm ? (
          <ImplementForm 
            openImplementForm={openImplementForm}
            setOpenImplementForm={setOpenImplementForm}
            user_id={user.user_icv_id}
            mode={"add"}
            property={property}
            origin={origin}
            setImplementSelected={setImplementSelected}
            setImplementTransportSelected={setImplementTransportSelected}
            implementSelected={implementSelected}
          />
        ) : null
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userAll: {
      loading: state.userAll.loading,
      registro: state.userAll.registro,
      error: state.userAll.error,
    },
    eventTypeCrop: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationEventType,
      error: state.enumerated.error,
    },
    eventOperationType: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationEventOperationType,
      error: state.enumerated.error,
    },
    nameOperation: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationNameOperation,
      error: state.enumerated.error,
    },
    products: {
      loading: state.userAll.loading,
      registros: state.userAll.registro.user_products,
      error: state.userAll.error,
    },
    eventOperationMachinery: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationEventOperationMachinery,
      error: state.enumerated.error,
    },
    eventOperationMachineryImplements: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationEventOperationMachineryImplements,
      error: state.enumerated.error,
    },
    eventGenericTransportMachinery: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationEventGenericTransportMachinery,
      error: state.enumerated.error,
    },
    eventOperationEcoinvent: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationEventOperationEcoinvet,
      error: state.enumerated.error,
    },
    enumerationEventMainOperationProducts: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationEventMainOperationProducts,
      error: state.enumerated.error,
    },
    eventGenericHasTransport: {
      loading: state.enumerated.loading,
      registro: state.enumerated.enumerationEventGenericHasTransport,
      error: state.enumerated.error,
    },
    eventGenericTransportType: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationEventGenericTransportType,
      error: state.enumerated.error,
    },
    loadingEnumeration: state.enumerated.loading,
    eventForEdit: {
      loading: state.userAll.eventForEdit.loading,
      registro: state.userAll.eventForEdit.registro,
      error: state.userAll.eventForEdit.error,
    },
    eventGenericTransportImplements: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationEventGenericTransportImplements,
      error: state.enumerated.error,
    },
    eventMainOperationProducts:{
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationEventMainOperationProducts,
      error: state.enumerated.error,
    },
    eventApplicationUnit: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationEventApplicationUnit,
      error: state.enumerated.error,
    }
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEnumerationEventOperationType: (event_type) => dispatch(fetchEnumerationEventOperationType(event_type)),
    fetchEnumerationEventTypeCrop: (params) => dispatch(fetchEnumerationEventTypeCrop(params)),
    fetchEnumerationNameOperation: (params) => dispatch(fetchEnumerationNameOperation(params)),
    fetchEnumerationEventApplicationType: (params) => dispatch(fetchEnumerationEventApplicationType(params)),
    fetchEnumerationEventApplicationUnit: (prod_id) => dispatch(fetchEnumerationEventApplicationUnit(prod_id)),
    fetchEnumeracionEventOperationMachinery: (par) => dispatch(fetchEnumeracionEventOperationMachinery(par)),
    fetchEnumerationEventOperationMachineryImplements: (par) => dispatch(fetchEnumerationEventOperationMachineryImplements(par)),
    fetchEnumerationEventOperationEcoinvet: (params) => dispatch(fetchEnumerationEventOperationEcoinvet(params)),
    addLandEvent: (params) => dispatch(addLandEvent(params)),
    fetchEnumerationEventMainOperationProducts: (params) => dispatch(fetchEnumerationEventMainOperationProducts(params)),
    fetchEnumerationEventGenericHasTransport: (op_name) => dispatch(fetchEnumerationEventGenericHasTransport(op_name)),
    fetchEnumerationEventGenericTransportType: (op_name) => dispatch(fetchEnumerationEventGenericTransportType(op_name)),
    fetchEnumerationEventGenericTransportMachinery: (params) => dispatch(fetchEnumerationEventGenericTransportMachinery(params)),
    fetchEnumerationEventGenericTransportImplements: (params) => dispatch(fetchEnumerationEventGenericTransportImplements(params)),
    fetchUserAll: (user_id) => dispatch(fetchUserAll(user_id)),
    resetEnumerationEventOperationMachineryImplements: () => dispatch(resetEnumerationEventOperationMachineryImplements()),
    editLandEvent: (params) => dispatch(editLandEvent(params)),
    fetchEventForEdition: (params) => dispatch(fetchEventForEdition(params)),
    fetchEditEventData: (params) => dispatch(fetchEditEventData(params)),
    checkEventGenericHasMachinery: (op_name) => dispatch(checkEventGenericHasMachinery(op_name)),
    checkEventTransportHasMachinery: (params) => dispatch(checkEventTransportHasMachinery(params)),
    fetchEnumerationPesticideFoliarInterception: (params) => dispatch(fetchEnumerationPesticideFoliarInterception(params)),
    fetchEnumerationPesticideApplicationMethods: (params) => dispatch(fetchEnumerationPesticideApplicationMethods(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlotEventForm);