import { Box, Collapse, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, tableCellClasses } from '@mui/material'
import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { formatNumberFloat } from '../../../hooks/functions';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.common.white,
        fontSize: 12,
        lineHeight: 1.2,
        padding: '6px',
        opacity: 0.9
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
        lineHeight: 1.2,
        padding: '6px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        //border: 0,
    },
    '&:MuiTableCell-root': {
        lineHeight: 1.0,
    }
}));

function Applications(props) {
    const { rows } = props

    const RowsApplications = (props) => {
        const { row, index } = props
        const [openDetailsProduct, setOpenDetailsProduct] = useState(false)

        return (
            <>
                <StyledTableRow>
                    <StyledTableCell>{index + 1}</StyledTableCell>
                    <StyledTableCell>{row?.type || ''}</StyledTableCell>
                    <StyledTableCell>{row?.product.name || ''}</StyledTableCell>
                    <StyledTableCell>
                        <IconButton
                            size="small"
                            onClick={() => setOpenDetailsProduct(!openDetailsProduct)}
                        >
                            {openDetailsProduct ? <KeyboardArrowUpIcon sx={{ fontSize: 15 }} /> : <KeyboardArrowDownIcon sx={{ fontSize: 15 }} />}
                        </IconButton>
                    </StyledTableCell>
                    <StyledTableCell>{formatNumberFloat(row?.amount) || ''}</StyledTableCell>
                    <StyledTableCell>{row?.amount_unit || ''}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow style={{ paddingBottom: 0, paddingTop: 0 }} >
                    <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                        <Collapse in={openDetailsProduct} timeout={'auto'} sx={{ p: 2 }} unmountOnExit>
                            <Grid container>
                                <Grid item md={12}>
                                    <Grid item sx={{ fontWeight: 'bold'}}>Agricultural Input</Grid>
                                    <Grid item sx={{mb:2}}>
                                        <Box sx={{ fontSize: 12, lineHeight: 1.2 }}>
                                            {row.product.name && (<Box><strong>Name: </strong>{row.product.name}</Box>)}
                                            {row.product.main_type && (<Box><strong>Type: </strong>{row.product.main_type}</Box>)}
                                            {row.product.type && (<Box><strong>Specific Type: </strong>{row.product.type}</Box>)}
                                            {row.product.dens && (<Box><strong>Density: </strong>{row.product.dens}{' kg/l'}</Box>)}
                                            {row.product.N && (<Box><strong>N Concentration: </strong>{row.product.N}{' Kg N/Kg'}</Box>)}
                                            {row.product.P && (<Box><strong>P Concentration: </strong>{row.product.P}{' Kg P/Kg'}</Box>)}
                                            {row.product.K && (<Box><strong>K Concentration: </strong>{row.product.K}{' Kg K/Kg'}</Box>)}
                                            {row.foliar_interception && (<Box><strong>Foliar interception: </strong>{row.foliar_interception}</Box>)}
                                            {row.fi_amount && (<Box><strong>Foliar interception amount: </strong>{row.fi_amount}</Box>)}
                                            {row.application_method && (<Box><strong>Application method: </strong>{row.application_method}</Box>)}
                                        </Box>
                                    </Grid>
                                </Grid>
                                {
                                    row.product.heavy_metal_content ? (
                                        <Grid xs={12} md={5} item>
                                            <Grid item sx={{ fontWeight: 'bold' }}>Heavy Metal</Grid>
                                            <Box sx={{ fontSize: 12, lineHeight: 1.2, mb:2 }}>
                                                {
                                                    Object.entries(row.product.heavy_metal_content)
                                                        .map(([key, val]) => (
                                                            <Box key={key}><strong>{key} content: </strong>{val} {`Kg ${key}/kg`}</Box>
                                                        ))
                                                }
                                            </Box>
                                        </Grid>
                                    ) : null
                                }
                                {
                                    row.product.source ? (
                                        <Grid item xs={12}>
                                            <Grid item sx={{ fontWeight: 'bold', mt:2 }}>Source</Grid>
                                            {
                                                Object.entries(row.product.source)
                                                    .map(([key, val]) => (
                                                        <Box key={key}><strong>Source of {key}: </strong>{val}</Box>
                                                    ))
                                            }
                                        </Grid>
                                    ) : null
                                }
                                {
                                    row.product.active_principles && row.product.active_principles.length > 0 && (
                                        <Grid xs={12} md={12} item sx={{mt:2}}>
                                            <TableContainer component={Paper} >
                                                <Table size="small" aria-label="a dense table">
                                                    <TableHead sx={{}}>
                                                        <StyledTableRow>
                                                            <StyledTableCell align='center' colSpan={7}>Active Principles</StyledTableCell>
                                                        </StyledTableRow>
                                                        <StyledTableRow sx={{ fontSize: '10px', fontWeight: 600 }}>
                                                            <StyledTableCell sx={{ fontSize: '10px', fontWeight: 600 }}>#</StyledTableCell>
                                                            <StyledTableCell sx={{ fontSize: '10px', fontWeight: 600 }}>Name</StyledTableCell>
                                                            <StyledTableCell sx={{ fontSize: '10px', fontWeight: 600 }} align="right">Amount</StyledTableCell>
                                                            <StyledTableCell sx={{ fontSize: '10px', fontWeight: 600 }} align="right">Unit</StyledTableCell>
                                                        </StyledTableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        
                                                        {
                                                            row.product.active_principles && row.product.active_principles.map((row, index) => (
                                                                <StyledTableRow
                                                                    key={index}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                >
                                                                    <StyledTableCell component="th" scope="row">
                                                                        {index + 1}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell>{row.name}</StyledTableCell>
                                                                    <StyledTableCell align="right">{row.amount}</StyledTableCell>
                                                                    <StyledTableCell align="right">{row.unit}</StyledTableCell>
                                                                </StyledTableRow>
                                                            ))
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Collapse>
                    </StyledTableCell>
                </StyledTableRow>
            </>
        )
    }

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: '100%' }} >
                <TableHead>
                    <StyledTableRow>
                        <StyledTableCell align='center' colSpan={7}>Applications</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <StyledTableCell>#</StyledTableCell>
                        <StyledTableCell>Type</StyledTableCell>
                        <StyledTableCell>Agricultural Inputs</StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell>Amount</StyledTableCell>
                        <StyledTableCell>Unit</StyledTableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {
                        rows && rows.map((row, index) => (
                            <RowsApplications row={row} key={index} index={index} />
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default Applications