import React from 'react'
import ShowDataContainer from '../../layout/ShowDataContainer'
import { Box, Typography } from '@mui/material'
import { formatNumberFloat } from '../../../hooks/functions'
import Machinery from '../showData/Machinery'

function ShowOperation(props) {

  const {item}= props

  return (
    <ShowDataContainer>
      <Typography variant="subtitle2" sx={{width: "33%", flexShrink: 0, fontWeight: 600,}}>
        Main Operation
      </Typography>
      <Box>
        {item.name ? (<Box>Name:{" "}{item.name}</Box>) : null}
        {item.ecoinvent ? (<Box>Ecoinvent name:{" "}{item.ecoinvent}</Box>) : null}
        {item.type ? (<Box>Type:{" "}{item.type}</Box>) : null}
        {item.amount ? (<Box>Amount:{" "}{formatNumberFloat(item.amount)}</Box>) : null}
      </Box>
      {
        item.machinery && item.machinery.length !== 0 
        ? (
          <Box>
            <Machinery rows={item.machinery} />
          </Box>
        )
        : null
      }
    </ShowDataContainer>
  )
}

export default ShowOperation
