import { Box, Divider, Grid, Typography } from '@mui/material'
//import { useState } from 'react'

function HeaderProperty(props) {
    const { property, land } = props
    const user = JSON.parse(localStorage.getItem('user')) || {}
    return (
        <Box sx={{ width:'100%'}}>
            <Grid container  >

                <Grid item xs={12} sm={6} md={6} lg={6}>
                    {user && <Typography variant="subtitle2" >User: {user.email}</Typography>}
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ display: 'flex', justifyContent: { sm: 'start', md: 'end' } }}>
                    {
                        land &&
                        <Typography variant="subtitle2"
                            sx={{
                                whiteSpace: 'nowrap',
                                //width: '50%',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            Land: {land.name}
                        </Typography>}
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                    {
                        (property?._id || property?.id) &&
                        <Typography variant="subtitle2"
                            sx={{
                                whiteSpace: 'nowrap',
                                //width: '50%',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',

                            }}
                        >
                            
                            Property: {property?.name || property?.id}
                        </Typography>
                    }
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ display: 'flex', justifyContent: { sx: 'start', sm: 'end' } }}>
                    {land && <Typography variant="subtitle2" >Area: {land.area} ha</Typography>}
                </Grid>
            </Grid>
            <Divider />

        </Box>
    )
}

export default HeaderProperty