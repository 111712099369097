import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { deleteProduct, fetchUserAll } from "../../redux/userAllSlice";
import { objetoVazio } from "../../hooks/functions";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import HeaderProperty from "../layout/HeaderProperty";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ConfirmDialog from "../layout/ConfirmDialog";
import ProductsForm from "./ProductsForm";
import ProductsFormEdit from "./ProductsFormEdit";
import Loading from '../layout/Loading'
import TitlePage from '../layout/TitlePage';
import ShowSummary from '../layout/ShowSummary';
import ShowSummaryActions from '../layout/ShowSummaryActions'
import ShowRegister from './ShowRegister';
import ShowSource from './ShowSource';
import ShowHeavyMetalContent from './ShowHeavyMetalContent';
import ShowActivePrinciples from './ShowActivePrinciples';
import ButtonEdit from '../layout/ButtonEdit'
import SnackbarCustom from '../layout/SnackbarCustom';

const Products = (props) => {
  
  const { userAll, fetchUserAll, deleteProduct } = props;
  const [prop_products, setProp_products] = useState([]);
  const [openProductForm, setOpenProductForm] = useState(false);
  const [openProductFormEdit, setOpenProductFormEdit] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [productSelected, setProductSelected] = useState({});
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [openNoDelete, setOpenNoDelete] = useState(false)
  const [msgErroNoDelete, setMsgErroNoDelete] = useState("")

  const property = JSON.parse(localStorage.getItem("property"));
  const user = JSON.parse(localStorage.getItem("user"));
  
  useEffect(() => {

    const getAllUser = async () => {
      if(objetoVazio(userAll.registro)){
        await fetchUserAll(user.user_icv_id)
      }
    }
    getAllUser()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getProducts = async () => {
      if (userAll.registro.properties) {
        const indexProp = userAll.registro.properties.findIndex((reg) => reg._id === property._id);
        await setProp_products(userAll.registro.properties[indexProp].prop_products)
      }
    }
    getProducts()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAll]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleOpenProductForm = () => {
    setOpenProductForm(true);
  };

  const handleAdd = () => {
    handleOpenProductForm();
  };

  const handleOpenProductFormEdit = () => {
    setOpenProductFormEdit(true);
  };

  const handleDeleteProduct = async (item) => {
    const params = {
      user_id: user.user_icv_id,
      prop_id: property._id,
      prod_id: item._id,
    };
    const result = await deleteProduct(params)
    // Quando deletar um maquinário, implemento ou produto, se ele estiver sendo usado por um 
    // evento, vai gerar um erro “409 Conflict”. A mensagem deste erro deve ser exposta ao 
    // usuário.
    if(!!result.payload.error){
      setMsgErroNoDelete(result.payload.error)
      setOpenNoDelete(true)
    }
  };

  const handleEdit = (item) => {
    setProductSelected(item);
    handleOpenProductFormEdit();
  };

  return (
    <>
      <Box sx={{ width: { xs: 340, sm: 500, md: 800 } }}>
        <HeaderProperty property={property} />

        {
          userAll.loading 
          ? (
            <Loading />
          ) 
          : (
            <>
              <TitlePage 
                handleAdd={handleAdd} 
                title={'Agricultural Inputs List'} 
                info={
                  <Typography component={'span'}>
                    The <strong>Agricultural Inputs</strong> section encompasses all products utilized 
                    as inputs within the <strong>Property</strong>, covering a range of items such as 
                    water, fertilizers, soil correctives, and pesticides. It's important to note that, 
                    unfortunately, inoculants, adjuvants, and lubricants are not currently available 
                    within ICVCalc for inventory purposes.<br/><br/>

                    The information stored in the <strong>Agricultural Inputs</strong> section will 
                    contribute to the inventory calculations only when a specific 
                    <strong> Agricultural Input</strong> is employed in an <strong>application 
                    Event</strong>. For a more comprehensive understanding of the role of 
                    <strong> Agricultural Inputs</strong> in the broader context of 
                    <strong> Events</strong>, please consult the <a href="/icvcalc/help" 
                    style={{ cursor: 'pointer' }}><strong>Help</strong></a>{' '} tab. 
                    This section provides additional details on <strong>Events</strong> and their 
                    significance in the overall inventory calculation process.
                  </Typography>
                }
              />

              <Box sx={{ paddingBottom: "100px" }}>
                {
                  prop_products
                  .slice()
                  .sort((a,b) => a.name.toUpperCase() < b.name.toUpperCase() ? -1: 1)
                  .map((item, index) => (
                    <Accordion
                      key={index}
                      expanded={expanded === `panel${index}`}
                      onChange={handleChange(`panel${index}`)}
                      elevation={2}
                    >
                      
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <ShowSummary 
                          title={item.name} 
                          subtitle={`${item.main_type} ${item.type || ''}`}
                        />

                        <ShowSummaryActions 
                          item={item} 
                          setSelected={setProductSelected} 
                          setConfirmOpen={setConfirmOpen}
                        />
                        {
                          item._id === productSelected._id 
                          ? (
                            <ConfirmDialog
                              name="Delete Agricultural Input"
                              open={confirmOpen}
                              setOpen={setConfirmOpen}
                              onConfirm={() => handleDeleteProduct(item)}
                              title={`${item.name} ${item?.main_type}`}
                              subtitulo={`{item.type}`}
                            >
                            </ConfirmDialog>
                          ) 
                          : null
                        }
                      </AccordionSummary>
                      
                      <AccordionDetails>
                        <Grid container spacing={2} display={"flex"}>
                          
                          <Grid item xs={6}>
                            <ShowRegister item={item}/>
                          </Grid>

                          {
                            !objetoVazio(item.source) 
                            ? (
                              <Grid item xs={6}>
                                <ShowSource item={item} />
                              </Grid>
                            ) 
                            : null
                          }

                          {
                            !objetoVazio(item.heavy_metal_content) 
                            ? (
                              <Grid item xs={6}>
                                <ShowHeavyMetalContent item={item}/>
                              </Grid>
                            ) 
                            : null
                          }

                          {
                            item.active_principles && item.active_principles.length > 0 
                            ? (
                              <Grid item xs={6}>
                                <ShowActivePrinciples item={item} />
                              </Grid>
                            ) 
                            : null
                          }
                        </Grid>
                        <ButtonEdit item={item} handleEdit={handleEdit}/>
                      </AccordionDetails>

                      {
                        openProductFormEdit && productSelected._id === item._id && (
                          <ProductsFormEdit
                            openProductFormEdit={openProductFormEdit}
                            setOpenProductFormEdit={setOpenProductFormEdit}
                            user_id={user.user_icv_id}
                            productSelected={productSelected}
                            property={property}
                          />
                        )
                      }
                    </Accordion>
                  ))
                }
              </Box>

              <Box sx={{ pb: "100px" }}></Box>
            </>
          )
        }
      </Box>
      {
        openProductForm 
        ? (
          <ProductsForm
            openProductForm={openProductForm}
            setOpenProductForm={setOpenProductForm}
            user_id={user.user_icv_id}
            property={property}
          />
        ) : null
      }

      {
        openNoDelete
        ? (
          <SnackbarCustom
            open={openNoDelete}
            setOpen={setOpenNoDelete}
            msg={msgErroNoDelete}
          />
        )
        : null
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userAll: {
      loading: state.userAll.loading,
      registro: state.userAll.registro,
      error: state.userAll.error,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserAll: (user_id) => dispatch(fetchUserAll(user_id)),
    deleteProduct: (params) => dispatch(deleteProduct(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
