import { Box, Collapse, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, tableCellClasses } from '@mui/material'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { styled } from '@mui/material/styles';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.common.white,
        fontSize: 12,
        lineHeight: 1.2,
        padding: '6px',
        opacity: 0.9
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
        lineHeight: 1.2,
        padding: '6px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        //border: 0,
    },
    '&:MuiTableCell-root': {
        lineHeight: 1.0,
    }
}));

export const Transports = (props) => {
    const { rows } = props

    const RowsTransports = (props) => {
        const { row, index } = props
        const [openDetailsProduct, setOpenDetailsProduct] = useState(false)
        return (
            <>
                <StyledTableRow>
                    <StyledTableCell>{index + 1}</StyledTableCell>
                    <StyledTableCell>{row.type}</StyledTableCell>
                    <StyledTableCell>{row.name}</StyledTableCell>
                    <StyledTableCell>
                        {
                            row.machinery.length !== 0 ? (
                                <>
                                    {row.machinery[0]?.machine_name}
                                    <IconButton
                                        size="small"
                                        onClick={() => setOpenDetailsProduct(!openDetailsProduct)}
                                    >
                                        {openDetailsProduct ? <KeyboardArrowUpIcon sx={{ fontSize: 15 }} /> : <KeyboardArrowDownIcon sx={{ fontSize: 15 }} />}
                                    </IconButton>
                                </>
                            ) : null
                        }

                    </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow style={{ paddingBottom: 0, paddingTop: 0 }} >
                    <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                        <Collapse in={openDetailsProduct} timeout={'auto'} sx={{ p: 2 }} unmountOnExit>
                            <Grid container>
                                {
                                    row.machinery.length !== 0 ? (
                                        <>
                                            <Grid md={6} item >
                                                <Box sx={{ fontWeight: 'bold' }}>Machinery</Box>
                                                <Box sx={{ fontSize: 12, lineHeight: 1.2 }}>
                                                    {row.machinery[0].lifespan && (<Box>Lifespan: {row.machinery[0].lifespan}</Box>)}
                                                    {row.machinery[0].tset_lifespan && (<Box>Tireset Lifespan: {row.machinery[0].tset_lifespan}</Box>)}
                                                    {row.machinery[0].tset_rubbed_off && (<Box>Rubber Removed: {row.machinery[0].tset_rubbed_off}</Box>)}
                                                    {row.machinery[0].tset_weight && (<Box>Tireset Weight: {row.machinery[0].tset_weight}</Box>)}
                                                    {row.machinery[0].weight && (<Box>Weight: {row.machinery[0].weight}</Box>)}
                                                    {row.machinery[0].fuel_cons && (<Box>Fuel Consumption: {row.machinery[0].fuel_cons}</Box>)}
                                                    {row.machinery[0].energy_cons && (<Box>Energy Consumption: {row.machinery[0].energy_cons}</Box>)}
                                                    {row.machinery[0].power_type && (<Box>Power Type: {row.machinery[0].power_type}</Box>)}
                                                    {row.machinery[0].operating_yield && (<Box>Operating Yield: {row.machinery[0].operating_yield}</Box>)}
                                                    {row.machinery[0].power && (<Box>Power: {row.machinery[0].power}</Box>)}
                                                </Box>
                                            </Grid>
                                        </>
                                    ) : null
                                }
                                
                                {
                                    row.machinery.length > 0 && row.machinery[0].fuel && (
                                        <Grid item md={6}>
                                            <Grid item sx={{ fontWeight: 'bold' }}>Fuel</Grid>
                                            <Grid item>
                                                <Box sx={{ fontSize: 12, lineHeight: 1.2 }}>
                                                    {row.machinery[0].fuel.type && (<Box>Type: {row.machinery[0].fuel.type}</Box>)}
                                                    {row.machinery[0].fuel.diesel_dens && (<Box>Diesel Density: {row.machinery[0].fuel?.diesel_dens}</Box>)}
                                                    {row.machinery[0].fuel.biodiesel_perc && (<Box>Biodiesel Percentage: {row.machinery[0].fuel?.biodiesel_perc}</Box>)}
                                                    {row.machinery[0].fuel.biodiesel_dens && (<Box>Biodiesel Density: {row.machinery[0].fuel?.biodiesel_dens}</Box>)}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Collapse>
                    </StyledTableCell>
                </StyledTableRow>
            </>
        )
    }


    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: '100%' }} >
                <TableHead>
                    <StyledTableRow>
                        <StyledTableCell align='center' colSpan={7} >Additional Transports</StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                        <StyledTableCell>#</StyledTableCell>
                        <StyledTableCell>Type</StyledTableCell>
                        <StyledTableCell>Name</StyledTableCell>
                        <StyledTableCell>Machinery</StyledTableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {
                        rows && rows.map((row, index) => (
                            <RowsTransports row={row} key={index} index={index} />
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Transports)