import { Box, Typography } from '@mui/material'
import React from 'react'

const ShowRegister = (props) => {

  const {item} = props

  return (
    <Box sx={{ border: "1px solid green", borderLeft: "5px solid green", borderRadius: 2, p: 2, mb: 2,}}>
      <Typography variant="subtitle2" sx={{ width: "33%", flexShrink: 0, fontWeight: 600, }}>
        Register
      </Typography>

      <Typography variant="subtitle2" sx={{ lineHeight: 1.3, fontSize: "0.8rem" }}>
        {item.type ? (<Box><strong>Type: </strong>{item.type}</Box>) : null}
        {item.implement_name ? (<Box><strong>Name: </strong>{item.implement_name}</Box>) : null}
        {item.specific_type ? (<Box><strong>Specific Type: </strong>{item.specific_type}</Box>) : null}
        {item.specific_subtype ? (<Box><strong>Specific Subtype: </strong>{item.specific_subtype}</Box>) : null}
        {item.lifespan ? (<Box><strong>Lifespan: </strong>{item.lifespan}{" h"}</Box>) : null}
        {item.tset_lifespan ? (<Box><strong>Tireset Lifespan: </strong>{item.tset_lifespan}{" h"}</Box>) : null}
        {item.tset_weight ? (<Box><strong>Tireset Weight: </strong>{item.tset_weight}{" kg tireset/kg implement"}</Box>) : null}
        {item.tset_rubbed_off ? (<Box><strong>Rubber Removed: </strong>{item.tset_rubbed_off}{" kg rubber/kg tireset"}</Box>) : null}
        {item.weight ? (<Box><strong>Weight: </strong>{item.weight}{" kg"}</Box>) : null}
      </Typography>

    </Box>
  )
}

export default ShowRegister
