import { Box, Typography } from '@mui/material'
import React from 'react'
import { formatedDate } from '../../../hooks/functions'
import ShowDataContainer from '../../layout/ShowDataContainer'

function ShowEventRegister(props) {

  const {item} = props
  return (
    <ShowDataContainer>
      <Typography variant="subtitle2" sx={{width: "33%", flexShrink: 0, fontWeight: 600,}}>
        Event
      </Typography>
      <Box>
        {item.type ? (<Box><strong>Type:</strong>{" "}{item.type}</Box>) : null}
        {item.date ? (<Box><strong>Date:</strong>{" "}{formatedDate(item.date)}</Box>) : null}
        {item.area_burnt ? (<Box><strong>Area Burning:</strong>{" "}{item.area_burnt}{" "}ha</Box>) : null}
        {item.comb_fuel ? (<Box><strong>Combustion fuel:</strong>{" "}{item.comb_fuel}{" "}kg/ha</Box>) : null}
        {item.water_cons ? (<Box><strong>Water consumed:</strong>{" "}{item.water_cons}{" "}m³</Box>) : null}
        {item.frequency ? (<Box><strong>Frequency of application:</strong>{" "}{item.frequency}{" "}days</Box>) : null}
      </Box>
    </ShowDataContainer>
  )
}

export default ShowEventRegister
