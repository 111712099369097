import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Register from "./register";
import ForgotPassword from "./forgotPassword";
import { authUser } from "../../redux/userSlice";
import { connect } from "react-redux";
import Cover from "./Cover";
import LogoProjeto from "./LogoProjeto";
import Footer from "./Footer";
import ChangePassword from "./changePassword";
import LoginIcon from "@mui/icons-material/Login";
import api from '../../services/api';

function Login(props) {
  const { dispatchUserAuth } = props;
  const navigate = useNavigate();

  const [form, setForm] = useState({ email: null, password: null });
  const [msgErro, setMsgErro] = useState({ email: null, password: null });
  const [openRegister, setOpenRegister] = useState(false);
  const [checkedPrivacyNotice, setCheckedPrivacyNotice] = useState(false);

  function changeForm(e) {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    setMsgErro({ email: null, password: null });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (form.email === "") {
      msgErro.email = "Please provide a valid email";
    }
    if (form.password === "") {
      msgErro.password = "Enter your password";
    }
    const isAuthenticated = await authenticate(form);
    if (!!isAuthenticated) {
      return navigate("/home");
    }
  };

  const authenticate = async (form) => {
    const authenticatedUser = await dispatchUserAuth(form);

    if (authenticatedUser.payload?.error) {
      if (authenticatedUser.payload.error.cod === "001") {
        setMsgErro({
          email: authenticatedUser.payload.error.msg,
          password: null,
        });
      } else if (authenticatedUser.payload.error.cod === "002") {
        setMsgErro({
          email: null,
          password: authenticatedUser.payload.error.msg,
        });
      } else if (authenticatedUser.payload.error.cod === "003") {
        setMsgErro({
          email: authenticatedUser.payload.error.msg,
          password: null,
        });
      }
      return false;
    } else {
      salvarHeaderAuthorization(authenticatedUser.payload?.result.token_api)
      window.localStorage.setItem(
        "user",
        JSON.stringify(authenticatedUser.payload?.result) || ""
      );
      return true;
    }
  };

  const handleChangePrivacyNotice = (event) => {
    setCheckedPrivacyNotice(event.target.checked);
  };

  const salvarHeaderAuthorization = (token) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + token;
  }

  return (
    <>
      <Grid container component="main" sx={{ height: "100vh", width: "100%" }}>
        <Cover />

        <Grid item xxs={12} xs={12} sm={12} md={6} lg={6}
          component={Paper}
          elevation={6}
          square
        >
          <Container>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                //height: "100vh",
              }}
            >
              <Box width={"100%"} mt={1}>
                <Link
                  underline="none"
                  //href="https://www.cnpma.embrapa.br/privacidade/icvcalc/PrivacyNoticeICVCalc_EN.pdf"
                  href="https://www.cnpma.embrapa.br/privacidade/icvcalc/PrivacyNoticeICVCalcEmbrapa_EN.pdf"
                  variant="body2"
                  target="_blank"
                  rel="noreferrer"
                  color="blue"
                >
                  Privacy Notice
                </Link>
                {" | "}
                <Link
                  underline="none"
                  href="https://www.cnpma.embrapa.br/privacidade/icvcalc/TermsUseICVCalcEmbrapa_EN.pdf"
                  variant="body2"
                  target="_blank"
                  rel="noreferrer"
                  color="blue"
                >
                  Terms of Use
                </Link>
              </Box>

              <LogoProjeto />

              <Card
                elevation={5}
                sx={{
                  maxWidth: { xxs: 250, xs: 300, sm: 420 },
                  px: 2,
                  mt: -3,
                  borderRadius: 4,
                  mb: 5,
                  backgroundColor: "#EFF7EF",
                }}
              >
                <Box component="form" noValidate onSubmit={handleSubmit} >
                  <CardContent sx={{py:0, pt:3}}>
                    <TextField
                      size="small"
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="E-mail"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      onChange={changeForm}
                      value={form.email || ""}
                      error={!!msgErro.email}
                      helperText={msgErro.email}
                      InputLabelProps={{ shrink: true }}
                    />

                    <TextField
                      size="small"
                      margin="normal"
                      required
                      fullWidth
                      type="password"
                      id="password"
                      label="Password"
                      name="password"
                      autoComplete="current-password"
                      onChange={changeForm}
                      value={form.password || ""}
                      error={!!msgErro.password}
                      helperText={msgErro.password}
                      InputLabelProps={{ shrink: true }}
                    />
                    <Box>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkedPrivacyNotice}
                              onChange={handleChangePrivacyNotice}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label={
                            <Box
                              sx={{
                                py: 1,
                                fontSize: {
                                  xxs: "10px",
                                  xs: "10px",
                                  md: "12px",
                                },
                              }}
                            >
                              <Typography variant="body2" fontSize={"inherit"}>
                                For the security of your personal data, Embrapa
                                provides the{" "}
                                <Link
                                  underline="none"
                                  href="https://www.cnpma.embrapa.br/privacidade/icvcalc/PrivacyNoticeICVCalcEmbrapa_EN.pdf"
                                  variant="body2"
                                  target="_blank"
                                  rel="noreferrer"
                                  color="blue"
                                  fontSize={"inherit"}
                                  tabIndex={-1}
                                >
                                  Privacy Notice
                                </Link>{" "}
                                and the ICVCalc - Embrapa{" "}
                                <Link
                                  underline="none"
                                  href="https://www.cnpma.embrapa.br/privacidade/icvcalc/TermsUseICVCalcEmbrapa_EN.pdf"
                                  variant="body2"
                                  target="_blank"
                                  rel="noreferrer"
                                  color="blue"
                                  fontSize={"inherit"}
                                  tabIndex={-1}
                                >
                                  Terms of Use
                                </Link>
                                .
                              </Typography>
                              <Typography variant="body2" fontSize={"inherit"}>
                                <strong>
                                  I have read, understood and agree with the
                                  conditions and responsibilities established in
                                  the Terms of Use.
                                </strong>
                              </Typography>
                            </Box>
                          }
                        />
                      </FormGroup>
                    </Box>
                    <Grid container spacing={2}>
                      <Grid item xxs={12} xs={6} >
                        <ChangePassword />
                      </Grid>
                      <Grid item xxs={12} xs={6} >
                        <ForgotPassword email={form.email} />
                      </Grid>
                    </Grid>
                  </CardContent>

                  <CardActions sx={{ pb: 3, px: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xxs={12} xs={6}>
                        <Register
                          openRegister={openRegister}
                          setOpenRegister={setOpenRegister}
                        />
                      </Grid>

                      <Grid item xxs={12} xs={6}>
                        <Button
                          fullWidth
                          type="submit"
                          variant="contained"
                          size="small"
                          //sx={{ minWidth: "150px" }}
                          disabled={
                            !form.email ||
                            !form.password ||
                            !checkedPrivacyNotice
                          }
                          startIcon={<LoginIcon />}
                        >
                          Login
                        </Button>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Box>
              </Card>

              <Footer />
            </Box>
          </Container>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userAuth: {
      loading: state.userAuth.loading,
      userAuth: state.userAuth.user,
      error: state.userAuth.error,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchUserAuth: (formLogin) => dispatch(authUser(formLogin)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
