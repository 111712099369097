import { Box } from '@mui/material'
import React from 'react'
import LogoEmbrapa from '../../components/img/logo-embrapa.png'

function Footer() {
    return (
        <Box
            display={'flex'}
            justifyContent={'center'}
            gap={2}
        >
            <Box

                minWidth={150}
                minHeight={50}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                }}
            >

                <Box
                    component={"img"}
                    alt="Capa ICVCalc"
                    src={LogoEmbrapa}
                    loading="lazy"
                    sx={{ width: 150, }}
                />
            </Box>
        </Box >
    )
}

export default Footer
