import React from 'react'
import ShowDataContainer from '../../layout/ShowDataContainer'
import { Box, Typography } from '@mui/material'

function ShowMetalContent(props) {

  const {itemPlot} = props

  return (
    <ShowDataContainer>
      <Typography variant="subtitle2" sx={{flexShrink: 0, fontWeight: 600,}}>
        METAL CONTENT
      </Typography>
      {
        itemPlot.crop?.dry_mass_heavy_metal_content 
        ? (
          <Box>
            {
              Object.entries(itemPlot.crop.dry_mass_heavy_metal_content).map(([key, val]) => (
                <Box key={key}><strong>{key} content: </strong>{val} {`kg ${key}/kg DM`}</Box>
              ))
            }
          </Box>
        ) 
        : null
      }
    </ShowDataContainer>
  )
}

export default ShowMetalContent
