import { Box } from '@mui/material'
import React from 'react'
import NavBarHorizontal from './NavBarHorizontal'

function Header() {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '10vh',
            }}
        >
            <NavBarHorizontal />
            
        </Box>
    )
}

export default Header